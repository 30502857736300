import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from 'styled-components'
import get from 'lodash.get'

import getProductPage from '../utils/getProductPage'

import Navbar from '../components/Navbar'
import SubNavbar from '../components/SubNavbar'
import Footer from '../components/Footer'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import TextWithCodeExample from '../components/TextWithCodeExample'
import TextWithImage from '../components/TextWithImage'
import CTABanner from '../components/CTABanner'
import CTASection from '../components/CTASection'
import Features from '../components/Features'
import UseCases from '../components/UseCases'
import Customers from '../components/Customers'
import Testimonial from '../components/Testimonial'
import Testimonials from '../components/Testimonials'
import BannerWithBoxes from '../components/BannerWithBoxes'
import LinkBoxes from '../components/LinkBoxes'
import FAQs from '../components/FAQs'
import Libraries from '../components/Libraries'
import FeaturesSmall from '../components/FeaturesSmall'
import PricingTableV4 from '../components/PricingTableV4'
import PricingOverview from '../components/PricingOverview'
import CustomerStory from '../components/CustomerStory'
import Text from '../components/Text'
import MarketingPixels from '../components/MarketingPixels'
import CURLDemo from '../components/CURLDemo'
import FeaturesWithInfoBox from '../components/FeaturesWithInfoBox'
import CaseStudy from '../components/CaseStudy'
import CaseStudyCarousel from '../components/CaseStudyCarousel'
import BlogLinks from '../components/BlogLinks'
import Videos from '../components/Videos'
import Glossary from '../components/Glossary'
import Steps from '../components/Steps'
import Categories from '../components/Categories'
import ScriptEmbed from '../components/ScriptEmbed'

import useUtmTags from '../hooks/useUtmTags'

import HighlightStyle from '../styles/HighlightStyle'
import ParallaxIllustrations from '../styles/ParallaxIllustrations'

const PreviewPlaceholder = styled.div`
  width: 100%;
  height: 0px !important;
`

const Page = ({ data, location, pageContext }) => {
  const utmTags = useUtmTags({ location })
  const { utm_source, utm_medium, utm_campaign, utm_term } = utmTags

  if (typeof localStorage !== 'undefined') {
    if (utm_source || utm_medium || utm_campaign || utm_term) {
      localStorage.setItem('campaign_meta', JSON.stringify({ utm_source, utm_medium, utm_campaign, utm_term }))
    }
  }

  const page = data?.prismicPage?.data
  const body = page?.body
  const hideNavbarNavigation = get(page, 'hide_navbar_navigation')
  const hideFooterNavigation = get(page, 'hide_footer_navigation')

  const global = data?.allPrismicGlobal?.nodes?.[0]?.data
  const showHiringLabel = global?.show_hiring_label

  const { pathname } = location
  const productPage = getProductPage(pathname)

  return (
    <>
      <HighlightStyle productPage={productPage} />
      <Navbar pathname={pathname} productPage={productPage} hideNavigation={hideNavbarNavigation} global={global} />
      {productPage && !hideNavbarNavigation && <SubNavbar productPage={productPage} pathname={pathname} />}
      <SEO
        title={get(page, 'meta_title')}
        description={get(page, 'meta_description')}
        image={get(page, 'meta_image.url')}
      />
      <PreviewPlaceholder />
      <ParallaxIllustrations pathname={pathname} />
      {body &&
        body.map((slice, index) => {
          const type = slice?.__typename
          const primary = slice?.primary
          const items = slice?.items
          if (type === 'PrismicPageDataBodyHeroWithCodeExample') {
            return (
              <Hero
                key={index}
                heading={primary.heading}
                headingSize={primary.heading_size}
                features={items}
                subheading={primary.description}
                subheadingMobile={primary.description_mobile}
                additionalDescription={primary.additional_description}
                button1Text={primary.button_1_text}
                button1Link={primary.button_1_link}
                button1AnchorLink={primary.button_1_anchor_link}
                button2Text={primary.button_2_text}
                button2Link={primary.button_2_link}
                codeExample={primary.code_example}
                codeExample2={primary.code_example_2}
                codeExampleContainer={primary.code_example_container}
                align={primary.align}
                position={primary.position}
                backgroundColor={primary.background_color}
                formattedExamples={pageContext?.codeExamples}
                formattedExamples1={pageContext.codeExamples.find(
                  (example) => example.main_heading === get(primary, 'code_example.document.data.main_heading')
                )}
                formattedExamples2={pageContext.codeExamples.find(
                  (example) => example.main_heading === get(primary, 'code_example_2.document.data.main_heading')
                )}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyHeroWithImage') {
            return (
              <Hero
                key={index}
                title={primary.title}
                heading={primary.heading}
                headingSize={primary.heading_size}
                features={items}
                subheading={primary.description}
                button1Text={primary.button_1_text}
                button1Link={primary.button_1_link}
                button2Text={primary.button_2_text}
                button2Link={primary.button_2_link}
                image={primary.image?.gatsbyImageData}
                lottieAnimation={primary.lottie_animation}
                backgroundColor={primary.background_color}
                goBackText={primary.go_back_text}
                goBackLink={primary.go_back_link}
                isLargeImage={primary.is_large_image}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyTextWithCodeExample') {
            return (
              <TextWithCodeExample
                key={index}
                heading={primary.heading}
                description={primary.description}
                features={items}
                codeExample={primary.code_example}
                codeExamplePosition={primary.code_example_position}
                productPage={productPage}
                formattedExamples={pageContext.codeExamples.find(
                  (example) => example.main_heading === get(primary, 'code_example.document.data.main_heading')
                )}
              />
            )
          } else if (type === 'PrismicPageDataBodyTextWithImage') {
            return (
              <TextWithImage
                key={index}
                heading={primary.heading}
                headingTag={primary.heading_tag}
                description={primary.description}
                text={primary.text}
                imagePosition={primary.image_position}
                image={primary.image?.gatsbyImageData}
                lottieAnimation={primary.lottie_animation}
                videoUrl={primary.video_url}
                button1Text={primary.button_1_text}
                button1Link={primary.button_1_link}
                button2Text={primary.button_2_text}
                button2Link={primary.button_2_link}
                backgroundColor={primary.background_color}
                features={items}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyCtaBanner') {
            return (
              <CTABanner
                key={index}
                version={primary.version}
                heading={primary.heading}
                paragraph={primary.paragraph}
                buttonText={primary.button_text}
                link={primary.link}
                backgroundColor={primary.background_color}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyCtaSection') {
            return (
              <CTASection
                key={index}
                version={primary.version}
                heading={primary.heading}
                headingSize={primary.heading_size}
                description={primary.description}
                buttons={items}
                buttonType={primary.button_type}
                backgroundColor={primary.background_color}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyFeatures') {
            return (
              <Features
                key={index}
                version={primary.version}
                heading={primary.heading}
                backgroundColor={primary.background_color}
                buttonText={primary.button_text}
                buttonLink={primary.button_link}
                features={items}
                productPage={productPage}
                align={pathname === '/serverless-functions/' ? 'center' : 'left'}
              />
            )
          } else if (type === 'PrismicPageDataBodyUseCases') {
            return (
              <UseCases
                key={index}
                version={primary.version}
                heading={primary.heading}
                headingSize={primary.heading_size}
                subheading={primary.subheading}
                numberOfItemsPerRow={primary.number_of_items_per_row}
                button1Text={primary.button_1_text}
                button1Link={primary.button_1_link}
                button2Text={primary.button_2_text}
                button2Link={primary.button_2_link}
                items={items}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyCustomers') {
            return (
              <Customers
                key={index}
                version={primary.version}
                heading={primary.heading}
                paragraph={primary.paragraph}
                paragraphSize={primary.paragraph_size}
                backgroundColor={primary.background_color}
                buttonText={primary.button_text}
                buttonLink={primary.button_link}
                logos={items}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyTestimonial') {
            return (
              <Testimonial
                key={index}
                version={primary.version}
                backgroundColor={primary.background_color}
                image={primary.image?.gatsbyImageData}
                quote={primary.quote}
                name={primary.name}
                details={primary.details}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyTestimonials') {
            return <Testimonials key={index} items={items} />
          } else if (type === 'PrismicPageDataBodyBannerWithBoxes') {
            return (
              <BannerWithBoxes
                key={index}
                version={primary.version}
                heading={primary.heading}
                subheading={primary.subheading}
                backgroundColor={primary.background_color}
                items={items}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyLinkBoxes') {
            return <LinkBoxes key={index} heading={primary.heading} boxColor={primary.box_color} items={items} />
          } else if (type === 'PrismicPageDataBodyFaqs') {
            return (
              <FAQs
                key={index}
                version={primary.version}
                heading={primary.heading}
                items={items}
                align={pathname === '/serverless-functions/' ? 'center' : 'left'}
              />
            )
          } else if (type === 'PrismicPageDataBodyLibraries') {
            return (
              <Libraries
                key={index}
                largeHeading={primary.large_heading}
                smallHeading={primary.small_heading}
                buttonText={primary.button_text}
                link={primary.link}
                version={primary.version}
                row1Heading={primary.row_1_heading}
                row2Heading={primary.row_2_heading}
                additionalText={primary.additional_text}
                items={items}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyFeaturesSmall') {
            return (
              <FeaturesSmall
                key={index}
                version={primary.version}
                itemsPerRow={primary.items_per_row}
                heading={primary.heading}
                description={primary.description}
                items={items}
                theme={primary.theme}
                additionalFeatures={primary.additional_features}
                buttonText={primary.button_text}
                buttonLink={primary.button_link}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyPricingTableV4') {
            return (
              <PricingTableV4
                key={index}
                heading={primary.heading}
                subheading={primary.subheading}
                option1={primary.option_1}
                option1Heading={primary.option_1_heading}
                option1Subheading={primary.option_1_subheading}
                option2={primary.option_2}
                option2Heading={primary.option_2_heading}
                option2Subheading={primary.option_2_subheading}
                option2Features={primary.option_2_features}
                option2Image={primary.option_2_image?.gatsbyImageData}
                option2ButtonText={primary.option_2_button_text}
                option2ButtonLink={primary.option_2_button_link}
                rowHeadings={primary.row_headings}
                items={items}
                ctaBox1Heading={primary.cta_box_1_heading}
                ctaBox1Subheading={primary.cta_box_1_subheading}
                ctaBox1ButtonText={primary.cta_box_1_button_text}
                ctaBox1Link={primary.cta_box_1_link}
                ctaBox2Heading={primary.cta_box_2_heading}
                ctaBox2Subheading={primary.cta_box_2_subheading}
                ctaBox2ButtonText={primary.cta_box_2_button_text}
                ctaBox2Link={primary.cta_box_2_link}
                ctaBox2HeadingForOption2={primary.cta_box_2_heading_for_option_2}
                ctaBox2SubheadingForOption2={primary.cta_box_2_subheading_for_option_2}
                ctaBox2ButtonTextForOption2={primary.cta_box_2_button_text_for_option_2}
                ctaBox2LinkForOption2={primary.cta_box_2_link_for_option_2}
                secondaryTableID={primary.secondary_table_id}
                secondaryTableHeading={primary.secondary_table_heading}
                secondaryTableSubheading={primary.secondary_table_subheading}
                secondaryTableFootnote={primary.secondary_table_footnote}
                secondaryTableRowHeadings={primary.secondary_table_row_headings}
                secondaryTableCtaColumnHeading={primary.secondary_table_cta_column_heading}
                secondaryTableCtaColumnText={primary.secondary_table_cta_column_text}
                secondaryTableCtaColumnButtonText={primary.secondary_table_cta_column_button_text}
                secondaryTableCtaBoxHeading={primary.secondary_table_cta_box_heading}
                secondaryTableCtaBoxButton_text={primary.secondary_table_cta_box_button_text}
                secondaryTableCtaBoxLink={primary.secondary_table_cta_box_link}
                showSecondaryTableOnOption2={primary.show_secondary_table_on_option_2}
                tertiaryTableID={primary.tertiary_table_id}
                tertiaryTableHeading={primary.tertiary_table_heading}
                tertiaryTableSubheading={primary.tertiary_table_subheading}
                tertiaryTableFootnote={primary.tertiary_table_footnote}
                tertiaryTableRowHeadings={primary.tertiary_table_row_headings}
                supportBannerHeading={primary.support_banner_heading}
                supportBannerSubheading={primary.support_banner_subheading}
                supportBannerButtonText={primary.support_banner_button_text}
                supportBannerButtonLink={primary.support_banner_button_link}
                supportBannerText={primary.support_banner_text}
                productPage={productPage}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyPricingOverview') {
            return (
              <PricingOverview
                key={index}
                heading={primary.pricing_heading}
                ctaTitle={primary.cta_title}
                ctaHeading={primary.cta_heading}
                ctaSubheading={primary.cta_subheading}
                ctaButtonText={primary.cta_button_text}
                ctaButtonLink={primary.cta_button_link}
                heading2={primary.heading_2}
                subheading2={primary.subheading_2}
                buttonText={primary.button_text}
                buttonLink={primary.button_link}
                items={items}
              />
            )
          } else if (type === 'PrismicPageDataBodyCustomerStory') {
            return (
              <CustomerStory
                key={index}
                heading={primary.heading}
                description={primary.description}
                image={primary.image?.gatsbyImageData}
                imagePosition={primary.image_position}
                buttonText={primary.button_text}
                buttonLink={primary.button_link}
                items={items}
              />
            )
          } else if (type === 'PrismicPageDataBodyText') {
            return <Text key={index} text={primary.text} items={items} />
          } else if (type === 'PrismicPageDataBodyCurlDemo') {
            return (
              <CURLDemo
                key={index}
                heading={primary.heading}
                buttonText={primary.button_text}
                ctaButtonText={primary.cta_button_text}
                ctaButtonLink={primary.cta_button_link}
                lottieAnimation1={primary.lottie_animation_1}
                title1={primary.title_1}
                body1={primary.body_1}
                lottieAnimation2={primary.lottie_animation_2}
                title2={primary.title_2}
                body2={primary.body_2}
                lottieAnimation3={primary.lottie_animation_3}
                title3={primary.title_3}
                body3={primary.body_3}
                steps={items}
              />
            )
          } else if (type === 'PrismicPageDataBodyFeaturesWithInfoBox') {
            return (
              <FeaturesWithInfoBox
                key={index}
                heading={primary.heading}
                subheading={primary.subheading}
                infoBoxText={primary.info_box_text}
                image={primary.image?.gatsbyImageData}
                backgroundColor={primary.background_color}
                items={items}
                pathname={pathname}
              />
            )
          } else if (type === 'PrismicPageDataBodyCaseStudy') {
            return (
              <CaseStudy
                key={index}
                logo={primary.logo?.url}
                backgroundColor={primary.background_color}
                heading={primary.heading}
                description={primary.description}
                image={primary.image?.gatsbyImageData}
                buttonText={primary.button_text}
                buttonLink={primary.button_link}
                videoUrl={primary.video_url}
                heading2={primary.heading_2}
                description2={primary.description_2}
                buttonText2={primary.button_text_2}
                buttonLink2={primary.button_link_2}
                heading3={primary.heading_3}
                description3={primary.description_3}
                buttonText3={primary.button_text_3}
                buttonLink3={primary.button_link_3}
                image3={primary.image_3?.gatsbyImageData}
                image3Position={primary.image_3_position}
              />
            )
          } else if (type === 'PrismicPageDataBodyCaseStudyCarousel') {
            return (
              <CaseStudyCarousel
                key={index}
                version={primary.version}
                showButtons={primary.show_buttons}
                backgroundColor={primary.background_color}
                items={items}
              />
            )
          } else if (type === 'PrismicPageDataBodyBlogLinks') {
            return (
              <BlogLinks
                key={index}
                heading={primary.heading}
                subheading={primary.subheading}
                backgroundColor={primary.background_color}
                items={items}
              />
            )
          } else if (type === 'PrismicPageDataBodyVideos') {
            return <Videos key={index} heading={primary.heading} items={items} />
          } else if (type === 'PrismicPageDataBodyGlossary') {
            return (
              <Glossary key={index} heading={primary.heading} text={primary.text} items={items} location={location} />
            )
          } else if (type === 'PrismicPageDataBodySteps') {
            return <Steps key={index} items={items} />
          } else if (type === 'PrismicPageDataBodyCategories') {
            return <Categories key={index} heading={primary.heading} items={items} />
          } else if (type === 'PrismicPageDataBodyScriptEmbed') {
            return (
              <ScriptEmbed
                key={index}
                id={primary.script_embed.document.id}
                src={primary.script_embed.document.data.embed_code.text}
              />
            )
          }
        })}
      <MarketingPixels pathname={pathname} />
      <Footer hideNavigation={hideFooterNavigation} showHiringLabel={showHiringLabel} />
    </>
  )
}

export default withPrismicPreview(Page)

export const query = graphql`
  query PageQuery($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        hide_navbar_navigation
        hide_footer_navigation
        body {
          ... on PrismicPageDataBodyHeroWithCodeExample {
            __typename
            primary {
              heading
              heading_size
              description
              description_mobile
              additional_description
              button_1_text
              button_1_link {
                ...Link
              }
              button_1_anchor_link
              button_2_text
              button_2_link {
                ...Link
              }
              align
              position
              background_color
              code_example {
                ...CodeExample
              }
              code_example_2 {
                ...CodeExample
              }
              code_example_container {
                document {
                  ... on PrismicCodeExampleContainer {
                    data {
                      body {
                        ... on PrismicCodeExampleContainerDataBodyCodeBlock {
                          primary {
                            heading
                            button_text
                            button_link {
                              ...Link
                            }
                          }
                          items {
                            code_example {
                              ...CodeExample
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            items {
              feature
            }
          }
          ... on PrismicPageDataBodyHeroWithImage {
            __typename
            primary {
              background_color
              title
              heading
              heading_size
              description
              image {
                gatsbyImageData
              }
              lottie_animation {
                ...Link
              }
              button_1_text
              button_1_link {
                ...Link
              }
              button_2_text
              button_2_link {
                ...Link
              }
              background_color
              go_back_text
              go_back_link {
                ...Link
              }
              is_large_image
            }
            items {
              feature
              heading
              text
            }
          }
          ... on PrismicPageDataBodyTextWithCodeExample {
            __typename
            primary {
              heading
              description
              code_example {
                ...CodeExample
              }
              code_example_position
            }
            items {
              feature
            }
          }
          ... on PrismicPageDataBodyTextWithImage {
            __typename
            primary {
              heading
              heading_tag
              description
              text {
                richText
              }
              image {
                gatsbyImageData
              }
              image_position
              lottie_animation {
                ...Link
              }
              video_url
              button_1_text
              button_1_link {
                ...Link
              }
              button_2_text
              button_2_link {
                ...Link
              }
              background_color
            }
            items {
              feature
            }
          }
          ... on PrismicPageDataBodyCtaBanner {
            __typename
            primary {
              version
              heading
              paragraph
              button_text
              link {
                ...Link
              }
              background_color
            }
          }
          ... on PrismicPageDataBodyCtaSection {
            __typename
            primary {
              version
              heading
              heading_size
              description
              button_type
              background_color
            }
            items {
              button_text
              button_link {
                ...Link
              }
            }
          }
          ... on PrismicPageDataBodyFeatures {
            __typename
            primary {
              version
              heading
              background_color
              button_text
              button_link {
                ...Link
              }
            }
            items {
              icon {
                url
              }
              icon_hover {
                url
              }
              heading
              description
              link_text
              link {
                ...Link
              }
            }
          }
          ... on PrismicPageDataBodyUseCases {
            __typename
            primary {
              version
              heading
              heading_size
              subheading
              number_of_items_per_row
              button_1_text
              button_1_link {
                ...Link
              }
              button_2_text
              button_2_link {
                ...Link
              }
            }
            items {
              image {
                gatsbyImageData
              }
              icon {
                url
              }
              icon_hover {
                url
              }
              heading
              description
              description_list {
                richText
              }
              link_text
              link {
                ...Link
              }
            }
          }
          ... on PrismicPageDataBodyCustomers {
            __typename
            primary {
              version
              heading
              paragraph
              paragraph_size
              background_color
              button_text
              button_link {
                ...Link
              }
            }
            items {
              logo {
                gatsbyImageData
              }
            }
          }
          ... on PrismicPageDataBodyTestimonial {
            __typename
            primary {
              version
              background_color
              details
              name
              quote
              image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicPageDataBodyTestimonials {
            __typename
            items {
              quote
              name
              details
              image {
                url
              }
            }
          }
          ... on PrismicPageDataBodyBannerWithBoxes {
            __typename
            primary {
              version
              heading
              subheading
              background_color
            }
            items {
              heading
              description
              button_text
              link {
                ...Link
              }
              image {
                gatsbyImageData
              }
              icon {
                url
              }
            }
          }
          ... on PrismicPageDataBodyLinkBoxes {
            __typename
            primary {
              heading
              box_color
            }
            items {
              heading
              description
              button_text
              link {
                ...Link
              }
            }
          }
          ... on PrismicPageDataBodyFaqs {
            __typename
            primary {
              version
              heading
            }
            items {
              question
              answer {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyLibraries {
            __typename
            primary {
              large_heading
              small_heading
              button_text
              link {
                ...Link
              }
              version
              row_1_heading
              row_2_heading
              additional_text {
                richText
              }
            }
            items {
              library_icon {
                url
              }
              library_icon_hover {
                url
              }
              link {
                ...Link
              }
              row
            }
          }
          ... on PrismicPageDataBodyFeaturesSmall {
            __typename
            primary {
              version
              items_per_row
              heading
              description
              theme
              additional_features {
                document {
                  ... on PrismicAdditionalFeatures {
                    data {
                      main_heading
                      features {
                        heading
                        description
                        badge_text
                      }
                    }
                  }
                }
              }
              button_text
              button_link {
                ...Link
              }
            }
            items {
              heading
              descriptionJSON: description {
                richText
              }
              icon {
                url
              }
            }
          }
          ... on PrismicPageDataBodyPricingTableV4 {
            __typename
            primary {
              heading
              subheading
              option_1
              option_1_heading
              option_1_subheading
              option_2
              option_2_heading
              option_2_subheading
              option_2_features {
                document {
                  ... on PrismicFeatures {
                    data {
                      features {
                        feature
                      }
                    }
                  }
                }
              }
              option_2_image {
                gatsbyImageData
              }
              option_2_button_text
              option_2_button_link {
                ...Link
              }
              row_headings {
                ...TableRowHeadings
              }
              cta_box_1_heading
              cta_box_1_subheading {
                richText
              }
              cta_box_1_button_text
              cta_box_1_link {
                ...Link
              }
              cta_box_2_heading
              cta_box_2_subheading {
                richText
              }
              cta_box_2_button_text
              cta_box_2_link {
                ...Link
              }
              cta_box_2_heading_for_option_2
              cta_box_2_subheading_for_option_2 {
                richText
              }
              cta_box_2_button_text_for_option_2
              cta_box_2_link_for_option_2 {
                ...Link
              }
              secondary_table_id
              secondary_table_heading
              secondary_table_subheading
              secondary_table_footnote {
                richText
              }
              secondary_table_row_headings {
                ...TableRowHeadings
              }
              secondary_table_cta_column_heading
              secondary_table_cta_column_text
              secondary_table_cta_column_button_text
              secondary_table_cta_box_heading
              secondary_table_cta_box_button_text
              secondary_table_cta_box_link {
                ...Link
              }
              show_secondary_table_on_option_2
              tertiary_table_id
              tertiary_table_heading
              tertiary_table_subheading
              tertiary_table_footnote {
                richText
              }
              tertiary_table_row_headings {
                ...TableRowHeadings
              }
              support_banner_heading
              support_banner_subheading
              support_banner_button_text
              support_banner_button_link {
                ...Link
              }
              support_banner_text {
                richText
              }
            }
            items {
              pricing_plan_v3: pricing_plan {
                document {
                  ... on PrismicPricingPlanV3 {
                    data {
                      plan_name
                      price {
                        richText
                      }
                      button_text
                      button_link {
                        ...Link
                      }
                      additional_text
                      fields {
                        type_of_value
                        value {
                          richText
                        }
                        additional_information
                        link_to_support_packages
                      }
                    }
                  }
                }
              }
              table
            }
          }
          ... on PrismicPageDataBodyPricingOverview {
            __typename
            primary {
              pricing_heading {
                richText
              }
              cta_title
              cta_heading
              cta_subheading
              cta_button_text
              cta_button_link {
                ...Link
              }
              heading_2
              subheading_2
              button_text
              button_link {
                ...Link
              }
            }
            items {
              plan {
                document {
                  ... on PrismicPricingPlanV3 {
                    data {
                      plan_name
                      price {
                        richText
                      }
                      price_alternate {
                        richText
                      }
                      button_text
                      button_link {
                        ...Link
                      }
                      features {
                        feature
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyCustomerStory {
            __typename
            primary {
              heading
              description
              image {
                gatsbyImageData
              }
              image_position
              button_text
              button_link {
                ...Link
              }
            }
            items {
              heading
              text
            }
          }
          ... on PrismicPageDataBodyText {
            __typename
            primary {
              text {
                richText
              }
            }
            items {
              table_left {
                richText
              }
              table_right {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyCurlDemo {
            __typename
            primary {
              heading
              button_text
              cta_button_text
              cta_button_link {
                ...Link
              }
              lottie_animation_1 {
                ...Link
              }
              title_1
              body_1
              lottie_animation_2 {
                ...Link
              }
              title_2
              body_2
              lottie_animation_3 {
                ...Link
              }
              title_3
              body_3
            }
            items {
              step {
                richText
              }
            }
          }
          ... on PrismicPageDataBodyFeaturesWithInfoBox {
            __typename
            primary {
              heading
              subheading
              info_box_text {
                richText
              }
              image {
                gatsbyImageData
              }
              background_color
            }
            items {
              feature_heading
              feature_text {
                richText
              }
              icon {
                url
              }
            }
          }
          ... on PrismicPageDataBodyCaseStudy {
            __typename
            primary {
              background_color
              heading
              description
              logo {
                url
              }
              image {
                gatsbyImageData
              }
              button_text
              button_link {
                ...Link
              }
            }
          }
          ... on PrismicPageDataBodyCaseStudyCarousel {
            __typename
            primary {
              version
              show_buttons
              background_color
            }
            items {
              title
              heading
              description
              tag
              tag_text
              tag_2
              tag_2_text
              tag_3
              tag_3_text
              logo {
                url
              }
              image {
                gatsbyImageData
              }
              button_text
              button_link {
                ...Link
              }
            }
          }
          ... on PrismicPageDataBodyBlogLinks {
            __typename
            primary {
              heading
              subheading
              background_color
            }
            items {
              image {
                gatsbyImageData
              }
              label
              heading
              description
              tag
              tag_2
              tag_3
              button_text
              link {
                ...Link
              }
            }
          }
          ... on PrismicPageDataBodyVideos {
            __typename
            primary {
              heading
            }
            items {
              video
              text
            }
          }
          ... on PrismicPageDataBodyGlossary {
            __typename
            primary {
              heading
              text {
                richText
              }
            }
            items {
              letter
              glossary_text {
                richText
              }
            }
          }
          ... on PrismicPageDataBodySteps {
            __typename
            items {
              step_text
              heading
              text
              button_text
              button_link {
                ...Link
              }
              image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicPageDataBodyCategories {
            __typename
            primary {
              heading
            }
            items {
              item
              title
              item_text {
                richText
              }
              image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicPageDataBodyScriptEmbed {
            __typename
            id
            primary {
              script_embed {
                document {
                  ... on PrismicScriptEmbed {
                    id
                    data {
                      embed_code {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicGlobal {
      nodes {
        data {
          show_hiring_label
          show_banner
          banner_text {
            richText
          }
          banner_label
        }
      }
    }
  }

  fragment Link on PrismicLinkType {
    uid
    type
    link_type
    url
  }

  fragment TableRowHeadings on PrismicLinkType {
    document {
      ... on PrismicTableRowHeadings {
        data {
          rows {
            heading
            additional_information
            tooltip_on_secondary_table
            link_text
            link {
              ...Link
            }
          }
        }
      }
    }
  }

  fragment CodeExample on PrismicLinkType {
    document {
      ... on PrismicCodeExample {
        data {
          main_heading
          example {
            heading
            code {
              richText
            }
            button_text
            link {
              ...Link
            }
          }
        }
      }
    }
  }
`
