import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import { CustomLink } from '../utils/linkResolver'
import ReactPlayer from 'react-player/youtube'

import { useInView } from 'react-intersection-observer'

import LottieAnimation from './LottieAnimation'
import PrismicRichText from './PrismicRichText'

import Container from '../styles/Container'
import { Paragraph, Heading2, Heading3, Heading5 } from '../styles/Text'
import { Button } from '../styles/Buttons'

import checkIcon from '../images/check-icon.svg'
import checkIconBlack from '../images/check-icon-black.svg'
import theme from '../styles/theme'

const OuterContainer = styled.div`
  ${(p) =>
    p.backgroundColor === 'blue' &&
    css`
      background-color: ${p.theme.blue};
      color: white;
      & + div.footer > div {
        margin-top: 0px;
      }
    `}
  ${(p) =>
    p.backgroundColor === 'green' &&
    css`
      background-color: ${p.theme.green};
      & ~ div.footer > div {
        margin-top: 0px;
        border-top: 0px;
      }
    `}
  ${(p) =>
    p.backgroundColor === 'purple grey' &&
    css`
      background-color: ${p.theme.purpleGreyDarker};
      & ~ div.footer > div {
        margin-top: 0px;
        border-top: 0px;
      }
    `}
  ${(p) =>
    p.backgroundColor === 'light grey' &&
    css`
      background-color: ${p.theme.purpleGreyLighter};
      & ~ div.footer > div {
        margin-top: 0px;
        border-top: 0px;
      }
    `}
  ${(p) =>
    p.backgroundColor === 'gradient' &&
    css`
      background: linear-gradient(270deg, #160228 41.18%, #491577 108.68%);
      color: white;
      & ~ div.footer > div {
        margin-top: 0px;
        border-top: 0px;
      }
    `}
    ${(p) =>
    p.backgroundColor === 'dark purple' &&
    css`
      background-color: #190c25;
      color: white;
      & + div.footer > div {
        margin-top: 0px;
      }
    `}
`

const CustomContainer = styled(Container)`
  padding-bottom: 32px;
  ${(p) => p.backgroundColor === 'blueish grey' && `background-color: ${p.theme.blueishGrey};`}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 40px;
    ${(p) => p.backgroundColor === 'blueish grey' && `padding: 0px 0px 0px 40px;`}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 64px;
    padding-bottom: 64px;
    ${(p) => p.backgroundColor === 'blueish grey' && `padding: 0px 0px 0px 40px; max-width: 1110px;`}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${Heading2}, ${Heading3} {
    padding-top: 24px;
    margin-bottom: 8px;
  }
  ${Heading5} {
    padding-top: 32px;
    margin-bottom: 8px;
  }
  ${Paragraph} {
    margin-bottom: 40px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    ${(p) =>
      (p.backgroundColor === 'blueish grey' ||
        p.productPage === 'channels' ||
        p.pathname === '/serverless-functions/' ||
        p.pathname === '/game-development/') &&
      `align-items: center;`}
    ${Heading2}, ${Heading3} {
      padding-top: 0px;
    }
    ${Heading5} {
      padding-top: 0px;
    }
    ${Paragraph} {
      margin-bottom: 0px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    justify-content: center;
  }
`

const Text = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    ${(p) => p.imagePosition === 'left' && 'order: 1;'}
    ${(p) => p.backgroundColor === 'blueish grey' && `margin-bottom: 20px;`}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 445px;
    margin-right: auto;
    ${(p) => p.imagePosition === 'left' && 'margin-right: 0px; margin-left: auto;'}
  }
`

const CustomHeading2 = styled(Heading2)`
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) =>
      p.productPage === 'channels' &&
      p.backgroundColor !== 'gradient' &&
      css`
        letter-spacing: 0.5px;
        margin-bottom: 24px !important;
      `}
  }
`

const CustomHeading3 = styled(Heading3)`
  ${(p) => p.hasPinkText && 'color: #FF8DE2;'}
`

const TextWrapper = styled.div`
  p:not(:last-child) {
    margin-bottom: 1.4em;
  }
  ul {
    :not(:first-child) {
      margin-top: 16px;
    }
    li {
      position: relative;
      padding-left: 23px;
      :not(:first-child) {
        margin-top: 8px;
      }
      ::before {
        content: '';
        width: 4px;
        height: 4px;
        flex-shrink: 0;
        border-radius: 100%;
        margin-right: 14px;
        position: absolute;
        left: 8px;
        top: 12px;
        background-color: ${(p) => p.theme.textColor};
        ${(p) => p.backgroundColor === 'dark purple' && 'background-color: white;'}
      }
    }
  }
`

const Features = styled.ul`
  margin-top: -16px;
  margin-bottom: 40px;
  li {
    display: flex;
    align-items: flex-start;
    :not(:last-child) {
      margin-bottom: 8px;
      ${(p) => p.productPage === 'beams' && 'margin-bottom: 16px;'}
    }
  }
  img {
    margin-right: 14px;
    margin-top: 3px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 24px;
    margin-bottom: 32px;
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 48px;
  a:nth-child(2) button {
    margin-top: 16px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 0px;
    a:nth-child(1) button {
      margin-top: 16px;
    }
    a:nth-child(1) {
      margin-right: 16px;
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  max-width: 324px;
  align-self: center;
  img {
    width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    max-width: initial;
    align-self: flex-start;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-left: 30px;
    ${(p) => p.imagePosition === 'left' && 'margin-left: 0px; margin-right: 30px;'}
  }
`

const VideoContainer = styled.div`
  width: 100%;
  align-self: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    margin-bottom: 0px;
    order: 0;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-left: 30px;
    ${(p) => p.imagePosition === 'left' && 'margin-left: 0px; margin-right: 30px;'}
  }
`

const VideoInner = styled.div`
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  > div {
    position: absolute !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100% !important;
    height: auto !important;
  }
`

const TextWithImage = ({
  heading,
  headingTag,
  description,
  text,
  imagePosition,
  image,
  lottieAnimation,
  videoUrl,
  button1Text,
  button1Link,
  button2Text,
  button2Link,
  features,
  productPage,
  backgroundColor,
  pathname = '',
}) => {
  const hasFeatures = features[0].feature
  const lottieAnimationUrl = get(lottieAnimation, 'url')

  const [inViewRef, inView] = useInView({
    threshold: 1,
    triggerOnce: true,
  })

  const hasPinkText =
    pathname?.endsWith('game-development/') ||
    pathname?.endsWith('community/') ||
    pathname?.endsWith('pusher-dev-rewards/')

  return (
    <OuterContainer backgroundColor={backgroundColor}>
      <CustomContainer backgroundColor={backgroundColor} ref={inViewRef}>
        <Wrapper backgroundColor={backgroundColor} productPage={productPage} pathname={pathname}>
          <Text imagePosition={imagePosition} backgroundColor={backgroundColor}>
            {heading &&
              (headingTag === 'h1' ? (
                <CustomHeading2 as={headingTag} productPage={productPage} backgroundColor={backgroundColor}>
                  {heading}
                </CustomHeading2>
              ) : (
                <CustomHeading3 hasPinkText={hasPinkText}>{heading}</CustomHeading3>
              ))}
            {description && <Paragraph>{description}</Paragraph>}
            {text && (
              <TextWrapper backgroundColor={backgroundColor}>
                <PrismicRichText text={text} />
              </TextWrapper>
            )}
            {hasFeatures && (
              <Features productPage={productPage}>
                {features.map(({ feature }, index) => {
                  return (
                    <Paragraph as="li" bold={productPage === 'beams' ? false : true} key={index}>
                      <img src={productPage === 'beams' ? checkIconBlack : checkIcon} alt="" />
                      {feature}
                    </Paragraph>
                  )
                })}
              </Features>
            )}
            {(button1Text || button2Text) && (
              <ButtonContainer>
                {button1Text && (
                  <CustomLink link={button1Link}>
                    <Button
                      fullWidthOnSmall
                      reversedWhite={
                        backgroundColor === 'gradient' || backgroundColor === 'dark purple'
                          ? true
                          : backgroundColor === 'blue' && productPage !== 'channels'
                          ? true
                          : false
                      }
                      dark={
                        backgroundColor === 'green' ||
                        backgroundColor === 'purple grey' ||
                        productPage === 'channels' ||
                        pathname === '/serverless-functions/'
                          ? true
                          : false
                      }
                      textColor={
                        backgroundColor === 'gradient' || backgroundColor === 'dark purple'
                          ? theme.textColor
                          : productPage === 'channels'
                          ? 'white'
                          : backgroundColor === 'blue'
                          ? theme.blue
                          : backgroundColor === 'green' && theme.green
                      }
                      backgroundColor={backgroundColor}
                    >
                      {button1Text}
                    </Button>
                  </CustomLink>
                )}
                {button2Text && (
                  <CustomLink link={button2Link}>
                    <Button
                      fullWidthOnSmall
                      reversedWhite={
                        backgroundColor === 'gradient'
                          ? true
                          : backgroundColor === 'blue' && productPage !== 'channels'
                          ? true
                          : false
                      }
                      reversedDark={
                        backgroundColor === 'green' || backgroundColor === 'purple grey' || productPage === 'channels'
                          ? true
                          : false
                      }
                      textColor={
                        backgroundColor === 'gradient'
                          ? theme.textColor
                          : productPage === 'channels'
                          ? 'white'
                          : backgroundColor === 'blue'
                          ? theme.blue
                          : backgroundColor === 'green' && theme.green
                      }
                      backgroundColor={backgroundColor}
                    >
                      {button2Text}
                    </Button>
                  </CustomLink>
                )}
              </ButtonContainer>
            )}
          </Text>
          {lottieAnimationUrl ? (
            <ImageContainer>
              <LottieAnimation path={lottieAnimationUrl} playStart={inView} />
            </ImageContainer>
          ) : videoUrl ? (
            <VideoContainer imagePosition={imagePosition}>
              <VideoInner>
                <ReactPlayer controls url={videoUrl} width="100%" height="100%" />
              </VideoInner>
            </VideoContainer>
          ) : (
            image && (
              <ImageContainer imagePosition={imagePosition}>
                <GatsbyImage image={image} alt="" />
              </ImageContainer>
            )
          )}
        </Wrapper>
      </CustomContainer>
    </OuterContainer>
  )
}

export default TextWithImage
