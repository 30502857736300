import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import homepageIllustration1 from '../images/homepage-illustration-1.svg'
import homepageIllustration2 from '../images/homepage-illustration-2.svg'
import homepageIllustration3 from '../images/homepage-illustration-3.svg'
import homepageIllustration4 from '../images/homepage-illustration-4.svg'

const Container = styled(animated.div)`
  display: none;
  @media (min-width: ${p => p.theme.breakpointXl}) {
    display: inline-block;
    position: absolute;
    z-index: -1;
    ${p => p.position && p.position.top && `top: ${p.position.top};`}
    ${p => p.position && p.position.right && `right: ${p.position.right};`}
    ${p => p.position && p.position.left && `left: ${p.position.left};`}
    ${p => p.position && p.position.zIndex && `z-index: ${p.position.zIndex};`}
    ${p => p.noTextCollision && 'display: none;'}
  }
  @media (min-width: 1540px) {
    ${p => p.noTextCollision && 'display: inline-block;'}
  }
`

const Illustration = ({ image, position, noTextCollision }) => {
  const ref = useRef()
  const [{ offset }, set] = useSpring(() => ({ offset: 0 }))

  const handleScroll = () => {
    const posY = ref.current.getBoundingClientRect().top
    const offset = window.pageYOffset - posY
    set({ offset })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <Container
      position={position}
      ref={ref}
      style={{ transform: offset.interpolate(o => `translateY(${o * -0.1}px)`) }}
      noTextCollision={noTextCollision}
    >
      <img src={image} alt="" />
    </Container>
  )
}

const HomepageIllustrations = () => {
  return (
    <>
      <Illustration noTextCollision image={homepageIllustration1} position={{ left: '0px', top: '0px' }} />
      <Illustration image={homepageIllustration2} position={{ right: '0px', top: '0px' }} />
      <Illustration image={homepageIllustration3} position={{ left: '0px', top: '1900px' }} />
      <Illustration image={homepageIllustration4} position={{ right: '0px', top: '2100px' }} />
    </>
  )
}

const HomepageIllustrationsMedium = () => {
  return (
    <>
      <Illustration noTextCollision image={homepageIllustration1} position={{ left: '0px', top: '0px' }} />
      <Illustration image={homepageIllustration2} position={{ right: '0px', top: '0px' }} />
      <Illustration image={homepageIllustration4} position={{ right: '0px', top: '2100px' }} />
    </>
  )
}

const HomepageIllustrationsShort = () => {
  return (
    <>
      <Illustration noTextCollision image={homepageIllustration1} position={{ left: '0px', top: '0px' }} />
      <Illustration image={homepageIllustration2} position={{ right: '0px', top: '0px' }} />
    </>
  )
}

const ParallaxIllustrations = ({ pathname }) => {
  if (pathname === '/channels/features') {
    return <HomepageIllustrationsShort />
  } else if (pathname === '/channels') {
    return <HomepageIllustrationsMedium />
  } else if (pathname === '/') {
    return <HomepageIllustrations />
  } else {
    return null
  }
}

export default ParallaxIllustrations
