import React from 'react'
import styled from 'styled-components'

import useActionAndReset from '../hooks/useActionAndReset'
import { getHeadingLinkAndValue } from '../components/shared/TableOfContent'

import LinkSVG from '../../static/images/link.inline.svg'
import CheckCircleSVG from '../../static/images/check-circle.inline.svg'

const CopyLinkButton = styled.button`
  color: ${(p) => (p.state === 'initial' ? p.theme.chameleon.colors.pusher.darkPurple : p.theme.successGreen)};
  margin-left: 16px;
  display: none;
`

export const withCopyLink = (Component) => {
  const ComponentWithCopyLink = (props) => {
    const { id, displayValue } = getHeadingLinkAndValue(props.children[0])

    const { state, triggerAction } = useActionAndReset({
      action: () => {
        const link = `${window.location.origin}${window.location.pathname}#${id}`
        navigator.clipboard.writeText(link)
      },
    })

    return (
      <Component id={id} onClick={triggerAction} {...props}>
        {displayValue}

        <CopyLinkButton className="copy">{state === 'initial' ? <LinkSVG /> : <CheckCircleSVG />}</CopyLinkButton>
      </Component>
    )
  }

  return ComponentWithCopyLink
}
