import styled, { css } from 'styled-components'
import { withCopyLink } from '../../utils/hocs'

export const getTypographyStyling = (element = 'p') => css`
  line-height: ${(p) => p.theme.chameleon.type.mobile[element].lineHeight};
  font-size: ${(p) => p.theme.chameleon.type.mobile[element].fontSize};
  font-weight: 600;
  letter-spacing: 0.25px;

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    font-size: ${(p) => p.theme.chameleon.type.desktop[element].fontSize};
    line-height: ${(p) => p.theme.chameleon.type.desktop[element].lineHeight};
  }
`

const toggleCopyLinkIconStyling = css`
  display: inline-block;

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }

    :hover .copy {
      display: inline-block;
    }
  }
`

export const H1 = styled.h1`
  ${getTypographyStyling('h1')}
`

export const H2 = styled.h2`
  ${getTypographyStyling('h2')}
`

export const H3 = styled.h3`
  ${getTypographyStyling('h3')}
`

export const H4 = styled.h4`
  ${getTypographyStyling('h4')}
`

export const H5 = styled.h5`
  ${getTypographyStyling('h5')}
`

const H1WithCopyStyling = styled(H1)`
  ${toggleCopyLinkIconStyling}
`
const H2WithCopyStyling = styled(H2)`
  ${toggleCopyLinkIconStyling}
`
const H3WithCopyStyling = styled(H3)`
  ${toggleCopyLinkIconStyling}
`
const H4WithCopyStyling = styled(H4)`
  ${toggleCopyLinkIconStyling}
`
const H5WithCopyStyling = styled(H5)`
  ${toggleCopyLinkIconStyling}
`

export const H1WithCopyIcon = withCopyLink(H1WithCopyStyling)
export const H2WithCopyIcon = withCopyLink(H2WithCopyStyling)
export const H3WithCopyIcon = withCopyLink(H3WithCopyStyling)
export const H4WithCopyIcon = withCopyLink(H4WithCopyStyling)
export const H5WithCopyIcon = withCopyLink(H5WithCopyStyling)

export const P = styled.p`
  ${getTypographyStyling('p')}
  font-weight: 500;
  letter-spacing: 0.25px;
  margin: 16px 0;

  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const A = styled.a`
  color: ${(p) => p.theme.chameleon.colors.pusher.darkPurple};
  border-bottom: 2px solid ${(p) => p.theme.chameleon.colors.pusher.darkPurple};
  font-weight: 600;
  padding-bottom: 1px;
  transition: all 0.15s ease-in;

  :hover {
    cursor: pointer;
    color: ${(p) => p.theme.chameleon.colors.pusher.darkPink};
    border-color: ${(p) => p.theme.chameleon.colors.pusher.darkPink};
  }
`

export const Ol = styled.ul`
  background-color: ${(p) => p.theme.chameleon.colors.pusher.lightGreenishGrey};
  padding: 8px;
  padding-left: 32px;
  margin: 16px 0;

  li {
    display: list-item;
    list-style: decimal;
    list-style-type: decimal;
  }
`

export const Ul = styled.ul`
  background-color: ${(p) => p.theme.chameleon.colors.pusher.lightGreenishGrey};
  padding: 8px;
  padding-left: 32px;
  margin: 16px 0;

  li {
    display: list-item;
    list-style: disc;
    list-style-type: disc;
  }
`

export const Li = styled.li`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
`
