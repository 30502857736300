import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Button } from '../styles/Buttons'
import { Heading3, Paragraph } from '../styles/Text'

import caretDown from '../images/caret-down.svg'

const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 80px;
  }
`

const StepsContent = styled.div`
  border: 2px solid #efecff;
  padding: 16px;
  border-radius: 4px;
  order: 1;
  margin-top: 24px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: calc((100% / 3) * 2 - 24px);
    order: 0;
    margin-top: 0px;
  }
`

const StepsContentLeft = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: calc(50% - 24px);
  }
`

const StepsContentRight = styled.div`
  margin-top: 48px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: calc(50% - 24px);
    margin-top: 0px;
  }
`

const Heading = styled(Heading3)``

const CustomParagraph = styled(Paragraph)`
  margin-top: 16px;
`

const ButtonWrapper = styled.div`
  margin-top: 8px;
`

const StepsList = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
    width: calc((100% / 3) - 24px);
  }
`

const StepButton = styled.button`
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  text-align: left;
  ${(p) => p.active && `background-color: ${p.theme.purpleGreyDark};`}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: 75%;
    margin-left: auto;
  }
`

const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.textColor};
  color: white;
  ${(p) =>
    p.active &&
    css`
      background-color: white;
      color: ${p.theme.textColor};
    `}
`

const StepText = styled.div``

const SelectContainer = styled.div`
  position: relative;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
  ::before {
    content: '';
    background-image: url(${caretDown});
    background-size: cover;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
`

const Select = styled.select`
  width: 100%;
  padding: 8px 16px;
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${(p) => p.theme.textColor};
  background-color: #e6e2fc;
  appearance: button;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
`

const Steps = ({ items }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const currentItem = items?.[selectedIndex]
  const heading = currentItem?.heading
  const text = currentItem?.text
  const buttonText = currentItem?.button_text
  const buttonLink = currentItem?.button_link
  const image = currentItem?.image?.gatsbyImageData

  return (
    <CustomContainer>
      <StepsContent>
        <StepsContentLeft>
          {heading && <Heading>{heading}</Heading>}
          {text && <CustomParagraph>{text}</CustomParagraph>}
          {buttonText && (
            <ButtonWrapper>
              <CustomLink link={buttonLink}>
                <Button dark>{buttonText}</Button>
              </CustomLink>
            </ButtonWrapper>
          )}
        </StepsContentLeft>
        <StepsContentRight>{image && <GatsbyImage image={image} />}</StepsContentRight>
      </StepsContent>
      <StepsList>
        {items?.map((item, index) => {
          const stepText = item?.step_text
          const active = selectedIndex === index
          return (
            <StepButton onClick={() => setSelectedIndex(index)} active={active} key={index}>
              <StepNumber active={active}>{index + 1}</StepNumber>
              <StepText>{stepText}</StepText>
            </StepButton>
          )
        })}
      </StepsList>
      <SelectContainer>
        <Select value={selectedIndex} onChange={(e) => setSelectedIndex(Number(e?.target?.value))}>
          {items?.map((item, index) => {
            const stepText = item?.step_text
            return (
              <option value={index} key={index}>
                {index + 1} - <StepText>{stepText}</StepText>
              </option>
            )
          })}
        </Select>
      </SelectContainer>
    </CustomContainer>
  )
}

export default Steps
