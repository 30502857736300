import React from 'react'

const MarketingPixels = ({ pathname }) => {
  const axel = Math.random() + ''
  const a = axel * 10000000000000

  const pixels = (id, a) => {
    return (
      <>
        <img
          alt=""
          src={`https://pubads.g.doubleclick.net/activity;xsp=${id};ord=${a}?`}
          width={1}
          height={1}
          border={0}
          style={{ position: 'absolute' }}
        />
        <noscript>
          <img
            alt=""
            src={`https://pubads.g.doubleclick.net/activity;xsp=${id};ord=1?`}
            width={1}
            height={1}
            border={0}
            style={{ position: 'absolute' }}
          />
        </noscript>
      </>
    )
  }

  if (pathname === '/') {
    return pixels(5059592, a)
  } else {
    return null
  }
}

export default MarketingPixels
