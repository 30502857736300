import React from 'react'
import styled, { css } from 'styled-components'

import Container from '../styles/Container'
import { Heading3, Paragraph } from '../styles/Text'
import { Button } from '../styles/Buttons'
import theme from '../styles/theme'

import { CustomLink } from '../utils/linkResolver'
import widont from '../utils/widont'

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.textColor};
  a {
    width: 100%;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      width: initial;
    }
  }
  ${(p) => p.backgroundColor === 'black' && `background-color: #190c25;`}
  ${(p) => p.version === 'v4' && `background-color: white; padding-top: 40px; padding-bottom: 40px;`}
  ${(p) => p.pathname === '/game-development/' && `background-color: #190C25;`}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    & ~ div.footer > div {
      margin-top: 0px;
    }
  }
`

const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  ${(p) =>
    p.version === 'v4' &&
    p.backgroundColor !== 'white' &&
    `background-color: ${p.theme.textColor}; border-radius: 8px; padding: 24px; margin: 24px;`}
  ${(p) => p.backgroundColor === 'white' && 'padding-top: 0px; padding-bottom: 0px;'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-bottom: 64px;
    padding-top: 64px;
    ${(p) => p.version === 'v3' && 'padding-top: 80px; padding-bottom: 32px;'}
    ${(p) =>
      p.version === 'v4' &&
      p.backgroundColor !== 'white' &&
      `background-color: ${p.theme.textColor}; border-radius: 8px; padding: 24px; margin: 0px auto;`}
      ${(p) => p.pathname === '/game-development/' && 'background-color: #372B52;'}
      ${(p) => p.backgroundColor === 'white' && 'padding-top: 0px; padding-bottom: 0px;'}
  }
`

const CustomHeading3 = styled(Heading3)`
  margin-bottom: 8px;
  max-width: 495px;
  ${(p) =>
    p.version === 'v3' &&
    p.backgroundColor !== 'black' &&
    css`
      max-width: 630px;
      margin-bottom: 0;
      font-size: 29px;
      line-height: 148%;
      font-weight: 500;
      letter-spacing: 0.5px;
    `}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) =>
      p.version === 'v3' &&
      p.backgroundColor !== 'black' &&
      css`
        font-weight: 600;
        font-size: 39px;
        line-height: 140%;
        letter-spacing: 0.25px;
      `}
    ${(p) =>
      p.backgroundColor === 'black' &&
      css`
        max-width: 800px;
        white-space: break-spaces;
      `}
      ${(p) => p.version === 'v4' && 'margin-bottom: 24px;'}
  }
`

const CustomParagraph = styled(Paragraph)`
  margin-bottom: 32px;
  max-width: 450px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 540px;
  }
`

const CTABanner = ({ version, heading, paragraph, buttonText, link, backgroundColor, pathname }) => {
  return (
    <OuterContainer backgroundColor={backgroundColor} version={version} pathname={pathname}>
      <CustomContainer version={version} pathname={pathname} backgroundColor={backgroundColor}>
        {heading && (
          <CustomHeading3
            center
            white={backgroundColor !== 'white'}
            version={version}
            as={version === 'v3' ? 'h1' : 'h2'}
            backgroundColor={backgroundColor}
          >
            {heading}
          </CustomHeading3>
        )}
        {paragraph && (
          <CustomParagraph center white>
            {widont(paragraph)}
          </CustomParagraph>
        )}
        {buttonText && (
          <CustomLink link={link}>
            {backgroundColor === 'black' || version === 'v4' ? (
              <Button
                reversedWhite={backgroundColor !== 'white'}
                dark={backgroundColor === 'white'}
                textColor={backgroundColor === 'white' ? 'white' : theme.darkerGrey}
                fullWidthOnSmall
              >
                {buttonText}
              </Button>
            ) : (
              <Button reversed fullWidthOnSmall>
                {buttonText}
              </Button>
            )}
          </CustomLink>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default CTABanner
