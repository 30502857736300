import React, { useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import get from 'lodash.get'
import { CustomLink } from '../utils/linkResolver'

import { Paragraph } from '../styles/Text'
import { Button } from '../styles/Buttons'

import caretDown from '../images/caret-down-white.svg'

const fadeIn = keyframes`
  0% {
  opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const OuterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${(p) =>
    p.fadeIn &&
    css`
      animation: ${fadeIn} forwards 500ms ease-in-out;
    `}
`

const CustomParagraph = styled(Paragraph)`
  padding: 2px 16px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${(p) => p.theme.midGrey};
  color: white;
`

const Wrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  background-color: ${(p) => p.theme.darkerGrey};
  color: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 0px;
    height: 100%;
  }
`

const Mobile = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: auto;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
`

const Desktop = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-direction: column;
    height: 100%;
    [data-reach-tabs] {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    [data-reach-tab-panels] {
      height: 100%;
      overflow: hidden;
    }
    [data-reach-tab-panel] {
      height: 100%;
    }
  }
`

const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
  ::before {
    content: '';
    background-image: url(${caretDown});
    background-size: cover;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
`

const Select = styled.select`
  width: 100%;
  padding: 8px 16px;
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: white;
  background-color: ${(p) => p.theme.midGrey};
  appearance: button;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
`

const CustomTabList = styled(TabList)`
  display: flex;
  height: 44px;
  flex-shrink: 0;
  margin: 16px;
  margin-bottom: 24px;
`
const CustomTab = styled(Tab)`
  padding: 8px 8px 6px;
  border-radius: 3px;
  margin-right: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  transition: all 0.1s ease;
  background-color: ${(p) => p.theme.midGrey};
  :last-child {
    margin-right: 0px;
  }
  ${(p) =>
    !p.active &&
    css`
      background-color: transparent;
      :hover {
        background-color: ${(p) => p.theme.purple};
      }
    `}
`

const Code = styled.div`
  padding-left: 50px;
  padding-bottom: 25px;
  flex-grow: 1;
  font-family: 'Maison Neue Mono', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  overflow: auto;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    height: 100%;
  }
`

const Line = styled.p`
  position: relative;
  ::before {
    content: '${(p) => p.linenumber}';
    position: absolute;
    left: -26px;
    ${(p) => p.linenumber > 9 && 'left: -35px;'}
    top: 0px;
    width: 10px;
    height: 10px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  ${Button}, a {
    width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-left: 24px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 24px;
    ${Button} {
      width: auto;
    }
  }
`

const CodeExample = ({ examples, heading, fadeIn, formattedExamples }) => {
  const [tabIndex, setTabIndex] = useState(0)
  const [selectIndex, setSelectIndex] = useState(0)
  return (
    <OuterWrapper fadeIn={fadeIn}>
      {heading && (
        <CustomParagraph bold as="h2">
          {heading}
        </CustomParagraph>
      )}
      <Wrapper>
        <Mobile>
          <SelectContainer>
            <Select value={selectIndex} onChange={(e) => setSelectIndex(e.target.value)} aria-label="Choose language">
              {examples.map((example, index) => {
                return (
                  <option value={index} key={index}>
                    {example.heading}
                  </option>
                )
              })}
            </Select>
          </SelectContainer>
          {get(formattedExamples, 'example', []).map((example, index) => {
            const lines = example.code
            return (
              index == selectIndex && (
                <React.Fragment key={index}>
                  <Code>
                    <pre>
                      <code className="hljs">
                        {lines.map((line, index) => {
                          return (
                            <Line
                              linenumber={index + 1}
                              key={index}
                              dangerouslySetInnerHTML={{ __html: line ? line : '<br />' }}
                            />
                          )
                        })}
                      </code>
                    </pre>
                  </Code>
                  {example.button_text && (
                    <ButtonWrapper>
                      <CustomLink link={example.link}>
                        <Button reversed>{example.button_text}</Button>
                      </CustomLink>
                    </ButtonWrapper>
                  )}
                </React.Fragment>
              )
            )
          })}
        </Mobile>
        <Desktop>
          <Tabs onChange={(index) => setTabIndex(index)}>
            <CustomTabList count={examples.length}>
              {examples.map((example, index) => {
                return (
                  <CustomTab
                    key={index}
                    count={examples.length}
                    active={index === tabIndex}
                    first={index === 0}
                    last={index === examples.length - 1}
                  >
                    {example.heading}
                  </CustomTab>
                )
              })}
            </CustomTabList>
            <TabPanels key={Math.random()}>
              {get(formattedExamples, 'example', []).map((example, index) => {
                const lines = example.code
                return (
                  <TabPanel key={index}>
                    <Code>
                      <pre>
                        <code className="hljs">
                          {lines.map((line, index) => {
                            return (
                              <Line
                                linenumber={index + 1}
                                key={index}
                                dangerouslySetInnerHTML={{ __html: line ? line : '<br />' }}
                              />
                            )
                          })}
                        </code>
                      </pre>
                    </Code>
                  </TabPanel>
                )
              })}
            </TabPanels>
          </Tabs>
          {examples.map((example, index) => {
            return (
              tabIndex === index &&
              example.button_text && (
                <ButtonWrapper key={index}>
                  <CustomLink link={example.link}>
                    <Button reversed>{example.button_text}</Button>
                  </CustomLink>
                </ButtonWrapper>
              )
            )
          })}
        </Desktop>
      </Wrapper>
    </OuterWrapper>
  )
}

export default CodeExample
