import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import LandingPageContainer from '../../styles/LandingPageContainer'

const CustomContainer = styled(LandingPageContainer)`
  display: flex;
  flex-direction: column;
`

const Heading = styled.h2`
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 23px;
  line-height: 148%;
  text-align: center;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    font-size: 39px;
    line-height: 124%;
  }
`

const Subheading = styled.p`
  max-width: 600px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 18px;
  line-height: 164%;
  text-align: center;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    font-size: 20px;
    line-height: 164%;
  }
`

const Image = styled(GatsbyImage)`
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    margin-top: 48px;
  }
`

const Quote = styled.blockquote`
  max-width: 850px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 18px;
  line-height: 164%;
  text-align: center;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    font-size: 20px;
    line-height: 164%;
  }
`

const Name = styled.p`
  margin-top: 24px;
  font-family: 'Maison Neue';
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    margin-top: 32px;
  }
`

const Details = styled.p`
  font-family: 'Maison Neue';
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.25px;
`

const Testimonial = ({ slice }) => {
  const heading = slice?.primary?.heading
  const subheading = slice?.primary?.subheading
  const image = slice?.primary?.image?.gatsbyImageData
  const quote = slice?.primary?.quote
  const name = slice?.primary?.name
  const details = slice?.primary?.details
  return (
    <CustomContainer>
      {heading && <Heading>{heading}</Heading>}
      {subheading && <Subheading>{subheading}</Subheading>}
      {image && <Image image={image} alt={name} />}
      {quote && (
        <Quote center as="blockquote">
          {quote}
        </Quote>
      )}
      {name && <Name>{name}</Name>}
      {details && <Details>{details}</Details>}
    </CustomContainer>
  )
}

export default Testimonial
