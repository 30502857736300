import React from 'react'
import { graphql, navigate } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'

import { CustomLink } from '../utils/linkResolver'

import SEO from '../components/SEO'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import Container from '../styles/Container'
import { Heading1, Heading4, Heading5, Heading4Styles, ParagraphStyles, Heading2 } from '../styles/Text'

import caretDown from '../images/caret-down-purple.svg'

const CustomContainer = styled(Container)`
  padding-bottom: 48px;
  @media (min-width: 1200px) {
    padding-bottom: 36px;
  }
`

const HeadingContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 36px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 40px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 64px;
    padding-bottom: 56px;
  }
`

const SelectOuterContainer = styled.div`
  position: sticky;
  top: -2px;
  background-color: white;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 1;
  &[stuck] {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: none;
  }
`

const SelectContainer = styled.div`
  position: relative;
  top: 0px;
  max-width: 100%;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: inline-block;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: none;
  }
  ::before {
    content: '';
    background-image: url(${caretDown});
    background-size: cover;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
`

const Select = styled.select`
  width: 100%;
  max-width: 100%;
  padding: 8px 36px 8px 16px;
  border: 2px solid ${(p) => p.theme.blueLight};
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${(p) => p.theme.purpleDark};
  background-color: white;
  appearance: button;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
`

const Wrapper = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: flex;
    align-items: flex-start;
  }
`

const Menu = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-right: 30px;
    margin-bottom: 24px;
    padding: 16px;
  }
  @media (min-width: 1200px) {
    margin-right: 64px;
  }
`

const MenuLinkWrapper = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
  padding: 4px 8px;
  border-radius: 4px;
  ${(p) => p.selected && 'background-color: #BFB1FF;'}
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

const Section = styled.div``

const SectionHeading = styled(Heading2)`
  margin-bottom: 16px;
`

const SectionLink = styled.div`
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
  text-decoration: underline;
`

const Text = styled.div`
  h3 {
    ${Heading4Styles}
    margin-bottom: 8px;
  }
  p,
  ul,
  ol {
    ${ParagraphStyles}
  }
  li {
    margin-bottom: 24px;
  }
  ol {
    margin: 0;
    padding: 0;
    padding-left: 20px;
    text-indent: -20px;
    list-style: none;
    counter-reset: custom-counter;
    li {
      counter-increment: custom-counter;
      ::before {
        content: counter(custom-counter) '. ';
        font-weight: 700;
      }
    }
  }
  ul li {
    position: relative;
    text-indent: 0px;
    padding-left: 14px;
    ::before {
      content: '•';
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
  p {
    margin-bottom: 28px;
  }
  p + ol,
  p + ul {
    margin-top: -4px;
  }
  a {
    font-weight: 600;
    text-decoration: underline;
  }
`

const NestedList = styled.div`
  ${(p) => p.level === '2' && 'padding-left: 30px;'}
  ${(p) => p.level === '3' && 'padding-left: 60px;'}
`

const Table = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
`

const TableHeading = styled(Heading4)`
  padding-bottom: 20px;
`

const TableWrapper = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
  }
`

const Mobile = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
`

const Desktop = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
    width: 100%;
  }
`

const Column = styled.div`
  :first-child {
    margin-bottom: 24px;
  }
`

const ColumnHeading = styled(Heading5)`
  margin-bottom: 4px;
  padding: 8px 16px;
  background-color: ${(p) => p.theme.textColor};
  font-weight: 600;
  font-size: 16px;
  line-height: 124%;
  letter-spacing: 0.25px;
  color: white;
`

const ColumnText = styled(Text)`
  padding: 16px;
  background-color: #d9d4f5;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-grow: 1;
  }
`

const Row = styled.div`
  display: flex;
  :nth-child(n + 3) {
    margin-top: 4px;
  }
  :nth-child(2n + 3) ${ColumnText} {
    background-color: #eeecfb;
  }
`

const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 2px;
  ${ColumnText} {
    border-right: 0px;
  }
`
const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 2px;
`

const Legal = ({ data }) => {
  const heading = data?.prismicLegalPage?.data?.heading
  const legalBannerText = data?.prismicLegalPage?.data?.legal_banner_text
  const legalSections = data?.prismicLegalPage?.data?.legal_sections
  const hasMultipleLegalSections = legalSections?.length > 1

  const selectedSection = data?.prismicLegalSection?.data
  const sectionHeading = selectedSection?.title
  const sectionDownloadLink = selectedSection?.download_link
  const sectionBody = selectedSection?.body
  const sectionUid = selectedSection?.uid

  const global = data?.allPrismicGlobal?.nodes?.[0]?.data
  const showHiringLabel = global?.show_hiring_label

  return (
    <>
      <SEO
        title={selectedSection?.meta_title}
        description={selectedSection?.meta_description}
        image={selectedSection?.meta_image?.url}
      />
      <Navbar legalBannerText={legalBannerText} global={global} />
      <Container>
        {heading && (
          <HeadingContainer>
            <Heading1>{heading}</Heading1>
          </HeadingContainer>
        )}
      </Container>
      {hasMultipleLegalSections && (
        <SelectOuterContainer id="select-container">
          <SelectContainer>
            <Select
              value={sectionUid}
              onChange={(e) => {
                navigate(`/legal-archived/${e.target.value}`)
              }}
              aria-label="Choose section"
            >
              {legalSections.map((section, index) => {
                const title = section?.legal_section?.document?.data?.title
                const uid = section?.legal_section?.document?.uid
                return (
                  <option value={uid} key={index}>
                    {title}
                  </option>
                )
              })}
            </Select>
          </SelectContainer>
        </SelectOuterContainer>
      )}
      <CustomContainer>
        <Wrapper>
          {hasMultipleLegalSections && (
            <Menu>
              {legalSections.map((section, index) => {
                const title = section?.legal_section?.document?.data?.title
                const link = section?.legal_section?.document
                return (
                  <MenuLinkWrapper key={index} selected={title === sectionHeading}>
                    <CustomLink link={link}>{title}</CustomLink>
                  </MenuLinkWrapper>
                )
              })}
            </Menu>
          )}
          {selectedSection && (
            <Section>
              {sectionHeading && <SectionHeading as="h2">{sectionHeading}</SectionHeading>}
              {(sectionDownloadLink?.uid || sectionDownloadLink?.url) && (
                <SectionLink>
                  <CustomLink link={sectionDownloadLink}>Download as PDF</CustomLink>
                </SectionLink>
              )}
              {sectionBody &&
                sectionBody.map((slice, index) => {
                  const { slice_type, primary, items } = slice
                  if (slice_type === 'text') {
                    const text = primary?.text?.richText
                    return (
                      <Text key={index}>
                        <RichText render={text} />
                      </Text>
                    )
                  } else if (slice_type === 'nested_list') {
                    return (
                      <Text key={index}>
                        {items &&
                          items.map((item, index) => {
                            const level = item?.level
                            const list = item?.list?.richText
                            return (
                              <NestedList key={index} level={level}>
                                <RichText render={list} />
                              </NestedList>
                            )
                          })}
                      </Text>
                    )
                  } else if (slice_type === 'table') {
                    const heading = primary?.heading
                    const column1Heading = primary?.column_1_heading
                    const column2Heading = primary?.column_2_heading
                    return (
                      <Table key={index}>
                        {heading && <TableHeading as="h3">{heading}</TableHeading>}
                        <TableWrapper>
                          <Mobile>
                            <Column>
                              {column1Heading && <ColumnHeading as="h4">{column1Heading}</ColumnHeading>}
                              {items &&
                                items.map((item, index) => {
                                  const column1Text = item?.column_1_text?.richText
                                  return (
                                    <ColumnText key={index} showBorder={column1Text}>
                                      <RichText render={column1Text} />
                                    </ColumnText>
                                  )
                                })}
                            </Column>
                            <Column>
                              {column2Heading && <ColumnHeading as="h4">{column2Heading}</ColumnHeading>}
                              {items &&
                                items.map((item, index) => {
                                  const column2Text = item?.column_2_text?.richText
                                  return (
                                    <ColumnText key={index} showBorder={column2Text}>
                                      <RichText render={column2Text} />
                                    </ColumnText>
                                  )
                                })}
                            </Column>
                          </Mobile>
                          <Desktop>
                            <Row>
                              <Left>{column1Heading && <ColumnHeading as="h4">{column1Heading}</ColumnHeading>}</Left>
                              <Right>{column2Heading && <ColumnHeading as="h4">{column2Heading}</ColumnHeading>}</Right>
                            </Row>
                            {items &&
                              items.map((item, index) => {
                                const column1Text = item?.column_1_text?.richText
                                const column2Text = item?.column_2_text?.richText
                                return (
                                  <Row key={index}>
                                    <Left>
                                      <ColumnText showBorder={true}>
                                        <RichText render={column1Text} />
                                      </ColumnText>
                                    </Left>
                                    <Right>
                                      <ColumnText showBorder={true}>
                                        <RichText render={column2Text} />
                                      </ColumnText>
                                    </Right>
                                  </Row>
                                )
                              })}
                          </Desktop>
                        </TableWrapper>
                      </Table>
                    )
                  }
                })}
            </Section>
          )}
        </Wrapper>
      </CustomContainer>
      <Footer showHiringLabel={showHiringLabel} />
    </>
  )
}

export default withPrismicPreview(Legal)

export const query = graphql`
  query LegalPageTemplateQuery($sectionUid: String!, $pageUid: String!) {
    prismicLegalPage(uid: { eq: $pageUid }) {
      _previewable
      data {
        heading
        legal_banner_text {
          richText
        }
        legal_sections {
          legal_section {
            link_type
            document {
              ... on PrismicLegalSection {
                uid
                type
                data {
                  title
                  download_link {
                    ...Link
                  }
                  body {
                    ... on PrismicLegalSectionDataBodyText {
                      slice_type
                      primary {
                        text {
                          richText
                        }
                      }
                    }
                    ... on PrismicLegalSectionDataBodyTable {
                      slice_type
                      primary {
                        heading
                        column_1_heading
                        column_2_heading
                      }
                      items {
                        column_1_text {
                          richText
                        }
                        column_2_text {
                          richText
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicLegalSection(uid: { eq: $sectionUid }) {
      uid
      type
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        title
        download_link {
          ...Link
        }
        body {
          ... on PrismicLegalSectionDataBodyText {
            slice_type
            primary {
              text {
                richText
              }
            }
          }
          ... on PrismicLegalSectionDataBodyTable {
            slice_type
            primary {
              heading
              column_1_heading
              column_2_heading
            }
            items {
              column_1_text {
                richText
              }
              column_2_text {
                richText
              }
            }
          }
          ... on PrismicLegalSectionDataBodyNestedList {
            slice_type
            items {
              level
              list {
                richText
              }
            }
          }
        }
      }
    }
    allPrismicGlobal {
      nodes {
        data {
          show_hiring_label
          show_banner
          banner_text {
            richText
          }
          banner_label
        }
      }
    }
  }
`
