const getLinkableID = (text) => {
  const splitText = String(text)
    .replace(/[^\w\s-]/gi, '')
    .toLowerCase()
    .split(' ')

  // if the first word is a number, remove it
  if (splitText[0].match(/^\d+$/)) splitText.shift() // remove the index i.e [0, introduction] -> [introduction]

  return splitText.join('-')
}

export default getLinkableID
