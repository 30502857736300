import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import { CustomLink } from '../utils/linkResolver'
import widont from '../utils/widont'

import CodeExample from './CodeExample'
import CodeExampleContainer from './CodeExampleContainer'
import LottieAnimation from './LottieAnimation'

import Container from '../styles/Container'
import { Heading2, Paragraph, Heading4Styles, Heading2Styles, Heading3 } from '../styles/Text'
import { H1 } from './shared/Typography'
import { Button } from '../styles/Buttons'

import checkIcon from '../images/check-icon.svg'

const OuterContainer = styled.div`
  ${(p) => p.backgroundColor === 'blueish grey' && `background-color: ${p.theme.blueishGrey}; padding-bottom: 10px;`}
  ${(p) => p.backgroundColor === 'black' && `background-color: black; color: white;`}
  ${(p) => p.backgroundColor === 'blue' && `background-color: ${p.theme.blue}; color: white;`}
  ${(p) => p.backgroundColor === 'dark purple' && `background-color: #190C25; color: white;`}
  ${(p) => p.pathname === '/serverless-functions/' && `background-color: #300D4F;`}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    ${(p) => p.backgroundColor === 'black' && `padding-bottom: 32px;`}
  }
`

const CustomContainer = styled(Container)`
  padding-top: 24px;
  padding-bottom: 32px;
  ${(p) => p.pathname === '/serverless-functions/' && `padding-bottom: 0px;`}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 40px;
    ${(p) => p.goBackLink && 'padding-bottom: 0px;'}
    ${(p) => (p.isLargeImage || p.backgroundColor === 'dark purple') && 'display: flex; align-items: center;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: flex;
    padding-top: 64px;
    ${(p) => p.pathname === '/serverless-functions/' && `padding-top: 40px;`}
    ${(p) => p.position === 'left' && 'padding-top: 100px; padding-bottom: 75px;'}
    ${(p) => p.goBackLink && 'padding-bottom: 0px;'}
    ${(p) => p.isLargeImage && 'padding-bottom: 80px;'}
    ${(p) => p.backgroundColor === 'blue' && `padding-top: 80px; padding-bottom: 96px;`}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) => p.position === 'left' && 'padding-top: 140px; padding-bottom: 115px;'}
    ${(p) => p.goBackLink && 'padding-bottom: 0px;'}
    ${(p) => p.align === 'center' && 'align-items: center;'}
    ${(p) => p.backgroundColor === 'blue' && `padding-bottom: 32px;`}
    ${(p) => (p.pathname === '/serverless-functions/' || p.pathname === '/game-development/') && `align-items: center;`}
  }
`

const Left = styled.div`
  margin-bottom: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.position === 'left' && 'order: 1; align-self: center;'}
    ${(p) => p.backgroundColor === 'black' && `align-self: center;`}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-bottom: 0px;
    ${(p) => p.position === 'left' && 'max-width: 462px; margin-left: auto;'}
  }
`

const Title = styled.p`
  ${Heading4Styles}
  margin-bottom: 8px;
  ${(p) => p.isPurple && 'color: #FF68F4; font-weight: 900;'}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 20px;
  }
`

const CustomHeading1 = styled(H1)`
  line-height: 58px;
  max-width: 467px;
  ${(p) => p.hasMobileFullWidthText && 'max-width: 100%'};
  ${(p) => p.hasMobileCenteredText && 'text-align: center'};
  ${(p) =>
    p.isHomepage &&
    css`
      font-weight: 500;
      text-align: center;
      margin-top: 16px;
      margin-left: auto;
      margin-right: auto;
      span {
        color: ${p.theme.blue};
      }
    `}
  ${(p) => p.hasPinkText && 'color: #FF8DE2;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.hasMobileCenteredText && 'text-align: left'};
    ${(p) => p.isHomepage && 'text-align: left; margin-left: 0; margin-right: 0;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    min-width: 370px;
    max-width: 370px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    min-width: 540px;
    max-width: 540px;
    ${(p) => p.position === 'left' && 'min-width: 445px; max-width: 445px;'}
  }
`

const CustomHeading2 = styled(Heading2)`
  max-width: 467px;
  ${(p) => p.isPurple && 'color: #FF8DE3;'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    min-width: 370px;
    max-width: 370px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    min-width: 540px;
    max-width: 540px;
  }
`

const CustomHeading3 = styled(Heading3)`
  max-width: 467px;
  font-weight: 600;
  height: 100%;
  display: flex;
  align-items: center;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    min-width: 370px;
    max-width: 370px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    min-width: 540px;
    max-width: 540px;
  }
`

const Subheading = styled(Paragraph)`
  display: none;
  margin-top: 8px;
  margin-bottom: 16px;
  max-width: 450px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
  }
`

const SubheadingMobile = styled(Paragraph)`
  margin-top: 24px;
  margin-bottom: 16px;
  max-width: 450px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
    margin-left: 0;
    margin-right: 0;
  }
`

const AdditionalDescription = styled(Paragraph)`
  margin-top: 64px;
  margin-bottom: 8px;
  font-weight: 700;
  max-width: 450px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 32px;
    margin-bottom: 0px;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }
`

const Features = styled.ul`
  margin-top: 24px;
  margin-bottom: 40px;
  li {
    display: flex;
    align-items: flex-start;
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
  img {
    margin-right: 9px;
    margin-top: 3px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 32px;
  }
`

const Item = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
`

const ItemHeading = styled.p`
  position: relative;
  padding-left: 20px;
  ${Heading2Styles}
  :before {
    content: '';
    width: 4px;
    height: 26px;
    position: absolute;
    left: 0px;
    top: 4px;
    background-color: #bfb1ff;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 39px;
    line-height: 124%;
    :before {
      height: 39px;
    }
  }
`

const ItemText = styled.p`
  margin-top: 4px;
  padding-left: 24px;
`

const ButtonContainer = styled.div`
  margin-bottom: 40px;
  a:nth-child(2) button {
    margin-top: 8px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 0px;
    a:nth-child(1) button {
      margin-top: 16px;
    }
    a:nth-child(1) {
      margin-right: 16px;
    }
    a:nth-child(2) button {
      margin-top: 16px;
    }
  }

  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    ${(p) => p.pathname === '/serverless-functions/' && `margin-bottom: 40px`};
  }
`

const CodeExampleWrapper = styled.div`
  height: 492px;
  ${(p) => p.example2 && 'height: 600px;'}
  display: flex;
  flex-direction: column;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.example2 && 'height: 450px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    height: auto;
    min-height: 400px;
    max-width: auto;
    min-width: 530px;
    margin-left: 30px;
    ${(p) => p.position === 'left' && 'margin-right: 30px; margin-left: 0px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    min-width: 540px;
    ${(p) => p.position === 'left' && 'max-width: 540px;'}
  }
`

const CodeExampleSpacing = styled.div`
  margin-top: 16px;
`

const ImageContainer = styled.div`
  width: 100%;
  ${(p) => p.isLargeImage && 'display: none;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.isLargeImage && 'display: block; width: 75%; max-width: 450px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 450px;
    margin-left: auto;
    margin-bottom: 32px;
    ${(p) => p.isLargeImage && 'width: 100%; margin-bottom: 0px; '}
    ${(p) => p.pathname === '/serverless-functions/' && `margin-bottom: 0px; align-self: flex-end;`}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 540px;
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
`

const GoBackWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  a {
    display: flex;
    align-items: center;
    background-color: ${(p) => p.theme.textColor};
    padding: 10px 12px;
    border-radius: 4px;
    color: white;
    line-height: 28px;
    letter-spacing: 0.5px;
    font-weight: 600;
  }
`

const Hero = ({
  title,
  heading,
  headingSize,
  features,
  button1Text,
  button1Link,
  button1AnchorLink,
  button2Text,
  button2Link,
  codeExample,
  codeExample2,
  codeExampleContainer,
  image,
  lottieAnimation,
  subheading,
  subheadingMobile,
  additionalDescription,
  align,
  position,
  backgroundColor,
  goBackText,
  goBackLink,
  formattedExamples1,
  formattedExamples2,
  formattedExamples,
  isLargeImage,
  productPage,
  pathname = '',
}) => {
  const example = get(codeExample, 'document.data.example')
  const exampleHeading = get(codeExample, 'document.data.main_heading')
  const example2 = get(codeExample2, 'document.data.example')
  const exampleHeading2 = get(codeExample2, 'document.data.main_heading')
  const exampleContainer = get(codeExampleContainer, 'document.data.body')
  const lottieAnimationUrl = get(lottieAnimation, 'url')
  const hasFeatures = get(features, '[0].feature')
  const hasItems = get(features, '[0].heading')

  const isHomepage = pathname === '/'
  const hasMobileFullWidthText = pathname === '/serverless-functions/'
  const hasMobileCenteredText = pathname === '/serverless-functions/'
  const hasPinkText =
    pathname?.endsWith('game-development/') ||
    pathname?.endsWith('community/') ||
    pathname?.endsWith('pusher-dev-rewards/')

  const formattedHeading = heading.replace('realtime', '<span>realtime</span>')

  return (
    <OuterContainer backgroundColor={backgroundColor} pathname={pathname}>
      <CustomContainer
        position={position}
        goBackLink={goBackLink?.uid}
        isLargeImage={isLargeImage}
        align={align}
        backgroundColor={backgroundColor}
        pathname={pathname}
      >
        <Left position={position} backgroundColor={backgroundColor}>
          {goBackText && (
            <GoBackWrapper>
              <CustomLink link={goBackLink}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect
                    x="5.65698"
                    y="0.34314"
                    width="2"
                    height="8"
                    transform="rotate(45 5.65698 0.34314)"
                    fill="white"
                  />
                  <rect
                    x="7.07129"
                    y="10.2427"
                    width="2"
                    height="8"
                    transform="rotate(135 7.07129 10.2427)"
                    fill="white"
                  />
                </svg>
                <span>{goBackText}</span>
              </CustomLink>
            </GoBackWrapper>
          )}
          {title && <Title isPurple={pathname.endsWith('developer-spotlight')}>{title}</Title>}
          {heading && headingSize === 'large' ? (
            isHomepage ? (
              <CustomHeading1
                position={position}
                backgroundColor={backgroundColor}
                align={align}
                dangerouslySetInnerHTML={{ __html: formattedHeading }}
                hasMobileFullWidthText={hasMobileFullWidthText}
                hasMobileCenteredText={hasMobileCenteredText}
                isHomepage
              />
            ) : (
              <CustomHeading1
                position={position}
                backgroundColor={backgroundColor}
                align={align}
                hasPinkText={hasPinkText}
                hasMobileFullWidthText={hasMobileFullWidthText}
                hasMobileCenteredText={hasMobileCenteredText}
              >
                {heading}
              </CustomHeading1>
            )
          ) : headingSize === 'small' ? (
            <CustomHeading3 as="h1" isPurple={pathname.endsWith('live-events')}>
              {heading}
            </CustomHeading3>
          ) : (
            <CustomHeading2 as="h1" isPurple={pathname.endsWith('live-events')}>
              {heading}
            </CustomHeading2>
          )}
          {subheading && (
            <>
              <Subheading>{widont(subheading)}</Subheading>
              <SubheadingMobile>{widont(subheadingMobile ? subheadingMobile : subheading)}</SubheadingMobile>
            </>
          )}
          {additionalDescription && <AdditionalDescription>{widont(additionalDescription)}</AdditionalDescription>}
          {hasFeatures && (
            <Features>
              {features.map(({ feature }, index) => {
                return (
                  <Paragraph as="li" bold key={index}>
                    <img src={checkIcon} alt="" />
                    {feature}
                  </Paragraph>
                )
              })}
            </Features>
          )}
          {hasItems &&
            features.map((item, index) => {
              const itemHeading = get(item, 'heading')
              const itemText = get(item, 'text')
              return (
                <Item key={index}>
                  <ItemHeading>{itemHeading}</ItemHeading>
                  <ItemText>{itemText}</ItemText>
                </Item>
              )
            })}
          {(button1Text || button2Text) && (
            <ButtonContainer pathname={pathname}>
              {button1Text &&
                (button1Link?.url ? (
                  <CustomLink link={button1Link}>
                    <Button
                      reversedWhite={backgroundColor === 'black' || backgroundColor === 'dark purple' ? true : false}
                      dark={productPage !== 'beams' ? true : false}
                      textColor={
                        backgroundColor === 'black' || backgroundColor === 'dark purple' ? '#300D4F' : undefined
                      }
                      fullWidthOnSmall
                      green={productPage === 'beams' ? true : false}
                    >
                      {button1Text}
                    </Button>
                  </CustomLink>
                ) : (
                  button1AnchorLink && (
                    <a href={`#${button1AnchorLink}`}>
                      <Button
                        reversedWhite={backgroundColor === 'black' ? true : false}
                        dark={productPage !== 'beams' ? true : false}
                        textColor={backgroundColor === 'black' ? '#300D4F' : undefined}
                        fullWidthOnSmall
                        green={productPage === 'beams' ? true : false}
                      >
                        {button1Text}
                      </Button>
                    </a>
                  )
                ))}
              {button2Text && (
                <CustomLink link={button2Link}>
                  <Button
                    reversedWhite={backgroundColor === 'black' ? true : false}
                    dark={productPage !== 'beams' ? true : false}
                    textColor={backgroundColor === 'black' ? '#300D4F' : undefined}
                    fullWidthOnSmall
                    green={productPage === 'beams' ? true : false}
                  >
                    {button2Text}
                  </Button>
                </CustomLink>
              )}
            </ButtonContainer>
          )}
        </Left>
        {exampleContainer ? (
          <CodeExampleContainer exampleContainer={exampleContainer} formattedExamples={formattedExamples} />
        ) : (
          example && (
            <CodeExampleWrapper example2={example2} position={position}>
              <CodeExample examples={example} heading={exampleHeading} formattedExamples={formattedExamples1} />
              {example2 && (
                <>
                  <CodeExampleSpacing />
                  <CodeExample examples={example2} heading={exampleHeading2} formattedExamples={formattedExamples2} />
                </>
              )}
            </CodeExampleWrapper>
          )
        )}
        {lottieAnimationUrl ? (
          <ImageContainer>
            <LottieAnimation path={lottieAnimationUrl} autoplay />
          </ImageContainer>
        ) : (
          image && (
            <ImageContainer isLargeImage={isLargeImage} pathname={pathname}>
              <Image image={image} alt="" />
            </ImageContainer>
          )
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default Hero
