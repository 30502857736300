import React from 'react'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'
import get from 'lodash.get'
import { GatsbyImage } from 'gatsby-plugin-image'

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Heading2Styles, Heading3, Heading4Styles, Heading5Styles, Paragraph } from '../styles/Text'
import { Button } from '../styles/Buttons'

const OuterContainer = styled.div`
  position: relative;
  overflow-y: visible;
  background-color: ${(p) => p.theme.blueishGreyLight};
  ${(p) => p.productPage === 'beams' && `background-color: ${p.theme.greenLight};`}
  ${(p) => p.backgroundColor === 'White' && `background-color: white;`}
  ${(p) => p.backgroundColor === 'Dark Purple' && `background-color: #190C25; color: white;`}
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 72px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-bottom: 64px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-top: 64px;
    padding-bottom: 64px;
    ${(p) => p.version === 'pricing' && 'padding-top: 24px; padding-bottom: 80px;'}
  }
`

const CustomHeading3 = styled(Heading3)`
  max-width: 280px;
  margin-bottom: 32px;
  ${(p) => p.version === 'pricing' && 'text-align: center; margin-left: auto; margin-right: auto; margin-bottom: 16px;'}
  ${(p) =>
    p.version === 'v2' &&
    css`
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 8px;
      ${Heading2Styles}
    `}
    ${(p) => p.backgroundColor === 'Dark Purple' && 'color: #FF8DE2;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: 300px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    ${(p) =>
      p.backgroundColor === 'White' &&
      `text-align: left; margin-left: 0px; margin-right: 0px; margin-bottom: 64px; max-width: 400px;`}
    ${(p) =>
      p.version === 'pricing' && 'text-align: center; margin-left: auto; margin-right: auto; margin-bottom: 16px;'}
      ${(p) =>
      p.version === 'v2' &&
      css`
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
        ${Heading2Styles}
      `}
      ${(p) => !p.hasSubheading && 'margin-bottom: 32px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 400px;
  }
`

const Subheading = styled(Heading3)`
  text-align: center;
  margin-bottom: 40px;
  ${(p) =>
    p.version === 'v2' &&
    css`
      ${Heading5Styles}
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    `}
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity 500ms ease;
  opacity: 0;
  ${(p) => p.inView && 'opacity: 1;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
  }
`

const Item = styled.div`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 18px;
  padding-right: 18px;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.08);
  background-color: white;
  border-radius: 6px;
  :not(:last-child) {
    margin-bottom: 32px;
  }
  a {
    margin-top: 38px;
  }
  ${Button} {
    width: 100%;
  }
  ${(p) =>
    p.backgroundColor === 'White' &&
    css`
      background-color: ${p.theme.blue};
      color: white;
      box-shadow: none;
      a {
        margin-top: 18px;
        margin-right: auto;
      }
    `}
  ${(p) => p.isHomepage && `background-color: ${p.theme.textColor};`}
    ${(p) =>
    p.version === 'pricing' &&
    css`
      border: 2px solid #e2ddf0;
      box-shadow: none;
      a {
        margin-right: auto;
        margin-top: auto;
      }
      p {
        margin-bottom: 40px;
      }
    `}
    ${(p) =>
    p.version === 'v2' &&
    css`
      background-color: white;
      color: ${p.theme.textColor};
      border: 3px solid #e6e2fc;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
    `}
    ${(p) =>
    p.backgroundColor === 'Dark Purple' &&
    css`
      background-color: #190c25;
      border: 3px solid #716689;
      color: white;
      box-shadow: none;
      a {
        margin-top: 18px;
        margin-right: auto;
      }
      ${p.gatsbyImageData && 'border: 3px solid #FF8DE2;'}
    `}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc((100% - 60px) / 3);
    :not(:last-child) {
      margin-bottom: 0px;
      margin-right: 30px;
    }
    ${Paragraph} {
      /* max-width: 135px; */
      margin-left: auto;
      margin-right: auto;
      ${(p) =>
        p.backgroundColor === 'White' &&
        css`
          margin-left: 0px;
          margin-right: 0px;
        `}
      ${(p) => p.version === 'v2' && 'margin-bottom: auto;'}
    }
    a {
      margin-top: 40px;
      align-self: center;
      ${(p) => p.backgroundColor === 'White' && 'margin-top: 28px;'}
    }
    ${(p) =>
      p.itemsCount === 2 &&
      css`
        width: calc(50% - 15px);
      `}
  }
  ${(p) =>
    p.version === 'pricing' &&
    css`
      a {
        margin-right: auto;
        margin-top: auto;
      }
      p {
        margin-bottom: 40px;
      }
    `}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    ${Paragraph} {
      max-width: initial;
    }
  }

  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-top: 16px;
    padding-bottom: 16px;
    ${Paragraph} {
      max-width: initial;
    }
  }
`

const ItemInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${(p) => p.version === 'v2' && 'height: 100%;'}
`

const ItemHeading = styled(Heading3)`
  ${(p) => p.hasIcon && `margin-bottom: 12px;`}
  ${(p) => p.hasIcon && `${Heading4Styles}`}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) => p.hasIcon && `${Heading4Styles}`}
  }
`

const IconWrapper = styled.div`
  width: 24px;
  flex-shrink: 0;
  margin-right: 16px;
  margin-bottom: 16px;
`

const ImageWrapper = styled.div`
  margin-bottom: 16px;
`

const Label = styled.div`
  margin-right: auto;
  margin-bottom: 12px;
  padding: 2px 12px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.blue};
  font-weight: 600;
  color: white;
  line-height: 28px;
  letter-spacing: 0.25px;
  ${(p) => p.green && `background-color: ${p.theme.green}; color: ${p.theme.textColor};`}
`

const BannerWithBoxes = ({ version, heading, subheading, backgroundColor, items, productPage, pathname }) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  const itemsCount = get(items, 'length')

  const isHomepage = pathname === '/'

  return (
    <OuterContainer productPage={productPage} backgroundColor={backgroundColor}>
      <CustomContainer version={version}>
        {heading && (
          <CustomHeading3 backgroundColor={backgroundColor} version={version} hasSubheading={subheading}>
            {heading}
          </CustomHeading3>
        )}
        {subheading && <Subheading version={version}>{subheading}</Subheading>}
        {items && (
          <ItemsContainer ref={inViewRef} inView={inView}>
            {items.map(({ heading, description, button_text, link, icon, image }, index) => {
              const iconUrl = get(icon, 'url')
              const gatsbyImageData = image?.gatsbyImageData
              return version === 'pricing' ? (
                <Item key={index} itemsCount={itemsCount} version={version}>
                  <ItemInner>
                    {index === 0 && <Label>Channels</Label>}
                    {index === 1 && <Label green>Beams</Label>}
                    {heading && (
                      <ItemHeading
                        version={version}
                        hasIcon={iconUrl}
                        center={backgroundColor === 'White' || backgroundColor === 'Dark Purple' ? false : true}
                      >
                        {heading}
                      </ItemHeading>
                    )}
                    {description && (
                      <Paragraph
                        center={backgroundColor === 'White' || backgroundColor === 'Dark Purple' ? false : true}
                      >
                        {description}
                      </Paragraph>
                    )}
                    {button_text && (
                      <CustomLink link={link}>
                        <Button dark>{button_text}</Button>
                      </CustomLink>
                    )}
                  </ItemInner>
                </Item>
              ) : (
                <Item
                  key={index}
                  version={version}
                  backgroundColor={backgroundColor}
                  itemsCount={itemsCount}
                  isHomepage={isHomepage}
                  gatsbyImageData={gatsbyImageData}
                >
                  {gatsbyImageData && (
                    <ImageWrapper>
                      <GatsbyImage image={gatsbyImageData} alt="" />
                    </ImageWrapper>
                  )}
                  {iconUrl && (
                    <IconWrapper>
                      <img src={iconUrl} alt="" />
                    </IconWrapper>
                  )}
                  <ItemInner version={version}>
                    {heading && (
                      <ItemHeading
                        hasIcon={iconUrl}
                        center={backgroundColor === 'White' || backgroundColor === 'Dark Purple' ? false : true}
                      >
                        {heading}
                      </ItemHeading>
                    )}
                    {description && (
                      <Paragraph
                        center={backgroundColor === 'White' || backgroundColor === 'Dark Purple' ? false : true}
                      >
                        {description}
                      </Paragraph>
                    )}
                    {button_text && (
                      <CustomLink link={link}>
                        <Button
                          dark={backgroundColor === 'White' ? true : false}
                          reversedWhite={isHomepage || backgroundColor === 'Dark Purple' ? true : false}
                          textColor={isHomepage || backgroundColor === 'Dark Purple' ? '#300D4F' : 'white'}
                        >
                          {button_text}
                        </Button>
                      </CustomLink>
                    )}
                  </ItemInner>
                </Item>
              )
            })}
          </ItemsContainer>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default BannerWithBoxes
