import React from 'react'
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs'
import get from 'lodash.get'

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Heading2, Heading3, Heading4, Heading5, Heading5Styles } from '../styles/Text'
import { Button } from '../styles/Buttons'

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 80px;
  ${(p) => p.productPage && 'padding-bottom: 72px;'}
  & + div.footer > div {
    margin-top: 0px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.productPage && 'padding-bottom: 43px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

const CustomHeading2 = styled(Heading2)`
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  strong {
    color: ${(p) => p.theme.blue};
    font-weight: 500;
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
`

const ItemsContainer = styled.div`
  width: 100%;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 16px 16px 22px;
  border: 2px solid #ebe7f9;
  margin-bottom: 24px;
  :before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 6px;
    height: calc(100% + 4px);
    background-color: #5d2be7;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: calc(50% - 12px);
  }
`

const ItemTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const PlanName = styled.div`
  display: flex;
  ${Heading5Styles}
  font-weight: 600;
  text-align: left;
  p {
    margin-left: 6px;
  }
  p,
  strong {
    font-weight: 500;
  }
`

const ItemTopRight = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: block;
  }
`

const ItemBottom = styled.div`
  margin-top: 16px;
  color: #7f7fa3;
`

const PlanFeatures = styled.div`
  display: inline-block;
`

const ItemButtonWrapper = styled.div`
  margin-top: 16px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: none;
  }
`

const CustomHeading3 = styled(Heading3)`
  margin-top: 48px;
  text-align: center;
`

const Subheading = styled(Heading4)`
  margin-top: 8px;
  text-align: center;
`

const ButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`

const PricingOverview = ({
  heading,
  ctaTitle,
  ctaHeading,
  ctaSubheading,
  ctaButtonText,
  ctaButtonLink,
  heading2,
  subheading2,
  buttonText,
  buttonLink,
  items,
}) => {
  return (
    <CustomContainer>
      {heading && (
        <CustomHeading2>
          <RichText render={heading?.richText} />
        </CustomHeading2>
      )}
      {items && (
        <Wrapper>
          <ItemsContainer>
            {items.map((item, index) => {
              return <Item key={index} item={item?.plan?.document?.data} />
            })}
            {ctaHeading && (
              <CTAItem
                title={ctaTitle}
                heading={ctaHeading}
                subheading={ctaSubheading}
                buttonText={ctaButtonText}
                buttonLink={ctaButtonLink}
              />
            )}
          </ItemsContainer>
        </Wrapper>
      )}
      {heading2 && <CustomHeading3>{heading2}</CustomHeading3>}
      {subheading2 && <Subheading>{subheading2}</Subheading>}
      {buttonText && buttonLink && (
        <ButtonWrapper>
          <CustomLink link={buttonLink}>
            <Button dark>{buttonText}</Button>
          </CustomLink>
        </ButtonWrapper>
      )}
    </CustomContainer>
  )
}

export default PricingOverview

const Item = ({ item }) => {
  const planName = get(item, 'plan_name')
  const price = item?.price?.richText
  const features = get(item, 'features')
  const buttonText = get(item, 'button_text')
  const buttonLink = get(item, 'button_link')

  return (
    <ItemWrapper>
      <ItemTop>
        <PlanName>
          {planName} <RichText render={price} />
        </PlanName>
        <ItemTopRight>
          {buttonText && (
            <CustomLink link={buttonLink}>
              <Button dark textColor="white">
                {buttonText}
              </Button>
            </CustomLink>
          )}
        </ItemTopRight>
      </ItemTop>
      <ItemBottom>
        <PlanFeatures>
          {features &&
            features.map((item, index) => {
              const feature = get(item, 'feature')
              return (
                <span key={index}>
                  {index !== 0 && ', '}
                  {feature}
                </span>
              )
            })}
        </PlanFeatures>
        {buttonText && (
          <ItemButtonWrapper>
            <CustomLink link={buttonLink}>
              <Button dark textColor="white">
                {buttonText}
              </Button>
            </CustomLink>
          </ItemButtonWrapper>
        )}
      </ItemBottom>
    </ItemWrapper>
  )
}

const CTAItemWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 16px 16px 16px 22px;
  border: 2px solid #ebe7f9;
  margin-bottom: 24px;
  :before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: 6px;
    height: calc(100% + 4px);
    background-color: #5d2be7;
  }
`

const CTAItemTitle = styled(Heading5)`
  margin-bottom: 16px;
  font-weight: 600;
`

const CTAItemHeading = styled(Heading2)``

const CTAItemSubheading = styled(Heading5)`
  margin-top: 16px;
  font-weight: 500;
`

const CTAItemButtonWrapper = styled.div`
  margin-top: 16px;
`

const CTAItem = ({ title, heading, subheading, buttonText, buttonLink }) => {
  return (
    <CTAItemWrapper>
      {title && <CTAItemTitle>{title}</CTAItemTitle>}
      {heading && <CTAItemHeading>{heading}</CTAItemHeading>}
      {subheading && <CTAItemSubheading>{subheading}</CTAItemSubheading>}
      {buttonText && (
        <CTAItemButtonWrapper>
          <CustomLink link={buttonLink}>
            <Button dark textColor="white">
              {buttonText}
            </Button>
          </CustomLink>
        </CTAItemButtonWrapper>
      )}
    </CTAItemWrapper>
  )
}
