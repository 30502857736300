import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import get from 'lodash.get'

import Container from '../styles/Container'
import { Heading1Styles, Paragraph } from '../styles/Text'
import { CustomLink } from '../utils/linkResolver'
import { Button } from '../styles/Buttons'

const CustomContainer = styled(Container)`
  padding-bottom: 72px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    justify-content: center;
    padding-top: 40px;
  }
`

const Text = styled.div`
  a {
    display: inline-block;
    margin-top: 32px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    ${(p) => p.imagePosition === 'left' && 'order: 1;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 450px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    width: 100%;
    max-width: 500px;
    flex-shrink: 0;
    margin-right: auto;
    ${(p) => p.imagePosition === 'left' && 'margin-right: 0px;'}
  }
`

const Heading = styled.h2`
  ${Heading1Styles}
  ${(p) => p.white && 'color: white;'}
`

const Description = styled(Paragraph)`
  margin-top: 8px;
`

const ImageContainer = styled.div`
  width: 100%;
  max-width: 324px;
  align-self: center;
  margin-top: 32px;
  img {
    width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    max-width: initial;
    margin-top: 0px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 370px;
    margin-left: 70px;
    ${(p) => p.imagePosition === 'left' && 'margin-left: 0px; margin-right: 70px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 540px;
    width: 100%;
  }
`

const Item = styled.div`
  margin-top: 24px;
`

const ItemHeading = styled.p`
  position: relative;
  padding-left: 20px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  :before {
    content: '';
    width: 4px;
    height: 28px;
    position: absolute;
    left: 0px;
    top: 4px;
    background-color: #bfb1ff;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 31px;
    line-height: 38px;
    :before {
      height: 31px;
    }
  }
`

const ItemText = styled.p`
  margin-top: 4px;
  padding-left: 24px;
`

const CustomerStory = ({ image, imagePosition, heading, description, buttonText, buttonLink, items }) => {
  const hasItems = items[0].heading
  return (
    <CustomContainer>
      <Wrapper>
        <Text imagePosition={imagePosition}>
          {heading && <Heading>{heading}</Heading>}
          {description && <Description>{description}</Description>}
          {hasItems &&
            items.map((item, index) => {
              const itemHeading = get(item, 'heading')
              const itemText = get(item, 'text')
              return (
                <Item key={index}>
                  <ItemHeading>{itemHeading}</ItemHeading>
                  <ItemText>{itemText}</ItemText>
                </Item>
              )
            })}
          {buttonText && (
            <CustomLink link={buttonLink}>
              <Button dark>{buttonText}</Button>
            </CustomLink>
          )}
        </Text>
        {image && (
          <ImageContainer imagePosition={imagePosition}>
            <GatsbyImage image={image} alt="" />
          </ImageContainer>
        )}
      </Wrapper>
    </CustomContainer>
  )
}

export default CustomerStory
