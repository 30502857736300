import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import get from 'lodash.get'

import Container from '../styles/Container'
import { Heading3, Heading4, Paragraph } from '../styles/Text'

import checkIcon from '../images/check-blue-icon.svg'
import checkIconPurple from '../images/check-icon-purple.svg'
import infoIcon from '../images/info-icon-blue.svg'
import infoIconBlack from '../images/info-icon-black-features.svg'
import PrismicRichText from './PrismicRichText'

const OuterContainer = styled.div`
  ${(p) => p.backgroundColor === 'light grey' && `background-color: ${p.theme.purpleGreyLighter};`}
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 80px;
  ${(p) => p.backgroundColor === 'light grey' && 'padding-top: 12px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 32px;
    padding-bottom: 68px;
    ${(p) => p.backgroundColor === 'light grey' && 'padding-top: 12px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 1010px;
    padding-top: 64px;
    padding-bottom: 12px;
    ${(p) => p.backgroundColor === 'light grey' && 'padding-top: 12px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 1110px;
    padding: 76px 0px 40px;
    ${(p) => p.backgroundColor === 'light grey' && 'padding-top: 12px;'}
  }
`

const CustomHeading3 = styled(Heading3)`
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 730px;
  }
`

const CustomHeading4 = styled(Heading4)`
  margin-top: 16px;
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 730px;
  }
`

const FeaturesHeading = styled(Heading4)`
  margin-bottom: 8px;
`

const Wrapper = styled.div`
  margin-top: 48px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-bottom: 64px;
  }
`

const ItemsContainer = styled.div`
  transition: opacity 500ms ease;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-wrap: wrap;
    max-width: 635px;
    margin-right: 64px;
    ${(p) =>
      p.hasImage &&
      css`
        max-width: 540px;
        margin-right: 15px;
        width: calc(50% - 15px);
      `}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    ${(p) =>
      p.hasImage &&
      css`
        margin-right: 15px;
      `}
  }
`

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  :not(:last-child) {
    margin-bottom: 24px;
  }
  ${(p) => p.hasCustomIcon && 'flex-direction: column;'}
`

const DescriptionParagraph = styled(Paragraph)`
  a {
    padding-bottom: 1px;
    border-bottom: 3px solid ${(p) => p.theme.purpleDark};
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: ${(p) => p.theme.purpleDark};
    transition: all 0.1s ease;
    :hover {
      color: white;
      background-color: ${(p) => p.theme.purple};
      border-bottom: 3px solid ${(p) => p.theme.purple};
    }
    :active {
      color: ${(p) => p.theme.textColor};
      background-color: ${(p) => p.theme.grey};
      border-bottom: 3px solid ${(p) => p.theme.grey};
    }
  }
`

const CheckIcon = styled.img`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  margin-top: 6px;
`

const InfoBox = styled.div`
  display: flex;
  background-color: #f6f6ff;
  padding: 10px;
  align-items: flex-start;
  margin-top: 32px;
  p {
    line-height: 28px;
    letter-spacing: 0.25px;
    font-weight: 500;
  }
  ${(p) => p.backgroundColor === 'light grey' && 'background-color: white; align-self: flex-end;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: 350px;
    margin-top: 0px;
    ${(p) => p.backgroundColor === 'light grey' && 'max-width: 500px;'}
  }
`

const InfoBoxIcon = styled.img`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 4px;
  margin-top: 3px;
`

const ImageContainer = styled.div`
  width: 100%;
  max-width: 400px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  img {
    width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    max-width: initial;
    margin-top: 0px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
  }
`

const CustomIcon = styled.img`
  margin-bottom: 16px;
`

const FeaturesWithInfoBox = ({ heading, subheading, infoBoxText, image, backgroundColor, items, pathname }) => {
  let hasInfoBoxText = false
  if (Array.isArray(infoBoxText?.richText)) {
    hasInfoBoxText = infoBoxText?.richText?.[0]?.text
  }

  return (
    <OuterContainer backgroundColor={backgroundColor}>
      <CustomContainer backgroundColor={backgroundColor}>
        {heading && <CustomHeading3>{heading}</CustomHeading3>}
        {subheading && <CustomHeading4>{subheading}</CustomHeading4>}
        <Wrapper>
          <ItemsContainer hasImage={image}>
            {items &&
              items.map(({ feature_heading, feature_text, icon }, index) => {
                const iconUrl = get(icon, 'url')
                return (
                  <Item key={index} hasCustomIcon={iconUrl}>
                    {iconUrl ? (
                      <CustomIcon src={iconUrl} alt="" />
                    ) : (
                      <CheckIcon src={pathname.endsWith('live-events') ? checkIconPurple : checkIcon} alt="" />
                    )}
                    <div>
                      {feature_heading && <FeaturesHeading>{feature_heading}</FeaturesHeading>}
                      {feature_text && (
                        <DescriptionParagraph as="div">
                          <PrismicRichText text={feature_text} />
                        </DescriptionParagraph>
                      )}
                    </div>
                  </Item>
                )
              })}
          </ItemsContainer>
          {hasInfoBoxText ? (
            <InfoBox backgroundColor={backgroundColor}>
              <>
                <InfoBoxIcon src={backgroundColor === 'light grey' ? infoIconBlack : infoIcon} alt="" />
                <PrismicRichText text={infoBoxText} />
              </>
            </InfoBox>
          ) : (
            image && (
              <ImageContainer>
                <GatsbyImage image={image} alt="" />
              </ImageContainer>
            )
          )}
        </Wrapper>
      </CustomContainer>
    </OuterContainer>
  )
}

export default FeaturesWithInfoBox
