import React from 'react'
import { RichText } from 'prismic-reactjs'

import { linkResolver } from '../utils/linkResolver'

const PrismicRichText = ({ text, htmlSerializer }) => {
  const richText = text?.richText
  if (richText) {
    return <RichText render={richText} linkResolver={linkResolver} {...{ htmlSerializer }} />
  } else {
    return null
  }
}

export default PrismicRichText
