import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import logo from '../../images/pusher-logo.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 32px;
`

const Logo = styled.img`
  height: 54px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    height: 62px;
  }
`

const SignUpLink = styled.a`
  display: flex;
  padding: 8px 16px;
  background-color: ${(p) => p.theme.textColor};
  border-radius: 4px;
  color: white;
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
`

const Navigation = ({ location}) => {
  const BUSINESS_ONLY_PAGES = [
    '/channels/realtime-enterprise/',
  ]

  const signupLink = BUSINESS_ONLY_PAGES.includes(location.pathname)
    ? 'https://dashboard.pusher.com/business_signup'
    : 'https://dashboard.pusher.com/accounts/sign_up'

  return (
    <Wrapper>
      <Link to="/?utm_medium=ads_landing_page&utm_source=ads_landing_page&utm_campaign=logo_traffic_ads_landing_pages">
        <Logo src={logo} alt="Pusher" />
      </Link>

      <SignUpLink href={signupLink}>Sign up</SignUpLink>
    </Wrapper>
  )
}

export default Navigation
