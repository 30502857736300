import React from 'react'
import styled, { css } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Paragraph, Heading2, Heading5, StyledLinkCaretComponent, Heading5Styles } from '../styles/Text'
import { Button } from '../styles/Buttons'

const OuterContainer = styled.div`
  ${(p) =>
    p.backgroundColor === 'black' &&
    css`
      background-color: #190c25;
      color: white;
      & + div.footer > div {
        margin-top: 0px;
      }
    `}
  ${(p) =>
    p.backgroundColor === 'dark purple' &&
    css`
      background-color: #190c25;
      color: white;
    `}
`

const CustomContainer = styled(Container)`
  padding-top: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 40px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 48px;
    padding-bottom: 16px;
  }
`

const HeadingWrapper = styled.div`
  margin-bottom: 40px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-bottom: 72px;
  }
`

const CustomHeading = styled(Heading2)`
  ${(p) => !p.isDarkPurple && 'text-align: center;'}
  ${(p) => p.isDarkPurple && 'color: #FF8DE2;'}
`

const CustomSubheading = styled.p`
  max-width: 600px;
  margin-top: 8px;
  ${Heading5Styles}
`

const OuterWrapper = styled.div`
  > *:not(:last-child) {
    display: block;
    border-bottom: 1px solid #9187ab;
    margin-bottom: 32px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > * {
      width: calc(50% - 15px);
      margin-bottom: 32px;
    }
    > *:not(:last-child) {
      border-bottom: 0px;
      margin-bottom: 90px;
    }
    ${(p) =>
      p.isDarkPurple &&
      css`
        > * {
          width: calc((100% / 3) - 32px);
          margin-bottom: 32px;
        }
      `}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h2 {
    margin-bottom: 8px;
    font-weight: 500;
  }
  ${Paragraph} {
    margin-bottom: 12px;
  }
  padding-bottom: 40px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    ${(p) => p.itemsCount > 1 && 'flex-direction: column;'}
    ${(p) => p.itemsCount > 2 && 'padding-bottom: 0px;'}
    h2 {
      margin-bottom: 16px;
    }
    ${Paragraph} {
      margin-bottom: 0px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    justify-content: center;
  }
`

const Text = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    ${(p) => p.itemsCount > 1 && 'width: 100%;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 445px;
    ${(p) => p.itemsCount > 1 && 'max-width: 100%;'}
    margin-right: auto;
  }
`

const Label = styled.div`
  display: inline-block;
  margin-bottom: 16px;
  padding: 2px 12px;
  background-color: #ff8de2;
  border-radius: 100px;
  color: #300d4f;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.25px;
  ${(p) => p.itemsCount > 2 && 'background-color: #6825B1; color: white;'}
`

const Tags = styled.div`
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;
`

const TextTag = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid #bfb1ff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
`

const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
`

const ImageContainer = styled.div`
  width: 100%;
  align-self: center;
  img {
    width: 100%;
  }
  order: -1;
  margin-bottom: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    align-self: flex-start;
    order: 0;
    margin-bottom: 0px;
    ${(p) => p.itemsCount > 1 && 'width: 100%; order: -1; margin-bottom: 16px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-left: 30px;
    ${(p) => p.itemsCount > 1 && 'max-width: 100%;  margin-left: 0px;'}
  }
`

const BlogLinks = ({ heading, subheading, backgroundColor, items }) => {
  const itemsCount = items?.length

  const isDarkPurple = backgroundColor === 'dark purple'

  return (
    <OuterContainer backgroundColor={backgroundColor}>
      <CustomContainer>
        {heading && (
          <HeadingWrapper>
            <CustomHeading isDarkPurple={isDarkPurple}>{heading}</CustomHeading>
            {subheading && <CustomSubheading>{subheading}</CustomSubheading>}
          </HeadingWrapper>
        )}
        <OuterWrapper itemsCount={itemsCount} isDarkPurple={isDarkPurple}>
          {items.map((item, index) => {
            const { label, heading, description, tag, tag_2, tag_3, image, button_text, link } = item
            const imageData = image?.gatsbyImageData
            const tags = [tag, tag_2, tag_3].filter(Boolean)
            const hasTags = tags?.length > 0
            return (
              <CustomLink key={index} link={link}>
                <Wrapper itemsCount={itemsCount}>
                  <Text itemsCount={itemsCount}>
                    {label && <Label itemsCount={itemsCount}>{label}</Label>}
                    {heading && <Heading5 as="h2">{heading}</Heading5>}
                    {description && <Paragraph>{description}</Paragraph>}
                    {hasTags && (
                      <Tags>
                        {tags?.map((tag, index) => {
                          return <TextTag key={index}>{tag}</TextTag>
                        })}
                      </Tags>
                    )}
                    {button_text && (
                      <ButtonContainer itemsCount={itemsCount}>
                        {button_text && isDarkPurple ? (
                          <Button reversedWhite={true} textColor="black">
                            {button_text}
                          </Button>
                        ) : (
                          <StyledLinkCaretComponent color={backgroundColor === 'black' ? 'white' : undefined}>
                            {button_text}
                          </StyledLinkCaretComponent>
                        )}
                      </ButtonContainer>
                    )}
                  </Text>
                  {imageData && (
                    <ImageContainer itemsCount={itemsCount}>
                      <GatsbyImage image={imageData} alt="" />
                    </ImageContainer>
                  )}
                </Wrapper>
              </CustomLink>
            )
          })}
        </OuterWrapper>
      </CustomContainer>
    </OuterContainer>
  )
}

export default BlogLinks
