import React from 'react'
import { createGlobalStyle } from 'styled-components'

const GeneralHighlightStyle = createGlobalStyle`
.hljs {
  display: block;
  color: white;
  line-height: 1.65;
}

.hljs-comment,
.hljs-quote {
  color: #9389b7;
  font-style: italic;
}

.hljs-keyword,
.hljs-subst {
  color: #b200fd;
}

.hljs-string,
.hljs-doctag {
  color: #6c62be;
}

.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-tag,
.hljs-section,
.hljs-type {
  color: #5654ae;
}

.hljs-params,
.hljs-literal,
.hljs-number {
  color: #724608;
}

.hljs-title {
  color: #7000ff;
  font-weight: normal;
}

.hljs-tag {
  color: #515a6f !important;
}

.hljs-tag,
.hljs-name {
  color: #515a6f;
  font-weight: normal;
}

.hljs-attribute,
.hljs-attr {
  color: #a26612;
}

.hljs-variable,
.hljs-template-variable {
  color: #008080;
}

.hljs-regexp,
.hljs-link {
  color: #b68;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #7000ff;
}

.hljs-meta {
  color: #730ec2;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
`

const ChatkitHighlightStyle = createGlobalStyle`
.hljs {
  display: block;
  color: #C1C1FF;
  line-height: 1.65;
}

.hljs p::before {
  color: white;
}

.hljs-comment,
.hljs-quote {
  color: #8180C8;
  font-style: italic;
}

.hljs-keyword,
.hljs-subst {
  color: #FF8473;
}

.hljs-string,
.hljs-doctag {
  color: #FFD6D6;
}

.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-tag,
.hljs-section,
.hljs-type {
  color: #FFD6D6;
}

.hljs-params,
.hljs-literal,
.hljs-number {
  color: #FFD6D6;
}

.hljs-title {
  color: #FFFFFF;
  font-weight: normal;
}

.hljs-tag {
  color: #515a6f !important;
}

.hljs-tag,
.hljs-name {
  color: #515a6f;
  font-weight: normal;
}

.hljs-attribute,
.hljs-attr {
  color: #FFADA9;
}

.hljs-variable,
.hljs-template-variable {
  color: #008080;
}

.hljs-regexp,
.hljs-link {
  color: #b68;
}

.hljs-symbol,
.hljs-bullet {
  color: #C6FFEC;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #C193FC;
}

.hljs-meta {
  color: #27ae5f;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
`

const BeamsHighlightStyle = createGlobalStyle`
.hljs {
  display: block;
  color: #FFFFFF;
  line-height: 1.65;
}

.hljs p::before {
  color: white;
}

.hljs-comment,
.hljs-quote {
  color: #95a0ba;
  font-style: italic;
}

.hljs-keyword,
.hljs-subst {
  color: #00FFAA;
}

.hljs-string,
.hljs-doctag {
  color: #bfb1ff;
}

.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-tag,
.hljs-section,
.hljs-type {
  color: #C6FFEC;
}

.hljs-params,
.hljs-literal,
.hljs-number {
  color: #82FFD5;
}

.hljs-title {
  color: #FFFFFF;
  font-weight: normal;
}

.hljs-tag {
  color: #515a6f !important;
}

.hljs-tag,
.hljs-name {
  color: #515a6f;
  font-weight: normal;
}

.hljs-attribute,
.hljs-attr {
  color: #C6FFEC;
}

.hljs-variable,
.hljs-template-variable {
  color: #008080;
}

.hljs-regexp,
.hljs-link {
  color: #b68;
}

.hljs-symbol,
.hljs-bullet {
  color: #C6FFEC;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #C193FC;
}

.hljs-meta {
  color: #27ae5f;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
`

const HighlightStyle = ({ productPage }) => {
  return (
    <>
      {productPage === 'chatkit' ? (
        <ChatkitHighlightStyle />
      ) : productPage === 'beams' ? (
        <BeamsHighlightStyle />
      ) : (
        <GeneralHighlightStyle />
      )}
    </>
  )
}

export default HighlightStyle
