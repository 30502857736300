import styled from 'styled-components'

const LandingPageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 40px 16px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 30px;
    max-width: 1224px;
    padding-left: 64px;
    padding-right: 64px;
    > * {
      grid-column: 1/-1;
    }
  }
`

export default LandingPageContainer
