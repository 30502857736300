import React from 'react'
import styled from 'styled-components'
import get from 'lodash.get'
import { useInView } from 'react-intersection-observer'

import CodeExample from './CodeExample'

import Container from '../styles/Container'
import { Heading3, Paragraph } from '../styles/Text'

import checkIcon from '../images/check-icon.svg'
import checkIconBlack from '../images/check-icon-black.svg'

const CustomContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 40px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    ${(p) => p.codeExamplePosition === 'left' && 'padding-bottom: 80px;'}
  }
`

const Left = styled.div`
  margin-bottom: 64px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-bottom: 0px;
    width: calc(50% - 15px);
    ${(p) => p.codeExamplePosition === 'left' && 'order: 1;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 470px;
  }
`

const CustomHeading3 = styled(Heading3)`
  max-width: 467px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    min-width: 370px;
    max-width: 370px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    min-width: 540px;
    max-width: 540px;
  }
`

const Description = styled(Paragraph)`
  margin-top: 8px;
  max-width: 470px;
`

const Features = styled.ul`
  margin-top: 24px;
  margin-bottom: 40px;
  li {
    display: flex;
    align-items: flex-start;
    :not(:last-child) {
      margin-bottom: 8px;
      ${(p) => p.productPage === 'beams' && 'margin-bottom: 16px;'}
    }
  }
  img {
    margin-right: 14px;
    margin-top: 3px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 32px;
  }
`

const CodeExampleContainer = styled.div`
  height: 476px;
  display: flex;
  flex-direction: column;
  transition: opacity 500ms ease;
  opacity: 0;
  ${(p) => p.inView && 'opacity: 1;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 576px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    height: 456px;
    width: calc(50% - 15px);
    max-width: auto;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    height: 438px;
    min-width: 540px;
  }
`

const CheckIcon = styled.img`
  flex-shrink: 0;
`

const TextWithCodeExample = ({
  heading,
  features,
  description,
  codeExample,
  codeExamplePosition,
  productPage,
  formattedExamples,
}) => {
  const example = get(codeExample, 'document.data.example')
  const exampleHeading = get(codeExample, 'document.data.main_heading')
  const hasFeatures = features[0].feature
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  return (
    <CustomContainer codeExamplePosition={codeExamplePosition}>
      <Left codeExamplePosition={codeExamplePosition}>
        {heading && <CustomHeading3>{heading}</CustomHeading3>}
        {description && <Description>{description}</Description>}
        {hasFeatures && (
          <Features productPage={productPage}>
            {features.map(({ feature }, index) => {
              return (
                <Paragraph as="li" bold={productPage === 'beams' ? false : true} key={index}>
                  <CheckIcon src={productPage === 'beams' ? checkIconBlack : checkIcon} alt="" />
                  {feature}
                </Paragraph>
              )
            })}
          </Features>
        )}
      </Left>
      {example && (
        <CodeExampleContainer ref={inViewRef} inView={inView}>
          <CodeExample examples={example} formattedExamples={formattedExamples} heading={exampleHeading} />
        </CodeExampleContainer>
      )}
    </CustomContainer>
  )
}

export default TextWithCodeExample
