const getProductPage = pathname => {
  const path = String(pathname)
  if (path.startsWith('/channels')) {
    return 'channels'
  } else if (path.startsWith('/chatkit')) {
    return 'chatkit'
  } else if (path.startsWith('/beams')) {
    return 'beams'
  } else {
    return undefined
  }
}

export default getProductPage
