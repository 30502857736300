import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import AnimateHeight from 'react-animate-height'

import PrismicRichText from './PrismicRichText'
import CaretSVG from '../../static/images/caret.inline.svg'

import Container from '../styles/Container'
import { Heading2, Paragraph, StyledLinkStyles } from '../styles/Text'

const CustomContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 16px;
  text-align: ${(p) => p.align || 'left'};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-bottom: 0px;
    margin-bottom: -24px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 860px;
    ${(p) => p.version === 'v2' && 'max-width: 1200px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 1280px;
    padding-top: 64px;
  }
`

const Mobile = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
`

const Desktop = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
  }
`

const FAQsContainer = styled.div`
  margin-top: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-top: 48px;
  }
`

const Left = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
  }
`

const Right = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
  }
`

const ThreeColumns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  > * {
    width: calc((100% / 3) - 24px);
  }
`

const FAQs = ({ version, heading, items, align }) => {
  const evenItems = items.filter((item, index) => {
    return index % 2 === 0
  })
  const oddItems = items.filter((item, index) => {
    return index % 2 === 1
  })
  return (
    <CustomContainer version={version} align={align}>
      {heading && <Heading2>{heading}</Heading2>}
      <Mobile>
        <FAQsContainer>
          {items &&
            items.map((item, index) => {
              return <FAQItem key={index} item={item} version={version} />
            })}
        </FAQsContainer>
      </Mobile>
      <Desktop>
        <FAQsContainer>
          {version === 'v2' ? (
            <>
              <ThreeColumns>
                {items &&
                  items.map((item, index) => {
                    return <FAQItem key={index} item={item} version={version} />
                  })}
              </ThreeColumns>
            </>
          ) : (
            <>
              <Left>
                {evenItems &&
                  evenItems.map((item, index) => {
                    return <FAQItem key={index} item={item} version={version} />
                  })}
              </Left>
              <Right>
                {oddItems &&
                  oddItems.map((item, index) => {
                    return <FAQItem key={index} item={item} version={version} />
                  })}
              </Right>
            </>
          )}
        </FAQsContainer>
      </Desktop>
    </CustomContainer>
  )
}

export default FAQs

const FAQItemWrapper = styled.div`
  text-align: left;
  position: relative;
  margin-bottom: 32px;
  ${(p) =>
    p.version === 'v2' &&
    css`
      background-color: #f8f6ff;
      border-radius: 8px;
    `}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 64px;
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${(p) =>
    p.version === 'v2' &&
    css`
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 16px;
      text-align: left;
      column-gap: 24px;
      svg {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
      }
    `}
`

const Question = styled(Paragraph)`
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: 0.5px;
`

const Answer = styled(Paragraph)`
  padding-top: 8px;
  a {
    ${StyledLinkStyles}
    color: ${(p) => p.theme.textColor};
    border-bottom: 3px solid ${(p) => p.theme.textColor};
    :hover {
      background-color: #7d6cad;
      border-bottom: 3px solid #7d6cad;
    }
  }
  ${(p) => p.version === 'v2' && 'padding: 16px; padding-top: 0px;'}
`

const CaretIcon = styled(CaretSVG)`
  transition: transform 0.3s ease-in-out;
  ${(p) => p.$isActive && 'transform: rotate(180deg);'}

  path {
    stroke-width: 1;
  }
`

const FAQItem = ({ item, version }) => {
  const question = item?.question
  const answer = item?.answer

  const [height, setHeight] = useState(0)

  const toggle = () => {
    setHeight((s) => (s === 0 ? 'auto' : 0))
  }

  return (
    <FAQItemWrapper version={version}>
      {version === 'v2' ? (
        <>
          <Top version={version} as="button" onClick={toggle}>
            {question && <Question>{question}</Question>}
            <CaretIcon $isActive={height === 'auto'} />
          </Top>
          <AnimateHeight duration={500} height={height}>
            {answer && (
              <Answer as="div" version={version}>
                <PrismicRichText text={answer} />
              </Answer>
            )}
          </AnimateHeight>
        </>
      ) : (
        <>
          <Top version={version}>{question && <Question>{question}</Question>}</Top>
          {answer && (
            <Answer as="div" version={version}>
              <PrismicRichText text={answer} />
            </Answer>
          )}
        </>
      )}
    </FAQItemWrapper>
  )
}
