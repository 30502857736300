import React from 'react'
import styled, { css } from 'styled-components'

import { CustomLink } from '../../utils/linkResolver'
import PrismicRichText from '../PrismicRichText'

import LandingPageContainer from '../../styles/LandingPageContainer'
import { Button } from '../../styles/Buttons'
import { Heading2, Heading5Styles } from '../../styles/Text'

const CustomContainer = styled(LandingPageContainer)`
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    row-gap: 30px;
  }
`

const Heading = styled(Heading2)`
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: 600;
`

const TextWrapper = styled.div`
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  p {
    ${Heading5Styles}
    font-weight: 600;
  }
  p:not(:last-child) {
    margin-bottom: 1.4em;
  }
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    margin-top: -14px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    display: flex;
    justify-content: center;
    margin-top: -14px;
    ${(p) => p.buttonPosition === 'bottom' && 'margin-top: 16px;'}
  }
`

const Item = styled.div`
  padding: 12px 16px;
  border: 2px solid #d6d1eb;
  border-radius: 4px;
  :not(:first-child) {
    margin-top: 16px;
  }
  ${(p) =>
    p.colorTheme === 'highlight' &&
    css`
      background-color: #f3f0ff;
      border: 2px solid #f3f0ff;
    `}
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    grid-column: span 3;
    padding: 16px;
    border-radius: 8px;
    :not(:first-child) {
      margin-top: 0px;
    }
  }
`

const ItemTop = styled.div`
  padding-bottom: 12px;
`

const ItemHeading = styled.h3`
  color: #5d2be7;
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 31px;
  line-height: 140%;
  letter-spacing: 0.25px;
  ${(p) => p.colorTheme === 'highlight' && `color: ${p.theme.textColor};`}
`

const ItemSubheading = styled.p`
  ${Heading5Styles}
  font-weight: 600;
`

const ItemPrice = styled.div`
  font-family: 'Maison Neue';
  font-size: 16px;
  line-height: 44px;
  letter-spacing: 0.25px;
  overflow-wrap: break-word;
  strong {
    font-size: 32px;
    font-weight: 600;
  }
`

const ItemText = styled.div`
  margin-top: 16px;
  ul {
    :not(:first-child) {
      margin-top: 16px;
    }
    li {
      position: relative;
      padding-left: 12px;
      :not(:first-child) {
        margin-top: 8px;
      }
      ::before {
        content: '';
        width: 4px;
        height: 4px;
        flex-shrink: 0;
        border-radius: 100%;
        margin-right: 8px;
        position: absolute;
        left: 0px;
        top: 9px;
        background-color: ${(p) => p.theme.textColor};
        ${(p) => p.white && 'background-color: white;'}
      }
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    margin-top: 0px;
  }
`

const Plans = ({ slice }) => {
  const heading = slice?.primary?.heading
  const text = slice?.primary?.text
  const buttonText = slice?.primary?.button_text
  const buttonLink = slice?.primary?.button_link
  const buttonPosition = slice?.primary?.button_position
  const items = slice?.items
  return (
    <CustomContainer>
      {heading && <Heading>{heading}</Heading>}
      {text && (
        <TextWrapper>
          <PrismicRichText text={text} />
        </TextWrapper>
      )}
      {buttonText && buttonPosition !== 'bottom' && (
        <ButtonWrapper>
          <CustomLink link={buttonLink}>
            <Button dark>{buttonText}</Button>
          </CustomLink>
        </ButtonWrapper>
      )}
      {items?.map((item, index) => {
        const heading = item?.heading
        const subheading = item?.subheading
        const price = item?.price
        const text = item?.text
        const theme = item?.theme
        return (
          <Item colorTheme={theme} key={index}>
            <ItemTop>
              <ItemHeading colorTheme={theme}>{heading}</ItemHeading>
              {subheading && <ItemSubheading>{subheading}</ItemSubheading>}
              <ItemPrice>
                <PrismicRichText text={price} />
              </ItemPrice>
            </ItemTop>
            <ItemText>
              <PrismicRichText text={text} />
            </ItemText>
          </Item>
        )
      })}
      {buttonText && buttonPosition === 'bottom' && (
        <ButtonWrapper buttonPosition={buttonPosition}>
          <CustomLink link={buttonLink}>
            <Button dark>{buttonText}</Button>
          </CustomLink>
        </ButtonWrapper>
      )}
    </CustomContainer>
  )
}

export default Plans
