import { createGlobalStyle } from 'styled-components'
import modernNormalize from 'styled-modern-normalize'

const GlobalStyle = createGlobalStyle`
  ${modernNormalize}

  * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'Maison Neue', sans-serif;
    font-size: 16px;
    line-height: 28px;
    color: ${(p) => p.theme.textColor};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    padding: 0;
    margin: 0;
    border: none;
    background: none;
    color: inherit;
    cursor: pointer;
  }

  img {
    vertical-align: middle;
  }

  figure {
    margin: 0;
  }

  pre {
    margin: 0;
  }

  blockquote {
    margin: 0;
  }

  .js-focus-visible :focus:not(.focus-visible) {
  outline: none;
  }

  input[type='text'],
  input[type='email'] {
    appearance: none; /* for iOS */
  }

  textarea {
    appearance: none; /* for iOS */
  }

  pre, code {
    font-family: 'Maison Neue Mono', sans-serif;
  }

  .ReactModal__Content {
    border: none !important;
    border-radius: 0 !important;
  }

  .pagination {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 40px 16px;
    position: relative;

    .previous,
    .next {
      display: none;
    }
  }

  .pagination-li {
    border-radius: 4px;
    color: ${(p) => p.theme.textColor};

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      cursor: pointer;
    }

    :hover,
    &.selected {
      background-color: ${(p) => p.theme.chameleon.colors.pusher.lightBlueishGrey};
    }
  }

  .react-tooltip {
    position: absolute;
    width: 200px !important;
    background-color: ${(p) => p.theme.textColor} !important;
    padding: 8px;
    border-radius: 6px;
    opacity: 1;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.25px;
    text-align: center;
    color: white;
    z-index: 9999;

    button {
      font-weight: 600;
      padding-bottom: 1px;
      border-bottom: 3px solid;
  
      :hover {
        color: ${(p) => p.theme.textColor};
        background-color: white;
        border-bottom: 3px solid white;
      }
    }
  }

  .react-tooltip__place-top:after {
    border-top-color: ${(p) => p.theme.textColor};
    border-top-width: 13px;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    bottom: -13px;
    margin-left: -13px;
  }
  .react-tooltip__place-left:after {
    border-left-color: ${(p) => p.theme.textColor};
    border-left-width: 13px;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    right: -13px;
    margin-top: -13px;
  }
  .react-tooltip__place-right:after {
    border-right-color: ${(p) => p.theme.textColor};
    border-right-width: 13px;
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    left: -13px;
    margin-top: -13px;
  }
  .react-tooltip__place-bottom:after {
    border-bottom-color: ${(p) => p.theme.textColor};
    border-bottom-width: 13px;
    border-left: 13px solid transparent;
    border-right: 13px solid transparent;
    top: -13px;
    margin-left: -13px;
  }
  
  .hs-cta-embed {
    display: none!important;
    height: 0!important;
    width: 100%!important;
    overflow: hidden!important;
  }

  [role='tooltip'] {
    pointer-events: all !important;
  }
`

export default GlobalStyle
