import React from 'react'
import { graphql } from 'gatsby'

import Navigation from '../components/LandingPage/Navigation'
import LandingPageComponentSwitcher from '../components/LandingPage/LandingPageComponentSwitcher'
import Footer from '../components/LandingPage/Footer'

const LandingPage = ({ data, location }) => {
  const page = data?.prismicLandingPage?.data
  const slices = page?.body
  return (
    <div>
      <Navigation location={location} />
      <LandingPageComponentSwitcher slices={slices} />
      <Footer />
    </div>
  )
}

export default LandingPage

export const query = graphql`
  query LandingPageQuery($uid: String!) {
    prismicLandingPage(uid: { eq: $uid }) {
      _previewable
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        body {
          ... on PrismicLandingPageDataBodyTextWithImage {
            __typename
            primary {
              heading
              heading_tag
              heading_size
              text_1 {
                richText
              }
              text_2 {
                richText
              }
              button_1_text
              button_1_link {
                ...Link
              }
              button_1_anchor_link
              button_2_text
              button_2_link {
                ...Link
              }
              image {
                gatsbyImageData
              }
              image_position
              label {
                gatsbyImageData
              }
              logo_1 {
                gatsbyImageData
              }
              logo_2 {
                gatsbyImageData
              }
              logo_3 {
                gatsbyImageData
              }
              logo_4 {
                gatsbyImageData
              }
            }
            items {
              item_text {
                richText
              }
            }
          }
          ... on PrismicLandingPageDataBodyCtaBanner {
            __typename
            primary {
              heading
              button_text
              button_link {
                ...Link
              }
            }
          }
          ... on PrismicLandingPageDataBodyDoubleCtas {
            __typename
            primary {
              id
              heading_1
              subheading_1
              text_1 {
                richText
              }
              button_1_text
              button_1_link {
                ...Link
              }
              heading_2
              subheading_2
              text_2 {
                richText
              }
              button_2_text
              button_2_link {
                ...Link
              }
            }
          }
          ... on PrismicLandingPageDataBodyTestimonial {
            __typename
            primary {
              heading
              subheading
              image {
                gatsbyImageData
              }
              quote
              name
              details
            }
          }
          ... on PrismicLandingPageDataBodyLibraries {
            __typename
            primary {
              heading
              row_1_heading
              row_2_heading
            }
            items {
              library_icon {
                url
              }
              library_icon_hover {
                url
              }
              link {
                ...Link
              }
              row
            }
          }
          ... on PrismicLandingPageDataBodyPlans {
            __typename
            primary {
              heading
              text {
                richText
              }
              button_text
              button_link {
                ...Link
              }
              button_position
            }
            items {
              heading
              subheading
              price {
                richText
              }
              text {
                richText
              }
              theme
            }
          }
        }
      }
    }
  }
  fragment Link on PrismicLinkType {
    uid
    type
    link_type
    url
  }
`
