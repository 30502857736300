import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs'
import { CustomLink } from '../utils/linkResolver'

import CodeExample from './CodeExample'

import { Button } from '../styles/Buttons'
import HighlightStyle from '../styles/HighlightStyle'
import theme from '../styles/theme'

const Wrapper = styled.div`
  position: relative;
  width: calc(100% + 32px);
  left: -16px;
  padding: 0px 16px 16px;
  background-color: ${(p) => p.theme.midLighterGrey};
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0px;
    padding: 0px;
    box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    [data-reach-tabs] {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    [data-reach-tab-panels] {
      height: 100%;
      overflow: hidden;
    }
    [data-reach-tab-panel] {
      height: 100%;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-left: 30px;
    width: 530px;
  }

  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    width: 540px;
  }
`

const CustomTabList = styled(TabList)`
  display: flex;
  height: 88px;
  flex-shrink: 0;
  padding: 24px 0px 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding: 24px 24px 16px;
  }
`
const CustomTab = styled(Tab)`
  width: calc(100% / ${(p) => p.count});
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  transition: all 0.1s ease;
  background-color: ${(p) => p.theme.midGrey};
  color: white;
  :last-child {
    /* prevent display issue */
    flex-grow: 1;
  }
  ${(p) =>
    !p.active &&
    css`
      background-color: ${(p) => p.theme.darkerGrey};
      :hover {
        background-color: ${(p) => p.theme.purple};
      }
    `}
  ${(p) => p.first && 'border-top-left-radius: 6px; border-bottom-left-radius: 6px;'}
  ${(p) => p.last && 'border-top-right-radius: 6px; border-bottom-right-radius: 6px;'}
`

const CodeExampleWrapper = styled.div`
  height: 492px;
  ${(p) => p.example2 && 'height: 600px;'}
  display: flex;
  flex-direction: column;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 452px;
    padding-right: 24px;
    padding-bottom: 16px;
    padding-left: 24px;
    ${(p) =>
      p.example2 &&
      css`
        > div {
          height: 210px;
        }
      `}
  }
`

const CodeExampleSpacing = styled.span`
  display: block;
  margin-top: 16px;
`

const ButtonWrapper = styled.div`
  margin-top: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 0px;
    margin-bottom: 24px;
    margin-left: 24px;
  }
`

const CodeExampleContainer = ({ exampleContainer, formattedExamples }) => {
  const [tabIndex, setTabIndex] = useState(0)
  return (
    <>
      <Wrapper>
        <HighlightStyle
          productPage={tabIndex === 0 ? 'channels' : tabIndex === 1 ? 'chatkit' : tabIndex === 2 && 'beams'}
        />
        <Tabs onChange={(index) => setTabIndex(index)}>
          <CustomTabList count={exampleContainer.length}>
            {exampleContainer.map((example, index) => {
              const heading = get(example, 'primary.heading')
              return (
                <CustomTab
                  key={index}
                  count={exampleContainer.length}
                  active={index === tabIndex}
                  first={index === 0}
                  last={index === exampleContainer.length - 1}
                >
                  {heading}
                </CustomTab>
              )
            })}
          </CustomTabList>
          <TabPanels key={Math.random()}>
            {exampleContainer.map((example, index) => {
              const example1 = get(example, 'items[0].code_example.document.data.example')
              const example1Heading = get(example, 'items[0].code_example.document.data.main_heading')
              const example2 = get(example, 'items[1].code_example.document.data.example')
              const example2Heading = get(example, 'items[1].code_example.document.data.main_heading')
              const buttonText = get(example, 'primary.button_text')
              const buttonLink = get(example, 'primary.button_link')
              const formattedExample1 = formattedExamples.find((example) => example.main_heading === example1Heading)
              const formattedExample2 = formattedExamples.find((example) => example.main_heading === example2Heading)
              return (
                <TabPanel key={index}>
                  <CodeExampleWrapper key={index} example2={example2}>
                    <CodeExample examples={example1} heading={example1Heading} formattedExamples={formattedExample1} />
                    {example2 && (
                      <>
                        <CodeExampleSpacing />
                        <CodeExample
                          examples={example2}
                          heading={example2Heading}
                          formattedExamples={formattedExample2}
                        />
                      </>
                    )}
                  </CodeExampleWrapper>
                  {buttonText && (
                    <ButtonWrapper>
                      <CustomLink link={buttonLink}>
                        <Button reversedWhite textColor={theme.midLighterGrey}>
                          {buttonText}
                        </Button>
                      </CustomLink>
                    </ButtonWrapper>
                  )}
                </TabPanel>
              )
            })}
          </TabPanels>
        </Tabs>
      </Wrapper>
    </>
  )
}

export default CodeExampleContainer
