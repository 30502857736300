import React, { useCallback, useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import useEmblaCarousel from 'embla-carousel-react'

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Paragraph, Heading2, Heading3, Heading5, Heading4, Heading3Styles } from '../styles/Text'
import { Button } from '../styles/Buttons'

const OuterContainer = styled.div`
  background-color: #c7b9ff;
  ${(p) => p.backgroundColor === 'black' && 'background-color: #190C25; color: white;'}
  ${(p) => p.backgroundColor === 'white' && 'background-color: white;'}
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 56px;
  padding-left: 0px;
  padding-right: 0px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 40px;
    padding-bottom: 68px;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 64px;
    ${(p) =>
      p.backgroundColor === 'black' &&
      css`
        padding-top: 16px;
        padding-bottom: 24px;
      `}
    padding-left: 0px;
    padding-right: 0px;
  }
`

const EmblaCarouselReact = styled.div`
  overflow: hidden;
  ${(p) =>
    p.backgroundColor === 'black' &&
    'border: 1px solid #6C5F8F; border-radius: 8px; margin-left: 16px; margin-right: 16px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.backgroundColor === 'black' && 'margin-left: 45px; margin-right: 45px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) => p.backgroundColor === 'black' && 'margin-left: 0px; margin-right: 0px;'}
  }
`

const Slide = styled.div`
  flex: 0 0 100%;
  padding-left: 16px;
  padding-right: 16px;
  ${(p) => p.backgroundColor === 'black' && 'padding: 16px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-left: 45px;
    padding-right: 45px;
    ${(p) => p.backgroundColor === 'black' && 'padding: 40px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-left: 85px;
    padding-right: 85px;
    ${(p) => p.backgroundColor === 'black' && 'padding: 40px;'}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${Heading2} {
    margin-bottom: 14px;
  }
  ${Heading5} {
    padding-top: 32px;
    margin-bottom: 8px;
  }
  ${Paragraph} {
    margin-bottom: 40px;
  }
  ${(p) => p.marginTop && 'margin-top: 80px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    ${Heading2}, ${Heading3} {
      padding-top: 0px;
    }
    ${Heading5} {
      padding-top: 0px;
    }
    ${Paragraph} {
      margin-bottom: 0px;
    }
    ${(p) => p.marginTop && 'margin-top: 160px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    justify-content: center;
  }
`

const LogoContainer = styled.div`
  max-width: 255px;
  margin-bottom: 24px;
  img {
    width: 100%;
  }
`

const CustomHeading2 = styled(Heading2)``

const CustomHeading4 = styled(Heading4)`
  margin-bottom: 16px;
`

const Description = styled(Paragraph)`
  display: none;
  ${(p) => p.version === 'v2' && 'display: block;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
  }
`

const Text = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    ${(p) => p.imagePosition === 'left' && 'order: 1;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 445px;
    margin-right: auto;
    ${(p) => p.imagePosition === 'left' && 'margin-right: 0px; margin-left: auto;'}
  }
`

const Label = styled.div`
  display: inline-block;
  margin-bottom: 16px;
  padding: 2px 12px;
  background-color: #ff8de2;
  border-radius: 100px;
  color: #300d4f;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.25px;
  ${(p) => p.itemsCount > 2 && 'background-color: #6825B1; color: white;'}
`

const Tags = styled.div`
  display: none;
  ${(p) => p.version === 'v2' && 'display: block;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
  }
`

const TextTag = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid #bfb1ff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 16px;
    margin-bottom: 0px;
  }
`

const TextTagV2 = styled.div`
  ${Heading3Styles}
  width: 100%;
  margin-top: 16px;
  padding-left: 16px;
  border-left: 4px solid #bfb1ff;
`

const TextTagAdditionalText = styled.p`
  margin-top: 4px;
  padding-left: 20px;
`

const ButtonContainer = styled.div`
  margin-bottom: 8px;
  margin-top: 8px;
  a:nth-child(2) button {
    margin-top: 16px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 0px;
    a:nth-child(1) button {
      margin-top: 16px;
    }
    a:nth-child(1) {
      margin-right: 16px;
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  align-self: center;
  ${(p) => p.mobileFirst && 'order: -1;'}
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    max-width: initial;
    align-self: flex-start;
    margin-bottom: 0px;
    ${(p) => p.mobileFirst && 'order: 0;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 540px;
    margin-left: 30px;
    ${(p) => p.imagePosition === 'left' && 'margin-left: 0px; margin-right: 30px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    width: 100%;
  }
`

const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  ${(p) => p.backgroundColor === 'black' && 'display: none;'}
`

const Tag = styled.button`
  display: flex;
  :not(:last-child) {
    margin-right: 24px;
  }
  p {
    color: white;
    padding: 2px 20px;
    border-radius: 31px;
    background-color: ${(p) => p.theme.textColor};
    color: white;
    :hover {
      color: white;
      background-color: #7d6cad;
    }
    ${(p) =>
      p.selected &&
      css`
        background-color: white;
        color: ${p.theme.textColor};
        :hover {
          background-color: white;
          color: ${p.theme.textColor};
        }
      `}
  }
`

const DotButtonContainer = styled.div`
  margin-top: 32px;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 48px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
`

const DotButton = styled.button`
  width: 16px;
  height: 16px;
  transition: all 0.1s ease;
  border-radius: 16px;
  :not(:last-child) {
    margin-right: 16px;
  }
  background-color: ${(p) => p.theme.textColor};
  ${(p) => p.backgroundColor === 'black' && 'border: 2px solid white; background-color: transparent;'}
  :hover {
    background-color: #7d6cad;
    ${(p) => p.backgroundColor === 'black' && 'background-color: transparent;'}
  }
  ${(p) =>
    p.selected &&
    css`
      background-color: white;
      :hover {
        background-color: white;
      }
      ${p.backgroundColor === 'white' &&
      css`
        background-color: #c7b9ff;
        :hover {
          background-color: #c7b9ff;
        }
      `}
    `}
`

const CaseStudyCarousel = ({ version, items, showButtons, backgroundColor }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollTo = useCallback((index) => emblaApi.scrollTo(index), [emblaApi])

  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }
    if (emblaApi) {
      setScrollSnaps(emblaApi.scrollSnapList())
      emblaApi.on('select', onSelect)
      onSelect()
    }
    return () => emblaApi && emblaApi.destroy()
  }, [emblaApi])

  return (
    <OuterContainer backgroundColor={backgroundColor}>
      <CustomContainer backgroundColor={backgroundColor}>
        {version !== 'v2' && (
          <TagWrapper backgroundColor={backgroundColor}>
            {items &&
              items.map((item, index) => {
                const title = get(item, 'title')
                return (
                  <Tag
                    key={index}
                    selected={index === selectedIndex}
                    onClick={() => scrollTo(index)}
                    aria-label="Change slide"
                  >
                    <Paragraph semibold>{title}</Paragraph>
                  </Tag>
                )
              })}
          </TagWrapper>
        )}
        <EmblaCarouselReact ref={emblaRef} backgroundColor={backgroundColor}>
          <div style={{ display: 'flex' }}>
            {items &&
              items.map((item, index) => {
                const label = get(item, 'title')
                const heading = get(item, 'heading')
                const description = get(item, 'description')
                const tag = get(item, 'tag')
                const tagAdditionalText = get(item, 'tag_text')
                const tag2 = get(item, 'tag_2')
                const tag2AdditionalText = get(item, 'tag_2_text')
                const tag3 = get(item, 'tag_3')
                const tag3AdditionalText = get(item, 'tag_3_text')
                const logoUrl = get(item, 'logo.url')
                const image = item?.image?.gatsbyImageData
                const buttonText = get(item, 'button_text')
                const buttonLink = get(item, 'button_link')
                const tags = [tag, tag2, tag3].filter(Boolean)
                const tagsAdditionalTags = [tagAdditionalText, tag2AdditionalText, tag3AdditionalText].filter(Boolean)
                const hasTags = tags?.length > 0
                return (
                  <Slide backgroundColor={backgroundColor} key={index}>
                    {logoUrl && (
                      <LogoContainer>
                        <img src={logoUrl} alt="" />
                      </LogoContainer>
                    )}
                    <Wrapper>
                      <Text imagePosition={version === 'v2' && 'left'}>
                        {label && <Label>{label}</Label>}
                        {heading && backgroundColor === 'black' ? (
                          <CustomHeading4>{heading}</CustomHeading4>
                        ) : (
                          <CustomHeading2>{heading}</CustomHeading2>
                        )}
                        {description && <Description version={version}>{description}</Description>}
                        {hasTags && (
                          <Tags version={version}>
                            {tags?.map((tag, index) => {
                              const additionalText = tagsAdditionalTags?.[index]
                              return version === 'v2' ? (
                                <>
                                  <TextTagV2 key={index}>{tag}</TextTagV2>
                                  {additionalText && <TextTagAdditionalText>{additionalText}</TextTagAdditionalText>}
                                </>
                              ) : (
                                <TextTag key={index}>{tag}</TextTag>
                              )
                            })}
                          </Tags>
                        )}
                        {buttonText && (
                          <ButtonContainer>
                            {buttonText && (
                              <CustomLink link={buttonLink}>
                                <Button
                                  dark
                                  reversedWhite={backgroundColor === 'black'}
                                  textColor={backgroundColor === 'black' ? '#190C25' : undefined}
                                >
                                  {buttonText}
                                </Button>
                              </CustomLink>
                            )}
                          </ButtonContainer>
                        )}
                      </Text>
                      {image && (
                        <ImageContainer mobileFirst imagePosition={version === 'v2' && 'left'}>
                          <GatsbyImage image={image} alt="" />
                        </ImageContainer>
                      )}
                    </Wrapper>
                  </Slide>
                )
              })}
          </div>
        </EmblaCarouselReact>
        {showButtons && (
          <DotButtonContainer>
            {scrollSnaps.map((snap, index) => {
              return (
                <DotButton
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => scrollTo(index)}
                  aria-label="Change slide"
                  backgroundColor={backgroundColor}
                ></DotButton>
              )
            })}
          </DotButtonContainer>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default CaseStudyCarousel
