import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from '../styles/Container'
import { Heading4, Paragraph } from '../styles/Text'

const OuterContainer = styled.div`
  ${(p) =>
    (p.productPage === 'channels' || p.isHomepage) &&
    p.version === 'v2' &&
    `background-color: ${p.theme.textColor}; margin-top: 0px; color: white;`}
  ${(p) =>
    p.productPage === 'beams' && p.version === 'v2' && `background-color: ${p.theme.greenLight}; margin-top: 36px;`}
    ${(p) => p.backgroundColor === 'grey' && `background-color: #F0F1F6; color: ${p.theme.textColor};`}
    ${(p) => p.backgroundColor === 'white' && `background-color: white; color: ${p.theme.textColor};`}
  & + div.footer > div {
    margin-top: 0px;
  }
`

const CustomContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-bottom: 80px;
    ${(p) => p.version === 'v2' && 'padding-top: 40px; padding-bottom: 40px;'}
  }
`

const Wrapper = styled.div`
  padding: 32px 16px;
  border: 1px solid ${(p) => p.theme.blueishGrey};
  border-radius: 4px;
  background-color: white;
  ${(p) => p.version === 'v2' && 'border: 0px; background-color: transparent; padding: 0px;'}
`

const Quote = styled(Heading4)`
  margin-bottom: 16px;
  max-width: 556px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 860px;
    ${(p) => p.version === 'v2' && 'margin-bottom: 30px;'}
  }
`

const Image = styled(GatsbyImage)`
  display: block !important;
  width: 56px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  ${(p) => p.version === 'v2' && 'width: 169px; margin-bottom: 22px;'}
`

const Testimonial = ({ version, backgroundColor, image, quote, name, details, productPage, pathname }) => {
  const isHomepage = pathname === '/'
  return (
    <OuterContainer
      productPage={productPage}
      isHomepage={isHomepage}
      version={version}
      backgroundColor={backgroundColor}
    >
      <CustomContainer version={version}>
        <Wrapper version={version}>
          {image && <Image image={image} alt={name} version={version} />}
          {quote && (
            <Quote center as="blockquote" version={version}>
              {quote}
            </Quote>
          )}
          {name && (
            <Paragraph center bold>
              {name}
            </Paragraph>
          )}
          {details && <Paragraph center>{details}</Paragraph>}
        </Wrapper>
      </CustomContainer>
    </OuterContainer>
  )
}

export default Testimonial
