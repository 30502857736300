import { useState } from 'react'

const ACTION_TYPES = {
  initial: 'initial',
  triggered: 'triggered',
}

export default function useActionAndReset({ action }) {
  const [state, setState] = useState(ACTION_TYPES.initial)

  const triggerAction = () => {
    if (action && typeof action === 'function') action()
    setState(ACTION_TYPES.triggered)
    setTimeout(() => {
      setState(ACTION_TYPES.initial)
    }, 2000)
  }

  return { state, triggerAction }
}
