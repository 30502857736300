import React from 'react'

import TextWithImage from './TextWithImage'
import CTABanner from './CTABanner'
import Testimonial from './Testimonial'
import Libraries from './Libraries'
import Plans from './Plans'
import DoubleCTAs from './DoubleCTAs'

const LandingPageComponentSwitcher = ({ slices }) => {
  return (
    <>
      {slices?.map((slice, index) => {
        switch (slice?.__typename) {
          case 'PrismicLandingPageDataBodyTextWithImage':
            return <TextWithImage slice={slice} key={'slice-' + index} />
          case 'PrismicLandingPageDataBodyCtaBanner':
            return <CTABanner slice={slice} key={'slice-' + index} />
          case 'PrismicLandingPageDataBodyTestimonial':
            return <Testimonial slice={slice} key={'slice-' + index} />
          case 'PrismicLandingPageDataBodyLibraries':
            return <Libraries slice={slice} key={'slice-' + index} />
          case 'PrismicLandingPageDataBodyPlans':
            return <Plans slice={slice} key={'slice-' + index} />
          case 'PrismicLandingPageDataBodyDoubleCtas':
            return <DoubleCTAs slice={slice} key={'slice-' + index} />
          default:
            return null
        }
      })}
    </>
  )
}

export default LandingPageComponentSwitcher
