import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import loadable from '@loadable/component'
const VideoPlayer = loadable(() => import('./VideoPlayer'))

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Paragraph, Heading1, Heading2, Heading3, Heading5 } from '../styles/Text'
import { Button } from '../styles/Buttons'
import theme from '../styles/theme'

const OuterContainer = styled.div`
  background-color: ${(p) => p.theme.nearBlack};
  color: white;
  ${(p) => p.backgroundColor === 'dark purple' && `background-color: ${p.theme.darkPurple};`}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-top: 8px;
  }
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 56px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 40px;
    padding-bottom: 68px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 48px;
    ${(p) => p.backgroundColor === 'dark purple' && `padding-top: 64px; padding-bottom: 112px;`}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${Heading2} {
    margin-bottom: 14px;
  }
  ${Heading5} {
    padding-top: 32px;
    margin-bottom: 8px;
  }
  ${Paragraph} {
    margin-bottom: 40px;
  }
  ${(p) => p.marginTop && 'margin-top: 80px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    ${Heading2}, ${Heading3} {
      padding-top: 0px;
    }
    ${Heading5} {
      padding-top: 0px;
    }
    ${Paragraph} {
      margin-bottom: 0px;
    }
    ${(p) => p.marginTop && 'margin-top: 160px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    justify-content: center;
  }
`

const LogoContainer = styled.div`
  max-width: 140px;
  margin-top: 22px;
  margin-bottom: 32px;
  img {
    width: 100%;
  }
`

const CustomHeading1 = styled(Heading1)`
  margin-bottom: 24px;
`

const CustomHeading3 = styled(Heading3)`
  margin-bottom: 16px;
`

const Text = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    ${(p) => p.imagePosition === 'left' && 'order: 1;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 445px;
    margin-right: auto;
    ${(p) => p.imagePosition === 'left' && 'margin-right: 0px; margin-left: auto;'}
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 48px;
  margin-top: 8px;
  a:nth-child(2) button {
    margin-top: 16px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 0px;
    a:nth-child(1) button {
      margin-top: 16px;
    }
    a:nth-child(1) {
      margin-right: 16px;
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  align-self: center;
  ${(p) => p.mobileFirst && 'order: -1;'}
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    max-width: initial;
    align-self: flex-start;
    margin-bottom: 0px;
    ${(p) => p.mobileFirst && 'order: 0;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 540px;
    margin-left: 30px;
    ${(p) => p.imagePosition === 'left' && 'margin-left: 0px; margin-right: 30px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    width: 100%;
  }
`

const VideoContainer = styled.div`
  width: 100%;
  align-self: center;
  margin-bottom: 24px;
  order: 1;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    align-self: flex-start;
    margin-bottom: 0px;
    order: 0;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-right: 30px;
  }
`

const VideoInner = styled.div`
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  > div {
    position: absolute !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100% !important;
    height: auto !important;
  }
`

const Tag = styled.div`
  display: flex;
  p {
    color: white;
    padding: 0px 12px;
    border-radius: 31px;
    background-color: ${(p) => p.theme.midLightGrey};
    margin-bottom: 32px;
    ${(p) => p.backgroundColor === 'dark purple' && `background-color: transparent; border: 2px solid white;`}
  }
`

const CaseStudy = ({
  backgroundColor,
  heading,
  description,
  logo,
  image,
  buttonText,
  buttonLink,
  videoUrl,
  heading2,
  description2,
  buttonText2,
  buttonLink2,
  heading3,
  description3,
  buttonText3,
  buttonLink3,
  image3,
  image3Position,
}) => {
  return (
    <OuterContainer backgroundColor={backgroundColor}>
      <CustomContainer backgroundColor={backgroundColor}>
        <Tag backgroundColor={backgroundColor}>
          <Paragraph semibold>Case study</Paragraph>
        </Tag>
        {logo && (
          <LogoContainer>
            <img src={logo} alt="" />
          </LogoContainer>
        )}
        <Wrapper>
          <Text>
            {heading && <CustomHeading1>{heading}</CustomHeading1>}
            {description && <Paragraph>{description}</Paragraph>}
            {buttonText && (
              <ButtonContainer>
                {buttonText && (
                  <CustomLink link={buttonLink}>
                    <Button fullWidthOnSmall reversedWhite textColor={theme.darkerGrey}>
                      {buttonText}
                    </Button>
                  </CustomLink>
                )}
              </ButtonContainer>
            )}
          </Text>
          {image && (
            <ImageContainer mobileFirst>
              <GatsbyImage image={image} alt="" />
            </ImageContainer>
          )}
        </Wrapper>
        {heading2 && (
          <Wrapper marginTop>
            {videoUrl && (
              <VideoContainer>
                <VideoInner>
                  <VideoPlayer url={videoUrl} />
                </VideoInner>
              </VideoContainer>
            )}
            <Text imagePosition="left">
              {heading2 && <CustomHeading3>{heading2}</CustomHeading3>}
              {description2 && <Paragraph>{description2}</Paragraph>}
              {buttonText2 && (
                <ButtonContainer>
                  {buttonText2 && (
                    <CustomLink link={buttonLink2}>
                      <Button fullWidthOnSmall reversedWhite textColor={theme.darkerGrey}>
                        {buttonText2}
                      </Button>
                    </CustomLink>
                  )}
                </ButtonContainer>
              )}
            </Text>
          </Wrapper>
        )}
        {heading3 && (
          <Wrapper marginTop>
            <Text imagePosition={image3Position}>
              {heading3 && <CustomHeading3>{heading3}</CustomHeading3>}
              {description3 && <Paragraph>{description3}</Paragraph>}
              {buttonText3 && (
                <ButtonContainer>
                  {buttonText3 && (
                    <CustomLink link={buttonLink3}>
                      <Button fullWidthOnSmall reversedWhite textColor={theme.darkerGrey}>
                        {buttonText3}
                      </Button>
                    </CustomLink>
                  )}
                </ButtonContainer>
              )}
            </Text>
            {image3 && (
              <ImageContainer mobileFirst imagePosition={image3Position}>
                <GatsbyImage image={image3} alt="" />
              </ImageContainer>
            )}
          </Wrapper>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default CaseStudy
