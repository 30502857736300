import React from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import { useInView } from 'react-intersection-observer'
import widont from '../utils/widont'
import { RichText } from 'prismic-reactjs'
import { CustomLink, linkResolver } from '../utils/linkResolver'

import Container from '../styles/Container'
import {
  Heading1Styles,
  Heading2,
  Heading2Styles,
  Heading3,
  Heading4,
  Heading5Styles,
  Paragraph,
  StyledLinkStyles,
} from '../styles/Text'

import checkIcon from '../images/check-icon.svg'
import { Button } from '../styles/Buttons'

const OuterContainer = styled.div`
  ${(p) =>
    p.colorTheme === 'dark purple' &&
    css`
      background-color: #190c25;
      color: white;
    `}
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 80px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 32px;
    padding-bottom: 68px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 64px;
    padding-bottom: 12px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 1110px;
    padding-top: 64px;
    padding-bottom: 40px;
    ${(p) =>
      p.colorTheme === 'blue' &&
      css`
        padding-top: 54px;
        padding-left: 0px;
        padding-right: 0px;
      `}
    ${(p) =>
      (p.itemsPerRow === '3 per row' || p.itemsPerRow === '4 per row') &&
      css`
        padding-left: 0px;
        padding-right: 0px;
      `}
      ${(p) => p.largePadding && 'padding-top: 128px; padding-bottom: 128px;'}
  }
`

const CustomHeading2 = styled(Heading2)`
  margin-bottom: 48px;
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-bottom: 64px;
  }
`

const CustomHeading3 = styled(Heading3)`
  margin-bottom: 48px;
  ${(p) =>
    p.version === 'v2' &&
    css`
      ${Heading2Styles}
    `}
  ${(p) =>
    p.version === 'v3' &&
    css`
      ${Heading1Styles}
      text-align: center;
    `}
    ${(p) => p.hasPinkText && 'color: #FF8DE2;'}
    @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-bottom: 64px;
    ${(p) => (p.colorTheme === 'dark purple' || p.version === 'v2') && 'margin-bottom: 8px;'}
    ${(p) => p.version === 'v3' && 'margin-bottom: 8px;'}
  }
`

const CustomHeading4 = styled(Heading4)`
  margin-bottom: 85px;
  ${(p) =>
    p.version === 'v2' &&
    css`
      ${Heading5Styles}
    `}
  ${(p) => p.version === 'v3' && 'text-align: center; margin-bottom: 32px;'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 610px;
    ${(p) => p.version === 'v3' && 'margin-left: auto; margin-right: auto;'}
  }
`

const IconContainer = styled.div`
  transition: opacity 500ms ease;
  opacity: 0;
  ${(p) => p.inView && 'opacity: 1;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-wrap: wrap;
  }
`

const Item = styled.div`
  :not(:last-child) {
    margin-bottom: 48px;
  }
  ${(p) => p.version === 'v3' && 'margin-bottom: 24px !important;'}
  ${(p) =>
    p.colorTheme === 'blue' &&
    css`
      border: 1px solid ${(p) => p.theme.blueLight};
      padding: 8px 34px 18px 8px;
      h4 {
        color: #6a52ff;
        margin-bottom: 8px;
      }
    `}
  ${(p) =>
    p.itemsPerRow === '3 per row' &&
    css`
      h4 {
        margin-bottom: 8px;
      }
    `}
      ${(p) =>
    p.version === 'v3' &&
    css`
      display: flex;
      align-items: center;
      border: 3px solid #e6e2fc;
      border-radius: 8px;
      h4 {
        margin-bottom: 0px;
        ${Heading5Styles}
      }
    `}

  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    :not(:last-child) {
      margin-bottom: 56px;
    }
    ${(p) =>
      p.colorTheme === 'blue' &&
      css`
        :not(:last-child),
        :last-child {
          margin-bottom: 32px;
        }
      `}
    width: calc(50% - 15px);
    :nth-child(2n + 1) {
      margin-right: 15px;
    }
    :nth-child(2n) {
      margin-left: 15px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: flex;
    align-items: flex-start;
    ${(p) =>
      p.itemsPerRow === '3 per row' &&
      css`
        flex-direction: column;
        width: calc((100% / 3) - 20px);
        :nth-child(2n + 1) {
          margin-right: 0px;
        }
        :nth-child(2n) {
          margin-left: 0px;
        }
        :nth-child(3n + 1),
        :nth-child(3n + 2) {
          margin-right: 30px;
        }
      `}
    ${(p) => p.version === 'v3' && 'flex-direction: row; align-items: center;'}
    ${(p) =>
      p.itemsPerRow === '4 per row' &&
      css`
        flex-direction: column;
        width: calc(25% - 22.5px);
        :nth-child(2n + 1) {
          margin-right: 0px;
        }
        :nth-child(2n) {
          margin-left: 0px;
        }
        :nth-child(4n + 1),
        :nth-child(4n + 2),
        :nth-child(4n + 3) {
          margin-right: 30px;
        }
      `}
  }
`

const Icon = styled.img`
  width: 64px;
  margin-bottom: 24px;
  flex-shrink: 0;
  ${(p) => p.version === 'v3' && 'width: 56px; margin: 5px;'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-right: 32px;
    ${(p) => (p.itemsPerRow === '3 per row' || p.itemsPerRow === '4 per row') && 'margin-bottom: 24px;'}
    ${(p) => p.version === 'v3' && 'margin: 5px;'}
  }
`

const FeaturesContainer = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid ${(p) => p.theme.blueishGrey};
  transition: opacity 500ms ease;
  opacity: 0;
  ${(p) => p.inView && 'opacity: 1;'}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-top: 12px;
  }
`

const Features = styled.div`
  margin-top: 32px;
  li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  img {
    margin-right: 9px;
    margin-top: 3px;
  }
  p {
    display: flex;
    align-items: center;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    margin-bottom: 12px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-bottom: 76px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-bottom: 48px;
  }
`

const Left = styled.ul`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    margin-right: 15px;
  }
`
const Right = styled.ul`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    margin-left: 15px;
  }
`

const Badge = styled.span`
  display: inline-block;
  padding-right: 8px;
  padding-left: 8px;
  margin-left: 8px;
  background-color: #ff8473;
  border-radius: 4px;
  font-size: 12px;
  line-height: 26px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: white;
`

const DescriptionParagraph = styled(Paragraph)`
  a {
    ${StyledLinkStyles}
    ${(p) =>
      p.productPage === 'channels' &&
      css`
        border-color: ${(p) => p.theme.textColor};
        color: ${(p) => p.theme.textColor};
        :hover {
          background-color: #7d6cad;
          border-color: #7d6cad;
        }
      `}
  }
`

const CheckIcon = styled.img`
  flex-shrink: 0;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`

const FeaturesSmall = ({
  version,
  itemsPerRow,
  heading,
  description,
  items,
  additionalFeatures,
  buttonText,
  buttonLink,
  theme,
  productPage,
  pathname = '',
}) => {
  const hasAdditionalFeatures = get(additionalFeatures, 'features[0].heading')
  const featuresCount = get(additionalFeatures, 'features.length')
  const [inViewRef1, inView1] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  const [inViewRef2, inView2] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })

  const hasPinkText = pathname?.endsWith('game-development/')

  return (
    <OuterContainer colorTheme={theme}>
      <CustomContainer
        colorTheme={theme}
        itemsPerRow={itemsPerRow}
        largePadding={itemsPerRow === '4 per row' && !(heading || description)}
      >
        {heading &&
          (theme === 'blue' ? (
            <CustomHeading2>{heading}</CustomHeading2>
          ) : (
            <CustomHeading3 version={version} productPage={productPage} colorTheme={theme} hasPinkText={hasPinkText}>
              {heading}
            </CustomHeading3>
          ))}
        {description && <CustomHeading4 version={version}>{description}</CustomHeading4>}
        <IconContainer ref={inViewRef1} inView={inView1}>
          {items &&
            items.map(({ icon, heading, descriptionJSON }, index) => {
              const iconUrl = get(icon, 'url')
              const description = descriptionJSON?.richText
              return (
                <Item itemsPerRow={itemsPerRow} key={index} colorTheme={theme} version={version}>
                  <Icon src={iconUrl} alt="" itemsPerRow={itemsPerRow} version={version} />
                  <div>
                    {heading &&
                      (itemsPerRow === '3 per row' || itemsPerRow === '4 per row' || theme === 'blue' ? (
                        <Heading4>{heading}</Heading4>
                      ) : (
                        <Paragraph bold>{heading}</Paragraph>
                      ))}
                    {description && (
                      <DescriptionParagraph as="div" productPage={productPage}>
                        <RichText render={description} linkResolver={linkResolver} />
                      </DescriptionParagraph>
                    )}
                  </div>
                </Item>
              )
            })}
        </IconContainer>
        {hasAdditionalFeatures && (
          <FeaturesContainer ref={inViewRef2} inView={inView2}>
            {additionalFeatures.main_heading && <Heading4>{additionalFeatures.main_heading}</Heading4>}
            <Features>
              <Left>
                {additionalFeatures.features.map((feature, index) => {
                  const heading = get(feature, 'heading')
                  const description = get(feature, 'description')
                  const badgeText = get(feature, 'badge_text')
                  if (index < featuresCount / 2) {
                    return (
                      <li key={index}>
                        <CheckIcon src={checkIcon} alt="" />
                        <div>
                          <Paragraph bold>
                            {heading}
                            {badgeText && <Badge>{badgeText}</Badge>}
                          </Paragraph>

                          <Paragraph>{widont(description)}</Paragraph>
                        </div>
                      </li>
                    )
                  }
                })}
              </Left>
              <Right>
                {additionalFeatures.features.map((feature, index) => {
                  const heading = get(feature, 'heading')
                  const description = get(feature, 'description')
                  const badgeText = get(feature, 'badge_text')
                  if (index >= featuresCount / 2) {
                    return (
                      <li key={index}>
                        <CheckIcon src={checkIcon} alt="" />
                        <div>
                          <Paragraph bold>
                            {heading}
                            {badgeText && <Badge>{badgeText}</Badge>}
                          </Paragraph>

                          <Paragraph>{widont(description)}</Paragraph>
                        </div>
                      </li>
                    )
                  }
                })}
              </Right>
            </Features>
          </FeaturesContainer>
        )}
        {buttonText && (
          <ButtonWrapper>
            {buttonText && (
              <CustomLink link={buttonLink}>
                <Button dark>{buttonText}</Button>
              </CustomLink>
            )}
          </ButtonWrapper>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default FeaturesSmall
