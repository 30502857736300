import React from 'react'
import styled from 'styled-components'

import PrismicRichText from './PrismicRichText'

import Container from '../styles/Container'
import { Heading1Styles, Heading3Styles, ParagraphStyles, Heading5Styles, Heading4Styles } from '../styles/Text'

const CustomContainer = styled(Container)`
  padding-top: 24px;
  padding-bottom: 48px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 64px;
    padding-bottom: 36px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-bottom: 8px;
  }
`

const Wrapper = styled.div`
  max-width: 908px;
  margin-left: auto;
  margin-right: auto;
  h1 {
    ${Heading1Styles}
    margin-bottom: 48px;
  }
  h2 {
    ${Heading3Styles}
    margin-bottom: 16px;
  }
  h3 {
    ${Heading4Styles}
    margin-bottom: 16px;
  }
  h4 {
    ${Heading5Styles}
    margin-bottom: 16px;
    font-weight: 500;
  }
  p,
  ul,
  ol {
    ${ParagraphStyles}
  }
  li {
    margin-bottom: 24px;
  }
  ol {
    margin: 0;
    padding: 0;
    padding-left: 20px;
    text-indent: -20px;
    list-style: none;
    counter-reset: custom-counter;
    li {
      counter-increment: custom-counter;
      ::before {
        content: counter(custom-counter) '. ';
        font-weight: 700;
      }
    }
  }
  ul li {
    position: relative;
    text-indent: 0px;
    padding-left: 14px;
    margin-bottom: 0px;
    ::before {
      content: '•';
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
  p {
    margin-bottom: 28px;
  }
  p + h2,
  p + h3,
  p + h4,
  ul + h2,
  ul + h3,
  ul + h4 {
    margin-top: 48px;
  }
  p + ol,
  p + ul {
    margin-top: -4px;
  }
  a {
    font-weight: 600;
    text-decoration: underline;
    overflow-wrap: break-word;
  }
  strong + a {
    font-weight: 700;
  }
`

const Table = styled.div`
  margin-top: 48px;
`

const Row = styled.div`
  padding: 8px 0px;
  border-top: 2px solid #eeecfb;
  :last-child {
    border-bottom: 2px solid #eeecfb;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
  }
`

const Column = styled.div`
  p {
    font-weight: 700;
    margin-bottom: 0px;
    a {
      font-weight: 500;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 50%;
  }
`

const Text = ({ text, items }) => {
  const hasItems = items?.[0]?.table_left?.richText?.length > 0

  return (
    <CustomContainer>
      <Wrapper>
        {text && <PrismicRichText text={text} />}
        {hasItems && (
          <Table>
            {items?.map((item, index) => {
              const tableLeft = item?.table_left
              const tableRight = item?.table_right
              return (
                <Row key={index}>
                  {tableLeft && (
                    <Column>
                      <PrismicRichText text={tableLeft} />
                    </Column>
                  )}
                  {tableRight && (
                    <Column>
                      <PrismicRichText text={tableRight} />
                    </Column>
                  )}
                </Row>
              )
            })}
          </Table>
        )}
      </Wrapper>
    </CustomContainer>
  )
}

export default Text
