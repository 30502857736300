import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import PrismicRichText from './PrismicRichText'

import Container from '../styles/Container'
import { Heading2Styles, Heading3, Heading4Styles, Heading5, ParagraphStyles } from '../styles/Text'

import caretDown from '../images/caret-down.svg'

const CustomContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 40px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
  }
`

const Heading = styled(Heading3)`
  margin-bottom: 24px;
`

const Tabs = styled.div`
  display: flex;
  column-gap: 16px;
`

const Tab = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f6ff;
  padding: 8px;
  border-radius: 4px;
  ${ParagraphStyles}
  text-align: center;
  font-weight: 600;
  min-height: 56px;
  display: none;
  ${(p) => p.active && 'background-color: #E6E2FC;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
  }
`

const SelectContainer = styled.div`
  position: relative;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
  ::before {
    content: '';
    background-image: url(${caretDown});
    background-size: cover;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
`

const Select = styled.select`
  width: 100%;
  padding: 5px 13px;
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${(p) => p.theme.textColor};
  border: 3px solid #e6e2fc;
  appearance: button;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
`

const Categories = ({ heading, items }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const splitItems = items
    .reduce((acc, cur) => {
      acc[cur['item']] = [...(acc[cur['item']] || []), cur]
      return acc
    }, [])
    .filter(Boolean)

  const currentItemArray = splitItems?.[selectedTab]
  return (
    <CustomContainer>
      {heading && <Heading>{heading}</Heading>}
      <Tabs>
        {splitItems?.map((itemArray, index) => {
          const title = itemArray?.[0]?.title
          return (
            <Tab onClick={() => setSelectedTab(index)} active={selectedTab === index} key={index}>
              {title}
            </Tab>
          )
        })}
      </Tabs>
      <SelectContainer>
        <Select value={selectedTab} onChange={(e) => setSelectedTab(Number(e?.target?.value))}>
          {splitItems?.map((itemArray, index) => {
            const title = itemArray?.[0]?.title

            return (
              <option value={index} key={index}>
                {title}
              </option>
            )
          })}
        </Select>
      </SelectContainer>
      <ContentPanel items={currentItemArray} key={currentItemArray} />
    </CustomContainer>
  )
}

export default Categories

const Content = styled.div`
  margin-top: 24px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
`

const Left = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 24px);
  }
`

const Right = styled.div`
  margin-top: 24px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 24px);
    margin-top: 0px;
  }
`

const Title = styled.h3`
  margin-bottom: 16px;
  ${Heading2Styles}
`

const TextWrapper = styled.div`
  a {
    text-decoration: underline;
    font-weight: 600;
  }
`

const FeaturesTitle = styled.h4`
  margin-top: 16px;
  margin-bottom: 8px;
  ${Heading4Styles}
`

const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

const Feature = styled.button`
  border: 2px solid #e6e2fc;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.25px;
  padding: 0px 12px;
  ${(p) =>
    p.active &&
    css`
      background-color: #916aff;
      border: 2px solid #916aff;
      color: white;
    `}
`

const SelectedWrapper = styled.div`
  background-color: #f8f6ff;
  border-radius: 8px;
  padding: 16px;
  a {
    text-decoration: underline;
    font-weight: 600;
  }
`

const FeatureTitle = styled(Heading5)`
  margin-top: 16px;
  font-weight: 600;
`

const ContentPanel = ({ items }) => {
  const [selectedFeature, setSelectedFeature] = useState(undefined)
  const mainItem = items?.[0]
  const title = mainItem?.title
  const text = mainItem?.item_text
  const image = mainItem?.image?.gatsbyImageData
  const features = items?.filter((_, index) => index > 0)
  const currentFeature = features?.[selectedFeature]
  const featureImage = currentFeature?.image?.gatsbyImageData
  const featureTitle = currentFeature?.title
  const featureText = currentFeature?.item_text
  const hasFeatures = features?.length > 0
  return (
    <Content>
      <Left>
        <Title>{title}</Title>
        {text && (
          <TextWrapper>
            <PrismicRichText text={text} />
          </TextWrapper>
        )}
        {hasFeatures && (
          <>
            <FeaturesTitle>{title} features:</FeaturesTitle>
            <Features>
              {features?.map((feature, index) => {
                const title = feature?.title
                const active = selectedFeature === index
                return (
                  <Feature onClick={() => setSelectedFeature(active ? undefined : index)} active={active} key={index}>
                    {title}
                  </Feature>
                )
              })}
            </Features>
          </>
        )}
      </Left>
      <Right>
        {selectedFeature !== undefined ? (
          <SelectedWrapper>
            {featureImage && <GatsbyImage image={featureImage} alt="" />}
            <FeatureTitle>{featureTitle}</FeatureTitle>
            {featureText && <PrismicRichText text={featureText} />}
          </SelectedWrapper>
        ) : (
          image && <GatsbyImage image={image} alt="" />
        )}
      </Right>
    </Content>
  )
}
