import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { addIdsHtmlSerializer } from '../utils/htmlSerializer'

import PrismicRichText from './PrismicRichText'

import Container from '../styles/Container'
import { ParagraphStyles, Heading5Styles, Heading1, StyledLinkStyles } from '../styles/Text'

import caretDown from '../images/caret-down.svg'

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
`

const Mobile = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
`

const Desktop = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
  }
`

const Heading = styled(Heading1)`
  margin-bottom: 16px;
`

const HeadingText = styled.p`
  max-width: 650px;
  a {
    ${StyledLinkStyles}
    border-color: ${(p) => p.theme.textColor};
    color: ${(p) => p.theme.textColor};
    overflow-wrap: break-word;
    :hover {
      background-color: #6825b1;
      border-color: #6825b1;
    }
  }
`

const GlossaryContainer = styled(Container)`
  padding-bottom: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    padding-bottom: 64px;
  }
`

const GlossaryNavigation = styled.ul`
  margin-bottom: 12px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 25%;
    flex-shrink: 0;
    margin-bottom: 0px;
  }
`

const Item = styled.li`
  :not(:last-child) {
    margin-bottom: 8px;
  }
`

const LetterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  line-height: 175%;
  font-weight: 600;
  ${(p) => p.isActive && 'background-color: #bfb1ff;'}
  :hover {
    background-color: #f0f1f6;
    ${(p) => p.isActive && 'background-color: #bfb1ff;'}
  }
  :focus-visible {
    border: 2px solid #458fff;
    ${(p) => p.isActive && 'background-color: #bfb1ff;'}
  }
  :active {
    background-color: #bfb1ff;
  }
`

const TextWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  h3 {
    ${Heading5Styles}
    margin-bottom: 8px;
    scroll-margin-top: 100px;
  }
  p,
  ul,
  ol {
    ${ParagraphStyles}
  }
  li {
    margin-bottom: 24px;
  }
  ol {
    margin: 0;
    padding: 0;
    padding-left: 20px;
    text-indent: -20px;
    list-style: none;
    counter-reset: custom-counter;
    li {
      counter-increment: custom-counter;
      ::before {
        content: counter(custom-counter) '. ';
        font-weight: 700;
      }
    }
  }
  ul li {
    position: relative;
    text-indent: 0px;
    padding-left: 14px;
    margin-bottom: 0px;
    ::before {
      content: '•';
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
  p {
    margin-bottom: 28px;
  }
  p + h2,
  p + h3,
  p + h4,
  ul + h2,
  ul + h3,
  ul + h4 {
    margin-top: 32px;
  }
  p + ol,
  p + ul {
    margin-top: -4px;
  }
  a {
    ${StyledLinkStyles}
    border-color: ${(p) => p.theme.textColor};
    color: ${(p) => p.theme.textColor};
    overflow-wrap: break-word;
    :hover {
      background-color: #6825b1;
      border-color: #6825b1;
    }
  }
  strong + a {
    font-weight: 700;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 75%;
  }
`

const Letter = styled(Heading1)`
  margin-bottom: 42px;
`

const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 48px;
  ::before {
    content: '';
    background-image: url(${caretDown});
    background-size: cover;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 12px;
    height: 12px;
    pointer-events: none;
  }
`

const Select = styled.select`
  width: 100%;
  padding: 8px 16px;
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border: 2px solid ${(p) => p.theme.textColor};
  color: ${(p) => p.theme.textColor} !important;
  background-color: white !important;
  appearance: button;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
`

const Glossary = ({ heading, text, items, location }) => {
  const currentLetter = location?.hash?.substring(1)?.charAt(0)?.toUpperCase() || 'A'
  const currentWord = location?.hash?.substring(1)

  const currentItemText = items?.find((item) => item?.letter?.toUpperCase() === currentLetter)?.glossary_text

  // Use as key to prevent rehydration issues
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
  }, [])

  // Scroll after rehydration
  useEffect(() => {
    if (isClient) {
      const element = document.getElementById(currentWord)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isClient, currentWord])

  return (
    <>
      <CustomContainer>
        {heading && <Heading>{heading}</Heading>}
        {text && (
          <HeadingText>
            <PrismicRichText text={text} />
          </HeadingText>
        )}
      </CustomContainer>
      <GlossaryContainer key={isClient}>
        <GlossaryNavigation>
          <Mobile>
            <SelectContainer>
              <Select
                value={currentLetter}
                onChange={(e) => navigate(`#${e.target.value}`)}
                aria-label="Choose language"
              >
                {items.map((item, index) => {
                  const letter = item?.letter
                  return (
                    <option value={letter} key={index}>
                      {letter}
                    </option>
                  )
                })}
              </Select>
            </SelectContainer>
          </Mobile>
          <Desktop>
            {items?.map((item, index) => {
              const letter = item?.letter
              const isActive = currentLetter === letter
              return (
                <Item key={`${index}-${isActive}}`}>
                  <LetterButton isActive={isActive} onClick={() => navigate(`#${letter}`)}>
                    {letter}
                  </LetterButton>
                </Item>
              )
            })}
          </Desktop>
        </GlossaryNavigation>
        <TextWrapper>
          <div>
            <Letter as="h2">{currentLetter}</Letter>
            <PrismicRichText text={currentItemText} htmlSerializer={addIdsHtmlSerializer} />
          </div>
        </TextWrapper>
      </GlossaryContainer>
    </>
  )
}

export default Glossary
