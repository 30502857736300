import React from 'react'
import { Link } from 'gatsby'

import HubspotButton, { getHubspotButton } from '../components/HubspotButton'

export const linkResolver = (doc) => {
  if (doc === undefined) return `/`
  if (doc.type === 'page') {
    if (doc.uid === 'homepage') {
      return '/'
    } else {
      return `/${doc.uid.replace(/_/g, '/')}`
    }
  } else if (doc.type === 'legal_section') {
    return `/legal-archived/${doc.uid.replace(/_/g, '/')}`
  }
  return `/${doc.uid}`
}

export const CustomLink = ({ link, backgroundColor, children }) => {
  const linkType = link?.link_type
  const type = link?.type
  const props = {
    href: link.url,
    // style: {
    //   display: 'inline-block',
    // },
  }

  let hubspotReplacement = {}
  if (linkType === 'Web') {
    hubspotReplacement = getHubspotButton({
      pathname: link.url,
      buttonText: children.props?.children.toString(),
    })
  }

  // This is a hardcoded fix requested by Jim & Meena. Yes it's bad but due to time constraints, it's the best we can do for now.
  const INTERNAL_LINKS_TO_OPEN_IN_NEW_TAB = [
    'https://pusher.com/docs/channels/?utm_medium=ads_landing_page&utm_source=ads_landing_page&utm_campaign=docs_traffic_ads_landing_pages',
    'https://pusher.com/channels/realtime-api#:~:text=Channels%20docs-,Channels%20tutorials,-Get%20started%20for',
    'https://pusher.com/tutorials/tagged/channels/?utm_medium=ads_landing_page&utm_source=ads_landing_page&utm_campaign=tutorials_traffic_ads_landing_pages',
    'https://pusher.com/channels/realtime-enterprise#plans',
    'https://pusher.com/contact',
  ]
  const isInternalLink =
    props.href?.startsWith('http://pusher.com/') || props.href?.startsWith('https://dashboard.pusher.com/')

  if ((props.href && INTERNAL_LINKS_TO_OPEN_IN_NEW_TAB.includes(props.href)) || !isInternalLink) {
    props.target = '_blank'
    props.rel = 'noreferrer'
  }

  if (linkType === 'Web' || linkType === 'Media') {
    return (
      <a {...props}>
        {children}

        {hubspotReplacement.portalId ? (
          <HubspotButton
            text={hubspotReplacement.buttonText}
            fallbackHref={hubspotReplacement.href}
            href={link.url}
            portalId={hubspotReplacement.portalId}
            webInteractiveContentId={hubspotReplacement.webInteractiveContentId}
            backgroundColor={backgroundColor}
          />
        ) : null}
      </a>
    )
  } else if (linkType === 'Document' || type) {
    return <Link to={linkResolver(link)}>{children}</Link>
  }
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return <a>{children}</a>
}
