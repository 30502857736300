exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-author-strapi-author-slug-js": () => import("./../../../src/pages/author/{strapiAuthor.Slug}.js" /* webpackChunkName: "component---src-pages-author-strapi-author-slug-js" */),
  "component---src-pages-blog-author-strapi-author-slug-js": () => import("./../../../src/pages/blog/author/{strapiAuthor.Slug}.js" /* webpackChunkName: "component---src-pages-blog-author-strapi-author-slug-js" */),
  "component---src-pages-blog-category-strapi-blog-category-slug-js": () => import("./../../../src/pages/blog/category/{strapiBlogCategory.Slug}.js" /* webpackChunkName: "component---src-pages-blog-category-strapi-blog-category-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-strapi-blog-post-slug-js": () => import("./../../../src/pages/blog/{strapiBlogPost.Slug}.js" /* webpackChunkName: "component---src-pages-blog-strapi-blog-post-slug-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-design-animation-js": () => import("./../../../src/pages/design/animation.js" /* webpackChunkName: "component---src-pages-design-animation-js" */),
  "component---src-pages-design-brand-guidelines-js": () => import("./../../../src/pages/design/brand-guidelines.js" /* webpackChunkName: "component---src-pages-design-brand-guidelines-js" */),
  "component---src-pages-design-chameleon-js": () => import("./../../../src/pages/design/chameleon.js" /* webpackChunkName: "component---src-pages-design-chameleon-js" */),
  "component---src-pages-design-index-js": () => import("./../../../src/pages/design/index.js" /* webpackChunkName: "component---src-pages-design-index-js" */),
  "component---src-pages-design-media-kit-js": () => import("./../../../src/pages/design/media-kit.js" /* webpackChunkName: "component---src-pages-design-media-kit-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-tutorials-index-js": () => import("./../../../src/pages/tutorials/index.js" /* webpackChunkName: "component---src-pages-tutorials-index-js" */),
  "component---src-pages-tutorials-strapi-tutorial-post-slug-js": () => import("./../../../src/pages/tutorials/{strapiTutorialPost.Slug}.js" /* webpackChunkName: "component---src-pages-tutorials-strapi-tutorial-post-slug-js" */),
  "component---src-pages-tutorials-tagged-strapi-tutorial-tag-slug-js": () => import("./../../../src/pages/tutorials/tagged/{strapiTutorialTag.Slug}.js" /* webpackChunkName: "component---src-pages-tutorials-tagged-strapi-tutorial-tag-slug-js" */),
  "component---src-templates-landing-page-template-js": () => import("./../../../src/templates/landing-page-template.js" /* webpackChunkName: "component---src-templates-landing-page-template-js" */),
  "component---src-templates-legal-page-template-js": () => import("./../../../src/templates/legal-page-template.js" /* webpackChunkName: "component---src-templates-legal-page-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

