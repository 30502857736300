/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

export const getHubspotButton = ({ pathname, buttonText }) => {
  if (!pathname.includes('.hubspot.com')) return {}

  const hubspotButtonDetails = {}
  const parsedUrl = new URL(pathname)

  hubspotButtonDetails.portalId = parsedUrl.searchParams.get('portalId')
  hubspotButtonDetails.webInteractiveContentId = parsedUrl.searchParams.get('webInteractiveContentId')

  return {
    ...hubspotButtonDetails,
    buttonText,
  }
}

export default function HubspotButton({ text, href, fallbackHref, portalId, webInteractiveContentId }) {
  if (!webInteractiveContentId || !portalId) return null

  return (
    <div
      className={`hs-cta-embed hs-cta-simple-placeholder ${
        webInteractiveContentId ? 'hs-cta-embed-' + webInteractiveContentId : ''
      }`}
      style={{ visibility: 'hidden', pointerEvents: 'none' }}
    >
      <a href={href || fallbackHref} target="_blank" rel="noopener">
        <img
          loading="lazy"
          src={`https://no-cache.hubspot.com/cta/default/${portalId}/interactive-${webInteractiveContentId}.png`}
          alt={text}
          style={{ display: 'none' }}
        />
      </a>
    </div>
  )
}
