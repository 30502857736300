import React from 'react'
import styled from 'styled-components'

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Heading2, Heading3, ParagraphStyles } from '../styles/Text'
import { Button } from '../styles/Buttons'

const OuterContainer = styled.div`
  ${(p) => p.backgroundColor === 'purple grey' && `background-color: ${p.theme.purpleGreyDarker};`}
  ${(p) => p.backgroundColor === 'black' && `background-color: #190C25; color: white;`}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) => p.backgroundColor === 'purple grey' && `margin-bottom: -60px;`}
  }
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 48px;
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-top: 64px;
    padding-bottom: 36px;
    ${(p) => p.version === 'v2' && 'padding-top: 48px; padding-bottom: 84px;'}
    ${(p) => (p.backgroundColor === 'purple grey' || p.backgroundColor === 'black') && 'padding-bottom: 84px;'}
  }
`

const CustomHeadingLarge = styled(Heading2)`
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) =>
      p.productPage === 'channels' || (p.isHomepage && 'font-size: 46px; line-height: 60px; margin-bottom: 24px;')}
  }
`

const CustomHeading3 = styled(Heading3)`
  max-width: 425px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  a {
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
  ${Button} {
    width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: center;
    a {
      :not(:last-child) {
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }
  }
`

const Paragraph = styled.p`
  ${ParagraphStyles}
  margin-bottom: 40px;
`

const CTASection = ({
  version,
  heading,
  headingSize,
  description,
  buttons,
  backgroundColor,
  productPage,
  pathname,
}) => {
  const isHomepage = pathname === '/'
  return (
    <OuterContainer backgroundColor={backgroundColor}>
      <CustomContainer version={version} productPage={productPage} backgroundColor={backgroundColor}>
        {heading && headingSize === 'Medium' ? (
          <CustomHeading3 center>{heading}</CustomHeading3>
        ) : (
          <CustomHeadingLarge center productPage={productPage} isHomepage={isHomepage}>
            {heading}
          </CustomHeadingLarge>
        )}
        {description && <Paragraph center>{description}</Paragraph>}
        {buttons && (
          <ButtonContainer>
            {buttons.map(({ button_text, button_link }, index) => {
              return (
                button_text && (
                  <CustomLink link={button_link} key={index}>
                    <Button
                      dark
                      reversedWhite={backgroundColor === 'black'}
                      textColor={backgroundColor === 'black' ? '#190C25' : undefined}
                    >
                      {button_text}
                    </Button>
                  </CustomLink>
                )
              )
            })}
          </ButtonContainer>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default CTASection
