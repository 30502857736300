import React from 'react'
import Helmet from 'react-helmet'

const SEO = ({ title, description, image, twitterImage, linkedInImage }) => {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="twitter:title" content={title} />}

      {title && <meta name="twitter:site" content="@pusher" />}
      {title && <meta name="twitter:card" content="summary_large_image" />}

      {description && <meta name="description" content={description} />}
      {description && <meta name="description" property="og:description" content={description} />}
      {description && <meta property="twitter:description" content={description} />}

      {(twitterImage || image) && <meta property="twitter:image:src" content={twitterImage || image} />}
      {(linkedInImage || image) && <meta property="og:image" content={linkedInImage || image} />}
    </Helmet>
  )
}

export default SEO
