import styled from 'styled-components'

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  ${p => p.largeMobilePadding && 'padding-left: 32px; padding-right: 32px;'};
  @media (min-width: ${p => p.theme.breakpointMd}) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (min-width: ${p => p.theme.breakpointLg}) {
    max-width: 1200px;
  }
  @media (min-width: ${p => p.theme.breakpointXl}) {
    max-width: 1280px;
    padding-left: 85px;
    padding-right: 85px;
  }
`

export default Container
