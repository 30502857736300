import React from 'react'

import slugify from './slugify'

export const addIdsHtmlSerializer = function (type, element, content, children, key) {
  const propsWithUniqueKey = function (props, key) {
    return Object.assign(props || {}, { key })
  }

  switch (type) {
    case 'heading3':
      return React.createElement('h3', propsWithUniqueKey({ id: slugify(element?.text) }, key), children)

    default:
      return null
  }
}
