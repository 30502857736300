import React from 'react'
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import { ThemeProvider } from 'styled-components'
import smoothscroll from 'smoothscroll-polyfill'
import 'focus-visible'

import { linkResolver } from './src/utils/linkResolver'

import PageTemplate from './src/templates/page-template'
import LandingPageTemplate from './src/templates/landing-page-template'
import LegalPageTemplate from './src/templates/legal-page-template'

import InstrumentationContextProvider from './src/utils/instrumentation'
import DefaultSEO from './src/components/DefaultSEO'
import Layout from './src/components/Layout'

import theme from './src/styles/theme'
import GlobalStyle from './src/styles/GlobalStyle'

import './src/styles/fonts.css'
import 'react-tooltip/dist/react-tooltip.css'
import CookiesConsent from './src/components/CookiesConsent'

export const onClientEntry = () => {
  smoothscroll.polyfill()
}

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <PrismicPreviewProvider
        repositoryConfigs={[
          {
            repositoryName: 'pusher-website',
            linkResolver,
            componentResolver: componentResolverFromMap({
              page: PageTemplate,
              landing_page: LandingPageTemplate,
              legal_section: LegalPageTemplate,
            }),
          },
        ]}
      >
        {element}
      </PrismicPreviewProvider>
    </ThemeProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <GlobalStyle />
      <InstrumentationContextProvider {...props}>
        <Layout {...props}>
          <DefaultSEO />
          {element}
          <CookiesConsent {...props} />
        </Layout>
      </InstrumentationContextProvider>
    </>
  )
}
