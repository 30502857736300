import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const globalSEOQuery = graphql`
  query GlobalSEOQuery {
    allPrismicGlobal {
      nodes {
        data {
          default_meta_description
          default_meta_title
          default_meta_image {
            url
          }
        }
      }
    }
  }
`

const DefaultSEO = () => {
  return (
    <StaticQuery
      query={globalSEOQuery}
      render={(data) => {
        const {
          default_meta_description = 'Pusher',
          default_meta_title = 'Pusher',
          default_meta_image = undefined,
        } = data.allPrismicGlobal.nodes[0].data
        const defaultMetaImage = default_meta_image?.url
        return (
          <Helmet>
            <html lang="en" />
            <title>{default_meta_title}</title>
            <meta property="og:title" content={default_meta_title} />
            <meta name="description" content={default_meta_description} />
            <meta property="og:description" content={default_meta_description} />
            {defaultMetaImage && <meta property="og:image" content={defaultMetaImage} />}
            <meta property="og:type" content="website" />
            <link rel="icon" type="image/png" href="/favicon-196x196.png" sizes="196x196" />
            <link rel="icon" type="image/png" href="/favicon-128x128.png" sizes="128x128" />
            <link rel="icon" type="image/png" href="/favicon-96x96.png" sizes="96x96" />
            <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
            <meta name="google-site-verification" content="jwI7PUfsfRwYM53d07lRhnl4D1JJfEUrk9JXvtmH180" />
            <meta name="facebook-domain-verification" content="qhrday0846mqkzam6n5aq9krqrrlyv" />
            {/* Preload fonts for better performance */}
            <link
              rel="preload"
              href="/fonts/MaisonNeueWEB-Medium.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              href="/fonts/MaisonNeueWEB-Demi.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              href="/fonts/MaisonNeueWEB-Bold.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              href="/fonts/MaisonNeueWEB-BlackItalic.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
            <link
              rel="preload"
              href="/fonts/MaisonNeueMonoWEB-Regular.woff2"
              as="font"
              type="font/woff2"
              crossOrigin="anonymous"
            />
          </Helmet>
        )
      }}
    />
  )
}

export default DefaultSEO
