import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { A, P } from './shared/Typography'
import { Button } from '../styles/Buttons'
import CloseSVG from '../../static/images/close.inline.svg'

import useLocalStorage from '../hooks/useLocalStorage'

const position = css`
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    right: auto;
    left: 32px;
    bottom: 32px;
    max-width: 400px;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
  box-shadow: 0px 1px 30px rgba(0, 0, 0, 0.18);
  font-size: 14px;
  padding: 32px;
  padding-top: 48px;
  ${position}

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    border-radius: 8px;
  }
`
const CloseButton = styled.button`
  background: none;
  border: none;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  margin: 0;
  opacity: ${(p) => (p.$disabled ? 0.5 : 1)};
  cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'pointer')};
`
const PrivacySettingsButton = styled.button`
  background: rgb(48, 13, 79, 0.5);
  color: #fff;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 4px;
  position: fixed;
  z-index: 1000;
  bottom: 32px;
  right: 16px;
  transition: background-color ease 300ms;

  :hover {
    background-color: rgb(48, 13, 79, 1);
  }

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    right: auto;
    left: 32px;
  }
`
const Intro = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const IntroText = styled(P)`
  font-size: 14px;
`
const IntroLinks = styled.div`
  display: flex;
  gap: 16px;
`
const ConsentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  border: 2px solid ${(p) => p.theme.chameleon.colors.pusher.borderColor};
  border-radius: 4px;
  outline: none;
  :checked {
    background-color: ${(p) => p.theme.chameleon.colors.pusher.darkPurple};
  }
`
const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`
const FormButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
`
const FormButton = styled(Button)`
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`

const PRIVACY_SETTINGS_TEXT = `Privacy`
const WIDGET_TEXT = `By using the Pusher site, you agree with our use of cookies.`
const COOKIE_POLICY_TEXT = `Cookie Policy`
const SEE_DETAILS_TEXT = `See details`
const ACCEPT_TEXT = `Accept`
const ACCEPT_ALL_TEXT = `Accept all`
const ACCEPT_SELECTED_TEXT = `Accept selected`

const WIDGET_VISIBILITY_STATE = {
  default: 'Default',
  expanded: 'Expanded',
  firstVisit: 'First Visit',
}

const COOKIE_OPTIONS = [
  {
    label: 'Essential',
    value: 'essential',
  },
  {
    label: 'Analytics',
    value: 'analytics',
  },
  {
    label: 'Marketing',
    value: 'marketing',
  },
]

const initialCookieSettings = {
  essential: true,
  analytics: true,
  marketing: true,
  responded: false,
  wiped: false,
}

export default function CookiesConsent({ location }) {
  const [widgetVisibilityState, setWidgetVisibilityState] = useState(WIDGET_VISIBILITY_STATE.default)
  const [savedCookieSettings, setSavedCookieSettings] = useLocalStorage('cookieSettings', initialCookieSettings)
  const canCloseWidget = savedCookieSettings.responded

  useEffect(() => {
    if (
      !savedCookieSettings.responded &&
      !savedCookieSettings.wiped &&
      widgetVisibilityState === WIDGET_VISIBILITY_STATE.default
    ) {
      setWidgetVisibilityState(WIDGET_VISIBILITY_STATE.firstVisit)
    }
  }, [savedCookieSettings, widgetVisibilityState])

  const updateGTMConsentSettings = () => {
    const { analytics, marketing } = savedCookieSettings
    const consentSettings = {
      ad_storage: marketing ? 'granted' : 'denied',
      analytics_storage: analytics ? 'granted' : 'denied',
    }

    window.gtag?.('consent', 'update', consentSettings)
  }

  const handleClose = () => {
    if (!canCloseWidget) return
    setWidgetVisibilityState(WIDGET_VISIBILITY_STATE.default)
  }

  const acceptAll = () => {
    const newValues = Object.entries(initialCookieSettings).reduce((acc, [key]) => {
      acc[key] = key === 'wiped' ? false : true
      return acc
    }, {})

    setSavedCookieSettings(newValues)
    setWidgetVisibilityState(WIDGET_VISIBILITY_STATE.default)
    updateGTMConsentSettings()
  }

  const onSubmit = (formSubmitEvent) => {
    formSubmitEvent.preventDefault()

    const formData = new FormData(formSubmitEvent.target)
    const values = Object.fromEntries(formData.entries())
    const transormedValues = Object.keys(savedCookieSettings).reduce((acc, key) => {
      acc[key] = values[key] === 'on' ? true : false
      return acc
    }, {})

    const newValues = {
      ...savedCookieSettings,
      ...transormedValues,
      essential: true,
      responded: true,
    }

    setSavedCookieSettings(newValues)
    setWidgetVisibilityState(WIDGET_VISIBILITY_STATE.default)
    updateGTMConsentSettings()
  }

  const INTRO_AND_LINKS = (
    <Intro>
      <IntroText>{WIDGET_TEXT}</IntroText>
      <IntroLinks>
        <A href="/legal/cookie-policy">{COOKIE_POLICY_TEXT}</A>
      </IntroLinks>
    </Intro>
  )

  const renderedOptions = COOKIE_OPTIONS.map((option) => (
    <CheckboxLabel key={option.value}>
      <Checkbox
        type="checkbox"
        id={option.value}
        name={option.value}
        defaultChecked={option.value === 'essential' ? true : savedCookieSettings[option.value]}
        disabled={option.value === 'essential'}
      />
      {option.label}
    </CheckboxLabel>
  ))

  let render = null

  if (location?.pathname.includes('blog') || location?.pathname.includes('tutorials')) return null

  if (widgetVisibilityState === WIDGET_VISIBILITY_STATE.default)
    return (
      <PrivacySettingsButton onClick={() => setWidgetVisibilityState(WIDGET_VISIBILITY_STATE.expanded)}>
        {PRIVACY_SETTINGS_TEXT}
      </PrivacySettingsButton>
    )

  if (widgetVisibilityState === WIDGET_VISIBILITY_STATE.firstVisit)
    render = (
      <FormButtons>
        <FormButton onClick={acceptAll}>{ACCEPT_TEXT}</FormButton>
        <FormButton reversedDark onClick={() => setWidgetVisibilityState(WIDGET_VISIBILITY_STATE.expanded)}>
          {SEE_DETAILS_TEXT}
        </FormButton>
      </FormButtons>
    )

  if (widgetVisibilityState === WIDGET_VISIBILITY_STATE.expanded)
    render = (
      <ConsentForm onSubmit={onSubmit}>
        <div>{renderedOptions}</div>

        <FormButtons>
          <FormButton onClick={acceptAll}>{ACCEPT_ALL_TEXT}</FormButton>
          <FormButton reversedDark={true} type="submit">
            {ACCEPT_SELECTED_TEXT}
          </FormButton>
        </FormButtons>
      </ConsentForm>
    )

  return (
    <Container $mode={widgetVisibilityState}>
      <CloseButton onClick={handleClose} disabled={!canCloseWidget} $disabled={!canCloseWidget}>
        <CloseSVG />
      </CloseButton>

      {INTRO_AND_LINKS}

      {render}
    </Container>
  )
}
