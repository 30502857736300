import React from 'react'
import styled from 'styled-components'

import { CustomLink } from '../../utils/linkResolver'

import LandingPageContainer from '../../styles/LandingPageContainer'

const CustomContainer = styled(LandingPageContainer)``

const Heading = styled.h2`
  margin-bottom: 16px;
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 23px;
  line-height: 148%;
  text-align: center;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    font-size: 39px;
    line-height: 124%;
  }
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RowWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 6px;
  a {
    position: relative;
    :not(:last-child) {
      margin-right: 16px;
    }
    :hover {
      img:nth-child(1) {
        opacity: 0;
      }
      img:nth-child(2) {
        opacity: 1;
      }
    }
  }
`

const RowHeading = styled.h3`
  :not(:first-child) {
    margin-top: 24px;
  }
  margin-bottom: 16px;
  font-family: 'Maison Neue';
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.25px;
`

const Icon = styled.img`
  height: 44px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    ${(p) => !p.hasHoverIcon && 'opacity: 1 !important; '}
    transition: ease-in-out 0.15s;
  }
`

const IconHover = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  height: 44px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    transition: ease-in-out 0.15s;
  }
`

const Libraries = ({ slice }) => {
  const heading = slice?.primary?.heading
  const row1Heading = slice?.primary?.row_1_heading
  const row2Heading = slice?.primary?.row_2_heading
  const items = slice?.items

  const row1Items = items?.filter((item) => item.row === '1' || item.row === null)
  const row2Items = items?.filter((item) => item.row === '2')
  return (
    <CustomContainer>
      {heading && <Heading>{heading}</Heading>}
      <IconContainer>
        <Row items={row1Items} heading={row1Heading} row="1" />
        <Row items={row2Items} heading={row2Heading} row="2" />
      </IconContainer>
    </CustomContainer>
  )
}

export default Libraries

const Row = ({ items, heading, row }) => {
  return (
    <>
      {heading && <RowHeading row={row}>{heading}</RowHeading>}
      <RowWrapper>
        {items &&
          items.map(({ library_icon, library_icon_hover, link }, index) => {
            const iconUrl = library_icon?.url
            const iconHoverUrl = library_icon_hover?.url
            return (
              <CustomLink link={link} key={index}>
                <Icon hasHoverIcon={iconHoverUrl} src={iconUrl} alt="" />
                {iconHoverUrl && <IconHover src={iconHoverUrl} alt="" />}
              </CustomLink>
            )
          })}
      </RowWrapper>
    </>
  )
}
