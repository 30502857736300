/* eslint-disable no-console */
/* eslint-disable no-undef */
import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useTransition, config, animated } from 'react-spring'
import { useInView } from 'react-intersection-observer'
import get from 'lodash.get'
import useEmblaCarousel from 'embla-carousel-react'

import { CustomLink } from '../utils/linkResolver'

import useScript from '../hooks/useScript'
import useInterval from '../hooks/useInterval'

import LottieAnimation from './LottieAnimation'
import PrismicRichText from './PrismicRichText'

import Container from '../styles/Container'
import { Heading3, Heading2Styles, Heading4Styles, ParagraphStyles } from '../styles/Text'
import { Button } from '../styles/Buttons'

import spinner from '../images/spinner.json'

const CustomContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-top: 40px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 84px;
    padding-bottom: 120px;
  }
`

const Right = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: calc(50% - 15px);
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 445px;
  }
`

const CustomHeading3 = styled(Heading3)`
  max-width: 467px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    min-width: 370px;
    max-width: 370px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    min-width: 540px;
    max-width: 540px;
  }
`

const Steps = styled.ul`
  margin-top: 24px;
  margin-bottom: 26px;
  li {
    display: flex;
    align-items: flex-start;
    :not(:last-child) {
      margin-bottom: 16px;
    }
  }
  img {
    margin-right: 14px;
    margin-top: 3px;
  }
`

const CodeExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  transition: opacity 500ms ease;
  opacity: 0;
  ${(p) => p.inView && 'opacity: 1;'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    order: 1;
    width: calc(50% - 15px);
    max-width: auto;
    margin-bottom: 0px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    min-width: 540px;
  }
`

const Step = styled.li`
  position: relative;
  padding-left: 34px;
  p {
    ${ParagraphStyles}
  }
  :before {
    content: '${(p) => p.number}';
    position: absolute;
    left: 0px;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: ${(p) => p.theme.blueMidLight};
  }
`

const ButtonContainer = styled.div`
  margin-bottom: 48px;
  a:nth-child(2) {
    display: inline-block;
    margin-top: 14px;
    width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 0px;
    a:nth-child(2) {
      width: auto;
    }
  }
`

const EnableButton = styled(Button)`
  margin-right: 14px;
  margin-top: 14px;
  ${(p) => p.beamsEnabled && 'opacity: 0.2;'}
`

const CodeWrapper = styled.div`
  position: relative;
  min-height: 352px;
  ${(p) => !p.showHint && `background-color: ${p.theme.darkGrey};`}
  border-radius: 6px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 100%;
  }
`

const Code = styled.div`
  padding: 16px;
  flex-grow: 1;
  overflow: auto;
  color: white;
  display: flex;
  flex-direction: column;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    height: 100%;
  }
`

const CodeInnerWrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CodeInner = styled(animated.div)`
  font-family: 'Maison Neue Mono', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  span {
    color: #bfb1ff;
    :hover {
      background-color: ${(p) => p.theme.purple};
      color: white;
    }
    :focus {
      background-color: transparent;
      color: #bfb1ff;
      outline: 1px solid ${(p) => p.theme.purple};
    }
  }
`

const CodeButtonWrapper = styled.div`
  display: flex;
  margin-top: auto;
  padding-top: 24px;
  button:first-child {
    margin-right: 16px;
  }
`

const CustomButton = styled(Button)`
  padding: 4px 12px;
`

const Overlay = styled(animated.div)`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f0f1f6;
  border-radius: 4px;
  color: ${(p) => p.theme.textColor};
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-align: center;
  span {
    padding: 4px;
    border-radius: 6px;
    background-color: #2a2a2a;
    font-family: 'Maison Neue Mono', sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: white;
  }
  div {
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
  ${(p) =>
    p.error &&
    css`
      background-color: ${(p) => p.theme.darkerGrey};
      color: white;
      p {
        ${Heading4Styles}
        margin-top: 10px;
      }
      h2 {
        ${Heading2Styles}
      }
    `}
`

let beamsInstanceId
let beamsPublishHost
let beamsClient

async function main(setBeamsEnabled, setUser, setReady, setError) {
  const { publishHost, instanceId } = await fetch(
    'https://landing-page-demo.pushnotifications.pusher.com/api/beams-config'
  ).then((r) => r.json())

  beamsInstanceId = instanceId
  beamsPublishHost = publishHost
  beamsClient = new PusherPushNotifications.Client({
    instanceId: beamsInstanceId,
  })

  const user = JSON.parse(localStorage.getItem('beams-demo-user'))

  let authIsValid
  if (!user) {
    authIsValid = false
  } else {
    const authCheckRes = await fetch(
      `https://landing-page-demo.pushnotifications.pusher.com/api/beams-auth?user_id=${user.id}`,
      {
        headers: {
          authorization: `Bearer ${user.token}`,
        },
      }
    )
    authIsValid = authCheckRes.status === 200
  }

  if (!authIsValid || user.id + '' !== (await beamsClient.getUserId())) {
    await beamsClient.stop()
    localStorage.removeItem('beams-demo-user')
    setBeamsEnabled(false)
    setUser(undefined)
    onNotificationsEnabled(setBeamsEnabled, setUser, setReady, setError)
    return
  }

  setBeamsEnabled(true)
  setUser(user)
  setReady(true)
}

async function onNotificationsEnabled(setBeamsEnabled, setUser, setReady, setError) {
  setBeamsEnabled(true)
  setUser(undefined)

  const user = await fetch('https://landing-page-demo.pushnotifications.pusher.com/api/user', {
    method: 'POST',
  }).then((r) => r.json())
  localStorage.setItem('beams-demo-user', JSON.stringify(user))

  const tokenProvider = new PusherPushNotifications.TokenProvider({
    url: 'https://landing-page-demo.pushnotifications.pusher.com/api/beams-auth',
    headers: {
      authorization: `Bearer ${user.token}`,
    },
  })

  try {
    await beamsClient.start()
    await beamsClient.setUserId('' + user.id, tokenProvider)
    setBeamsEnabled(true)
    setUser(user)
    setReady(true)
  } catch {
    setError(true)
  }
}

const CURLDemo = ({
  heading,
  buttonText,
  ctaButtonText,
  ctaButtonLink,
  lottieAnimation1,
  title1,
  body1,
  lottieAnimation2,
  title2,
  body2,
  lottieAnimation3,
  title3,
  body3,
  steps,
}) => {
  let isSafari = false
  let isWindows = false
  if (typeof navigator === 'object' && navigator.userAgent) {
    if (
      navigator.userAgent.indexOf('Opera') == -1 &&
      navigator.userAgent.indexOf('OPR') == -1 &&
      navigator.userAgent.indexOf('Chrome') == -1 &&
      navigator.userAgent.indexOf('Safari') != -1
    ) {
      isSafari = true
    }
  }
  if (typeof navigator === 'object' && navigator.platform) {
    if (navigator.platform.indexOf('Win32') > -1) {
      isWindows = true
    }
  }
  const hasSteps = steps[0].step
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  const [start, setStart] = useState(false)
  const [error, setError] = useState(false)
  const [beamsEnabled, setBeamsEnabled] = useState(false)
  const [user, setUser] = useState(undefined)
  const [ready, setReady] = useState(false)
  const [hasCopied, setHasCopied] = useState(false)
  const [showHint, setShowHint] = useState(false)

  const messages = [
    { title: title1, body: body1 },
    { title: title2, body: body2 },
    { title: title3, body: body3 },
  ]

  const copyText = () => {
    const element = document.getElementById('code')
    if (element) {
      if (navigator && navigator.clipboard) {
        const title = document.getElementById('title').innerText
        const body = document.getElementById('body').innerText
        const codeToCopy = `
        curl -X POST "${beamsPublishHost}/publish_api/v1/instances/${beamsInstanceId}/publishes/users" \\
           -H "Authorization: Bearer ${user.token}" \\
           -H "Content-Type: application/json" \\
           -d '{
             "users": ["${user.id}"],
             "web": {
               "notification": {
                 "title": "${title}",
                 "body": "${body}"
               }
             }
           }'
        `
        navigator.clipboard.writeText(codeToCopy)
      }
    }
    setHasCopied(true)
  }

  const [loaded] = useScript('https://js.pusher.com/beams/1.0/push-notifications-cdn.js')

  useEffect(() => {
    if (loaded && start) {
      main(setBeamsEnabled, setUser, setReady, setError)
    }
  }, [loaded, start])

  useEffect(() => {
    if (hasCopied) {
      const timer = setTimeout(() => setHasCopied(false), 2000)
      return () => clearTimeout(timer)
    }
  }, [hasCopied])

  useEffect(() => {
    if (ready) {
      setShowHint(true)
      const timer = setTimeout(() => setShowHint(false), 5000)
      return () => clearTimeout(timer)
    }
  }, [ready])

  const copyFadeIn = useTransition(hasCopied, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  })

  const hintFadeIn = useTransition(showHint, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  })

  const codeFadeIn = useTransition(beamsEnabled && user && ready && !showHint, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  })

  const loadingFadeIn = useTransition(start && !user, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  })

  const sendNotification = () => {
    const title = document.getElementById('title').innerText
    const body = document.getElementById('body').innerText
    fetch(`${beamsPublishHost}/publish_api/v1/instances/${beamsInstanceId}/publishes/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({
        users: [user.id],
        web: {
          notification: {
            title,
            body,
          },
        },
      }),
    })
  }

  const [selectedIndex, setSelectedIndex] = useState(0)

  return !isSafari ? (
    <CustomContainer id="demo">
      {!start ? (
        <Carousel
          items={[lottieAnimation1, lottieAnimation2, lottieAnimation3]}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      ) : (
        <CodeExampleContainer ref={inViewRef} inView={inView}>
          <CodeWrapper showHint={showHint}>
            <Code>
              {error ? (
                <Overlay error>
                  <h2>Oops!</h2>
                  <p>
                    You&apos;ll need to allow notifications to try Beams here. Change the settings in your browser to
                    try again.
                  </p>
                </Overlay>
              ) : (
                <>
                  {loadingFadeIn.map(
                    ({ item, props, key }) =>
                      item && (
                        <Overlay key={key} style={props}>
                          {spinner && <LottieAnimation animationData={spinner} loop autoplay />}
                          (Waiting for you to accept web push notifications)
                        </Overlay>
                      )
                  )}
                  {codeFadeIn.map(
                    ({ item, props, key }) =>
                      item && (
                        <CodeInnerWrapper key={key} style={props}>
                          <CodeInner
                            beamsEnabled={beamsEnabled}
                            id="code"
                            dangerouslySetInnerHTML={{
                              __html: `
<pre>curl -X POST "${beamsPublishHost}/publish_api/v1/instances/${beamsInstanceId}/publishes/users" \\
      -H "Authorization: Bearer ${user.token}" \\
      -H "Content-Type: application/json" \\
      -d '{
        "users": ["${user.id}"],
        "web": {
          "notification": {
            "title": "<span contenteditable="true" id="title">${messages[selectedIndex].title}</span>",
            "body": "<span contenteditable="true" id="body">${messages[selectedIndex].body}</span>"
          }
        }
      }'</pre>
                        `,
                            }}
                          />
                          <CodeButtonWrapper>
                            <CustomButton onClick={sendNotification} reversedWhite textColor="#300D4F">
                              Send Notification
                            </CustomButton>
                            {!isWindows && (
                              <CustomButton onClick={copyText} reversed>
                                Copy cURL request
                              </CustomButton>
                            )}
                          </CodeButtonWrapper>
                        </CodeInnerWrapper>
                      )
                  )}
                  {copyFadeIn.map(
                    ({ item, props, key }) =>
                      item && (
                        <Overlay key={key} style={props}>
                          Code copied to clipboard
                        </Overlay>
                      )
                  )}
                  {hintFadeIn.map(
                    ({ item, props, key }) =>
                      item && (
                        <Overlay key={key} style={props}>
                          <div>
                            Remember you can edit the <span>&quot;title&quot;</span> and <span>&quot;body&quot;</span>{' '}
                            text
                          </div>
                          <div>in the code block to see how Beams notifications</div>
                          <div>could work for your business</div>
                        </Overlay>
                      )
                  )}
                </>
              )}
            </Code>
          </CodeWrapper>
        </CodeExampleContainer>
      )}
      <Right>
        {heading && <CustomHeading3>{heading}</CustomHeading3>}
        {hasSteps && (
          <Steps>
            {steps.map(({ step }, index) => {
              if (isWindows) {
                step.richText[0].text = step.richText[0].text.replace(
                  ' or copy and paste the cURL snippet into your terminal',
                  ''
                )
                return (
                  <Step key={index} number={index + 1}>
                    <PrismicRichText text={step} />
                  </Step>
                )
              } else {
                return (
                  <Step key={index} number={index + 1}>
                    <PrismicRichText text={step} />
                  </Step>
                )
              }
            })}
          </Steps>
        )}
        {buttonText && (
          <ButtonContainer>
            {buttonText && (
              <EnableButton
                beamsEnabled={beamsEnabled}
                disabled={beamsEnabled}
                green
                fullWidthOnSmall
                onClick={() => {
                  setStart(true)
                }}
              >
                {buttonText}
              </EnableButton>
            )}
            {ctaButtonText && beamsEnabled && ready && !showHint && (
              <CustomLink link={ctaButtonLink}>
                <Button green fullWidthOnSmall>
                  {ctaButtonText}
                </Button>
              </CustomLink>
            )}
          </ButtonContainer>
        )}
      </Right>
    </CustomContainer>
  ) : (
    <div />
  )
}

export default CURLDemo

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 32px;
  overflow: hidden;
  img {
    width: 100%;
  }
  > div {
    height: 100%;
    background-color: #f0f1f6;
    > div {
      height: 100%;
      align-items: center;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    order: 1;
    width: calc(50% - 15px);
    max-width: initial;
    margin-bottom: 0px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 540px;
  }
`

const EmblaCarouselReact = styled.div`
  overflow: hidden;
`

const Slide = styled.div`
  flex: 0 0 100%;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 740px;
  }
`

const OuterAnimationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const AnimationWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  align-self: center;
  svg {
    vertical-align: middle;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: initial;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: 100%;
  }
`

const Carousel = ({ items, selectedIndex, setSelectedIndex }) => {
  const itemsCount = get(items, 'length')

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, draggable: false })
  const [scrollSnaps, setScrollSnaps] = useState([])
  const delay = 6000

  const scrollTo = useCallback((index) => emblaApi.scrollTo(index), [emblaApi])
  const scrollNext = useCallback(() => emblaApi.scrollNext(), [emblaApi])

  useInterval(() => {
    if (itemsCount > 1) {
      if (selectedIndex === scrollSnaps.length - 1) {
        scrollTo(0)
      } else {
        scrollNext()
      }
    }
  }, delay)

  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }
    if (emblaApi) {
      setScrollSnaps(emblaApi.scrollSnapList())
      emblaApi.on('select', onSelect)
      onSelect()
    }
    return () => emblaApi && emblaApi.destroy()
  }, [emblaApi])

  return (
    <CarouselContainer>
      <EmblaCarouselReact ref={emblaRef}>
        <div style={{ display: 'flex' }}>
          {items &&
            items.map((item, index) => {
              const lottieAnimation = get(item, 'url')
              return (
                <Slide key={index}>
                  {lottieAnimation && (
                    <OuterAnimationWrapper>
                      <AnimationWrapper>
                        <LottieAnimation path={lottieAnimation} autoplay play={index === selectedIndex} />
                      </AnimationWrapper>
                    </OuterAnimationWrapper>
                  )}
                </Slide>
              )
            })}
        </div>
      </EmblaCarouselReact>
    </CarouselContainer>
  )
}
