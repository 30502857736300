import { useState } from 'react'

export default function useUtmTags({ location }) {
  const [utmTags] = useState(() => {
    if (!location) return {}

    const url = location.search
    const urlParts = url.split('?')
    if (urlParts.length < 2) {
      return {}
    }

    const params = urlParts[1].split('&')
    const utm = {}
    for (let i = 0; i < params.length; i++) {
      const keyValue = params[i].split('=')
      const key = keyValue[0]
      const value = keyValue[1]
      if (key.startsWith('utm_')) {
        const camelCaseKey = key
          .substring(4)
          .replace(/-/g, '_')
          .replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase()
          })
        utm[`utm${camelCaseKey}`] = value
      }
    }

    return utm
  })

  return utmTags
}
