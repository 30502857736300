import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'

import { CustomLink } from '../../utils/linkResolver'
import PrismicRichText from '../PrismicRichText'

import LandingPageContainer from '../../styles/LandingPageContainer'
import { Button } from '../../styles/Buttons'

const CustomContainer = styled(LandingPageContainer)`
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    align-items: center;
  }
`

const TextWrapper = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    grid-column: 1/6;
    ${(p) => p.imagePosition === 'left' && 'grid-column: 8/13; order: 1;'}
  }
`
const ImageWrapper = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    grid-column: 7/13;
    ${(p) => p.imagePosition === 'left' && 'grid-column: 1/7;'}
  }
`

const Label = styled(GatsbyImage)`
  margin-bottom: 8px;
`

const Heading = styled.h2`
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 29px;
  line-height: 132%;
  letter-spacing: 0.25px;
  ${(p) =>
    p.headingSize === 'medium' &&
    css`
      font-size: 23px;
      line-height: 148%;
    `}
  ${(p) =>
    p.headingSize === 'small' &&
    css`
      font-size: 25px;
      line-height: 156%;
    `}
    @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    font-size: 49px;
    line-height: 116%;
    ${(p) =>
      p.headingSize === 'medium' &&
      css`
        font-size: 39px;
        line-height: 124%;
      `}
    ${(p) =>
      p.headingSize === 'small' &&
      css`
        font-size: 25px;
        line-height: 156%;
      `}
  }
`

const Text1Wrapper = styled.div`
  margin-top: 16px;
  font-family: 'Maison Neue';
  font-weight: 500;
  font-size: 16px;
  line-height: 164%;
  letter-spacing: 0.25px;
  p:not(:first-child) {
    margin-top: 24px;
  }
`

const FeatureItemsWrapper = styled.ul`
  margin-top: 24px;
`

const FeatureItem = styled.li`
  position: relative;
  padding-left: 40px;
  :not(:first-child) {
    margin-top: 16px;
  }
  ::before {
    content: '';
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 8px;
    position: absolute;
    left: 0px;
    top: 0px;
    background-image: url('/images/check.svg');
  }
  ul {
    margin-top: 8px;
    li {
      position: relative;
      padding-left: 12px;
      :not(:first-child) {
        margin-top: 8px;
      }
      ::before {
        content: '';
        width: 4px;
        height: 4px;
        flex-shrink: 0;
        border-radius: 100%;
        margin-right: 8px;
        position: absolute;
        left: 0px;
        top: 9px;
        background-color: ${(p) => p.theme.textColor};
        ${(p) => p.white && 'background-color: white;'}
      }
    }
  }
`

const Text2Wrapper = styled.div`
  margin-top: 16px;
  font-family: 'Maison Neue';
  font-weight: 500;
  font-size: 16px;
  line-height: 164%;
  letter-spacing: 0.25px;
  p:not(:first-child) {
    margin-top: 24px;
  }
`

const LogoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  column-gap: 10px;
  margin-top: 4px;
`

const Logo = styled(GatsbyImage)`
  grid-column: span 1;
  img {
    object-fit: contain !important;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 8px;
`

const Image = styled(GatsbyImage)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    margin-top: 0px;
  }
`

const TextWithImage = ({ slice }) => {
  const heading = slice?.primary?.heading
  const headingTag = slice?.primary?.heading_tag
  const headingSize = slice?.primary?.heading_size
  const button1Text = slice?.primary?.button_1_text
  const button1Link = slice?.primary?.button_1_link
  const button1AnchorLink = slice?.primary?.button_1_anchor_link
  const button2Text = slice?.primary?.button_2_text
  const button2Link = slice?.primary?.button_2_link
  const text1 = slice?.primary?.text_1
  const text2 = slice?.primary?.text_2
  const image = slice?.primary?.image?.gatsbyImageData
  const imagePosition = slice?.primary?.image_position
  const label = slice?.primary?.label?.gatsbyImageData
  const logo1 = slice?.primary?.logo_1?.gatsbyImageData
  const logo2 = slice?.primary?.logo_2?.gatsbyImageData
  const logo3 = slice?.primary?.logo_3?.gatsbyImageData
  const logo4 = slice?.primary?.logo_4?.gatsbyImageData
  const items = slice?.items
  const hasItems = slice?.items?.[0]?.item_text?.richText?.length > 0

  return (
    <CustomContainer>
      <TextWrapper imagePosition={imagePosition}>
        {label && <Label image={label} />}
        {heading && (
          <Heading headingSize={headingSize} as={headingTag === 'h1' ? 'h1' : 'h2'}>
            {heading}
          </Heading>
        )}
        {text1 && (
          <Text1Wrapper>
            <PrismicRichText text={text1} />
          </Text1Wrapper>
        )}
        {hasItems && (
          <FeatureItemsWrapper>
            {items?.map((item, index) => {
              const itemText = item?.item_text
              return (
                itemText && (
                  <FeatureItem key={index}>
                    <PrismicRichText text={itemText} />
                  </FeatureItem>
                )
              )
            })}
          </FeatureItemsWrapper>
        )}
        {text2 && (
          <Text2Wrapper>
            <PrismicRichText text={text2} />
          </Text2Wrapper>
        )}
        {logo1 && (
          <LogoWrapper>
            {logo1 && <Logo image={logo1} />}
            {logo2 && <Logo image={logo2} />}
            {logo3 && <Logo image={logo3} />}
            {logo4 && <Logo image={logo4} />}
          </LogoWrapper>
        )}
        {(button1Text || button2Text) && (
          <ButtonWrapper>
            {button1Text &&
              (button1AnchorLink ? (
                <a href={`#${button1AnchorLink}`}>
                  <Button dark>{button1Text}</Button>
                </a>
              ) : (
                <CustomLink link={button1Link}>
                  <Button dark>{button1Text}</Button>
                </CustomLink>
              ))}
            {button2Text && (
              <CustomLink link={button2Link}>
                <Button reversedDark>{button2Text}</Button>
              </CustomLink>
            )}
          </ButtonWrapper>
        )}
      </TextWrapper>
      <ImageWrapper imagePosition={imagePosition}>{image && <Image image={image} />}</ImageWrapper>
    </CustomContainer>
  )
}

export default TextWithImage
