import React from 'react'
import styled, { css } from 'styled-components'

import theme from './theme'

export const Heading1Styles = css`
  font-size: 29px;
  line-height: 148%;
  font-weight: 500;
  letter-spacing: 0.5px;
  ${(p) => p.center && 'text-align: center;'}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 49px;
    line-height: 132%;
  }
`
export const Heading1 = styled.h1`
  ${Heading1Styles}
`

export const Heading2Styles = css`
  font-size: 26px;
  line-height: 164%;
  font-weight: 500;
  letter-spacing: 0.5px;
  ${(p) => p.center && 'text-align: center;'}
  ${(p) => p.white && 'color: white;'}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 39px;
    line-height: 140%;
  }
`
export const Heading2 = styled.h2`
  ${Heading2Styles}
`

export const Heading3Styles = css`
  font-size: 23px;
  line-height: 164%;
  font-weight: 500;
  letter-spacing: 0.5px;
  ${(p) => p.center && 'text-align: center;'}
  ${(p) => p.white && 'color: white;'}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 31px;
    line-height: 148%;
  }
`
export const Heading3 = styled.h3`
  ${Heading3Styles}
`

export const Heading4Styles = css`
  font-size: 20px;
  line-height: 164%;
  font-weight: 500;
  letter-spacing: 0.5px;
  ${(p) => p.center && 'text-align: center;'}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 25px;
    line-height: 156%;
  }
`

export const Heading4 = styled.h4`
  ${Heading4Styles}
`

export const Heading5Styles = css`
  font-weight: 700;
  font-size: 18px;
  line-height: 164%;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 20px;
  }
`

export const Heading5 = styled.h5`
  ${Heading5Styles}
`

export const ParagraphStyles = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 172%;
  letter-spacing: 0.25px;
  ${(p) => p.semibold && 'font-weight: 600; letter-spacing: 0.5px;'}
  ${(p) => p.bold && 'font-weight: 700; letter-spacing: 0.5px;'}
  ${(p) => p.center && 'text-align: center;'}
  ${(p) => p.white && 'color: white;'}
`

export const Paragraph = styled.p`
  ${ParagraphStyles}
`

export const Copyright = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
`

export const StyledLinkStyles = css`
  border-bottom: 2px solid ${(p) => p.theme.purpleDark};
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: ${(p) => p.theme.purpleDark};
  transition: all 0.1s ease;
  :hover {
    color: white;
    background-color: ${(p) => p.theme.purple};
    border-bottom: 2px solid ${(p) => p.theme.purple};
  }
  :active {
    color: ${(p) => p.theme.textColor};
    background-color: ${(p) => p.theme.grey};
    border-bottom: 2px solid ${(p) => p.theme.grey};
  }
`
export const StyledLink = styled.span`
  ${StyledLinkStyles}
`

export const StyledLinkCaretStyles = css`
  font-weight: 600;
  font-size: 16px;
  line-height: 172%;
  position: relative;
  display: block;
  color: ${(p) => p.color};
  svg {
    transform: translateY(1px);
    ${(p) => p.reversed && 'transform: translateY(1px) rotate(180deg);'}
    rect {
      fill: ${(p) => p.color};
    }
  }
`
export const StyledLinkCaret = styled.span`
  ${StyledLinkCaretStyles}
`

export const StyledLinkCaretComponent = ({ children, reversed, color = theme.purpleDark }) => {
  return (
    <StyledLinkCaret reversed={reversed} color={color}>
      {reversed && <CaretRight />}
      {children}
      {!reversed && <CaretRight />}
    </StyledLinkCaret>
  )
}

const CaretRight = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        width="2"
        height="8"
        rx="1"
        transform="matrix(-0.707108 -0.707106 0.707108 -0.707106 5.65674 11.3137)"
        fill="#8731D2"
      />
      <rect
        width="2"
        height="8"
        rx="1"
        transform="matrix(0.707108 -0.707106 0.707108 0.707106 4.24268 1.41418)"
        fill="#8731D2"
      />
    </svg>
  )
}
