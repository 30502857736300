import React, { useState, useRef } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import AnimateHeight from 'react-animate-height'

import { CustomLink } from '../utils/linkResolver'

import PrismicRichText from './PrismicRichText'
import Tooltip from './Tooltip'

import Container from '../styles/Container'
import {
  Paragraph,
  ParagraphStyles,
  Heading2,
  Heading3,
  Heading3Styles,
  Heading4,
  Heading4Styles,
  StyledLink,
  StyledLinkStyles,
  Heading5,
} from '../styles/Text'
import { SelectButton, Button } from '../styles/Buttons'

import yesIcon from '../images/yes-icon.svg'
import noIcon from '../images/no-icon.svg'
import caretDownWhite from '../images/caret-down-white.svg'
import checkIcon from '../images/check-blue-icon.svg'

const CustomContainer = styled(Container)`
  padding-bottom: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-bottom: 80px;
  }
`

const Top = styled.div`
  margin-bottom: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 24px;
  }
`

const TopHeadingWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 48px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 1200px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 1280px;
    padding-left: 85px;
    padding-right: 85px;
  }
`

const TopHeading = styled(Heading2)`
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: 550px;
  }
`

const TopSubheading = styled(Paragraph)`
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

const PricingContainer = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Mobile = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-bottom: 64px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: none;
  }
`

const MobilePlanTop = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 164%;
  strong {
    font-weight: 500;
  }
  button {
    flex-shrink: 0;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Price = styled.div`
  ${Heading4Styles}
`

const Desktop = styled.div`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    display: block;
    width: 100%;
  }
`

const CustomSelectButtonPhone = styled(SelectButton)`
  display: block;
  margin-top: 8px;
  background-color: ${(p) => p.theme.purpleDark};
  color: white;
  ::before {
    background-image: url(${caretDownWhite});
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: none;
  }
`

const CustomSelectButtonTablet = styled(SelectButton)`
  display: none;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
    max-width: 186px;
    background-color: ${(p) => p.theme.purpleDark};
    color: white;
    ::before {
      background-image: url(${caretDownWhite});
    }
  }
`

const CTABoxContainer = styled.div`
  > div :nth-child(2) {
    margin-top: 16px;
  }
  ${(p) => p.ctaColumn && 'margin-bottom: 24px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    width: 100%;
    ${(p) => p.support && 'margin-top: -26px;'}
    > div :nth-child(2) {
      margin-top: 0px;
      margin-left: 16px;
    }
    ${(p) => p.ctaColumn && 'margin-bottom: 0px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) => p.support && 'margin-top: -40px;'}
    > div :nth-child(2) {
      margin-left: 32px;
    }
  }
`

const CTABox = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.blueishGreyLight};
  ${(p) => p.productPage === 'channels' && `background-color: ${p.theme.blue};`}
  ${(p) => p.purpleBg && `background-color: ${p.theme.textColor}; color: white;`}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    flex-direction: column;
    ${(p) => p.productPage === 'beams' && `align-items: center;`}
    width: 50%;
    ${(p) => p.fullWidth && 'width: 100%;'}
    a, button {
      margin-top: auto;
      ${(p) => p.productPage === 'channels' && `align-self: flex-start;`}
    }
  }
`

const CTAHeading = styled(Heading4)`
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 34px;
  ${(p) => p.productPage === 'channels' && `color: white;`}
  ${(p) => p.h3 && `${Heading3Styles}`}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    ${(p) => p.productPage === 'beams' && `text-align: center;`}
    ${(p) => p.productPage === 'channels' && `font-size: 34px; line-height: 48px;`}
  }
`

const CTASubheading = styled(Paragraph)`
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 32px;
  ${(p) => p.productPage === 'beams' && `text-align: center; max-width: 540px;`}
  ${(p) => p.productPage === 'channels' && `color: white;`}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-bottom: 28px;
    ${(p) => p.productPage === 'beams' && `max-width: 460px; text-align: center;`}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-bottom: 16px;
    ${(p) => p.productPage === 'channels' && `margin-bottom: 72px;`}
  }
`

const CTAButton = styled(Button)`
  line-height: 22px;
  min-height: 44px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: 172px;
    ${(p) => p.productPage === 'channels' && `margin-right: auto;`}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: initial;
  }
`

const RowContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 0px;
  ${(p) => p.support && 'margin-bottom: 16px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    flex-direction: column;
    margin-bottom: 80px;
    margin-top: 0px;
  }
`

const RowInnerContainer = styled.div`
  display: flex;
  width: 100%;
  > div:first-child {
    width: 100%;
  }
`

const LeftRow = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 50%;
    margin-right: 16px;
  }
`
const RightRow = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 50%;
    margin-left: 16px;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding: 7px 0px;
  border-top: 1px solid ${(p) => p.theme.blueMidLight};
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    justify-content: initial;
    align-items: flex-start;
    justify-content: center;
    min-height: 72px;
    margin-left: 12px;
    margin-right: 12px;
    padding: 11px 0px;
    border-top: none;
    ${Paragraph} {
      line-height: 24px;
      font-weight: 600;
      max-width: 130px;
      text-align: center;
    }
    ${(p) =>
      p.support &&
      css`
        background-color: #edeffa;
        padding: 6px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
  }
`

const Field = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  min-height: 50px;
  margin-left: 12px;
  margin-right: 12px;
  border-top: 1px solid #dfe3f7;
  ${(p) => p.support && 'border-top: 0px;'}
  ${(p) => p.customLayout && 'border-top: 0px;'}
`

const FieldInner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px 0px;
  ${(p) => p.customLayout && 'padding: 19px 0px;'}
  text-align: center;
  p {
    font-weight: 500;
  }
  ${(p) => p.support && `padding: 11px 0px;`}
`

const FieldInnerButton = styled.button`
  ${StyledLinkStyles}
  color: ${(p) => p.theme.textColor};
  border-bottom: 3px solid ${(p) => p.theme.textColor};
  :hover {
    background-color: #7d6cad;
    border-bottom: 3px solid #7d6cad;
  }
  transform: translateY(-3px);
  p {
    font-weight: 600;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    transform: translateY(0px);
  }
`

const Left = styled(Paragraph)`
  width: auto;
  flex-shrink: 1;
  line-height: 22px;
  letter-spacing: 0.25px;
`
const Right = styled(Paragraph)`
  width: auto;
  flex-shrink: 1;
  padding-left: 24px;
  text-align: right;
  p {
    display: inline-block;
  }
  a {
    ${StyledLinkStyles}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-left: 0px;
  }
`

const Column = styled.div`
  display: flex;
  width: 100%;
  > div {
    ${(p) => p.rowCount && `width: calc(100% / ${p.rowCount + 1});`}
  }
  :last-child {
    > div {
      border-bottom: 1px solid ${(p) => p.theme.blueMidLight};
    }
  }
  ${(p) =>
    p.support &&
    css`
      > div {
        ${(p) => p.rowCount && `width: calc(100% / ${p.rowCount + 2});`}
      }
      > div:first-child {
        ${(p) => p.rowCount && `width: calc(100% * 2 / ${p.rowCount + 1});`}
        ${(p) => p.rowCount && p.ctaColumn && `width: calc(100% / ${p.rowCount + 2});`}
      }
    `}
  margin-right: 8px;
  border-radius: 4px;
  p {
    display: inline-block;
  }
  ${(p) =>
    p.customLayout &&
    css`
      position: relative;
      margin-top: 16px;
      border: 2px solid #eae7f9;
      border-radius: 0px;
      :before {
        content: '';
        height: calc(100% + 4px);
        width: 6px;
        position: absolute;
        top: -2px;
        left: -2px;
        background-color: #5d2be7;
      }
    `}
`

const PlanHeading = styled.div`
  border-top: 1px solid #dfe3f7;
  display: flex;
  align-items: center;
  margin-right: 4px;
  ${(p) => p.customLayout && 'border-top: 0px;'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    min-width: 150px;
  }
`

const PlanHeadingInner = styled.div`
  padding: 2px 6px;
  min-height: 32px;
  min-width: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  p:first-child {
    font-weight: 700;
  }
  ${(p) =>
    p.support &&
    css`
      display: block;
    `}
  ${(p) =>
    p.customLayout &&
    css`
      align-items: flex-start;
      font-weight: 600;
      font-size: 20px;
      line-height: 164%;
      padding-left: 22px;
      p:first-child {
        font-weight: 600;
      }
      p:nth-child(2) {
        font-weight: 500;
        strong {
          font-weight: 500;
        }
      }
    `}
`

const RowHeadingsColumn = styled.div`
  display: flex;
  width: 100%;
  > div {
    ${(p) => p.rowCount && `width: calc(100% / ${p.rowCount + 1});`}
  }
  ${(p) =>
    p.support &&
    css`
      border-top: 0px;
      > div {
        ${(p) => p.rowCount && `width: calc(100% / ${p.rowCount + 2});`}
      }
      > div:first-child {
        ${(p) => p.rowCount && `width: calc(100% * 2 / ${p.rowCount + 1});`}
        ${(p) => p.rowCount && p.ctaColumn && `width: calc(100% / ${p.rowCount + 2});`}
      }
    `}
  ${PlanHeading} {
    border: 0px;
  }
`

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
`

const Options = styled.button`
  max-width: 100%;
  display: flex;
  justify-content: center;
  padding: 7px;
  border: 2px solid #efedf8;
  border-radius: 8px;
  :not(:last-child) {
    margin-bottom: 48px;
  }
`

const Option = styled.div`
  width: 244px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.5px;
  :nth-child(2) {
    margin-left: 12px;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    border-radius: 8px;
    z-index: -1;
  }
  :first-child:after {
    transform: translateX(25px);
  }
  :nth-child(2):after {
    transform: translateX(-25px);
  }
  ${(p) =>
    p.active &&
    css`
      :after {
        transition: transform 0.2s ease-out;
        background-color: #dad4fa;
      }
      :first-child:after {
        transform: translateX(0px);
      }
      :nth-child(2):after {
        transform: translateX(0px);
      }
    `}
`

const CustomParagraph = styled(Paragraph)`
  max-width: 720px;
  margin-bottom: 38px;
  margin-left: auto;
  margin-right: auto;
`

const CustomHeading3 = styled(Heading3)`
  margin-top: 32px;
  margin-bottom: 8px;
  /* ${(p) => p.support && 'margin-top: 0px;'} */
  ${(p) => p.noTopPadding && 'padding-top: 8px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 48px;
    ${(p) => p.support && 'margin-top: 0px;'}
    ${(p) => p.noTopPadding && 'padding-top: 0px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-top: 24px;
    ${(p) => p.support && 'margin-top: 0px;'}
    ${(p) => p.noTopPadding && 'padding-top: 0px;'}
  }
`

const OptionButton = styled.div`
  margin-bottom: 40px;
  button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-bottom: 56px;
  }
`

const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-top: 0px;
  }
`

const FeaturesList = styled.div``

const Feature = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 10px 0px;
  border-bottom: 1px solid white;
  height: 100%;
  img {
    margin-right: 16px;
    width: 22px;
    margin-top: 3px;
    flex-shrink: 0;
  }
  p {
    font-weight: 600;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding: 0px 0px 24px;
    img {
      margin-top: 6px;
    }
    p {
      font-size: 20px;
      line-height: 34px;
    }
  }
`

const FeatureOuter = styled.div`
  padding: 0px 16px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding: 0px 24x;
  }
`

const FeaturesImageWrapper = styled.div`
  width: 100%;
  max-width: 324px;
  align-self: center;
  img {
    width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    max-width: initial;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-left: 30px;
  }
`

const CircleIcon = styled.img`
  width: 24px;
  height: 24px;
`

const SupportAdditionalInfo = styled.p`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  padding-bottom: 7px;
  padding-right: 46px;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-right: 0px;
  }
`

const SupportLink = styled(StyledLink)`
  display: inline-block;
  margin-bottom: 16px;
`

const Footnote = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 22px;
  > div:first-child {
    margin-right: 4px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    position: relative;
    top: -66px;
    margin-bottom: -66px;
  }
`

const SupportBannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 54px;
  margin-bottom: 48px;
  padding: 32px 16px 48px;
  background-color: #c7b9ff;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 0px;
    margin-bottom: 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding: 46px 16px 32px;
    margin-top: 54px;
    margin-bottom: 80px;
  }
`

const SupportBannerHeading = styled(Heading2)`
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 58px;
    line-height: 74px;
    font-weight: 600;
  }
`
const SupportBannerSubheading = styled(Paragraph)`
  margin-top: 10px;
  margin-bottom: 24px;
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-bottom: 38px;
    font-size: 20px;
    line-height: 34px;
  }
`

const SupportBannerText = styled(Paragraph)`
  margin-top: 32px;
  a {
    ${StyledLinkStyles}
    color: ${(p) => p.theme.textColor};
    border-bottom: 3px solid ${(p) => p.theme.textColor};
    :hover {
      background-color: #7d6cad;
      border-bottom: 3px solid #7d6cad;
    }
  }
`

const Divider = styled.div`
  margin-top: 16px;
  ${(p) => p.selectedOption === 1 && 'margin-top: -16px;'}
`

const CTAColumn = styled.div`
  padding: 28px 8px 24px;
  background-color: ${(p) => p.theme.textColor};
  color: white;
  width: 145px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  p:first-child {
    ${Heading4Styles}
    text-align: center;
    @media (min-width: ${(p) => p.theme.breakpointXl}) {
      font-size: 20px;
      line-height: 164%;
    }
  }
  p:nth-child(2) {
    ${ParagraphStyles}
    font-weight: bold;
    text-align: center;
  }
  button {
    margin: 0px 13px;
  }
`

const EnterprisePlan = styled.div`
  position: relative;
  margin-top: 16px;
  border: 2px solid #eae7f9;
  border-radius: 0px;
  padding: 8px 16px 16px 22px;
  :before {
    content: '';
    height: calc(100% + 4px);
    width: 6px;
    position: absolute;
    top: -2px;
    left: -2px;
    background-color: #5d2be7;
  }
`

const EnterprisePlanTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 164%;
  margin-bottom: 16px;
`

const EnterprisePlanHeading = styled(Heading2)``

const EnterprisePlanSubheading = styled(Heading5)`
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 500;
`

const PricingTable = ({
  heading,
  subheading,
  option1,
  option2,
  option2Heading,
  option2Subheading,
  option2Features,
  option2Image,
  option2ButtonText,
  option2ButtonLink,
  rowHeadings,
  items,
  ctaBox1Heading,
  ctaBox1Subheading,
  ctaBox1ButtonText,
  ctaBox1Link,
  ctaBox2Heading,
  ctaBox2Subheading,
  ctaBox2ButtonText,
  ctaBox2Link,
  ctaBox2HeadingForOption2,
  ctaBox2SubheadingForOption2,
  ctaBox2ButtonTextForOption2,
  ctaBox2LinkForOption2,
  secondaryTableID,
  secondaryTableHeading,
  secondaryTableSubheading,
  secondaryTableFootnote,
  secondaryTableRowHeadings,
  secondaryTableCtaColumnHeading,
  secondaryTableCtaColumnText,
  secondaryTableCtaColumnButtonText,
  secondaryTableCtaBoxHeading,
  secondaryTableCtaBoxButton_text,
  secondaryTableCtaBoxLink,
  showSecondaryTableOnOption2,
  tertiaryTableID,
  tertiaryTableHeading,
  tertiaryTableSubheading,
  tertiaryTableFootnote,
  tertiaryTableRowHeadings,
  supportBannerHeading,
  supportBannerSubheading,
  supportBannerButtonText,
  supportBannerButtonLink,
  supportBannerText,
  productPage,
  pathname,
}) => {
  const mainItems = items.filter((item) => get(item, 'table') === 'Main Table')
  const secondaryItems = items.filter((item) => get(item, 'table') === 'Secondary Table')
  const tertiaryItems = items.filter((item) => get(item, 'table') === 'Tertiary Table')

  const features = option2Features?.document?.data?.features

  const numberOfPlans = get(mainItems, 'length')
  const rows = rowHeadings?.document?.data?.rows
  const rowCount = get(rows, 'length')
  let priceIndex = undefined
  let rowsWithoutPrice = undefined
  if (Array.isArray(rows)) {
    priceIndex = rows.findIndex((row) => {
      const heading = get(row, 'heading')
      if (heading === 'Price') {
        return true
      } else {
        return false
      }
    })
    rowsWithoutPrice = rows.filter((row) => {
      const heading = get(row, 'heading')
      if (heading === 'Price') {
        return false
      } else {
        return true
      }
    })
  }
  const rowWithoutPriceCount = get(rowsWithoutPrice, 'length')

  const secondaryTableNumberOfPlans = get(secondaryItems, 'length')
  const secondaryTableRows = secondaryTableRowHeadings?.document?.data?.rows
  const secondaryTableRowCount = get(secondaryTableRows, 'length')

  const tertiaryTableNumberOfPlans = get(tertiaryItems, 'length')
  const tertiaryTableRows = tertiaryTableRowHeadings?.document?.data?.rows
  const tertiaryTableRowCount = get(tertiaryTableRows, 'length')

  const [selectedOption, setSelectedOption] = useState(0)
  const toggleOption = () => {
    if (selectedOption === 0) {
      setSelectedOption(1)
    } else {
      setSelectedOption(0)
    }
  }

  const optionsRef = useRef()
  const goToTop = () => {
    const element = get(optionsRef, 'current')
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  const supportPackagesRef = useRef()
  const goToSupportPackages = () => {
    const element = get(supportPackagesRef, 'current')
    if (element) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  const customLayout = pathname === '/channels/pricing'

  return (
    <>
      <Top>
        <TopHeadingWrapper productPage={productPage}>
          {heading && <TopHeading as="h1">{heading}</TopHeading>}
          {subheading && <TopSubheading>{subheading}</TopSubheading>}
        </TopHeadingWrapper>
        {(option1 || option2) && (
          <OptionsWrapper>
            <Options onClick={toggleOption} ref={optionsRef}>
              {option1 && <Option active={selectedOption === 0}>{option1}</Option>}
              {option2 && <Option active={selectedOption === 1}>{option2}</Option>}
            </Options>
          </OptionsWrapper>
        )}
      </Top>
      <CustomContainer>
        <PricingContainer>
          <Mobile>
            {selectedOption === 0 ? (
              <>
                {mainItems &&
                  mainItems.map((item, index) => {
                    const planName = item?.pricing_plan_v3?.document?.data?.plan_name
                    const price = item?.pricing_plan_v3?.document?.data?.price
                    const fields = item?.pricing_plan_v3?.document?.data?.fields
                    const buttonText = item?.pricing_plan_v3?.document?.data?.button_text
                    const buttonLink = item?.pricing_plan_v3?.document?.data?.button_link
                    let fieldsWithoutPrice = undefined
                    if (Array.isArray(fields)) {
                      if (priceIndex !== -1) {
                        fieldsWithoutPrice = fields
                          .slice(0, priceIndex)
                          .concat(fields.slice(priceIndex + 1, fields.length))
                      } else {
                        fieldsWithoutPrice = fields
                      }
                    }
                    return (
                      <Plan key={index} customLayout={customLayout}>
                        {() => {
                          return (
                            <>
                              <MobilePlanTop>
                                <div>
                                  {planName && <p>{planName}</p>}
                                  {price && (
                                    <Price>
                                      <PrismicRichText text={price} />
                                    </Price>
                                  )}
                                </div>
                                {buttonText && buttonLink && (
                                  <CustomLink link={buttonLink}>
                                    <Button dark>{buttonText}</Button>
                                  </CustomLink>
                                )}
                              </MobilePlanTop>
                              <RowContainer>
                                <LeftRow>
                                  {rowsWithoutPrice &&
                                    rowsWithoutPrice.map((row, index) => {
                                      const value = get(fieldsWithoutPrice, `[${index}].value`)
                                      const typeOfValue = get(fieldsWithoutPrice, `[${index}].type_of_value`)
                                      const additionalInfo = get(
                                        fieldsWithoutPrice,
                                        `[${index}].additional_information`
                                      )
                                      const linkToSupportPackages = get(
                                        fieldsWithoutPrice,
                                        `[${index}].link_to_support_packages`
                                      )
                                      if (index < rowWithoutPriceCount / 2) {
                                        return (
                                          <Row key={index} index={index}>
                                            {row.heading && (
                                              <Left bold>
                                                {row.heading}
                                                {row.additional_information && (
                                                  <Tooltip
                                                    // row={row}
                                                    content={row.additional_information}
                                                    goToSupportPackages={goToSupportPackages}
                                                  />
                                                )}
                                              </Left>
                                            )}
                                            <Right as="div">
                                              {typeOfValue === 'Yes' ? (
                                                <p>
                                                  <CircleIcon src={yesIcon} alt="Yes" />
                                                </p>
                                              ) : typeOfValue === 'No' ? (
                                                <p>
                                                  <CircleIcon src={noIcon} alt="No" />
                                                </p>
                                              ) : (
                                                value && <PrismicRichText text={value} />
                                              )}
                                              {additionalInfo && (
                                                <Tooltip
                                                  // row={row}
                                                  content={
                                                    linkToSupportPackages
                                                      ? additionalInfo + 'Find out more'
                                                      : additionalInfo
                                                  }
                                                  place="left"
                                                  goToSupportPackages={goToSupportPackages}
                                                />
                                              )}
                                            </Right>
                                          </Row>
                                        )
                                      }
                                    })}
                                </LeftRow>
                                <RightRow>
                                  {rowsWithoutPrice &&
                                    rowsWithoutPrice.map((row, index) => {
                                      const value = get(fieldsWithoutPrice, `[${index}].value`)
                                      const typeOfValue = get(fieldsWithoutPrice, `[${index}].type_of_value`)
                                      const additionalInfo = get(
                                        fieldsWithoutPrice,
                                        `[${index}].additional_information`
                                      )
                                      const linkToSupportPackages = get(
                                        fieldsWithoutPrice,
                                        `[${index}].link_to_support_packages`
                                      )
                                      if (index >= rowWithoutPriceCount / 2) {
                                        return (
                                          <Row key={index} index={index}>
                                            {row.heading && (
                                              <Left bold>
                                                {row.heading}
                                                {row.additional_information && (
                                                  <Tooltip
                                                    content={row.additional_information}
                                                    goToSupportPackages={goToSupportPackages}
                                                  />
                                                )}
                                              </Left>
                                            )}
                                            <Right as="div">
                                              {typeOfValue === 'Yes' ? (
                                                <p>
                                                  <CircleIcon src={yesIcon} alt="Yes" />
                                                </p>
                                              ) : typeOfValue === 'No' ? (
                                                <p>
                                                  <CircleIcon src={noIcon} alt="No" />
                                                </p>
                                              ) : (
                                                value && <PrismicRichText text={value} />
                                              )}
                                              {additionalInfo && (
                                                <Tooltip
                                                  content={
                                                    linkToSupportPackages
                                                      ? additionalInfo + 'Find out more'
                                                      : additionalInfo
                                                  }
                                                  place="left"
                                                  goToSupportPackages={goToSupportPackages}
                                                />
                                              )}
                                            </Right>
                                          </Row>
                                        )
                                      }
                                    })}
                                </RightRow>
                              </RowContainer>
                            </>
                          )
                        }}
                      </Plan>
                    )
                  })}
                {customLayout && (
                  <EnterprisePlan>
                    {(ctaBox2Heading || ctaBox2Subheading?.richText?.[0] || ctaBox2ButtonText) && (
                      <>
                        <EnterprisePlanTitle>Enterprise</EnterprisePlanTitle>
                        {ctaBox2Heading && <EnterprisePlanHeading>{ctaBox2Heading}</EnterprisePlanHeading>}
                        {ctaBox2Subheading && (
                          <EnterprisePlanSubheading as="div" productPage={productPage}>
                            <PrismicRichText text={ctaBox2Subheading} />
                          </EnterprisePlanSubheading>
                        )}
                        {ctaBox2ButtonText && (
                          <CustomLink link={ctaBox2Link}>
                            <Button fullWidthOnSmall dark textColor="white">
                              {ctaBox2ButtonText}
                            </Button>
                          </CustomLink>
                        )}
                      </>
                    )}
                  </EnterprisePlan>
                )}
              </>
            ) : (
              <>
                {option2Heading && <CustomHeading3 center>{option2Heading}</CustomHeading3>}
                {option2Subheading && <CustomParagraph center>{option2Subheading}</CustomParagraph>}
                {option2ButtonText && (
                  <OptionButton>
                    <CustomLink link={option2ButtonLink}>
                      <CTAButton fullWidthOnSmall dark>
                        {option2ButtonText}
                      </CTAButton>
                    </CustomLink>
                  </OptionButton>
                )}
                {features && (
                  <FeatureContainer>
                    <FeaturesList>
                      {features.map((item, index) => {
                        const feature = get(item, 'feature')
                        return (
                          feature && (
                            <FeatureOuter key={index}>
                              <Feature>
                                <img src={checkIcon} alt="" />
                                <Paragraph>{feature}</Paragraph>
                              </Feature>
                            </FeatureOuter>
                          )
                        )
                      })}
                    </FeaturesList>
                    {option2Image && (
                      <FeaturesImageWrapper>
                        <GatsbyImage image={option2Image} alt="" />
                      </FeaturesImageWrapper>
                    )}
                  </FeatureContainer>
                )}
              </>
            )}
          </Mobile>
          <Desktop>
            {rowCount > 0 && (
              <RowContainer>
                {selectedOption === 0 ? (
                  <>
                    {rows && (
                      <RowHeadingsColumn rowCount={rowCount}>
                        <PlanHeading />
                        {rows.map((row, rowIndex) => {
                          return (
                            <Row key={rowIndex} index={rowIndex}>
                              {row.heading && (
                                <Paragraph semibold>
                                  {row.heading}
                                  {row.additional_information && (
                                    <Tooltip
                                      row={row}
                                      content={row.additional_information}
                                      goToSupportPackages={goToSupportPackages}
                                    />
                                  )}
                                </Paragraph>
                              )}
                            </Row>
                          )
                        })}
                        <Row />
                      </RowHeadingsColumn>
                    )}
                    {mainItems &&
                      mainItems.map((item, index) => {
                        const fields = item?.pricing_plan_v3?.document?.data?.fields
                        const planName = item?.pricing_plan_v3?.document?.data?.plan_name
                        const price = item?.pricing_plan_v3?.document?.data?.price
                        const buttonText = item?.pricing_plan_v3?.document?.data?.button_text
                        const buttonLink = item?.pricing_plan_v3?.document?.data?.button_link
                        return (
                          <Column key={index} rowCount={rowCount} customLayout={customLayout}>
                            <PlanHeading numberOfPlans={numberOfPlans} key={index} customLayout={customLayout}>
                              <PlanHeadingInner customLayout={customLayout}>
                                {planName && <p>{planName}</p>}
                                {price && <PrismicRichText text={price} />}
                              </PlanHeadingInner>
                            </PlanHeading>
                            {fields &&
                              fields.map((field, index) => {
                                const value = get(field, `value`)
                                const additionalInfo = get(field, `additional_information`)
                                const linkToSupportPackages = get(field, 'link_to_support_packages')
                                const typeOfValue = get(field, 'type_of_value')
                                return (
                                  <Field key={index} customLayout={customLayout}>
                                    <FieldInner customLayout={customLayout}>
                                      {typeOfValue === 'Yes' ? (
                                        <p>
                                          <CircleIcon src={yesIcon} alt="Yes" />
                                        </p>
                                      ) : typeOfValue === 'No' ? (
                                        <p>
                                          <CircleIcon src={noIcon} alt="No" />
                                        </p>
                                      ) : (
                                        value && <PrismicRichText text={value} />
                                      )}
                                      {additionalInfo && (
                                        <Tooltip
                                          content={
                                            linkToSupportPackages ? additionalInfo + 'Find out more' : additionalInfo
                                          }
                                          goToSupportPackages={goToSupportPackages}
                                        />
                                      )}
                                    </FieldInner>
                                  </Field>
                                )
                              })}
                            {buttonText && buttonLink && (
                              <Field customLayout={customLayout}>
                                <FieldInner buttonStyle customLayout={customLayout}>
                                  <CustomLink link={buttonLink}>
                                    <Button dark>{buttonText}</Button>
                                  </CustomLink>
                                </FieldInner>
                              </Field>
                            )}
                          </Column>
                        )
                      })}
                    {selectedOption === 0 && (
                      <EnterprisePlan>
                        {(ctaBox2Heading || ctaBox2Subheading?.richText?.[0] || ctaBox2ButtonText) && (
                          <>
                            <EnterprisePlanTitle>Enterprise</EnterprisePlanTitle>
                            {ctaBox2Heading && <EnterprisePlanHeading>{ctaBox2Heading}</EnterprisePlanHeading>}
                            {ctaBox2Subheading && (
                              <EnterprisePlanSubheading as="div" productPage={productPage}>
                                <PrismicRichText text={ctaBox2Subheading} />
                              </EnterprisePlanSubheading>
                            )}
                            {ctaBox2ButtonText && (
                              <CustomLink link={ctaBox2Link}>
                                <Button fullWidthOnSmall dark textColor="white">
                                  {ctaBox2ButtonText}
                                </Button>
                              </CustomLink>
                            )}
                          </>
                        )}
                      </EnterprisePlan>
                    )}
                  </>
                ) : (
                  <>
                    {option2Heading && <CustomHeading3 center>{option2Heading}</CustomHeading3>}
                    {option2Subheading && <CustomParagraph center>{option2Subheading}</CustomParagraph>}
                    {option2ButtonText && (
                      <OptionButton>
                        <CustomLink link={option2ButtonLink}>
                          <CTAButton fullWidthOnSmall dark>
                            {option2ButtonText}
                          </CTAButton>
                        </CustomLink>
                      </OptionButton>
                    )}
                    {features && (
                      <FeatureContainer>
                        <FeaturesList>
                          {features.map((item, index) => {
                            const feature = get(item, 'feature')
                            return (
                              feature && (
                                <FeatureOuter key={index}>
                                  <Feature>
                                    <img src={checkIcon} alt="" />
                                    <Paragraph>{feature}</Paragraph>
                                  </Feature>
                                </FeatureOuter>
                              )
                            )
                          })}
                        </FeaturesList>
                        {option2Image && (
                          <FeaturesImageWrapper>
                            <GatsbyImage image={option2Image} alt="" />
                          </FeaturesImageWrapper>
                        )}
                      </FeatureContainer>
                    )}
                  </>
                )}
              </RowContainer>
            )}
          </Desktop>
        </PricingContainer>
        {secondaryTableHeading ? (
          <CustomHeading3
            support
            center
            noTopPadding={selectedOption === 1}
            ref={supportPackagesRef}
            id={secondaryTableID}
          >
            {secondaryTableHeading}
          </CustomHeading3>
        ) : (
          <Divider selectedOption={selectedOption} />
        )}
        {secondaryTableSubheading && (
          <CustomParagraph support center>
            {secondaryTableSubheading}
          </CustomParagraph>
        )}
        {!(selectedOption === 1 && !showSecondaryTableOnOption2) && (
          <PricingContainer>
            <Mobile>
              {secondaryItems &&
                secondaryItems.map((item, index) => {
                  const planName = item?.pricing_plan_v3?.document?.data?.plan_name
                  const price = item?.pricing_plan_v3?.document?.data?.price
                  const fields = item?.pricing_plan_v3?.document?.data?.fields
                  const priceName = price?.richText?.[0]?.text
                  return (
                    <Plan key={index}>
                      {(toggle, height) => {
                        return (
                          <>
                            <MobilePlanTop>
                              <div>
                                {planName && <p>{planName}</p>}
                                {price &&
                                  (priceName === 'See fixed plans' ? (
                                    <Price>
                                      <FieldInnerButton
                                        onClick={() => {
                                          setSelectedOption(0)
                                          goToTop()
                                        }}
                                      >
                                        <PrismicRichText text={price} />
                                      </FieldInnerButton>
                                    </Price>
                                  ) : (
                                    <Price>
                                      <PrismicRichText text={price} />
                                    </Price>
                                  ))}
                              </div>
                              <CustomSelectButtonTablet
                                noBorder
                                onClick={toggle}
                                rotate={height === 'auto' ? true : false}
                              >
                                {height === 'auto' ? 'Close' : 'Find out more'}
                              </CustomSelectButtonTablet>
                            </MobilePlanTop>
                            <AnimateHeight duration={500} height={height}>
                              <RowContainer support>
                                <LeftRow>
                                  {secondaryTableRows &&
                                    secondaryTableRows.map((row, index) => {
                                      const value = get(fields, `[${index}].value`)
                                      const typeOfValue = get(fields, `[${index}].type_of_value`)
                                      const additionalInfo = get(fields, `[${index}].additional_information`)
                                      const tooltipOnSecondaryTable = get(row, `tooltip_on_secondary_table`)
                                      const additionalInformation = get(row, `additional_information`)
                                      if (index < secondaryTableRowCount / 2) {
                                        return (
                                          <>
                                            <Row key={index} index={index}>
                                              {row.heading && (
                                                <Left bold>
                                                  {row.heading}
                                                  {tooltipOnSecondaryTable && additionalInformation && (
                                                    <Tooltip
                                                      content={additionalInformation}
                                                      place="top"
                                                      goToSupportPackages={goToSupportPackages}
                                                    />
                                                  )}
                                                </Left>
                                              )}
                                              <Right as="div">
                                                {typeOfValue === 'Yes' ? (
                                                  <p>
                                                    <CircleIcon src={yesIcon} alt="Yes" />
                                                  </p>
                                                ) : typeOfValue === 'No' ? (
                                                  <p>
                                                    <CircleIcon src={noIcon} alt="No" />
                                                  </p>
                                                ) : (
                                                  value && <PrismicRichText text={value} />
                                                )}
                                                {additionalInfo && (
                                                  <Tooltip
                                                    content={additionalInfo}
                                                    place="left"
                                                    goToSupportPackages={goToSupportPackages}
                                                  />
                                                )}
                                              </Right>
                                            </Row>
                                            {row.additional_information && !tooltipOnSecondaryTable && (
                                              <SupportAdditionalInfo>
                                                {row.additional_information}
                                              </SupportAdditionalInfo>
                                            )}
                                            {row.link_text && (
                                              <CustomLink link={row.link}>
                                                <SupportLink>{row.link_text}</SupportLink>
                                              </CustomLink>
                                            )}
                                          </>
                                        )
                                      }
                                    })}
                                </LeftRow>
                                <RightRow>
                                  {secondaryTableRows &&
                                    secondaryTableRows.map((row, index) => {
                                      const value = get(fields, `[${index}].value`)
                                      const typeOfValue = get(fields, `[${index}].type_of_value`)
                                      const additionalInfo = get(fields, `[${index}].additional_information`)
                                      if (index >= secondaryTableRowCount / 2) {
                                        return (
                                          <>
                                            <Row key={index} index={index}>
                                              {row.heading && <Left bold>{row.heading}</Left>}
                                              <Right as="div">
                                                {typeOfValue === 'Yes' ? (
                                                  <p>
                                                    <CircleIcon src={yesIcon} alt="Yes" />
                                                  </p>
                                                ) : typeOfValue === 'No' ? (
                                                  <p>
                                                    <CircleIcon src={noIcon} alt="No" />
                                                  </p>
                                                ) : (
                                                  value && <PrismicRichText text={value} />
                                                )}
                                                {additionalInfo && (
                                                  <Tooltip
                                                    content={additionalInfo}
                                                    place="left"
                                                    goToSupportPackages={goToSupportPackages}
                                                  />
                                                )}
                                              </Right>
                                            </Row>
                                            {row.additional_information && (
                                              <SupportAdditionalInfo>
                                                {row.additional_information}
                                              </SupportAdditionalInfo>
                                            )}
                                            {row.link_text && (
                                              <CustomLink link={row.link}>
                                                <SupportLink>{row.link_text}</SupportLink>
                                              </CustomLink>
                                            )}
                                          </>
                                        )
                                      }
                                    })}
                                </RightRow>
                              </RowContainer>
                            </AnimateHeight>
                            <CustomSelectButtonPhone
                              noBorder
                              onClick={toggle}
                              rotate={height === 'auto' ? true : false}
                            >
                              {height === 'auto' ? 'Close' : 'Find out more'}
                            </CustomSelectButtonPhone>
                          </>
                        )
                      }}
                    </Plan>
                  )
                })}
              {secondaryTableCtaColumnHeading && (
                <CTABoxContainer ctaColumn>
                  <CTABox fullWidth purpleBg productPage={productPage}>
                    {secondaryTableCtaColumnHeading && (
                      <CTAHeading h3 productPage={productPage}>
                        {secondaryTableCtaColumnHeading}
                      </CTAHeading>
                    )}
                    {secondaryTableCtaColumnText && (
                      <CTASubheading productPage={productPage}>{secondaryTableCtaColumnText}</CTASubheading>
                    )}
                    {secondaryTableCtaColumnButtonText && (
                      <CTAButton
                        fullWidthOnSmall
                        onClick={() => {
                          setSelectedOption(1)
                          goToTop()
                        }}
                        productPage={productPage}
                      >
                        {secondaryTableCtaColumnButtonText}
                      </CTAButton>
                    )}
                  </CTABox>
                </CTABoxContainer>
              )}
              {secondaryTableFootnote?.richText?.[0]?.richText?.[0] && (
                <Footnote>
                  <div>*</div>
                  <div>
                    <PrismicRichText text={secondaryTableFootnote?.richText?.[0]} />
                  </div>
                </Footnote>
              )}
            </Mobile>
            <Desktop>
              <RowContainer>
                <RowInnerContainer>
                  <div>
                    {secondaryItems && (
                      <RowHeadingsColumn
                        ctaColumn={secondaryTableCtaColumnHeading}
                        support
                        rowCount={secondaryTableNumberOfPlans}
                      >
                        <PlanHeading />
                        {secondaryItems.map((item, itemIndex) => {
                          const planName = item?.pricing_plan_v3?.document?.data?.plan_name
                          return (
                            <Row support key={itemIndex} index={itemIndex}>
                              {planName && <Paragraph semibold>{planName}</Paragraph>}
                            </Row>
                          )
                        })}
                      </RowHeadingsColumn>
                    )}
                    <Column ctaColumn={secondaryTableCtaColumnHeading} support rowCount={secondaryTableNumberOfPlans}>
                      <PlanHeading>
                        <PlanHeadingInner support>
                          <p>Price</p>
                        </PlanHeadingInner>
                      </PlanHeading>
                      {secondaryItems &&
                        secondaryItems.map((item, itemIndex) => {
                          const price = item?.pricing_plan_v3?.document?.data?.price
                          const priceName = price?.richText?.[0]?.text
                          return (
                            <Field support key={itemIndex}>
                              <FieldInner support={!secondaryTableCtaColumnHeading}>
                                {price &&
                                  (priceName === 'See fixed plans' ? (
                                    <FieldInnerButton
                                      onClick={() => {
                                        setSelectedOption(0)
                                        goToTop()
                                      }}
                                    >
                                      <PrismicRichText text={price} />
                                    </FieldInnerButton>
                                  ) : (
                                    <PrismicRichText text={price} />
                                  ))}
                              </FieldInner>
                            </Field>
                          )
                        })}
                    </Column>
                    {secondaryTableRows &&
                      secondaryTableRows.map((row, rowIndex) => {
                        const heading = get(row, 'heading')
                        const additionalInfo = get(row, 'additional_information')
                        const tooltipOnSecondaryTable = get(row, 'tooltip_on_secondary_table')
                        const linkText = get(row, 'link_text')
                        const link = get(row, 'link')
                        return (
                          <Column
                            ctaColumn={secondaryTableCtaColumnHeading}
                            support
                            rowCount={secondaryTableNumberOfPlans}
                            key={rowIndex}
                          >
                            <PlanHeading key={rowIndex}>
                              <PlanHeadingInner support>
                                {heading && (
                                  <p>
                                    {heading}
                                    {tooltipOnSecondaryTable && (
                                      <Tooltip
                                        content={additionalInfo}
                                        place="top"
                                        goToSupportPackages={goToSupportPackages}
                                      />
                                    )}
                                  </p>
                                )}
                                {additionalInfo && !tooltipOnSecondaryTable && (
                                  <SupportAdditionalInfo>{additionalInfo}</SupportAdditionalInfo>
                                )}
                                {linkText && (
                                  <CustomLink link={link}>
                                    <SupportLink>{linkText}</SupportLink>
                                  </CustomLink>
                                )}
                              </PlanHeadingInner>
                            </PlanHeading>
                            {secondaryItems &&
                              secondaryItems.map((item, itemIndex) => {
                                const value = get(item, `pricing_plan_v3.document.data.fields[${rowIndex}].value`)
                                const typeOfValue = get(
                                  item,
                                  `pricing_plan_v3.document.data.fields[${rowIndex}].type_of_value`
                                )
                                return (
                                  <Field key={itemIndex}>
                                    <FieldInner support={!secondaryTableCtaColumnHeading}>
                                      {typeOfValue === 'Yes' ? (
                                        <p>
                                          <CircleIcon src={yesIcon} alt="Yes" />
                                        </p>
                                      ) : typeOfValue === 'No' ? (
                                        <p>
                                          <CircleIcon src={noIcon} alt="No" />
                                        </p>
                                      ) : (
                                        value && <PrismicRichText text={value} />
                                      )}
                                    </FieldInner>
                                  </Field>
                                )
                              })}
                          </Column>
                        )
                      })}
                  </div>
                  {secondaryTableCtaColumnHeading && (
                    <CTAColumn>
                      <p>{secondaryTableCtaColumnHeading}</p>
                      <p>{secondaryTableCtaColumnText}</p>
                      {secondaryTableCtaColumnButtonText && (
                        <CTAButton onClick={toggleOption}>{secondaryTableCtaColumnButtonText}</CTAButton>
                      )}
                    </CTAColumn>
                  )}
                </RowInnerContainer>
              </RowContainer>
              {secondaryTableFootnote?.richText?.[0] && (
                <Footnote>
                  <div>*</div>
                  <div>
                    <PrismicRichText text={secondaryTableFootnote} />
                  </div>
                </Footnote>
              )}
            </Desktop>
          </PricingContainer>
        )}
        {(secondaryTableCtaBoxHeading || secondaryTableCtaBoxButton_text) &&
          !(selectedOption === 1 && !showSecondaryTableOnOption2) && (
            <CTABoxContainer support>
              <CTABox fullWidth productPage={productPage}>
                {secondaryTableCtaBoxHeading && (
                  <CTAHeading h3 productPage={productPage}>
                    {secondaryTableCtaBoxHeading}
                  </CTAHeading>
                )}
                {secondaryTableCtaBoxButton_text && (
                  <CustomLink link={secondaryTableCtaBoxLink}>
                    <CTAButton fullWidthOnSmall productPage={productPage}>
                      {secondaryTableCtaBoxButton_text}
                    </CTAButton>
                  </CustomLink>
                )}
              </CTABox>
            </CTABoxContainer>
          )}
      </CustomContainer>
      {selectedOption === 1 && supportBannerHeading && (
        <SupportBannerContainer>
          {supportBannerHeading && <SupportBannerHeading center>{supportBannerHeading}</SupportBannerHeading>}
          {supportBannerSubheading && (
            <SupportBannerSubheading center>{supportBannerSubheading}</SupportBannerSubheading>
          )}
          {supportBannerButtonText && (
            <CustomLink link={supportBannerButtonLink}>
              <Button fullWidthOnSmall dark>
                {supportBannerButtonText}
              </Button>
            </CustomLink>
          )}
          {supportBannerText && (
            <SupportBannerText center>
              <PrismicRichText text={supportBannerText} />
            </SupportBannerText>
          )}
        </SupportBannerContainer>
      )}
      {selectedOption === 1 && (
        <CustomContainer>
          <CTABoxContainer>
            {(ctaBox1Heading || ctaBox1Subheading?.richText?.[0] || ctaBox1ButtonText) && (
              <CTABox fullWidth productPage={productPage}>
                {ctaBox1Heading && <CTAHeading productPage={productPage}>{ctaBox1Heading}</CTAHeading>}
                {ctaBox1Subheading && (
                  <CTASubheading as="div" productPage={productPage}>
                    <PrismicRichText text={ctaBox1Subheading} />
                  </CTASubheading>
                )}
                {ctaBox1ButtonText && (
                  <CustomLink link={ctaBox1Link}>
                    <CTAButton fullWidthOnSmall dark productPage={productPage}>
                      {ctaBox1ButtonText}
                    </CTAButton>
                  </CustomLink>
                )}
              </CTABox>
            )}
            {(ctaBox2HeadingForOption2 || ctaBox2SubheadingForOption2 || ctaBox2ButtonTextForOption2) && (
              <CTABox purpleBg fullWidth productPage={productPage}>
                {ctaBox2HeadingForOption2 && (
                  <CTAHeading productPage={productPage}>{ctaBox2HeadingForOption2}</CTAHeading>
                )}
                {ctaBox2SubheadingForOption2 && (
                  <CTASubheading as="div" productPage={productPage}>
                    <PrismicRichText text={ctaBox2SubheadingForOption2} />
                  </CTASubheading>
                )}
                {ctaBox2ButtonTextForOption2 &&
                  (ctaBox2LinkForOption2 ? (
                    <CustomLink link={ctaBox2LinkForOption2}>
                      <CTAButton fullWidthOnSmall productPage={productPage}>
                        {ctaBox2ButtonTextForOption2}
                      </CTAButton>
                    </CustomLink>
                  ) : (
                    <CTAButton
                      fullWidthOnSmall
                      reversedWhite
                      textColor="#300D4F"
                      onClick={() => {
                        setSelectedOption(0)
                        goToTop()
                      }}
                    >
                      {ctaBox2ButtonTextForOption2}
                    </CTAButton>
                  ))}
              </CTABox>
            )}
          </CTABoxContainer>
        </CustomContainer>
      )}
      {tertiaryTableHeading && (
        <CustomContainer>
          {tertiaryTableHeading ? (
            <CustomHeading3 noTopPadding support center ref={supportPackagesRef} id={tertiaryTableID}>
              {tertiaryTableHeading}
            </CustomHeading3>
          ) : (
            <Divider selectedOption={selectedOption} />
          )}
          {tertiaryTableSubheading && (
            <CustomParagraph support center>
              {tertiaryTableSubheading}
            </CustomParagraph>
          )}
          {tertiaryTableHeading && (
            <PricingContainer>
              <Mobile>
                {tertiaryItems &&
                  tertiaryItems.map((item, index) => {
                    const planName = item?.pricing_plan_v3?.document?.data?.plan_name
                    const price = item?.pricing_plan_v3?.document?.data?.price
                    const fields = item?.pricing_plan_v3?.document?.data?.fields
                    const priceName = price?.richText?.[0]?.text
                    return (
                      <Plan key={index}>
                        {(toggle, height) => {
                          return (
                            <>
                              <MobilePlanTop>
                                <div>
                                  {planName && <p>{planName}</p>}
                                  {price &&
                                    (priceName === 'See fixed plans' ? (
                                      <Price>
                                        <FieldInnerButton
                                          onClick={() => {
                                            setSelectedOption(0)
                                            goToTop()
                                          }}
                                        >
                                          <PrismicRichText text={price} />
                                        </FieldInnerButton>
                                      </Price>
                                    ) : (
                                      <Price>
                                        <PrismicRichText text={price} />
                                      </Price>
                                    ))}
                                </div>
                                <CustomSelectButtonTablet
                                  noBorder
                                  onClick={toggle}
                                  rotate={height === 'auto' ? true : false}
                                >
                                  {height === 'auto' ? 'Close' : 'Find out more'}
                                </CustomSelectButtonTablet>
                              </MobilePlanTop>
                              <AnimateHeight duration={500} height={height}>
                                <RowContainer support>
                                  <LeftRow>
                                    {tertiaryTableRows &&
                                      tertiaryTableRows.map((row, index) => {
                                        const value = get(fields, `[${index}].value`)
                                        const typeOfValue = get(fields, `[${index}].type_of_value`)
                                        const additionalInfo = get(fields, `[${index}].additional_information`)
                                        const tooltipOnTertiaryTable = get(row, `tooltip_on_tertiary_table`)
                                        const additionalInformation = get(row, `additional_information`)
                                        if (index < tertiaryTableRowCount / 2) {
                                          return (
                                            <>
                                              <Row key={index} index={index}>
                                                {row.heading && (
                                                  <Left bold>
                                                    {row.heading}
                                                    {tooltipOnTertiaryTable && additionalInformation && (
                                                      <Tooltip
                                                        content={additionalInformation}
                                                        place="top"
                                                        goToSupportPackages={goToSupportPackages}
                                                      />
                                                    )}
                                                  </Left>
                                                )}
                                                <Right as="div">
                                                  {typeOfValue === 'Yes' ? (
                                                    <p>
                                                      <CircleIcon src={yesIcon} alt="Yes" />
                                                    </p>
                                                  ) : typeOfValue === 'No' ? (
                                                    <p>
                                                      <CircleIcon src={noIcon} alt="No" />
                                                    </p>
                                                  ) : (
                                                    value && <PrismicRichText text={value} />
                                                  )}
                                                  {additionalInfo && (
                                                    <Tooltip
                                                      content={additionalInfo}
                                                      place="left"
                                                      goToSupportPackages={goToSupportPackages}
                                                    />
                                                  )}
                                                </Right>
                                              </Row>
                                              {row.additional_information && !tooltipOnTertiaryTable && (
                                                <SupportAdditionalInfo>
                                                  {row.additional_information}
                                                </SupportAdditionalInfo>
                                              )}
                                              {row.link_text && (
                                                <CustomLink link={row.link}>
                                                  <SupportLink>{row.link_text}</SupportLink>
                                                </CustomLink>
                                              )}
                                            </>
                                          )
                                        }
                                      })}
                                  </LeftRow>
                                  <RightRow>
                                    {tertiaryTableRows &&
                                      tertiaryTableRows.map((row, index) => {
                                        const value = get(fields, `[${index}].value`)
                                        const typeOfValue = get(fields, `[${index}].type_of_value`)
                                        const additionalInfo = get(fields, `[${index}].additional_information`)
                                        if (index >= tertiaryTableRowCount / 2) {
                                          return (
                                            <>
                                              <Row key={index} index={index}>
                                                {row.heading && <Left bold>{row.heading}</Left>}
                                                <Right as="div">
                                                  {typeOfValue === 'Yes' ? (
                                                    <p>
                                                      <CircleIcon src={yesIcon} alt="Yes" />
                                                    </p>
                                                  ) : typeOfValue === 'No' ? (
                                                    <p>
                                                      <CircleIcon src={noIcon} alt="No" />
                                                    </p>
                                                  ) : (
                                                    value && <PrismicRichText text={value} />
                                                  )}
                                                  {additionalInfo && (
                                                    <Tooltip
                                                      content={additionalInfo}
                                                      place="left"
                                                      goToSupportPackages={goToSupportPackages}
                                                    />
                                                  )}
                                                </Right>
                                              </Row>
                                              {row.additional_information && (
                                                <SupportAdditionalInfo>
                                                  {row.additional_information}
                                                </SupportAdditionalInfo>
                                              )}
                                              {row.link_text && (
                                                <CustomLink link={row.link}>
                                                  <SupportLink>{row.link_text}</SupportLink>
                                                </CustomLink>
                                              )}
                                            </>
                                          )
                                        }
                                      })}
                                  </RightRow>
                                </RowContainer>
                              </AnimateHeight>
                              <CustomSelectButtonPhone
                                noBorder
                                onClick={toggle}
                                rotate={height === 'auto' ? true : false}
                              >
                                {height === 'auto' ? 'Close' : 'Find out more'}
                              </CustomSelectButtonPhone>
                            </>
                          )
                        }}
                      </Plan>
                    )
                  })}
                {tertiaryTableFootnote && (
                  <Footnote>
                    <div>*</div>
                    <div>
                      <PrismicRichText text={tertiaryTableFootnote} />
                    </div>
                  </Footnote>
                )}
              </Mobile>
              <Desktop>
                <RowContainer>
                  <RowInnerContainer>
                    <div>
                      {tertiaryItems && (
                        <RowHeadingsColumn support rowCount={tertiaryTableNumberOfPlans}>
                          <PlanHeading />
                          {tertiaryItems.map((item, itemIndex) => {
                            const planName = item?.pricing_plan_v3?.document?.data?.plan_name
                            return (
                              <Row support key={itemIndex} index={itemIndex}>
                                {planName && <Paragraph semibold>{planName}</Paragraph>}
                              </Row>
                            )
                          })}
                        </RowHeadingsColumn>
                      )}
                      <Column support rowCount={tertiaryTableNumberOfPlans}>
                        <PlanHeading>
                          <PlanHeadingInner support>
                            <p>Price</p>
                          </PlanHeadingInner>
                        </PlanHeading>
                        {tertiaryItems &&
                          tertiaryItems.map((item, itemIndex) => {
                            const price = item?.pricing_plan_v3?.document?.data?.price
                            const priceName = price?.richText?.[0]?.text
                            return (
                              <Field support key={itemIndex}>
                                <FieldInner support>
                                  {price &&
                                    (priceName === 'See fixed plans' ? (
                                      <FieldInnerButton
                                        onClick={() => {
                                          setSelectedOption(0)
                                          goToTop()
                                        }}
                                      >
                                        <PrismicRichText text={price} />
                                      </FieldInnerButton>
                                    ) : (
                                      <PrismicRichText text={price} />
                                    ))}
                                </FieldInner>
                              </Field>
                            )
                          })}
                      </Column>
                      {tertiaryTableRows &&
                        tertiaryTableRows.map((row, rowIndex) => {
                          const heading = get(row, 'heading')
                          const additionalInfo = get(row, 'additional_information')
                          const tooltipOnTertiaryTable = get(row, 'tooltip_on_tertiary_table')
                          const linkText = get(row, 'link_text')
                          const link = get(row, 'link')
                          return (
                            <Column support rowCount={tertiaryTableNumberOfPlans} key={rowIndex}>
                              <PlanHeading key={rowIndex}>
                                <PlanHeadingInner support>
                                  {heading && (
                                    <p>
                                      {heading}
                                      {tooltipOnTertiaryTable && (
                                        <Tooltip
                                          content={additionalInfo}
                                          place="top"
                                          goToSupportPackages={goToSupportPackages}
                                        />
                                      )}
                                    </p>
                                  )}
                                  {additionalInfo && !tooltipOnTertiaryTable && (
                                    <SupportAdditionalInfo>{additionalInfo}</SupportAdditionalInfo>
                                  )}
                                  {linkText && (
                                    <CustomLink link={link}>
                                      <SupportLink>{linkText}</SupportLink>
                                    </CustomLink>
                                  )}
                                </PlanHeadingInner>
                              </PlanHeading>
                              {tertiaryItems &&
                                tertiaryItems.map((item, itemIndex) => {
                                  const value = get(item, `pricing_plan_v3.document.data.fields[${rowIndex}].value`)
                                  const typeOfValue = get(
                                    item,
                                    `pricing_plan_v3.document.data.fields[${rowIndex}].type_of_value`
                                  )
                                  return (
                                    <Field key={itemIndex}>
                                      <FieldInner support>
                                        {typeOfValue === 'Yes' ? (
                                          <p>
                                            <CircleIcon src={yesIcon} alt="Yes" />
                                          </p>
                                        ) : typeOfValue === 'No' ? (
                                          <p>
                                            <CircleIcon src={noIcon} alt="No" />
                                          </p>
                                        ) : (
                                          value && <PrismicRichText text={value} />
                                        )}
                                      </FieldInner>
                                    </Field>
                                  )
                                })}
                            </Column>
                          )
                        })}
                    </div>
                  </RowInnerContainer>
                </RowContainer>
                {tertiaryTableFootnote && (
                  <Footnote>
                    <div>*</div>
                    <div>
                      <PrismicRichText text={tertiaryTableFootnote} />
                    </div>
                  </Footnote>
                )}
              </Desktop>
            </PricingContainer>
          )}
        </CustomContainer>
      )}
    </>
  )
}

export default PricingTable

const PlanContainer = styled.div`
  margin-bottom: 16px;
`

const PlanInnerContainer = styled.div`
  border: 1px solid ${(p) => p.theme.blueMidLight};
  padding: 16px;
  border-radius: 4px;
  ${(p) =>
    p.customLayout &&
    css`
      position: relative;
      margin-top: 16px;
      border: 2px solid #eae7f9;
      border: 2px solid #eae7f9;
      border-radius: 0px;
      :before {
        content: '';
        height: calc(100% + 4px);
        width: 6px;
        position: absolute;
        top: -2px;
        left: -2px;
        background-color: #5d2be7;
      }
    `}
`

class Plan extends React.Component {
  state = {
    height: 0,
  }

  toggle = () => {
    const { height } = this.state

    this.setState({
      height: height === 0 ? 'auto' : 0,
    })
  }
  render() {
    return (
      <PlanContainer>
        <PlanInnerContainer customLayout={this.props.customLayout}>
          {this.props.children(this.toggle, this.state.height)}
        </PlanInnerContainer>
      </PlanContainer>
    )
  }
}
