import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect'
import getLinkableID from '../../utils/getLinkableId'

import caret from '../../../static/images/caret.svg'

const MobileWrapper = styled.div`
  position: relative;
  width: 100%;

  ::before {
    content: '';
    background-image: url(${caret});
    background-size: contain;
    position: absolute;
    right: 18px;
    top: 18px;
    width: 12px;
    height: 12px;
    pointer-events: none;
    background-repeat: no-repeat;
  }

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    display: none;
  }
`
const Select = styled.select`
  width: 100%;
  padding: 8px 16px;
  padding-right: 32px;
  border: 0px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${(p) => p.theme.textColor};
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  background-color: ${(p) => p.theme.chameleon.colors.pusher.lightBlueishGrey};
  appearance: button;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
`

const DesktopWrapper = styled.ul`
  display: none;
  scrollbar-gutter: stable;
  padding-right: 4px;
  overflow-y: hidden;
  width: 100%;
  max-height: 84vh;
  top: 0;

  :hover {
    overflow-y: auto;
  }

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    display: block;
  }

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.xl}) {
    max-width: 264px;
  }
`
const Heading = styled.li`
  display: block;
  margin-bottom: 8px;
  margin-left: ${(p) => DEPTH_MARGIN_MAPPING[p.$depth]}px;

  :first-of-type {
    border-top: none;
  }
  :last-of-type {
    margin-bottom: 0;
  }
`
const HeadingLink = styled.a`
  display: block;
  background-color: ${(p) => (p.$isActive ? p.theme.chameleon.colors.pusher.lightBlueishGrey : 'none')};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color ease 200ms;

  :hover {
    opacity: ${(p) => (p.$isActive ? 1 : 0.7)};
  }
`

const DEPTH_MARGIN_MAPPING = {
  2: 0,
  3: 24,
  4: 48,
  5: 72,
}

const TableOfContent = ({ headings }) => {
  const [activeSection, setActiveSection] = useState('')
  const desktopContainer = useRef(null)

  const SEEN = {}
  const headingsWithIntroduction = [{ value: '0. Introduction', depth: 2 }, ...headings].map(({ value, depth }) => {
    if (SEEN[value] > 0) {
      SEEN[value] += 1
      value = `${SEEN[value]}. ${value}`
    } else {
      SEEN[value] = 1
    }

    return { value, depth }
  })

  console.log('headingsWithIntroduction', headingsWithIntroduction)

  useIsomorphicLayoutEffect(() => {
    const hash = window.location.hash
    if (hash) setActiveSection(hash)

    const handleScroll = () => {
      const positions = headingsWithIntroduction.map(({ value }) => {
        const { id, href, displayValue } = getHeadingLinkAndValue(value)

        const element = document.getElementById(id)
        const headingElement = document.querySelector(`[href="${href}"]`)
        if (!element) return {}

        return {
          id,
          href,
          text: displayValue,
          posY: element.offsetTop - window.scrollY,
          headingElement,
        }
      })

      const nearestTop = positions.find(({ posY }) => {
        if (posY > 0 && posY < 180) return true
        return false
      })

      // console.log('setting active section', nearestTop, headingsWithIntroduction, positions)
      if (nearestTop && nearestTop.href !== activeSection) {
        setActiveSection(nearestTop.href)
        scrollParentToChild(desktopContainer.current, nearestTop.headingElement)
      } else if (window.scrollY < positions[0].posY) setActiveSection('')
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const pathname = window.location.pathname
    const displaySection =
      !activeSection || activeSection === '#0-introduction' ? pathname : activeSection.replace(/\d+-/, '')

    window.history.replaceState(null, null, displaySection)
  }, [activeSection])

  return (
    <>
      <MobileWrapper>
        <Select
          value={activeSection}
          onChange={(e) => {
            setActiveSection(e.target.value)
            window.location.hash = e.target.value
          }}
        >
          {headingsWithIntroduction.map(({ value }) => {
            const { href, displayValue } = getHeadingLinkAndValue(value)

            return (
              <option key={href} value={href}>
                {displayValue}
              </option>
            )
          })}
        </Select>
      </MobileWrapper>

      <DesktopWrapper ref={desktopContainer}>
        {headingsWithIntroduction.map(({ value, depth }) => {
          const { href, displayValue } = getHeadingLinkAndValue(value)

          return (
            <Heading key={href} $depth={depth}>
              <HeadingLink href={href} $isActive={activeSection === href}>
                {displayValue}
              </HeadingLink>
            </Heading>
          )
        })}
      </DesktopWrapper>
    </>
  )
}

export default TableOfContent

export function getHeadingLinkAndValue(value) {
  if (!value)
    return {
      id: '',
      href: '',
      displayValue: '',
    }

  const valueToUse = typeof value === 'string' ? value : value.props.children[0]
  const id = getLinkableID([valueToUse])
  const href = `#${id}`
  let displayValue = valueToUse.split('. ')
  displayValue = displayValue[displayValue.length - 1]

  // console.log('value', value, id, href, displayValue)
  return { id, href, displayValue }
}

function scrollParentToChild(parent, child) {
  const parentRect = parent.getBoundingClientRect()
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  }

  const childRect = child.getBoundingClientRect()
  const isChildVisible =
    childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parentViewableArea.height
  if (isChildVisible) return

  const scrollTop = childRect.top - parentRect.top
  const scrollBottom = childRect.bottom - parentRect.bottom
  parent.scrollTop += Math.abs(scrollTop) < Math.abs(scrollBottom) ? scrollTop : scrollBottom
}
