import React from 'react'
import loadable from '@loadable/component'

const LottieAnimationLoadable = loadable(() => import('./LottieAnimationLoadable'))

const LottieAnimation = ({ path, animationData, autoplay = false, loop = false, playStart, play }) => {
  return (
    <LottieAnimationLoadable
      path={path}
      animationData={animationData}
      autoplay={autoplay}
      loop={loop}
      playStart={playStart}
      play={play}
    />
  )
}

export default LottieAnimation
