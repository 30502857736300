import styled, { css } from 'styled-components'

import caretDown from '../images/caret-down.svg'
import caretDownPurple from '../images/caret-down-purple.svg'
import caretDownWhite from '../images/caret-down-white.svg'

export const Button = styled.button`
  padding: 8px 16px;
  background-color: ${(p) => p.theme.purpleDark};
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.5px;
  color: white;
  border-radius: 4px;
  transition: all 0.1s ease;
  :hover {
    background-color: ${(p) => p.theme.purple};
  }
  :active {
    background-color: ${(p) => p.theme.grey};
    color: ${(p) => p.theme.textColor};
  }
  ${(p) =>
    p.green &&
    css`
      background-color: ${(p) => p.theme.green};
      color: ${(p) => p.theme.textColor};
      :hover {
        background-color: #6e769c;
        color: white;
      }
    `}
  ${(p) =>
    p.dark &&
    css`
      background-color: ${(p) => p.theme.textColor};
      border: 2px solid ${(p) => p.theme.textColor};
      ${(p) => p.textColor && `color: ${p.textColor};`} :hover {
        color: white;
        background-color: #7d6cad;
        border: 2px solid #7d6cad;
        ${(p) =>
          p.backgroundColor === 'green' &&
          css`
            background-color: #6e769c;
            color: white;
            border: 2px solid #6e769c;
          `}
      }
    `}
  ${(p) =>
    p.reversedDark &&
    css`
      color: ${(p) => p.theme.textColor};
      background-color: transparent;
      border: 2px solid ${(p) => p.theme.textColor};
      :hover {
        background-color: ${(p) => p.theme.textColor};
        ${(p) => p.textColor && `color: ${p.textColor};`}
        border: 2px solid ${(p) => p.theme.textColor};
        color: white;
        ${(p) =>
          p.backgroundColor === 'green' &&
          css`
            background-color: #6e769c;
            color: white;
            border: 2px solid #6e769c;
          `}
      }
    `}
  ${(p) =>
    p.reversed &&
    css`
      background-color: white;
      color: ${(p) => p.theme.chameleon.colors.pusher.darkPurple};
      :hover {
        color: white;
      }
      :active {
        color: ${(p) => p.theme.textColor};
      }
    `}
  ${(p) =>
    p.reversedWhite &&
    css`
      background-color: white;
      color: ${(p) => p.theme.chameleon.colors.pusher.darkPurple};
      border: 2px solid white;
      padding: 6px 14px;
      ${(p) => p.textColor && `color: ${p.textColor};`} :hover {
        color: white;
        background-color: transparent;
        border: 2px solid white;
      }
      :active {
        color: ${(p) => p.theme.textColor};
      }
    `}
  ${(p) =>
    p.tertiary &&
    css`
      background-color: transparent;
      border: 2px solid ${(p) => p.theme.purpleDark};
      padding: 6px 14px;
      color: ${(p) => p.theme.purpleDark};
      :hover {
        color: white;
        border: 2px solid ${(p) => p.theme.purple};
      }
      :active {
        color: ${(p) => p.theme.textColor};
        border: 2px solid ${(p) => p.theme.grey};
      }
    `}
  ${(p) =>
    p.option &&
    css`
      background-color: transparent;
      border: 1px solid ${(p) => p.theme.blueishGrey};
      padding: 9px 8px;
      color: ${(p) => p.theme.purpleDark};
      :hover {
        color: white;
        border: 1px solid ${(p) => p.theme.purple};
      }
      :active {
        color: ${(p) => p.theme.textColor};
        border: 1px solid ${(p) => p.theme.grey};
      }
      ${p.selected &&
      css`
        color: white;
        background-color: ${(p) => p.theme.textColor};
        border: 1px solid ${(p) => p.theme.textColor};
        :hover {
          background-color: ${(p) => p.theme.textColor};
          border: 1px solid ${(p) => p.theme.textColor};
        }
        :active {
          color: white;
        }
      `}
    `}
  ${(p) =>
    p.fullWidthOnSmall &&
    css`
      width: 100%;
      @media (min-width: ${(p) => p.theme.breakpointMd}) {
        width: initial;
      }
    `}
`

export const SelectButton = styled.button`
  position: relative;
  width: 100%;
  padding: 6px 16px;
  border: 2px solid ${(p) => p.theme.purpleDark};
  border-radius: 4px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: ${(p) => p.theme.purpleDark};
  text-align: left;
  background-color: white;
  transition: all 0.1s ease;
  ${(p) =>
    p.noBorder &&
    css`
      border: none;
      padding: 8px 16px;
    `}
  ::before {
    content: '';
    background-image: url(${caretDownPurple});
    background-size: cover;
    position: absolute;
    right: 16px;
    top: 12px;
    width: 12px;
    height: 12px;
    pointer-events: none;
    ${(p) => p.rotate && 'transform: rotate(180deg); top: 17px;'}
    ${(p) => p.noBorder && 'top: 14px;'}
    ${(p) => p.noBorder && p.rotate && 'transform: rotate(180deg); top: 19px;'}
  }
  :hover {
    background-color: ${(p) => p.theme.purple};
    color: white;
    ::before {
      background-image: url(${caretDownWhite});
    }
  }
  :active {
    background-color: ${(p) => p.theme.grey};
    color: ${(p) => p.theme.textColor};
    ::before {
      background-image: url(${caretDown});
    }
  }
`
