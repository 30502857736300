import React from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import { useInView } from 'react-intersection-observer'

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Heading2Styles, Heading3, Heading4, Heading5Styles, Paragraph, StyledLink } from '../styles/Text'
import { Button } from '../styles/Buttons'

const OuterContainer = styled.div`
  position: relative;
  overflow-y: visible;
  ${(p) => p.backgroundColor === 'grey' && 'background-color: #F8F8F8;'}
  ${(p) => p.backgroundColor === 'grey' && p.version === 'v4' && 'background-color: #F0EFF4;'}
  ${(p) => p.backgroundColor === 'purple grey darker' && `background-color: ${p.theme.purpleGreyDarker};`}
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 80px;
  text-align: ${(p) => p.align || 'left'};
  ${(p) => p.productPage && 'padding-bottom: 72px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.productPage && 'padding-bottom: 43px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-bottom: 48px;
    ${(p) => p.version === 'v4' && 'padding-top: 80px; padding-bottom: 80px;'}
  }
`

const CustomHeading3 = styled(Heading3)`
  text-align: center;
  ${(p) => p.productPage && 'text-align: left;'}
  ${(p) =>
    p.version === 'v5' &&
    css`
      text-align: ${(p) => p.align || 'left'};
      ${Heading2Styles}
    `}
    ${(p) => p.align === 'center' && 'text-align: center;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    text-align: 'left';
    ${(p) => !p.productPage && 'max-width: 380px;'}
    ${(p) => p.productPage && 'text-align: center;'}

    ${(p) =>
      p.align === 'center' &&
      `max-width: unset;
      text-align: center;
      margin-left: auto;
    `}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) => !p.productPage && 'max-width: 410px;'}
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    ${(p) => p.version === 'v2' && 'max-width: initial;'}
    ${(p) => p.version === 'v3' && 'max-width: 600px;'}
    ${(p) => p.version === 'v5' && `max-width: 600px; text-align: left; margin-left: 0px;`}

    ${(p) =>
      p.align === 'center' &&
      `
      max-width: unset;
      text-align: center;
      margin-left: auto;
    `}
  }
`

const FeaturesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 72px;
  transition: opacity 500ms ease;
  opacity: 0;
  ${(p) => p.inView && 'opacity: 1;'}
  ${(p) => p.version === 'v4' && 'margin-top: 0;'}
  ${(p) => p.version === 'v5' && 'margin-top: 40px;'}
  > a {
    display: flex;
    width: 100%;
  }
  > a > li {
    width: 100%;
  }
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    ${(p) =>
      p.version === 'v3' &&
      css`
        flex-direction: row;
        padding: 12px 16px;
        border: 1px solid #d4d4f5;
        border-radius: 8px;
        h4 {
          text-align: left;
        }
      `}
    ${(p) =>
      p.version === 'v5' &&
      css`
        padding: 24px 24px 32px;
        border-radius: 8px;
        background-color: #f8f6ff;
        h4 {
          ${Heading5Styles}
          min-height: 66px;
        }
      `}
  }
  > li,
  > a {
    :not(:last-child) {
      margin-bottom: 64px;
      ${(p) => p.productPage && 'margin-bottom: 90px;'}
    }
    ${(p) =>
      p.version === 'v3' &&
      css`
        margin-bottom: 16px;
        :not(:last-child) {
          margin-bottom: 16px;
        }
      `}
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      width: calc(100% / 3);
      :not(:last-child) {
        margin-bottom: 0px;
        margin-right: 30px;
      }
      ${(p) =>
        p.version === 'v3' &&
        css`
          width: calc((100% / 3) - 20px);
          margin-bottom: 16px;
          :not(:last-child) {
            margin-bottom: 16px;
          }
          :nth-child(3n) {
            margin-right: 0px;
          }
        `}
    }
  }
  ${Paragraph} {
    margin-bottom: 5px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 32px;
    flex-direction: row;
    ${(p) =>
      p.version === 'v3' &&
      css`
        flex-wrap: wrap;
        li {
          transition: ease-in-out 0.2s;
          :hover {
            background-color: ${(p) => p.theme.purple};
            border-color: ${(p) => p.theme.purple};
            color: white;
            img:nth-child(2) {
              opacity: 1;
            }
          }
        }
      `}
    ${(p) => p.version === 'v4' && 'margin-top: 0;'}
    ${(p) => p.version === 'v5' && 'margin-top: 40px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-top: 82px;
    ${(p) => p.productPage && 'margin-top: 62px;'}
    ${(p) => p.version === 'v3' && 'margin-top: 72px;'}
    ${(p) => p.version === 'v4' && 'margin-top: 0;'}
    ${(p) => p.version === 'v5' && 'margin-top: 40px;'}
  }
`

const ImgContainer = styled.div`
  position: relative;
  height: 80px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 100%;
  }
  img:nth-child(2) {
    transition: ease-in-out 0.2s;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  ${(p) => p.version === 'v3' && 'height: initial; margin-right: 16px; margin-bottom: 0px;'}
  ${(p) => p.version === 'v5' && 'height: initial; width: 128px; margin-bottom: 16px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 72px;
    ${(p) => p.version === 'v3' && 'height: initial;'}
    ${(p) => p.version === 'v5' && 'height: initial;'}
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 48px;
  a,
  ${Button} {
    width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    a {
      width: initial;
    }
    justify-content: center;
  }
`

const LargeHeading = styled.h2`
  margin-bottom: 8px;
  font-size: 54px;
  line-height: 54px;
  font-weight: 600;
  letter-spacing: 0.5px;
  ${(p) => p.center && 'text-align: center;'}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    font-size: 88px;
    line-height: 88px;
  }
`

const Features = ({ version, heading, buttonText, buttonLink, features, productPage, backgroundColor, align }) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  return (
    <OuterContainer backgroundColor={backgroundColor} version={version}>
      <CustomContainer
        largeMobilePadding={productPage ? false : true}
        productPage={productPage}
        version={version}
        align={align}
      >
        {heading && (
          <CustomHeading3 productPage={productPage} version={version} align={align}>
            {heading}
          </CustomHeading3>
        )}
        {features && (
          <FeaturesContainer productPage={productPage} ref={inViewRef} inView={inView} version={version}>
            {features.map(({ icon, icon_hover, heading, description, link_text, link }, index) => {
              {
                const iconUrl = get(icon, 'url')
                const iconHoverUrl = get(icon_hover, 'url')
                return version === 'v3' && link ? (
                  <CustomLink link={link}>
                    <li key={index}>
                      {iconUrl && (
                        <ImgContainer version={version}>
                          <img src={iconUrl} alt="" />
                          <img src={iconHoverUrl} alt="" />
                        </ImgContainer>
                      )}
                      {heading && <Heading4 center>{heading}</Heading4>}
                      {description && <Paragraph center>{description}</Paragraph>}
                      {link_text && (
                        <CustomLink link={link}>
                          <StyledLink>{link_text}</StyledLink>
                        </CustomLink>
                      )}
                    </li>
                  </CustomLink>
                ) : (
                  <li key={index}>
                    {iconUrl && (
                      <ImgContainer version={version}>
                        <img src={iconUrl} alt="" />
                      </ImgContainer>
                    )}
                    {heading &&
                      (version === 'v4' ? (
                        <LargeHeading center>{heading}</LargeHeading>
                      ) : (
                        <Heading4 center>{heading}</Heading4>
                      ))}
                    {description && <Paragraph center>{description}</Paragraph>}
                    {link_text && (
                      <CustomLink link={link}>
                        <StyledLink>{link_text}</StyledLink>
                      </CustomLink>
                    )}
                  </li>
                )
              }
            })}
          </FeaturesContainer>
        )}
        {buttonText && (
          <ButtonContainer>
            <CustomLink link={buttonLink}>
              <Button dark>{buttonText}</Button>
            </CustomLink>
          </ButtonContainer>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default Features
