import React from 'react'
import styled from 'styled-components'
import get from 'lodash.get'
import ReactPlayer from 'react-player/youtube'

import Container from '../styles/Container'
import { Heading1Styles } from '../styles/Text'

const OuterContainer = styled.div`
  background-color: #160228;
  color: white;
  padding-top: 80px;
  padding-bottom: 80px;
`

const Heading = styled.h2`
  ${Heading1Styles}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-top: 64px;
  }
`

const Item = styled.div`
  p {
    margin-top: 16px;
  }
  :nth-child(2) {
    margin-top: 48px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 40px);
    :nth-child(2) {
      margin-top: 0px;
    }
  }
`

const VideoContainer = styled.div`
  width: 100%;
`

const VideoInner = styled.div`
  padding-top: calc(9 / 16 * 100%);
  position: relative;
  > div {
    position: absolute !important;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100% !important;
    height: auto !important;
  }
`

const Videos = ({ heading, items }) => {
  const hasItems = get(items, '[0].video')
  return (
    <OuterContainer>
      <Container>
        {heading && <Heading>{heading}</Heading>}
        <Wrapper>
          {hasItems &&
            items.map((item, index) => {
              const videoUrl = get(item, 'video')
              const text = get(item, 'text')
              return (
                <Item key={index}>
                  <VideoContainer>
                    <VideoInner>
                      <ReactPlayer controls url={videoUrl} width="100%" height="100%" />
                    </VideoInner>
                  </VideoContainer>
                  {text && <p>{text}</p>}
                </Item>
              )
            })}
        </Wrapper>
      </Container>
    </OuterContainer>
  )
}

export default Videos
