import React from 'react'
import styled from 'styled-components'

import { CustomLink } from '../../utils/linkResolver'

import LandingPageContainer from '../../styles/LandingPageContainer'
import { Button } from '../../styles/Buttons'

const CustomContainer = styled(LandingPageContainer)``

const Wrapper = styled.div`
  padding: 16px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.textColor};
  color: white;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    padding: 24px;
    border-radius: 6px;
  }
`

const Heading = styled.h2`
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 23px;
  line-height: 148%;
  text-align: center;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    font-size: 39px;
    line-height: 124%;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    margin-top: 24px;
  }
`

const CTABanner = ({ slice }) => {
  const heading = slice?.primary?.heading
  const buttonText = slice?.primary?.button_text
  const buttonLink = slice?.primary?.button_link

  return (
    <CustomContainer>
      <Wrapper>
        {heading && <Heading>{heading}</Heading>}
        {buttonText && (
          <ButtonWrapper>
            <CustomLink link={buttonLink}>
              <Button reversedWhite textColor="#300D4F">
                {buttonText}
              </Button>
            </CustomLink>
          </ButtonWrapper>
        )}
      </Wrapper>
    </CustomContainer>
  )
}

export default CTABanner
