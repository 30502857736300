import React, { useEffect } from 'react'
import { Script } from 'gatsby'
import styled, { css } from 'styled-components'

const hubspotFormsStyleOverride = css`
  #hubspot-form {
    font-family: 'Maison Neue', sans-serif !important;
  }

  fieldset {
    max-width: 100% !important;
    margin: 24px 0 !important;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='number'],
  input[type='date'],
  input[type='url'],
  input[type='password'],
  textarea {
    width: 100% !important;
    padding: 8px;
    border: 2px solid #e6e2fc;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 172%;
    color: #300d4f;
    outline: none;

    ::placeholder {
      color: #7f7fa3;
    }
    :focus-visible {
      border: 2px solid #c223ce;
    }
  }
  input.error {
    border: 2px solid #f11b1b;
  }
  .hs-error-msgs .hs-main-font-element {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.25px;
    color: #e51520;
  }

  input[type='radio'],
  input[type='checkbox'] {
    accent-color: #c223ce;
    margin-right: 8px;
  }

  fieldset > .hs-form-field > label > span {
    font-size: 16px;
    font-weight: 700;
    line-height: 28px; /* 175% */
    letter-spacing: 0.25px;
  }

  input[type='submit'] {
    border-style: none;
    border-radius: 4px;
    background-color: #300d4f;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    padding: 8px 16px;
    cursor: pointer;

    :hover {
      background-color: #7d6cad;
    }
  }
`

const Container = styled.div`
  margin: 0 auto;
  padding: 0 16px;
  margin-bottom: 64px;
  max-width: 992px;

  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding: 0 45px;
  }

  ${hubspotFormsStyleOverride}
`

const ScriptEmbed = ({ id, src }) => {
  const [renders, setRenders] = React.useState([])

  useEffect(() => {
    const toRender = []
    const parser = new DOMParser()
    const doc = parser.parseFromString(src, 'text/html')
    const scriptTags = doc.querySelectorAll('script')
    const srcScripts = [...scriptTags].filter((scriptTag) => scriptTag.getAttribute('src'))
    const inlineScripts = [...scriptTags].filter((scriptTag) => scriptTag.textContent.trim())

    srcScripts.forEach((scriptTag, index) => {
      let src = scriptTag.getAttribute('src')
      toRender.push(
        <Script
          key={`${id}-'src'`}
          id={`${id}-'src'`}
          src={src}
          type="text/javascript"
          onLoad={() => {
            if (index === srcScripts.length - 1) {
              setRenders((renders) => [
                ...renders,
                inlineScripts.map((inlineScript) => {
                  let inline = inlineScript.textContent.trim()
                  return (
                    <Script key={`${id}-'inline'`} id={`${id}-'inline'`} dangerouslySetInnerHTML={{ __html: inline }} />
                  )
                }),
              ])
            }
          }}
        />
      )
    })

    setRenders(toRender)
  }, [id, src])

  return (
    <>
      <Container>
        <div id="hubspot-form" />
      </Container>

      {renders}
    </>
  )
}

export default ScriptEmbed
