/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useEffect, useState } from 'react'
import Cookies from 'universal-cookie'

import useSessionStorage from '../hooks/useSessionStorage'
import useUtmTags from '../hooks/useUtmTags'

const API_URL = 'https://dotcom.messagebird.com/'
const cookies = new Cookies()

const generateSessionId = () => Math.random().toString(36).slice(2, 14)

const InstrumentationContext = createContext({})

const processInstrumentationCall = async ({ eventData, visitorData, sessionId }) => {
  const { event = 'track', pageId, activity, payload = {} } = eventData

  const data = {
    call: event,
    data: {
      visitorId: visitorData?.visitorId,
      sessionId,
      pageId,
      activity,
      payload,
    },
  }

  // eslint-disable-next-line no-console
  if (process.env.NODE_ENV !== 'production') return console.info(JSON.stringify(data)) // console.info is for local debugging

  fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  }).then((res) => res.text())
}

const loadScript = async (url, id) => {
  const isScriptAlreadyLoaded = document.querySelector(`script[src="${url}"]`)

  return new Promise((resolve, reject) => {
    const el = document.createElement('script')
    el.onload = resolve
    el.onerror = reject
    el.src = url
    el.id = id

    if (!isScriptAlreadyLoaded) {
      document.documentElement.appendChild(el)
    }
  })
}

export default function InstrumentationContextProvider({ children, location }) {
  const [sessionId] = useSessionStorage('sessionId', generateSessionId())
  const [firstPageLoadedAt] = useSessionStorage('firstPageLoadedAt', Date.now())
  const [pendingCalls, setPendingCalls] = useState([])
  const utmTags = useUtmTags({ location })
  const [visitorData, setVisitorData] = useState(null)

  useEffect(() => {
    loadScript('https://dotcom.messagebird.com/assets/fp.min.js', 'fingerprintjs').then(() => {
      window.FingerprintJS.load()
        .then((fp) => fp.get())
        .then((result) => {
          setVisitorData(result)
        })
    })
  }, [])

  const trackEvent = async (eventData) => {
    if (!visitorData?.visitorId) {
      setPendingCalls((prev) => [...prev, eventData])
      return null
    }

    processInstrumentationCall({ eventData, visitorData, sessionId, utmTags })
  }

  useEffect(() => {
    if (!window?.document?.referrer) {
      // extract all utm params from the url
      const utmParams = new URLSearchParams(location.search)
      const utmTags = {
        gclid: utmParams.get('gclid'),
      }
      utmParams.forEach((value, key) => {
        if (key.startsWith('utm_')) {
          utmTags[key] = value
        }
      })

      trackEvent({
        activity: 'campaign',
        payload: {
          referrer: window.document.referrer,
          ...utmTags,
        },
      })
    }
  }, [])

  useEffect(() => {
    if (visitorData?.visitorId && pendingCalls.length > 0) {
      pendingCalls.forEach((eventData) => processInstrumentationCall({ eventData, visitorData, sessionId, utmTags }))
      setPendingCalls([])
    }
  }, [pendingCalls, visitorData, sessionId, utmTags])

  useEffect(() => {
    if (visitorData?.visitorId && sessionId) {
      cookies.set('visitorId', visitorData.visitorId, {
        path: '/',
        domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.pusher.com',
      })
      cookies.set('sessionId', sessionId, {
        path: '/',
        domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.pusher.com',
      })
    }
  }, [visitorData?.visitorId, sessionId])

  const handleUnload = useCallback(async () => {
    const timeSpent = Date.now() - firstPageLoadedAt
    await trackEvent({
      event: 'pageUnload',
      payload: {
        timeSpent,
      },
    })
  }, [firstPageLoadedAt])

  useEffect(() => {
    if (!window) return
    window.addEventListener('beforeunload', handleUnload)
    return () => window.removeEventListener('beforeunload', handleUnload)
  }, [handleUnload])

  return <InstrumentationContext.Provider value={{ trackEvent }}>{children}</InstrumentationContext.Provider>
}

export const useInstrumentation = () => React.useContext(InstrumentationContext)
