import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import get from 'lodash.get'
import { useInView } from 'react-intersection-observer'
import AnimateHeight from 'react-animate-height'

import { CustomLink } from '../utils/linkResolver'
import widont from '../utils/widont'

import PrismicRichText from './PrismicRichText'

import Container from '../styles/Container'
import { Heading2, Heading3, Heading4, Heading5, Paragraph, StyledLinkCaretComponent } from '../styles/Text'
import { Button } from '../styles/Buttons'

import checkIcon from '../images/check-blue-icon-thick.svg'

const OuterContainer = styled.div`
  ${(p) => p.hasPinkText && 'background-color: #190C25; color: white;'}
`

const CustomContainer = styled(Container)`
  padding-top: 16px;
  padding-bottom: 80px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-bottom: 16px;
    ${(p) => p.version === 'v2' && 'padding-bottom: 64px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 16px;
    padding-bottom: 26px;
    scroll-margin-top: 88px;
    ${(p) => p.version === 'v2' && 'padding-bottom: 64px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-top: 40px;
    padding-bottom: 16px;
    ${(p) => p.version === 'v2' && 'padding-top: 64px; padding-bottom: 64px;'}
  }
`

const CustomHeading2 = styled(Heading2)`
  max-width: 100%;
  ${(p) => p.hasPinkText && 'color: #FF8DE2;'}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    ${(p) =>
      p.version === 'v2' &&
      css`
        max-width: 400px;
      `}
  }
`

const CustomHeading3 = styled(Heading3)`
  text-align: center;
`

const CustomHeading4 = styled(Heading4)`
  max-width: 100%;
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 680px;
    ${(p) => p.pathname.endsWith('live-events') && 'font-size: 34px; line-height: 48px;'}
  }
`

const Subheading = styled(Paragraph)`
  margin-top: 8px;
  ${(p) => p.version === 'v3' && 'max-width: 450px;'}
`

const ItemsContainer = styled.div`
  margin-top: 40px;
  transition: opacity 500ms ease;
  opacity: 0;
  ${(p) => p.inView && 'opacity: 1;'}
  > a, > div {
    display: block;
    :not(:last-child) {
      margin-bottom: 56px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    > a,
    > div {
      width: calc(50% - 15px);
      :not(:last-child) {
        margin-bottom: 64px;
      }
      margin-bottom: 64px;
      :nth-child(2n + 1) {
        margin-right: 15px;
      }
      :nth-child(2n) {
        margin-left: 15px;
      }
      h4,
      p,
      li,
      span,
      svg,
      rect {
        transition: color 0.15s ease-in, fill 0.15s ease-in;
      }
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    > a,
    > div {
      width: calc(25% - (90px / 4));
      :not(:last-child) {
        margin-bottom: 64px;
      }
      margin-bottom: 64px;
      :nth-child(2n) {
        margin-left: 0px;
      }
      :nth-child(2n + 1) {
        margin-right: 0px;
      }
      :nth-child(n) {
        margin-right: 30px;
      }
      :nth-child(4n) {
        margin-right: 0px;
      }
      ${(p) =>
        p.numberOfItemsPerRow === '3' &&
        css`
          width: calc((100% / 3) - 30px);
          :nth-child(4n) {
            margin-right: 30px;
          }
          :nth-child(3n) {
            margin-right: 0px;
          }
        `}
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-top: 32px;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  ${Heading4} {
    margin-bottom: 8px;
  }
  ${Paragraph} {
    margin-bottom: 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    align-items: flex-start;
    ${Paragraph} {
      margin-bottom: 0px;
    }
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  max-width: 400px;
  align-self: center;
  margin-bottom: 16px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    max-width: initial;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: 100%;
  }
`

const LinkText = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 16px;
  }
`

const Description = styled.div`
  li {
    position: relative;
    margin-left: 24px;
    :not(:last-child) {
      margin-bottom: 10px;
    }
    :before {
      content: '';
      position: absolute;
      top: 3px;
      left: -24px;
      width: 16px;
      height: 17px;
      background-image: url('${checkIcon}');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
`

const Wrapper = styled.div`
  margin-top: 56px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  max-width: 324px;
  align-self: center;
  img {
    width: 100%;
  }
  ${(p) => p.version === 'v2' && 'display: none;'}
  ${(p) => p.version === 'v3' && 'margin-top: 24px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(50% - 15px);
    max-width: initial;
    ${(p) =>
      p.version === 'v2' &&
      css`
        display: block;
      `}
    ${(p) => p.version === 'v3' && 'width: calc(60% - 15px); margin-top: 0px; margin-left: 30px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    margin-left: 30px;
  }
`

const DropdownContainer = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 50%;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: 100%;
    max-width: 500px;
    ${(p) => p.version === 'v3' && 'max-width: 368px;'}
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 40px;
  > a:nth-child(2) {
    margin-top: 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-top: 0;
  }
`

const UseCases = ({
  version,
  heading,
  headingSize,
  subheading,
  items,
  numberOfItemsPerRow,
  button1Text,
  button1Link,
  button2Text,
  button2Link,
  productPage,
  pathname,
}) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  })

  const [selectedIndex, setSelectedIndex] = useState(0)

  const image = items?.[selectedIndex]?.image?.gatsbyImageData

  const hasPinkText = pathname?.endsWith('game-development/')

  return (
    <OuterContainer hasPinkText={hasPinkText}>
      <CustomContainer version={version} id="use-cases">
        {heading &&
          (headingSize === 'large' ? (
            <CustomHeading2 version={version} hasPinkText={hasPinkText}>
              {heading}
            </CustomHeading2>
          ) : productPage === 'channels' ? (
            <CustomHeading4 pathname={pathname}>{heading}</CustomHeading4>
          ) : (
            <CustomHeading3>{heading}</CustomHeading3>
          ))}
        {subheading && <Subheading version={version}>{subheading}</Subheading>}
        {version === 'v2' || version === 'v3'
          ? items && (
              <Wrapper>
                <DropdownContainer version={version}>
                  {items.map((item, index) => {
                    const heading = get(item, 'heading')
                    const showDropdown = heading
                    return showDropdown && version !== 'v3' ? (
                      <Dropdown
                        key={index}
                        item={item}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        itemNr={index}
                      />
                    ) : (
                      <ClickableItem
                        key={index}
                        item={item}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        itemNr={index}
                        version={version}
                        hasPinkText={hasPinkText}
                      />
                    )
                  })}
                </DropdownContainer>
                {image && (
                  <ImageContainer version={version}>
                    <GatsbyImage image={image} alt="" />
                  </ImageContainer>
                )}
              </Wrapper>
            )
          : items && (
              <ItemsContainer ref={inViewRef} inView={inView} numberOfItemsPerRow={numberOfItemsPerRow}>
                {items.map(({ heading, description, description_list, image, link, link_text }, index) => {
                  const imageData = image?.gatsbyImageData
                  return description_list?.richText?.[0] ? (
                    <Item>
                      <CustomLink key={index} link={link}>
                        {imageData && <Image image={imageData} alt="" />}
                      </CustomLink>
                      {heading && <Heading4>{heading}</Heading4>}
                      {description ? (
                        <Paragraph>{widont(description)}</Paragraph>
                      ) : (
                        description_list && (
                          <Description>
                            <PrismicRichText text={description_list} />
                          </Description>
                        )
                      )}
                      {link_text && (
                        <LinkText>
                          <StyledLinkCaretComponent>{link_text}</StyledLinkCaretComponent>
                        </LinkText>
                      )}
                    </Item>
                  ) : (
                    <CustomLink key={index} link={link}>
                      <Item>
                        {imageData && <Image image={imageData} alt="" />}
                        {heading && <Heading4>{heading}</Heading4>}
                        {description ? (
                          <Paragraph>{widont(description)}</Paragraph>
                        ) : (
                          description_list && (
                            <Description>
                              <PrismicRichText text={description_list} />
                            </Description>
                          )
                        )}
                        {link_text && (
                          <LinkText>
                            <StyledLinkCaretComponent color="#300D4F">{link_text}</StyledLinkCaretComponent>
                          </LinkText>
                        )}
                      </Item>
                    </CustomLink>
                  )
                })}
              </ItemsContainer>
            )}

        {(button1Text || button2Text) && (
          <ButtonWrapper>
            {button1Text && (
              <CustomLink link={button1Link}>
                <Button dark>{button1Text}</Button>
              </CustomLink>
            )}
            {button2Text && (
              <CustomLink link={button2Link}>
                <Button dark>{button2Text}</Button>
              </CustomLink>
            )}
          </ButtonWrapper>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default UseCases

const DropdownWrapper = styled.div`
  position: relative;
  transition: ease-in-out 0.2s;
  border-radius: 4px;
  :not(:last-child) {
    margin-bottom: 8px;
  }
  svg,
  path {
    transition: ease-in-out 0.2s;
  }
  a {
    display: flex;
  }
  :hover {
    background-color: #7d6cad;
    color: white;
    path {
      stroke: white;
    }
    img:nth-child(2) {
      opacity: 1;
    }
  }
  ${(p) =>
    p.isSelected &&
    css`
      background-color: ${(p) => p.theme.blue};
      :hover {
        background-color: ${(p) => p.theme.blue};
      }
      color: white;
      path {
        stroke: white;
      }
      svg {
        transform: rotate(180deg);
      }
    `}
`

const TopWrapper = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
`

const Icon = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-height: 100%;
  }
  img:nth-child(2) {
    transition: ease-in-out 0.2s;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    ${(p) => p.active && 'opacity: 1;'}
  }
`

const Heading = styled(Heading4)`
  flex-grow: 1;
  text-align: left;
`

const Caret = styled.div`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 56px;
  a {
    margin-right: 32px;
    margin-bottom: 16px;
    @media (min-width: ${(p) => p.theme.breakpointMd}) {
      margin-right: auto;
    }
  }
`

const DropdownDescription = styled(Paragraph)`
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 0px 32px 0px 0px;
`

const CTAButton = styled(Button)`
  line-height: 22px;
  min-height: 44px;
  width: 100%;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: initial;
    max-width: 172px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: initial;
  }
`

const Dropdown = ({ item, selectedIndex, setSelectedIndex, itemNr }) => {
  const heading = get(item, 'heading')
  const description = get(item, 'description')
  const link = get(item, 'link')
  const linkText = get(item, 'link_text')
  const iconUrl = get(item, 'icon.url')
  const iconHoverUrl = get(item, 'icon_hover.url')

  const height = itemNr === selectedIndex ? 'auto' : 0

  return (
    <DropdownWrapper isSelected={height === 'auto'}>
      <TopWrapper onClick={() => setSelectedIndex(itemNr)}>
        {iconUrl && (
          <Icon active={height === 'auto'}>
            <img src={iconUrl} alt="" />
            <img src={iconHoverUrl} alt="" />
          </Icon>
        )}
        {heading && <Heading>{heading}</Heading>}
        <Caret isSelected={height === 'auto'}>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.51465 8.92123L11.9999 17.4065"
              stroke="#300D4F"
              strokeWidth="3"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.4854 8.92126L12.0001 17.4065"
              stroke="#300D4F"
              strokeWidth="3"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Caret>
      </TopWrapper>
      <AnimateHeight duration={500} height={height}>
        <BottomWrapper>
          {description && <DropdownDescription>{widont(description)}</DropdownDescription>}
          {linkText && (
            <CustomLink link={link}>
              <CTAButton dark textColor="white" fullWidthOnSmall>
                {linkText}
              </CTAButton>
            </CustomLink>
          )}
        </BottomWrapper>
      </AnimateHeight>
    </DropdownWrapper>
  )
}

const ClickableItemWrapper = styled.div`
  position: relative;
  transition: ease-in-out 0.2s;
  svg,
  path {
    transition: ease-in-out 0.2s;
  }
  a {
    display: flex;
  }
  :not(:last-child) {
    margin-bottom: 8px;
  }
  border-radius: 8px;
  :hover {
    background-color: #7d6cad;
    color: white;
    img:nth-child(2) {
      opacity: 1;
    }
  }
  ${(p) =>
    p.version === 'v3' &&
    css`
      border-radius: 4px;
      :hover {
        background-color: #e6e2fc;
        color: ${p.theme.textColor};
      }
    `}
  ${(p) =>
    p.hasPinkText &&
    css`
      :hover {
        background-color: #372b52;
        color: white;
      }
    `}
  ${(p) =>
    p.isSelected &&
    css`
      background-color: ${(p) => p.theme.blue};
      :hover {
        background-color: ${(p) => p.theme.blue};
      }
      color: white;
      svg {
        transform: rotate(180deg);
      }
      ${p.version === 'v3' &&
      css`
        background-color: #e6e2fc;
        color: ${p.theme.textColor};
        :hover {
          background-color: #e6e2fc;
          color: ${p.theme.textColor};
        }
      `}
      ${p.hasPinkText &&
      css`
        background-color: #372b52;
        color: white;
        :hover {
          background-color: #372b52;
          color: white;
        }
      `}
    `}
`

const ClickableItemTopWrapper = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
`

const ClickableItemIcon = styled.div`
  width: 34px;
  flex-shrink: 0;
  margin-right: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 34px;
    max-height: 100%;
  }
  img:nth-child(2) {
    transition: ease-in-out 0.2s;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    ${(p) => p.active && 'opacity: 1;'}
  }
`
const ClickableItemDescription = styled(Paragraph)`
  width: 100%;
  text-align: left;
`

const ClickableItemHeading = styled(Heading5)`
  text-align: left;
`

const ClickableItem = ({ item, selectedIndex, setSelectedIndex, itemNr, version, hasPinkText }) => {
  const heading = get(item, 'heading')
  const description = get(item, 'description')
  const iconUrl = get(item, 'icon.url')
  const iconHoverUrl = get(item, 'icon_hover.url')

  const isSelected = itemNr === selectedIndex

  return (
    <ClickableItemWrapper isSelected={isSelected} version={version} hasPinkText={hasPinkText}>
      <ClickableItemTopWrapper onClick={() => setSelectedIndex(itemNr)}>
        {iconUrl && (
          <ClickableItemIcon active={isSelected}>
            <img src={iconUrl} alt="" />
            <img src={iconHoverUrl} alt="" />
          </ClickableItemIcon>
        )}
        <div>
          {version === 'v3' && heading && <ClickableItemHeading>{heading}</ClickableItemHeading>}
          {description && <ClickableItemDescription>{widont(description)}</ClickableItemDescription>}
        </div>
      </ClickableItemTopWrapper>
    </ClickableItemWrapper>
  )
}
