const theme = {
  textColor: '#300D4F',
  primaryColor: '#FF5252',
  purple: '#C223CE',
  purpleLight: '#DB07EB',
  purpleDark: '#8731D2',
  purpleGrey: '#EDEAEF',
  purpleGreyDarker: '#C7B9FF',
  purpleGreyDark: '#E6E2FC',
  purpleGreyLight: '#F5F3F6',
  purpleGreyLighter: '#FBFAFB',
  orangeLight: '#FFF3F1',
  green: '#41FFB7',
  greenLight: '#E9FCF6',
  blueMidLight: '#DFE3F7',
  blueLight: '#E9E9FC',
  grey: '#DCDCDC',
  midLightGrey: '#353845',
  midLighterGrey: '#3E414F',
  midGrey: '#2D303E',
  darkGrey: '#2A2A2A',
  darkerGrey: '#1A1C25',
  darkPurple: '#24192E',
  nearBlack: '#101112',
  blue: '#5F30E2',
  blueishGrey: '#E0E0F9',
  blueishGreyLight: '#F8F6FF',
  successGreen: '#4BB543',

  chameleon: {
    type: {
      desktop: {
        h1: {
          fontSize: '48px',
          lineHeight: '64px',
        },
        h2: {
          fontSize: '40px',
          lineHeight: '52px',
        },
        h3: {
          fontSize: '32px',
          lineHeight: '44px',
        },
        h4: {
          fontSize: '24px',
          lineHeight: '36px',
        },
        h5: {
          fontSize: '16px',
          lineHeight: '28px',
        },
        p: {
          fontSize: '16px',
          lineHeight: '28px',
        },
      },
      mobile: {
        h1: {
          fontSize: '36px',
          lineHeight: '48px',
        },
        h2: {
          fontSize: '32px',
          lineHeight: '48px',
        },
        h3: {
          fontSize: '28px',
          lineHeight: '40px',
        },
        h4: {
          fontSize: '24px',
          lineHeight: '36px',
        },
        h5: {
          fontSize: '20px',
          lineHeight: '32px',
        },
        p: {
          fontSize: '16px',
          lineHeight: '28px',
        },
      },
    },
    colors: {
      pusher: {
        codeblockHeaderBg: '#231c38',
        codeblockBg: '#190c25',
        borderColor: '#eaecf5',
        darkPurple: '#300d4f',
        lightPurple: '#916aff',
        mediumPurple: '#6825b1',
        lightPink: '#ff8de3',
        darkPink: '#c223ce',
        lightBlueishGrey: '#e6e2fc',
        lighterBlueishGrey: '#f9f9fc',
        lightGreenishGrey: '#f0f1f6',
      },
      channels: {
        darkBlue: '#5d2be7',
        blueishPurple: '#c7b9ff',
        purplishBlack: '#221838',
      },
      beams: {
        green: '#40f2af',
        black: '#1a2420',
        offBlack: '#173138',
      },
    },
    breakpoints: {
      xs: '320px',
      sm: '464px',
      md: '744px',
      lg: '984px',
      xl: '1248px',
    },
  },

  breakpointMd: '700px',
  breakpointMdLg: '900px',
  breakpointLg: '1000px',
  breakpointXl: '1400px',
}

export default theme
