import React from 'react'
import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import get from 'lodash.get'

import useScrollInfo from '../hooks/useScrollInfo'

import Container from '../styles/Container'
import { Paragraph } from '../styles/Text'

import channelsLogo from '../images/channels-logo-dark.svg'
import beamsLogo from '../images/beams-logo.svg'
import arrow from '../images/navbar-arrow.svg'

const OuterContainer = styled.div`
  background-color: white;
  color: ${(p) => p.theme.textColor};
  ${(p) => p.productPage === 'channels' && `background-color: #F0F1F6;`}
  z-index: 2;
  ${(p) => p.pathname.endsWith('live-events') && 'background-color: #190C25; color: white'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    position: sticky;
    top: 0px;
  }
`

const CustomContainer = styled(Container)`
  position: relative;
  padding-right: 0px;
  padding-left: 0px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 100%;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 100%;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  overflow: auto;
`

const Logo = styled.img`
  display: none;
  height: 25px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: block;
  }
`

const Menu = styled.ul`
  padding-left: 16px;
  display: flex;
  white-space: nowrap;
  margin-right: auto;
  li:not(:last-child) {
    margin-right: 24px;
  }
  li:last-child {
    ${(p) => p.productPage === 'channels' && 'padding-right: 80px;'}
  }
  @media (min-width: 432px) {
    li:last-child {
      padding-right: 0px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    margin-right: 0px;
    li:not(:last-child) {
      margin-right: 40px;
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    li:not(:last-child) {
      margin-right: 48px;
    }
  }
`

const Gradient = styled.div`
  position: absolute;
  width: 35px;
  height: 100%;
  background: linear-gradient(to right, #1a1c2500, #f0f1f6);
  top: 0px;
  right: 0px;
  @media (min-width: 432px) {
    display: none;
  }
`

const pulse = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(2);
    opacity: 0;
  }
`

const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
  :before {
    content: '';
    width: 24px;
    height: 24px;
    background-color: ${(p) => p.theme.purpleDark};
    position: absolute;
    top: 1px;
    left: 0px;
    border-radius: 100%;
    animation: ${pulse} 2s ease-out forwards;
    animation-delay: 0.5s;
  }
  @media (min-width: 432px) {
    display: none;
  }
`

const Arrow = styled.img`
  position: relative;
  width: 24px;
  transition: 0.15s ease-in-out;
  ${(p) => p.scrolledToEnd && 'transform: rotate(180deg);'}
`

const SubNavbar = ({ productPage, pathname }) => {
  const [scrollInfo, setRef, ref] = useScrollInfo()
  const percentageScrolled = get(scrollInfo, 'x.percentage')
  const scrolledToEnd = percentageScrolled === 1
  const scrollToEnd = () => {
    ref.current.scrollTo({ left: ref.current.scrollWidth - ref.current.clientWidth, behavior: 'smooth' })
  }
  const scrollToStart = () => {
    ref.current.scrollTo({ left: 0, behavior: 'smooth' })
  }
  return (
    <OuterContainer productPage={productPage} pathname={pathname}>
      <CustomContainer>
        <NavbarContent ref={setRef}>
          <Link to={productPage === 'channels' ? '/channels' : '/beams'}>
            <Logo
              src={productPage === 'channels' ? channelsLogo : beamsLogo}
              alt={productPage === 'channels' ? 'Channels Logo' : 'Beams Logo'}
            />
          </Link>
          <Menu productPage={productPage}>
            {productPage === 'channels' && (
              <>
                <Paragraph as="li" semibold>
                  <Link to="/channels/pricing">Pricing</Link>
                </Paragraph>
                <Paragraph as="li" semibold>
                  <Link to="/channels/features">Features</Link>
                </Paragraph>
                <Paragraph as="li" semibold>
                  <Link to="/channels/use-cases">Use cases</Link>
                </Paragraph>
                <Paragraph as="li" semibold>
                  <Link to="/tutorials?product=Channels">Tutorials</Link>
                </Paragraph>
                <Paragraph as="li" semibold>
                  <a href="https://pusher.com/docs/channels">Docs</a>
                </Paragraph>
              </>
            )}
            {productPage === 'beams' && (
              <>
                <Paragraph as="li" semibold>
                  <Link to="/beams/pricing">Pricing</Link>
                </Paragraph>
                <Paragraph as="li" semibold>
                  <Link to="/tutorials?product=Beams">Tutorials</Link>
                </Paragraph>
                <Paragraph as="li" semibold>
                  <a href="https://pusher.com/docs/beams">Docs</a>
                </Paragraph>
              </>
            )}
          </Menu>
        </NavbarContent>
        {productPage === 'channels' && <Gradient />}
        {productPage === 'channels' && (
          <ArrowContainer>
            <Arrow
              src={arrow}
              alt=""
              scrolledToEnd={scrolledToEnd}
              onClick={scrolledToEnd ? scrollToStart : scrollToEnd}
            />
          </ArrowContainer>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default SubNavbar
