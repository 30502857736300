import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import Container from '../styles/Container'
import { Paragraph, Copyright } from '../styles/Text'

import logo from '../images/pusher-logo.svg'
import twitterLogo from '../images/twitter-logo.svg'
import youtubeLogo from '../images/youtube-logo.svg'
import linkedinLogo from '../images/linkedin-logo.svg'
import githubLogo from '../images/github-logo.svg'
import twitchLogo from '../images/twitch-logo.svg'
import discordLogo from '../images/discord-logo.svg'

const OuterContainer = styled.div`
  padding-top: 64px;
  padding-bottom: 56px;
  background-color: #f9f8ff;
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-top: ${(p) => (p.$hasTopMargin ? '60px' : '')};
  }
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    flex-direction: row;
    position: relative;
  }
`

const Bottom = styled.div`
  ${(p) => p.hideNavigation && 'margin-top: 24px;'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    ${(p) =>
      p.hideNavigation &&
      css`
        display: flex;
        flex-direction: column;
        margin-top: 0px;
      `}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    position: relative;
    margin-top: 0px;
    ${(p) =>
      p.hideNavigation &&
      css`
        margin-top: 0px;
      `}
  }
`

const LogoContainer = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    flex-grow: 1;
    ${(p) =>
      p.hideNavigation &&
      css`
        position: absolute;
        top: 0px;
        left: 0px;
      `}
  }
`

const Logo = styled.img`
  width: 144px;
  height: 62px;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 64px;
  column-gap: 30px;
  li {
    display: flex;
    margin-top: 16px;
  }
  a {
    display: block;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 64px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    flex-grow: 1;
    min-width: 690px;
    max-width: 690px;
    margin-top: 0px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    min-width: 720px;
    max-width: 720px;
    margin-bottom: 0px;
  }
`

const SubMenu = styled.div`
  margin-top: 40px;
  ul {
    font-weight: 500;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: calc(100% / 3);
    margin-top: 0px;
  }
`

const Heading = styled.h2`
  font-size: 16px;
  line-height: 172%;
  letter-spacing: 0.25px;
  font-weight: 700;
`

const SocialMediaContainer = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 0;
  }

  li {
    margin-right: 18px;
  }
  li:last-of-type {
    margin-right: 0;
  }

  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    ${(p) => p.hideNavigation && 'align-self: flex-end; margin-bottom: 86px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-bottom: 34px;
    ${(p) => p.hideNavigation && 'margin-bottom: 86px;'}
  }
`

const HiringLabel = styled.span`
  margin-left: 4px;
  padding: 3px 8px 3px;
  background-color: ${(p) => p.theme.purpleDark};
  border-radius: 4px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: white;
`

const Footer = ({ hideNavigation = false, showHiringLabel = false, hasTopMargin = true }) => {
  return (
    <div className="footer">
      <OuterContainer $hasTopMargin={hasTopMargin}>
        <Container>
          <Top>
            <LogoContainer hideNavigation={hideNavigation}>
              <Link to="/">
                <Logo src={logo} alt="Logo" />
              </Link>
            </LogoContainer>
            {!hideNavigation && (
              <Menu>
                <SubMenu>
                  <Heading bold>Products</Heading>
                  <ul>
                    <Paragraph as="li">
                      <Link to="/channels">Channels</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/beams">Beams</Link>
                    </Paragraph>
                  </ul>
                </SubMenu>
                <SubMenu>
                  <Heading as="h2">Developers</Heading>
                  <ul>
                    <Paragraph as="li">
                      <a href="https://pusher.com/docs">Docs</a>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/tutorials">Tutorials</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <a href="https://status.pusher.com/">Status</a>
                    </Paragraph>
                    <Paragraph as="li">
                      <a href="https://docs.bird.com/pusher">Support</a>
                    </Paragraph>
                    <Paragraph as="li">
                      <a
                        href="https://www.youtube.com/channel/UCoyqucxoFXDFnh3khD0rjUg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Sessions
                      </a>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/glossary">Glossary</Link>
                    </Paragraph>
                  </ul>
                </SubMenu>
                <SubMenu>
                  <Heading as="h2">Company</Heading>
                  <ul>
                    <Paragraph as="li">
                      <Link to="/contact?origin=footer">Contact Sales</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/user-stories">User stories</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/legal/support">Support</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/blog">Blog</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/careers">Careers {showHiringLabel && <HiringLabel>Hiring</HiringLabel>}</Link>
                    </Paragraph>
                  </ul>
                </SubMenu>
                <SubMenu>
                  <Heading as="h2">Legal</Heading>
                  <ul>
                    <Paragraph as="li">
                      <a href="http://www.messagebird.com/legal/terms-and-conditions">Terms & Conditions</a>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/legal/cookie-policy">Cookie Policy</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <a href="https://www.messagebird.com/en/legal/privacy">Privacy Policy</a>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/legal/contests">Contests</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/legal/quotas">Quotas</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/security">Security</Link>
                    </Paragraph>
                    <Paragraph as="li">
                      <Link to="/code-of-conduct">Code of Conduct</Link>
                    </Paragraph>
                  </ul>
                </SubMenu>
              </Menu>
            )}
          </Top>
          <Bottom hideNavigation={hideNavigation}>
            <SocialMediaContainer hideNavigation={hideNavigation}>
              <ul>
                <li>
                  <a href="https://twitter.com/pusher" target="_blank" rel="noopener noreferrer">
                    <img src={twitterLogo} alt="Twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCoyqucxoFXDFnh3khD0rjUg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={youtubeLogo} alt="Youtube" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/pusher-app/" target="_blank" rel="noopener noreferrer">
                    <img src={linkedinLogo} alt="LinkedIn" />
                  </a>
                </li>
                <li>
                  <a href="https://github.com/pusher" target="_blank" rel="noopener noreferrer">
                    <img src={githubLogo} alt="Github" />
                  </a>
                </li>
                <li>
                  <a href="https://www.twitch.tv/pusherdev" target="_blank" rel="noopener noreferrer">
                    <img src={twitchLogo} alt="Twitch" />
                  </a>
                </li>
                <li>
                  <a href="https://discord.gg/pEWjmP9uA7" target="_blank" rel="noopener noreferrer">
                    <img src={discordLogo} alt="Discord" />
                  </a>
                </li>
              </ul>
            </SocialMediaContainer>
            <Copyright>
              © {new Date().getFullYear()} Pusher Ltd. All rights reserved.
              <br />
              Pusher Limited is a company registered in England and Wales (No. 07489873) whose registered office is at
              MessageBird UK Limited, 3 More London Riverside, 4th Floor, London, United Kingdom, SE1 2AQ.
            </Copyright>
          </Bottom>
        </Container>
      </OuterContainer>
    </div>
  )
}

export default Footer
