import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import { CustomLink } from '../utils/linkResolver'
import { useInView } from 'react-intersection-observer'
import widont from '../utils/widont'

import Container from '../styles/Container'
import { Heading2Styles, Heading3, Paragraph } from '../styles/Text'
import { Button } from '../styles/Buttons'

const OuterOuterContainer = styled.div`
  ${(p) =>
    p.backgroundColor === 'black' &&
    css`
      background-color: #190c25;
      color: white;
    `}
  ${(p) =>
    p.backgroundColor === 'purple' &&
    css`
      background-color: ${p.theme.purpleGreyDark};
    `}
  ${(p) =>
    p.backgroundColor === 'grey' &&
    css`
      background-color: #f0f1f6;
    `}
  ${(p) =>
    p.backgroundColor === 'blue' &&
    css`
      background-color: ${p.theme.blue};
      color: white;
    `}
  ${(p) =>
    p.backgroundColor === 'dark purple' &&
    css`
      background-color: #190c25;
      color: white;
    `}
`

const OuterContainer = styled.div`
  position: relative;
  z-index: 1;
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  ${(p) => p.paragraphSize == 'large' && 'padding-top: 32px;'}
  padding-bottom: 48px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    ${(p) => p.paragraphSize == 'large' && 'padding-top: 48px;'}
    padding-bottom: 24px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-top: 72px;
    padding-bottom: 48px;
    ${(p) => p.paragraphSize == 'large' && 'padding-top: 64px; padding-bottom: 40px;'}
    ${(p) => p.backgroundColor === 'blue' && 'padding-top: 64px;'}
  }
`

const CustomHeading3 = styled(Heading3)`
  margin-bottom: 8px;
  ${(p) =>
    p.hasPinkText &&
    css`
      color: #ff8de2;
      ${Heading2Styles}
    `}
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    margin-bottom: 30px;
    text-align: center;
    ${(p) => p.backgroundColor === 'purple' && `text-align: left;`}
  }
`

const CustomParagraph = styled(Paragraph)`
  margin-bottom: 32px;
  max-width: 440px;
  ${(p) => p.paragraphSize == 'large' && 'max-width: 540px;'}
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 530px;
    margin-bottom: 48px;
    ${(p) =>
      p.paragraphSize == 'large' &&
      css`
        max-width: 670px;
        font-size: 22px;
        line-height: 36px;
        font-weight: 500;
        letter-spacing: 0.5px;
      `}
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    max-width: 540px;
    margin-bottom: 40px;
    ${(p) =>
      p.paragraphSize !== 'large' &&
      css`
        text-align: center;
        margin-right: auto;
        margin-left: auto;
      `}
    ${(p) => p.paragraphSize == 'large' && 'margin-bottom: 54px;'}
  }
`

const LogoContainer = styled.div`
  transition: opacity 500ms ease;
  opacity: 0;
  ${(p) => p.inView && 'opacity: 1;'}
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    width: calc(50% - 15px);
    align-items: center;
    :not(:last-child) {
      margin-bottom: 24px;
    }
    :nth-child(2n + 1) {
      margin-right: 15px;
    }
    :nth-child(2n) {
      margin-left: 15px;
    }
    ${(p) => p.version === 'v2' && 'justify-content: center;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    ${(p) =>
      p.version === 'v2'
        ? css`
            justify-content: center;
            > div {
              margin-right: 30px;
              width: calc((100% - 150px) / 6);
              :nth-child(2n + 1) {
                margin-right: 0px;
              }
              :nth-child(2n) {
                margin-left: 0px;
              }
              :nth-child(n) {
                margin-right: 30px;
              }
              :nth-child(6n) {
                margin-right: 0px;
              }
            }
          `
        : css`
            > div {
              width: calc((100% - 60px) / 3);
              :nth-child(3n + 1),
              :nth-child(3n + 2) {
                margin-right: 30px;
                margin-left: 0px;
              }
              :nth-child(3n + 3) {
                margin: 0px;
              }
              ${(p) =>
                p.logosCount > 6 &&
                css`
                  width: calc((100% - 90px) / 4);
                  :nth-child(3n + 1),
                  :nth-child(3n + 2) {
                    margin-right: 0px;
                  }
                  :nth-child(4n + 1),
                  :nth-child(4n + 2),
                  :nth-child(4n + 3) {
                    margin-right: 22.5px;
                  }
                `}
            }
          `}
  }
`

const Logo = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  max-width: 288px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    width: 89%;
    max-width: 325px;
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    width: 100%;
    max-width: 350px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
`

const Customers = ({
  version,
  heading,
  paragraph,
  paragraphSize,
  backgroundColor,
  logos,
  buttonText,
  buttonLink,
  pathname = '',
}) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  const logosCount = get(logos, 'length')

  const hasPinkText = pathname === '/game-development/'

  return (
    <OuterOuterContainer backgroundColor={backgroundColor}>
      <OuterContainer>
        <CustomContainer paragraphSize={paragraphSize} backgroundColor={backgroundColor}>
          {heading && (
            <CustomHeading3 backgroundColor={backgroundColor} hasPinkText={hasPinkText}>
              {heading}
            </CustomHeading3>
          )}
          {paragraph && <CustomParagraph paragraphSize={paragraphSize}>{widont(paragraph)}</CustomParagraph>}{' '}
          {logos && (
            <LogoContainer version={version} ref={inViewRef} inView={inView} logosCount={logosCount}>
              {logos.map((item, index) => {
                const logo = item?.logo?.gatsbyImageData
                return (
                  logo && (
                    <div key={index}>
                      <Logo image={logo} alt="" />
                    </div>
                  )
                )
              })}
            </LogoContainer>
          )}
          {buttonText && (
            <ButtonWrapper>
              <CustomLink link={buttonLink}>
                <Button>{buttonText}</Button>
              </CustomLink>
            </ButtonWrapper>
          )}
        </CustomContainer>
      </OuterContainer>
    </OuterOuterContainer>
  )
}

export default Customers
