import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import styled, { css, keyframes } from 'styled-components'
import useScrollLock from 'use-scroll-lock'
import { useTransition, animated, config } from 'react-spring'
import AnimateHeight from 'react-animate-height'

import PrismicRichText from './PrismicRichText'

import Container from '../styles/Container'
import { Paragraph, Heading4, ParagraphStyles } from '../styles/Text'
import { Button } from '../styles/Buttons'

import logo from '../images/pusher-logo.svg'
import logoWhite from '../images/pusher-logo-white.svg'
import logoSmall from '../images/pusher-logo-small.svg'
import channelsLogo from '../images/channels-logo.svg'
import beamsLogo from '../images/beams-logo.svg'
import caretDown from '../images/caret-down.svg'
import caretDownWhite from '../images/caret-down-white-thin.svg'
import caretDownThin from '../images/caret-down-thin.svg'
import triangleTop from '../images/triangle-top.svg'
import channelsCircleLogo from '../images/channels-circle-logo.svg'
import beamsCircleLogo from '../images/beams-circle-logo.svg'

const OuterOuterContainer = styled.div`
  ${(p) =>
    p.$isSticky
      ? css`
          position: sticky;
          top: 0;
        `
      : ''}
  z-index: 4;
`

const OuterContainer = styled.div`
  width: 100%;
  background-color: white;
  ${(p) => p.productPage === 'beams' && 'background-color: #f8f8fc;'}
  ${(p) => p.hideNavigation && 'background-color: white;'}
  ${(p) => p.showGradient && 'background: linear-gradient(90deg, #FF67FA 0%, #F86785 100%);'}
  ${(p) =>
    p.isDarkPurple &&
    css`
      background-color: #190c25;
      span {
        color: white;
      }
    `}
  z-index: 3;
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    ${(p) => p.productPage && 'position: static;'}
  }
`

const TopBanner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(p) => p.theme.purpleGreyDarker};
  padding: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 172%;
  letter-spacing: 0.25px;
  a {
    font-weight: 600;
    text-decoration: underline;
  }
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    flex-direction: row;
    justify-content: center;
  }
`

const BannerLabel = styled.div`
  flex-shrink: 0;
  padding: 0px 12px;
  margin-bottom: 8px;
  background-color: ${(p) => p.theme.textColor};
  border-radius: 100px;
  line-height: 28px;
  font-weight: 600;
  color: white;
  text-align: center;
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    margin-bottom: 0px;
    margin-right: 16px;
  }
`

const LegalBanner = styled.div`
  background-color: #ff8de3;
  padding: 16px 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 172%;
  letter-spacing: 0.25px;
  a {
    font-weight: 600;
    text-decoration: underline;
  }
`

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  height: 88px;
  align-items: center;
`

const CustomContainer = styled(Container)`
  max-width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  position: relative;
  overflow: auto;
  ${(p) => p.productPage === 'beams' && 'box-shadow: none;'}
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    max-width: 100%;
    overflow: initial;
  }
`

const Logo = styled.img`
  width: 120px;
  height: 52px;
  ${(p) =>
    p.hideOnMobile &&
    css`
      display: none;
      @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
        display: block;
      }
    `}
`

const ProductPageLogoContainer = styled.div`
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    display: none;
  }
`

const LogoSmall = styled.img`
  height: 38px;
  padding-right: 12px;
  border-right: 1px solid #e5e5e5;
`

const ProductPageLogo = styled.img`
  height: 26px;
  margin-left: 12px;
`

const CenterMenu = styled.ul`
  display: none;
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    display: flex;
    margin-left: 30px;
    > li {
      position: relative;
      cursor: pointer;
      :hover {
        > div {
          display: block;
        }
        > img {
          transform: translateY(0px) rotate(180deg);
        }
      }
    }
    > li:not(:last-child) {
      margin-right: 28px;
    }
    > li > img {
      margin-left: 10px;
      transform: translateY(-2px);
      transition: all 0.15s ease-in-out;
    }
  }
  @media (min-width: 1100px) {
    > li:not(:last-child) {
      margin-right: 40px;
    }
  }
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.xl}) {
    > li:not(:last-child) {
      margin-right: 48px;
    }
  }
`

const RightMenu = styled.ul`
  margin-left: auto;
  ${(p) => p.hideNavigation && 'display: flex;'}
  @media (max-width: calc(${(p) => p.theme.breakpointLg} - 1px)) {
    a,
    button {
      padding: 6px;
      font-weight: 600;
      line-height: 24px;
    }
  }
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    display: flex;
    align-items: center;
    margin-left: 0px;
    li:not(:last-child) {
      margin-right: 24px;
    }
  }
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.xl}) {
    li:not(:last-child) {
      margin-right: 32px;
    }
  }
`

const SignIn = styled(Paragraph)`
  display: none;
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    display: block;
  }
`

const BurgerMenuContainer = styled.div`
  width: 40px;
  height: 40px;
  margin-left: 16px;
  cursor: pointer;
  background-color: #bfb1ff;
  border-radius: 4px;
  ${(p) => p.hideNavigation && 'margin-left: auto;'}
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    display: none;
  }
`

const rotateTop = keyframes`
  0% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0px) rotate(45deg);
  }
`

const rotateBottom = keyframes`
  0% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(-2px) rotate(-45deg);
  }
`

const disappear = keyframes`
  0% {
  background-color: ${(p) => p.theme.textColor};
  }
  100% {
  background-color: transparent;
  }

`

const BurgerMenu = styled.span`
  display: block;
  width: 20px;
  height: 2px;
  background-color: ${(p) => p.theme.textColor};
  border-radius: 10px;
  transform: translate(10px, 19px);
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background-color: ${({ mobileMenuIsHidden, close }) => !mobileMenuIsHidden && !close && 'transparent'};
  ${(p) =>
    p.close &&
    css`
      animation: ${disappear} 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
    `}
  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    background-color: ${(p) => p.theme.textColor};
    transform: translateY(-7px);
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: ${({ mobileMenuIsHidden }) => !mobileMenuIsHidden && 'translateY(0px) rotate(45deg)'};
    ${(p) =>
      p.close &&
      css`
        animation: ${rotateTop} 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
      `}
  }
  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 2px;
    background-color: ${(p) => p.theme.textColor};
    transform: translateY(5px);
    border-radius: 10px;
    transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    transform: ${({ mobileMenuIsHidden }) => !mobileMenuIsHidden && 'translateY(-4px) rotate(-45deg)'};
    ${(p) =>
      p.close &&
      css`
        animation: ${rotateBottom} 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
      `}
  }
`

const HoverMenu = styled.div`
  display: none;
  width: 336px;
  position: absolute;
  padding-top: 8px;
  left: ${(p) => p.left};
`

const HoverMenuInner = styled.ul`
  position: relative;
  padding: 8px;
  background-color: white;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.24);
  border-radius: 6px;
  z-index: 3;
  a {
    display: block;
  }
  a:not(:last-child) {
    margin-bottom: 8px;
  }
  li {
    padding: 16px;
    border-radius: 3px;
    transition: all 0.15s ease-in-out;
    path {
      transition: all 0.15s ease-in-out;
    }
    :hover {
      background-color: ${(p) => p.theme.purple};
      color: white;
      path {
        fill: white;
      }
    }
  }
  img {
    padding-bottom: 9px;
  }
`

const TriangleTop = styled.img`
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
`

const MobileMenu = styled(animated.div)`
  height: 100vh;
  width: 100%;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: white;
  z-index: 3;
  overflow: auto;
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    left: initial;
    right: 0px;
  }

  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.lg}) {
    display: none;
  }
`

const MobileMenuInner = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 25px;
  background-color: white;
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
  }
`

const MobileMenuInnerTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  margin-top: -25px;
`

const MobileMenuInnerBottom = styled.div`
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    width: 321px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 16px;
    padding-right: 16px;
  }
`

const MobileLogo = styled.img`
  height: 52px;
`

const MobileList = styled.ul`
  margin-top: 24px;
`

const MobileListItem = styled.li`
  a {
    display: block;
    ${ParagraphStyles}
    padding: 16px 0px;
    border-bottom: 1px solid #ebeefc;
    font-weight: 600;
  }
  button {
    width: 100%;
    text-align: left;
    ${ParagraphStyles}
    padding: 16px 0px;
    border-bottom: 1px solid #ebeefc;
    font-weight: 600;
  }
`

const MobileSubList = styled.ul``

const MobileSubListItem = styled.li`
  img {
    margin-right: 8px;
  }
  a {
    padding-left: 32px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding-bottom: 16px;
  width: 100%;
  a {
    :first-child {
      margin-bottom: 16px;
    }
    :last-child {
      button:hover {
        color: white;
      }
    }
  }
  @media (min-width: ${(p) => p.theme.chameleon.breakpoints.md}) {
    flex-direction: column;
    margin-top: 80px;
    padding-bottom: 82px;
    a {
      width: 100%;
      :first-child {
        margin-right: 0px;
        margin-bottom: 14px;
      }
    }
  }
`

const SignInButton = styled(Button)`
  width: 100%;
  font-weight: 600;
`

const SignUpButton = styled(Button)`
  width: 100%;
  font-weight: 600;
`

const Navbar = ({ pathname = '', isSticky = true, productPage, hideNavigation = false, global, legalBannerText }) => {
  const [campaignMeta, setCampaignMeta] = useState(null)

  useEffect(() => {
    setCampaignMeta(JSON.parse(localStorage.getItem('campaign_meta')))
  }, [])

  const passQueryParamsToDash = (baseURL, productPage) => {
    const url = new URL(baseURL)
    if (campaignMeta) {
      for (const [paramName, paramValue] of Object.entries(campaignMeta)) {
        url.searchParams.set(paramName, paramValue)
      }
    }

    if (productPage) url.searchParams.set('product', productPage)

    return url.toString()
  }

  const [mobileMenuIsHidden, setMobileMenuIsHidden] = useState(true)
  const [scrollLockEnabled, setScrollLockEnabled] = useState(false)
  const ref = React.useRef(null)

  const toggleMobileMenu = () => {
    setMobileMenuIsHidden((s) => !s)
    setScrollLockEnabled((e) => !e)
  }

  useScrollLock(scrollLockEnabled, ref)

  const fadeInTransitions = useTransition(!mobileMenuIsHidden, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.stiff,
  })

  const signInLink = `https://dashboard.pusher.com/${productPage ? productPage : ''}`

  const isDarkPurple =
    pathname.endsWith('user-stories') || pathname.endsWith('game-development/') || pathname.endsWith('community/')

  const showBanner = global?.show_banner
  const bannerText = global?.banner_text
  const bannerLabel = global?.banner_label

  const hasLegalBannerText = legalBannerText?.richText?.[0]?.text

  return (
    <>
      <OuterOuterContainer
        $isSticky={isSticky}
        productPage={productPage}
        legalBannerText={hasLegalBannerText}
        showBanner={showBanner}
      >
        <OuterContainer
          productPage={productPage}
          hideNavigation={hideNavigation}
          showGradient={pathname.endsWith('developer-spotlight')}
          isDarkPurple={isDarkPurple}
        >
          {hasLegalBannerText ? (
            <LegalBanner>
              <PrismicRichText text={legalBannerText} />
            </LegalBanner>
          ) : (
            showBanner && (
              <TopBanner>
                {bannerLabel && <BannerLabel>{bannerLabel}</BannerLabel>}
                <PrismicRichText text={bannerText} />
              </TopBanner>
            )
          )}
          <CustomContainer productPage={productPage}>
            <NavbarContent>
              <Link to="/">
                <Logo
                  src={isDarkPurple ? logoWhite : logo}
                  alt="Pusher"
                  hideOnMobile={productPage && !hideNavigation}
                />
              </Link>
              {productPage && !hideNavigation && (
                <ProductPageLogoContainer>
                  <Link to="/">
                    <LogoSmall src={logoSmall} alt="Pusher" />
                  </Link>
                  <Link to={productPage === 'channels' ? '/channels' : '/beams'}>
                    <ProductPageLogo
                      src={productPage === 'channels' ? channelsLogo : beamsLogo}
                      alt={productPage === 'channels' ? 'Channels' : 'Beams'}
                    />
                  </Link>
                </ProductPageLogoContainer>
              )}
              {!hideNavigation && (
                <CenterMenu>
                  <Paragraph as="li" semibold>
                    <span>Products</span>
                    {isDarkPurple ? <img src={caretDownWhite} alt="" /> : <img src={caretDown} alt="" />}
                    <HoverMenu left="-122px">
                      <HoverMenuInner>
                        <TriangleTop src={triangleTop} alt="" />
                        <Link to="/channels">
                          <li>
                            <ChannelsLogo />
                            <Paragraph>Build scalable realtime features</Paragraph>
                          </li>
                        </Link>
                        <Link to="/beams">
                          <li>
                            <BeamsLogo />
                            <Paragraph>Programmatic push notifications</Paragraph>
                          </li>
                        </Link>
                      </HoverMenuInner>
                    </HoverMenu>
                  </Paragraph>
                  <Paragraph as="li" semibold>
                    <span>Developers</span>
                    {isDarkPurple ? <img src={caretDownWhite} alt="" /> : <img src={caretDown} alt="" />}
                    <HoverMenu left="-112px">
                      <HoverMenuInner>
                        <TriangleTop src={triangleTop} alt="" />
                        <a href="https://pusher.com/docs">
                          <li>
                            <Heading4>Docs</Heading4>
                            <Paragraph>Read the docs to learn how to use our products</Paragraph>
                          </li>
                        </a>
                        <Link to="/tutorials">
                          <li>
                            <Heading4>Tutorials</Heading4>
                            <Paragraph>Explore our tutorials to build apps with Pusher products</Paragraph>
                          </li>
                        </Link>
                        <a href="https://docs.bird.com/pusher">
                          <li>
                            <Heading4>Support</Heading4>
                            <Paragraph>Reach out to our support team for help and advice</Paragraph>
                          </li>
                        </a>
                        <Link to="/glossary">
                          <li>
                            <Heading4>Glossary</Heading4>
                            <Paragraph>Get familiar with Pusher-specific terminology</Paragraph>
                          </li>
                        </Link>
                      </HoverMenuInner>
                    </HoverMenu>
                  </Paragraph>
                  <Paragraph as="li" semibold bold={pathname.endsWith('user-stories')}>
                    <Link to="/user-stories">
                      <span>User stories</span>
                    </Link>
                  </Paragraph>
                  <Paragraph as="li" semibold>
                    <Link to="/blog">
                      <span>Blog</span>
                    </Link>
                  </Paragraph>
                  <Paragraph as="li" semibold>
                    <span>Pricing</span>
                    {isDarkPurple ? <img src={caretDownWhite} alt="" /> : <img src={caretDown} alt="" />}
                    <HoverMenu left="-133px">
                      <HoverMenuInner>
                        <TriangleTop src={triangleTop} alt="" />
                        <Link to="/channels/pricing">
                          <li>
                            <ChannelsLogo />
                            <Paragraph>Build scalable realtime features</Paragraph>
                          </li>
                        </Link>
                        <Link to="/beams/pricing">
                          <li>
                            <BeamsLogo />
                            <Paragraph>Programmatic push notifications</Paragraph>
                          </li>
                        </Link>
                      </HoverMenuInner>
                    </HoverMenu>
                  </Paragraph>
                </CenterMenu>
              )}
              <RightMenu hideNavigation={hideNavigation}>
                <SignIn as="li" semibold>
                  <a href={signInLink}>
                    <span>Sign in</span>
                  </a>
                </SignIn>
                <li>
                  <a
                    href={passQueryParamsToDash(
                      'https://dashboard.pusher.com/accounts/sign_up',
                      productPage
                    )}
                  >
                    <Button
                      green={productPage === 'beams' ? true : false}
                      dark={productPage !== 'beams' ? true : false}
                      reversedWhite={isDarkPurple ? true : false}
                      textColor={isDarkPurple ? '#160228' : undefined}
                    >
                      Sign up
                    </Button>
                  </a>
                </li>
              </RightMenu>
              {!hideNavigation && (
                <BurgerMenuContainer
                  role="button"
                  tabIndex="0"
                  aria-label="Burger Menu"
                  onClick={toggleMobileMenu}
                  onKeyPress={toggleMobileMenu}
                >
                  <BurgerMenu mobileMenuIsHidden={mobileMenuIsHidden} />
                </BurgerMenuContainer>
              )}
            </NavbarContent>
          </CustomContainer>
        </OuterContainer>
      </OuterOuterContainer>
      {fadeInTransitions.map(
        ({ item, props, key }) =>
          item && (
            <MobileMenu mobileMenuIsHidden={mobileMenuIsHidden} ref={ref} key={key} style={props}>
              <MobileMenuInner>
                <MobileMenuInnerTop>
                  <Link to="/">
                    <MobileLogo src={logo} alt="Pusher" />
                  </Link>
                  <RightMenu>
                    <a href={passQueryParamsToDash('https://dashboard.pusher.com/accounts/sign_up', productPage)}>
                      <Button
                        green={productPage === 'beams' ? true : false}
                        dark={productPage !== 'beams' ? true : false}
                      >
                        Sign up
                      </Button>
                    </a>
                  </RightMenu>
                  <BurgerMenuContainer
                    role="button"
                    tabIndex="0"
                    aria-label="Burger Menu"
                    onClick={toggleMobileMenu}
                    onKeyPress={toggleMobileMenu}
                  >
                    <BurgerMenu close mobileMenuIsHidden={mobileMenuIsHidden} />
                  </BurgerMenuContainer>
                </MobileMenuInnerTop>
                <MobileMenuInnerBottom>
                  <MobileList>
                    <MobileListItem>
                      <MobileListItemMenu title="Products">
                        <MobileSubList>
                          <MobileSubListItem>
                            <Link to="/channels">
                              <img src={channelsCircleLogo} alt="" />
                              Channels
                            </Link>
                          </MobileSubListItem>
                          <MobileSubListItem>
                            <Link to="/beams">
                              <img src={beamsCircleLogo} alt="" />
                              Beams
                            </Link>
                          </MobileSubListItem>
                        </MobileSubList>
                      </MobileListItemMenu>
                    </MobileListItem>
                    <MobileListItem>
                      <MobileListItemMenu title="Developers">
                        <MobileSubList>
                          <MobileSubListItem>
                            <a href="https://pusher.com/docs">Docs</a>
                          </MobileSubListItem>
                          <MobileSubListItem>
                            <Link to="/tutorials">Tutorials</Link>
                          </MobileSubListItem>
                          <MobileSubListItem>
                            <a href="https://docs.bird.com/pusher">Support</a>
                          </MobileSubListItem>
                          <MobileSubListItem>
                            <Link to="/glossary">Glossary</Link>
                          </MobileSubListItem>
                        </MobileSubList>
                      </MobileListItemMenu>
                    </MobileListItem>
                    <MobileListItem>
                      <MobileListItemMenu title="Pricing">
                        <MobileSubList>
                          <MobileSubListItem>
                            <Link to="/channels/pricing">
                              <img src={channelsCircleLogo} alt="" />
                              Channels
                            </Link>
                          </MobileSubListItem>
                          <MobileSubListItem>
                            <Link to="/beams/pricing">
                              <img src={beamsCircleLogo} alt="" />
                              Beams
                            </Link>
                          </MobileSubListItem>
                        </MobileSubList>
                      </MobileListItemMenu>
                    </MobileListItem>
                    <MobileListItem>
                      <Link to="/blog">Blog</Link>
                    </MobileListItem>
                    <MobileListItem>
                      <Link to="/user-stories">User Stories</Link>
                    </MobileListItem>
                  </MobileList>
                  <ButtonContainer>
                    <a href={passQueryParamsToDash('https://dashboard.pusher.com/accounts/sign_up', productPage)}>
                      <SignUpButton
                        green={productPage === 'beams' ? true : false}
                        dark={productPage !== 'beams' ? true : false}
                      >
                        Sign up
                      </SignUpButton>
                    </a>
                    <a href={signInLink}>
                      <SignInButton reversedDark>Sign in</SignInButton>
                    </a>
                  </ButtonContainer>
                </MobileMenuInnerBottom>
              </MobileMenuInner>
            </MobileMenu>
          )
      )}
    </>
  )
}

export default Navbar

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Caret = styled.div`
  flex-shrink: 0;
  img {
    width: 24px;
    height: 24px;
    transition: ease-in-out 0.2s;
  }
  ${(p) =>
    p.isSelected &&
    css`
      img {
        transform: rotate(180deg);
      }
    `}
`

const MobileListItemMenu = ({ title, children }) => {
  const [height, setHeight] = useState(0)

  const toggle = () => {
    setHeight((s) => (s === 0 ? 'auto' : 0))
  }

  return (
    <>
      <MenuButton onClick={toggle}>
        {title}{' '}
        <Caret isSelected={height === 'auto'}>
          <img src={caretDownThin} alt="" />
        </Caret>
      </MenuButton>
      <AnimateHeight duration={500} height={height}>
        {children}
      </AnimateHeight>
    </>
  )
}

const ChannelsLogo = () => {
  return (
    <svg width="152" height="26" viewBox="0 0 152 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M42.3518 4.86432C45.6438 4.86432 48.2014 7.12315 48.5569 10.4016L45.3324 10.9327C44.9976 8.96201 43.931 7.85456 42.3479 7.85456C40.1005 7.85456 38.7666 9.75933 38.7666 12.9486C38.7666 16.1378 40.1005 18.0426 42.3479 18.0426C44.0153 18.0426 45.0833 16.8692 45.3726 14.7202L48.5971 15.2746C48.1975 18.752 45.7502 21.0328 42.3479 21.0328C38.1879 21.0328 35.4707 17.8436 35.4707 12.9486C35.4707 8.05357 38.1918 4.86432 42.3518 4.86432Z"
        fill="#300D4F"
      />
      <path
        d="M51.5361 5.19647H54.8281V11.27H59.8771V5.19647H63.1691V20.7034H59.8771V14.2551H54.8281V20.7008H51.5361V5.19647Z"
        fill="#300D4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.7069 18.2867H75.668L76.4907 20.7006H79.7606L74.1991 5.19373H71.1744L65.6143 20.7006H68.8842L69.7069 18.2867ZM72.7085 9.69221L74.6653 15.4078H70.7076L72.6644 9.69221H72.7085Z"
        fill="#300D4F"
      />
      <path
        d="M82.1826 5.19647H85.5564L90.8311 15.0524H90.8752V5.19647H94.1672V20.7034H90.7895L85.5187 10.8436H85.4746V20.7008H82.1826V5.19647Z"
        fill="#300D4F"
      />
      <path
        d="M98.2393 5.19647H101.621L106.892 15.0524H106.936V5.19647H110.228V20.7034H106.847L101.577 10.8436H101.531V20.7008H98.2393V5.19647Z"
        fill="#300D4F"
      />
      <path
        d="M114.298 5.19647H124.328V8.20869H117.59V11.354H123.727V14.2331H117.59V17.6886H124.35V20.7008H114.298V5.19647Z"
        fill="#300D4F"
      />
      <path d="M127.774 5.19647H131.066V17.6886H137.65V20.7008H127.774V5.19647Z" fill="#300D4F" />
      <path
        d="M142.831 14.8974C143.254 17.2015 144.162 18.0918 146.238 18.0918C147.774 18.0918 148.64 17.5375 148.64 16.5631C148.64 15.4776 147.907 14.9465 145.304 14.06C141.679 12.8415 140.323 11.535 140.323 9.25292C140.323 6.66844 142.503 4.86835 145.594 4.86835C148.64 4.86835 151.02 6.70591 151.511 9.43125L148.307 10.1174C147.885 8.54477 147.017 7.81336 145.594 7.81336C144.37 7.81336 143.569 8.36773 143.569 9.23483C143.569 10.0321 144.236 10.4754 146.554 11.2507C148.488 11.9149 149.89 12.6463 150.714 13.4656C151.538 14.2849 151.936 15.3045 151.936 16.567C151.936 19.2471 149.601 21.0407 146.198 21.0407C142.483 21.0407 140.171 19.136 139.614 15.5926L142.831 14.8974Z"
        fill="#300D4F"
      />
      <path
        d="M16.4947 1.29257L14.2628 0.0145427C14.2291 -0.00484757 14.1875 -0.00484757 14.1538 0.0145427L11.8947 1.31324C11.861 1.33263 11.8194 1.33263 11.7857 1.31324L9.5266 0.0145427C9.49287 -0.00484757 9.45132 -0.00484757 9.4176 0.0145427L7.18574 1.29257C7.16872 1.30222 7.1582 1.32023 7.1582 1.33974C7.1582 1.35924 7.16872 1.37725 7.18574 1.3869L11.8078 4.05279C11.8248 4.06175 11.8452 4.06175 11.8623 4.05279L16.4895 1.39207C16.5099 1.38452 16.5238 1.36564 16.5249 1.34402C16.526 1.3224 16.5142 1.30218 16.4947 1.29257Z"
        fill="#6A52FF"
      />
      <path
        d="M21.2308 4.02081L18.9989 2.73891C18.9652 2.71952 18.9236 2.71952 18.8899 2.73891L11.8946 6.76036C11.8609 6.77975 11.8193 6.77975 11.7856 6.76036L4.79027 2.73891C4.75654 2.71952 4.71499 2.71952 4.68127 2.73891L2.44941 4.02081C2.43239 4.03046 2.42188 4.04847 2.42188 4.06798C2.42188 4.08749 2.43239 4.10549 2.44941 4.11514L11.8076 9.49862C11.8244 9.50888 11.8454 9.50888 11.8621 9.49862L21.2256 4.11514C21.2432 4.10646 21.2547 4.08901 21.2558 4.06948C21.2569 4.04995 21.2473 4.03136 21.2308 4.02081Z"
        fill="#6A52FF"
      />
      <path
        d="M14.209 10.9392V13.5055C14.2092 13.5249 14.2196 13.5426 14.2364 13.5522C14.2533 13.5618 14.2739 13.5617 14.2907 13.5521L23.6542 8.1686C23.6711 8.15919 23.6815 8.14137 23.6814 8.12208V5.61901C23.6815 5.58017 23.6608 5.54423 23.6271 5.52476C23.5933 5.50529 23.5517 5.50525 23.5179 5.52467L14.2635 10.8448C14.2297 10.8643 14.2089 10.9003 14.209 10.9392Z"
        fill="#6A52FF"
      />
      <path
        d="M14.2907 18.9964L23.6542 13.6129C23.6709 13.6027 23.6812 13.5847 23.6814 13.5651V11.0646C23.6815 11.0258 23.6608 10.9898 23.6271 10.9704C23.5933 10.9509 23.5517 10.9509 23.5179 10.9703L14.2635 16.2917C14.2295 16.311 14.2086 16.3471 14.209 16.3861V18.9512C14.2096 18.9702 14.2202 18.9876 14.237 18.9969C14.2537 19.0061 14.2741 19.0059 14.2907 18.9964Z"
        fill="#6A52FF"
      />
      <path
        d="M11.8405 22.9415V12.3012C11.8409 12.2622 11.8201 12.2261 11.786 12.2069L9.63592 10.9702C9.60215 10.9508 9.56052 10.9508 9.52678 10.9703C9.49303 10.9897 9.47231 11.0257 9.47242 11.0645V21.5808C9.47254 21.6196 9.45181 21.6555 9.41807 21.675C9.38432 21.6945 9.3427 21.6945 9.30893 21.6751L7.15882 20.4384C7.12499 20.419 7.1042 20.383 7.10432 20.3441V9.57714C7.10444 9.53824 7.08365 9.50226 7.04982 9.48281L4.89971 8.24743C4.86593 8.22801 4.82431 8.22804 4.79057 8.24751C4.75682 8.26698 4.73609 8.30292 4.73621 8.34176V18.8567C4.73633 18.8956 4.7156 18.9315 4.68186 18.951C4.64811 18.9704 4.60649 18.9705 4.57272 18.9511L2.42261 17.7157C2.38878 17.6962 2.36799 17.6602 2.36811 17.6213V6.84922C2.36822 6.81032 2.34743 6.77434 2.31361 6.75489L0.163497 5.52467C0.129722 5.50525 0.0881023 5.50529 0.0543566 5.52476C0.020611 5.54423 -0.000117278 5.58017 6.92109e-07 5.61901V19.0144C-9.78843e-05 19.0337 0.01034 19.0515 0.0272501 19.0609L11.8081 25.8374C11.8248 25.8477 11.8459 25.8477 11.8626 25.8374L23.6486 19.0609C23.6655 19.0515 23.676 19.0337 23.6759 19.0144V16.5113C23.676 16.4725 23.6553 16.4365 23.6215 16.4171C23.5878 16.3976 23.5461 16.3976 23.5124 16.417L12.004 23.0358C11.9703 23.0552 11.9286 23.0552 11.8949 23.0357C11.8611 23.0163 11.8404 22.9803 11.8405 22.9415Z"
        fill="#6A52FF"
      />
    </svg>
  )
}

const BeamsLogo = () => {
  return (
    <svg width="107" height="28" viewBox="0 0 107 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6506 5.7014L9.52966 6.9333C9.49647 6.95258 9.47597 6.98816 9.47583 7.02671V25.9249C9.47613 25.944 9.48635 25.9615 9.50275 25.971L11.7313 27.2652C11.7478 27.2748 11.7682 27.2748 11.7848 27.2654C11.8014 27.2559 11.8118 27.2383 11.8121 27.2191V5.79481C11.8119 5.75618 11.7914 5.72056 11.7581 5.7013C11.7248 5.68205 11.6838 5.68209 11.6506 5.7014Z"
        fill="#2AE9AA"
      />
      <path
        d="M14.2774 25.7937V23.3745C14.2771 23.3358 14.2973 23.2999 14.3304 23.2804C14.3634 23.2609 14.4043 23.2608 14.4375 23.2802L16.4313 24.4433C16.4638 24.463 16.4838 24.4986 16.4838 24.5369C16.4838 24.5753 16.4638 24.6109 16.4313 24.6306L14.3575 25.8436C14.3405 25.8535 14.3194 25.8532 14.3027 25.8428C14.286 25.8324 14.2763 25.8135 14.2774 25.7937Z"
        fill="#2AE9AA"
      />
      <path
        d="M14.1478 20.4191V17.944C14.148 17.9057 14.1689 17.8703 14.2027 17.8512C14.2365 17.8321 14.2781 17.8321 14.3119 17.8513L21.1012 21.7037C21.1354 21.7228 21.1564 21.7585 21.1564 21.7971C21.1564 21.8357 21.1354 21.8713 21.1012 21.8905L18.9206 23.1294C18.9036 23.1387 18.8829 23.1387 18.8659 23.1294L14.1752 20.4675C14.1572 20.458 14.1465 20.4391 14.1478 20.4191Z"
        fill="#2AE9AA"
      />
      <path
        d="M14.2774 15.0086V12.5173C14.277 12.4784 14.2977 12.4424 14.3315 12.4228C14.3654 12.4033 14.4072 12.4032 14.4411 12.4226L23.6975 17.719C23.7314 17.7388 23.7522 17.7748 23.7521 17.8137V20.3673C23.7523 20.3867 23.7419 20.4047 23.725 20.4145C23.7081 20.4243 23.6872 20.4243 23.6702 20.4146L14.3047 15.0559C14.2874 15.0464 14.2769 15.0282 14.2774 15.0086Z"
        fill="#2AE9AA"
      />
      <path
        d="M14.2773 9.58038V7.08903C14.277 7.0502 14.2977 7.01416 14.3315 6.9946C14.3654 6.97503 14.4072 6.97494 14.4411 6.99436L23.6975 12.2908C23.7314 12.3099 23.7523 12.3456 23.7521 12.3841V14.9391C23.7523 14.9585 23.7419 14.9765 23.725 14.9863C23.7081 14.9961 23.6872 14.9961 23.6702 14.9864L14.3046 9.62501C14.288 9.61616 14.2776 9.59906 14.2773 9.58038Z"
        fill="#2AE9AA"
      />
      <path
        d="M6.97893 8.41349L4.85568 9.64537C4.82223 9.66445 4.80161 9.70015 4.80176 9.73878V23.211C4.80206 23.23 4.8123 23.2475 4.82872 23.257L7.05577 24.5511C7.07272 24.5607 7.0934 24.5605 7.11025 24.5508C7.12709 24.5411 7.13763 24.5233 7.138 24.5038V8.50825C7.13705 8.47054 7.11686 8.43596 7.08455 8.41671C7.05224 8.39746 7.01234 8.39625 6.97893 8.41349Z"
        fill="#2AE9AA"
      />
      <path
        d="M2.17062 11.1316L0.0539015 12.3627C0.0204852 12.3817 -0.000103667 12.4174 4.09983e-05 12.456V20.4959C-0.000740985 20.5156 0.00971518 20.534 0.0269712 20.5433L2.25545 21.8366C2.27219 21.8463 2.29282 21.8463 2.30952 21.8365C2.32622 21.8267 2.33641 21.8087 2.33624 21.7893V11.2263C2.3366 11.1866 2.31551 11.1497 2.28112 11.1301C2.24674 11.1104 2.20445 11.111 2.17062 11.1316Z"
        fill="#2AE9AA"
      />
      <path
        d="M0.0005809 9.37832V6.82351C0.000139741 6.78482 0.0211368 6.74899 0.0552989 6.73014L11.8238 0.0142972C11.8577 -0.00476574 11.8993 -0.00476574 11.9332 0.0142972L23.6976 6.73285C23.7317 6.7517 23.7527 6.78752 23.7523 6.82622V9.37832C23.7525 9.39774 23.7421 9.41577 23.7251 9.42556C23.7082 9.43535 23.6872 9.43539 23.6702 9.42568L11.9058 2.70848C11.8888 2.69908 11.8681 2.69908 11.8511 2.70848L0.0867617 9.42568C0.069594 9.43767 0.0470028 9.43887 0.0286333 9.42878C0.0102637 9.41868 -0.000638621 9.39907 0.0005809 9.37832Z"
        fill="#2AE9AA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4341 6.5199H41.8652C44.426 6.5199 46.2665 8.1847 46.2665 10.4816C46.2665 11.6823 45.6954 12.6789 44.7648 13.2411V13.2838C46.0341 13.8732 46.8168 15.1386 46.8168 16.6767C46.8168 19.7788 44.9763 21.2691 41.189 21.2691H35.4341V6.5199ZM41.4223 12.3969C42.5437 12.3969 43.1576 11.8489 43.1576 10.8381C43.1576 9.848 42.5437 9.29996 41.4223 9.29996H38.5669V12.4021L41.4223 12.3969ZM43.7077 16.6532C43.7077 17.8967 43.0938 18.4861 41.7608 18.4861L38.5654 18.4822V14.8631H41.7816C43.0302 14.8631 43.7077 15.4899 43.7077 16.6532Z"
        fill="#300D4F"
      />
      <path
        d="M49.8616 6.51831H59.4051V9.38389H52.9934V12.3761H58.834V15.115H52.9934V18.4097H59.4259V21.2753H49.8616V6.51831Z"
        fill="#300D4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.0104 18.972H70.681L71.4585 21.2676H74.5735L69.2845 6.51831H66.407L61.1167 21.2689H64.2317L65.0104 18.972ZM67.8649 10.7967L69.7274 16.2332H65.9608L67.8233 10.7967H67.8649Z"
        fill="#300D4F"
      />
      <path
        d="M76.8792 6.51831H80.3485L84.5018 16.4425H84.5433L88.707 6.51831H92.1763V21.2689H89.1081V12.1667H89.0666L85.1949 21.2689H83.8399L79.9889 12.1667H79.9474V21.2689H76.8792V6.51831Z"
        fill="#300D4F"
      />
      <path
        d="M98.0578 15.7484C98.4602 17.9457 99.3272 18.7833 101.295 18.7833C102.755 18.7833 103.58 18.2559 103.58 17.3292C103.58 16.2951 102.882 15.791 100.406 14.9483C96.9572 13.785 95.6671 12.5455 95.6671 10.3753C95.6671 7.91039 97.7437 6.20422 100.681 6.20422C103.58 6.20422 105.844 7.95304 106.31 10.5446L103.262 11.1909C102.86 9.69539 102.036 9 100.681 9C99.5128 9 98.7561 9.52607 98.7561 10.3481C98.7561 11.1069 99.3908 11.5282 101.591 12.2663C103.431 12.8983 104.764 13.5924 105.548 14.3731C106.332 15.1538 106.716 16.1219 106.716 17.3227C106.716 19.8716 104.494 21.5791 101.257 21.5791C97.7243 21.5791 95.5243 19.7695 94.9948 16.396L98.0578 15.7484Z"
        fill="#300D4F"
      />
    </svg>
  )
}
