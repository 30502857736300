import React from 'react'
import loadable from '@loadable/component'

const TooltipLoadable = loadable(() => import('./TooltipLoadable'))

const Tooltip = ({ row, content, withIcon = true, place = 'right', goToSupportPackages }) => {
  return (
    <TooltipLoadable
      row={row}
      content={content}
      withIcon={withIcon}
      place={place}
      goToSupportPackages={goToSupportPackages}
    />
  )
}

export default Tooltip
