import React from 'react'
import { Script } from 'gatsby'

const ThirdPartyScripts = () => {
  return (
    <>
      <Script id="segment" async>
        {`!(function() {
            var analytics = (window.analytics = window.analytics || [])
            if (!analytics.initialize)
              if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.')
              else {
                analytics.invoked = !0
                analytics.methods = [
                  'trackSubmit',
                  'trackClick',
                  'trackLink',
                  'trackForm',
                  'pageview',
                  'identify',
                  'reset',
                  'group',
                  'track',
                  'ready',
                  'alias',
                  'debug',
                  'page',
                  'once',
                  'off',
                  'on',
                ]
                analytics.factory = function(t) {
                  return function() {
                    var e = Array.prototype.slice.call(arguments)
                    e.unshift(t)
                    analytics.push(e)
                    return analytics
                  }
                }
                for (var t = 0; t < analytics.methods.length; t++) {
                  var e = analytics.methods[t]
                  analytics[e] = analytics.factory(e)
                }
                analytics.load = function(t, e) {
                  var n = document.createElement('script')
                  n.type = 'text/javascript'
                  n.async = !0
                  n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'
                  var a = document.getElementsByTagName('script')[0]
                  a.parentNode.insertBefore(n, a)
                  analytics._loadOptions = e
                }
                analytics.SNIPPET_VERSION = '4.1.0'
                analytics.load('${
                  process.env.NODE_ENV === 'production'
                    ? 'qxc7FKqY7l22t25UgWru81Q7sDwLx04G'
                    : 'FusCqPwz72zbk0Anj8QYjI4KWoXlVmZS'
                }')
                analytics.page()
              }
          })()`}
      </Script>
      <Script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          gtag('consent', 'default', {
            'ad_storage': 'denied'
          });
          `}
      </Script>
      <Script id="gtm-datalayer" async>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-PK3QL4M');`}
      </Script>
      <Script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/6428535.js"></Script>
      <Script type="text/javascript">
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "i9dkv6jyk0");`}
      </Script>
    </>
  )
}

export default ThirdPartyScripts
