import React from 'react'
import styled, { css } from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { CustomLink } from '../utils/linkResolver'

import Container from '../styles/Container'
import { Heading3, Heading4, Paragraph, StyledLinkCaretComponent } from '../styles/Text'
import { Button } from '../styles/Buttons'
import theme from '../styles/theme'

const OuterContainer = styled.div`
  position: relative;
  overflow-y: visible;
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 72px;
  @media (min-width: ${p => p.theme.breakpointMd}) {
    padding-bottom: 64px;
  }
  @media (min-width: ${p => p.theme.breakpointXl}) {
    padding-top: 32px;
    padding-bottom: 64px;
  }
`

const CustomHeading3 = styled(Heading3)`
  margin-bottom: 32px;
  @media (min-width: ${p => p.theme.breakpointMd}) {
    max-width: 500px;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  transition: opacity 500ms ease;
  opacity: 0;
  ${p => p.inView && 'opacity: 1;'}
  a {
    display: flex;
    :not(:last-child) {
      margin-bottom: 32px;
    }
  }
  @media (min-width: ${p => p.theme.breakpointMd}) {
    flex-direction: row;
    a {
      width: calc((100% - 60px) / 3);
      :not(:last-child) {
        margin-bottom: 0px;
        margin-right: 30px;
      }
      h4,
      p,
      span,
      svg,
      rect {
        transition: color 0.15s ease-in, fill 0.15s ease-in;
      }
      ${p =>
        p.boxColor !== 'green' &&
        css`
          :hover {
            h4,
            p,
            span {
              color: ${p => p.theme.purpleLight};
            }
            rect {
              fill: ${p => p.theme.purpleLight};
            }
          }
        `}
    }
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${p => p.theme.purpleGreyDark};
  border-radius: 6px;
  border: 2px solid ${p => p.theme.blueLight};
  ${Button} {
    width: 100%;
  }
  p {
    margin-bottom: 16px;
  }
  ${p => p.boxColor === 'green' && `background-color: ${p.theme.green}; border: 0px;`}
  @media (min-width: ${p => p.theme.breakpointMd}) {
  }
`

const CustomHeading4 = styled(Heading4)`
  margin-bottom: 32px;
`

const LinkText = styled.div`
  margin-top: auto;
  align-self: flex-start;
`

const LinkBoxes = ({ heading, boxColor, items }) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })
  return (
    <OuterContainer>
      <CustomContainer>
        {heading && <CustomHeading3>{heading}</CustomHeading3>}
        {items && (
          <ItemsContainer ref={inViewRef} inView={inView} boxColor={boxColor}>
            {items.map(({ heading, description, button_text, link }, index) => {
              return (
                <CustomLink key={index} link={link}>
                  <Item boxColor={boxColor}>
                    {heading && <CustomHeading4>{heading}</CustomHeading4>}
                    {description && <Paragraph>{description}</Paragraph>}
                    {button_text && (
                      <LinkText link={link}>
                        <StyledLinkCaretComponent color={boxColor && theme.textColor}>
                          {button_text}
                        </StyledLinkCaretComponent>
                      </LinkText>
                    )}
                  </Item>
                </CustomLink>
              )
            })}
          </ItemsContainer>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default LinkBoxes
