import React from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash.get'

import { CustomLink } from '../utils/linkResolver'

import PrismicRichText from './PrismicRichText'

import Container from '../styles/Container'
import { Heading2, Heading3, Heading4, Heading5, Heading5Styles, Paragraph } from '../styles/Text'
import { Button } from '../styles/Buttons'

const OuterContainer = styled.div`
  ${(p) =>
    p.version === 'v4' &&
    'background-color: #ff8de2; background-image: url("/images/pink-background-pattern.png"); background-size: cover; background-position-x: center; background-repeat: no-repeat;'}
`

const CustomContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > a {
    align-self: stretch;
  }
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    > a {
      align-self: initial;
    }
  }

  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    padding-top: 64px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-bottom: 54px;
    ${(p) => p.version === 'v2' && 'padding-top: 24px;'}
  }
`

const CustomHeading = styled(Heading2)`
  margin-bottom: 16px;
`

const CustomHeading4 = styled(Heading4)`
  max-width: 686px;
  padding-top: 32px;
  ${(p) =>
    p.version === 'v4' &&
    css`
      max-width: 800px;
      padding-top: 0px;
      ${Heading5Styles}
      font-weight: 600;
    `}
`

const CustomHeading3 = styled(Heading3)``

const CustomParagraph = styled(Paragraph)`
  max-width: 462px;
`

const IconContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const RowWrapper = styled.div`
  max-width: 288px;
  ${(p) =>
    p.version === 'v4' &&
    css`
      a:not(:last-child) {
        margin-right: 16px;
      }
    `}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    display: flex;
    max-width: initial;
    ${(p) => p.aboveNineItems && 'display: flex; flex-wrap: wrap; max-width: 406px;'}
    a {
      position: relative;
      ${(p) =>
        p.version === 'v3' &&
        css`
          :not(:last-child) {
            margin-right: 16px;
          }
        `}
      :hover {
        img:nth-child(1) {
          opacity: 0;
        }
        img:nth-child(2) {
          opacity: 1;
        }
      }
    }
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: initial;
  }
`

const Icon = styled.img`
  height: 96px;
  ${(p) => p.version === 'v4' && 'height: 44px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 67px;
    ${(p) => p.aboveNineItems && 'height: 81px;'}
    ${(p) => !p.hasHoverIcon && 'opacity: 1 !important; '}
    transition: ease-in-out 0.15s;
    ${(p) => p.version === 'v4' && 'height: 44px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    height: 81px;
    ${(p) => p.version === 'v4' && 'height: 44px;'}
  }
`

const IconHover = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  height: 96px;
  ${(p) => p.version === 'v4' && 'height: 44px;'}
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    height: 67px;
    ${(p) => p.aboveNineItems && 'height: 81px;'}
    transition: ease-in-out 0.15s;
    ${(p) => p.version === 'v4' && 'height: 44px;'}
  }
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    height: 81px;
    ${(p) => p.version === 'v4' && 'height: 44px;'}
  }
`

const RowHeading = styled(Heading5)`
  margin-bottom: 16px;
  ${(p) => p.row === '2' && 'margin-top: 24px;'}
`

const AdditionalText = styled.div`
  max-width: 520px;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
  text-align: center;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
  }

  p {
    margin-bottom: 16px;
  }
  p:last-of-type {
    margin-bottom: 0px;
  }
`

const Libraries = ({
  largeHeading,
  smallHeading,
  items,
  buttonText,
  link,
  version,
  row1Heading,
  row2Heading,
  additionalText,
  productPage,
}) => {
  const row1Items = items.filter((item) => item.row === '1' || item.row === null)
  const row2Items = items.filter((item) => item.row === '2')

  return (
    <OuterContainer version={version}>
      <CustomContainer version={version}>
        {largeHeading &&
          (version === 'v2' || version === 'v4' ? (
            <CustomHeading center>{largeHeading}</CustomHeading>
          ) : (
            <CustomHeading3 center>{largeHeading}</CustomHeading3>
          ))}
        {smallHeading &&
          (version === 'v2' ? (
            <CustomParagraph center>{smallHeading}</CustomParagraph>
          ) : (
            <CustomHeading4 center version={version}>
              {smallHeading}
            </CustomHeading4>
          ))}

        {row1Items.length || row2Items.length ? (
          <IconContainer>
            <Row version={version} items={row1Items} heading={row1Heading} row="1" />
            <Row version={version} items={row2Items} heading={row2Heading} row="2" />
          </IconContainer>
        ) : null}
        {buttonText && (
          <CustomLink link={link}>
            <Button
              fullWidthOnSmall
              green={productPage === 'beams' ? true : false}
              dark={productPage === 'beams' ? false : true}
            >
              {buttonText}
            </Button>
          </CustomLink>
        )}
        {additionalText.richText[0]?.text && (
          <AdditionalText>
            <PrismicRichText text={additionalText} />
          </AdditionalText>
        )}
      </CustomContainer>
    </OuterContainer>
  )
}

export default Libraries

const Row = ({ version, items, heading, row }) => {
  const itemsCount = get(items, 'length')
  const aboveNineItems = itemsCount > 9
  return (
    <>
      {heading && <RowHeading row={row}>{heading}</RowHeading>}
      <RowWrapper version={version} aboveNineItems={aboveNineItems}>
        {items &&
          items.map(({ library_icon, library_icon_hover, link }, index) => {
            const iconUrl = get(library_icon, 'url')
            const iconHoverUrl = get(library_icon_hover, 'url')
            return (
              <CustomLink link={link} key={index}>
                <Icon
                  version={version}
                  hasHoverIcon={iconHoverUrl}
                  aboveNineItems={aboveNineItems}
                  src={iconUrl}
                  alt=""
                />
                {iconHoverUrl && (
                  <IconHover version={version} aboveNineItems={aboveNineItems} src={iconHoverUrl} alt="" />
                )}
              </CustomLink>
            )
          })}
      </RowWrapper>
    </>
  )
}
