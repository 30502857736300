import React from 'react'
import styled from 'styled-components'

import { CustomLink } from '../../utils/linkResolver'
import PrismicRichText from '../PrismicRichText'

import LandingPageContainer from '../../styles/LandingPageContainer'
import { Button } from '../../styles/Buttons'

const CustomContainer = styled(LandingPageContainer)`
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    padding-top: 40px;
  }
`

const Box = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #f3f0ff;
  :first-child {
    margin-bottom: 16px;
    background-color: ${(p) => p.theme.textColor};
    color: white;
  }
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    display: flex;
    flex-direction: column;
    grid-column: span 6;
    ${(p) => !p.showBox2 && 'grid-column: 2/12;'}
    :first-child {
      margin-bottom: 0px;
    }
  }
`

const Heading = styled.h2`
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 39px;
  line-height: 124%;
  letter-spacing: 0.25px;
`

const Subheading = styled.p`
  margin-top: 24px;
  font-family: 'Maison Neue';
  font-weight: 600;
  font-size: 20px;
  line-height: 164%;
  letter-spacing: 0.25px;
`

const Text = styled.div`
  margin-top: 24px;
  ul {
    :not(:first-child) {
      margin-top: 16px;
    }
    li {
      position: relative;
      padding-left: 23px;
      :not(:first-child) {
        margin-top: 8px;
      }
      ::before {
        content: '';
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        border-radius: 100%;
        margin-right: 14px;
        position: absolute;
        left: 0px;
        top: 7px;
        background-color: ${(p) => p.theme.textColor};
        ${(p) => p.white && 'background-color: white;'}
      }
    }
  }
`

const ButtonWrapper = styled.div`
  margin-top: auto;
  padding-top: 24px;
`

const DoubleCTAs = ({ slice }) => {
  const id = { ...(slice?.primary?.id && { id: slice?.primary?.id }) }
  const heading1 = slice?.primary?.heading_1
  const subheading1 = slice?.primary?.subheading_1
  const text1 = slice?.primary?.text_1
  const button1Text = slice?.primary?.button_1_text
  const button1Link = slice?.primary?.button_1_link
  const heading2 = slice?.primary?.heading_2
  const subheading2 = slice?.primary?.subheading_2
  const text2 = slice?.primary?.text_2
  const button2Text = slice?.primary?.button_2_text
  const button2Link = slice?.primary?.button_2_link
  const showBox2 = heading2 ? true : false

  return (
    <CustomContainer {...id}>
      <Box showBox2={showBox2}>
        {heading1 && <Heading>{heading1}</Heading>}
        {subheading1 && <Subheading>{subheading1}</Subheading>}
        {text1 && (
          <Text white>
            <PrismicRichText text={text1} />
          </Text>
        )}
        {button1Text && (
          <ButtonWrapper>
            <CustomLink link={button1Link}>
              <Button reversedWhite textColor="#300D4F">
                {button1Text}
              </Button>
            </CustomLink>
          </ButtonWrapper>
        )}
      </Box>
      {showBox2 && (
        <Box>
          {heading2 && <Heading>{heading2}</Heading>}
          {subheading2 && <Subheading>{subheading2}</Subheading>}
          {text1 && (
            <Text>
              <PrismicRichText text={text2} />
            </Text>
          )}
          {button2Text && (
            <ButtonWrapper>
              <CustomLink link={button2Link}>
                <Button dark>{button2Text}</Button>
              </CustomLink>
            </ButtonWrapper>
          )}
        </Box>
      )}
    </CustomContainer>
  )
}

export default DoubleCTAs
