import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash.get'
import useEmblaCarousel from 'embla-carousel-react'

import useInterval from '../hooks/useInterval'

import Container from '../styles/Container'
import { Heading4, Paragraph } from '../styles/Text'

const OuterContainer = styled.div`
  background-color: #f0f1f6;
  & + div.footer > div {
    margin-top: 0px;
  }
`

const CustomContainer = styled(Container)`
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 0px;
  padding-right: 0px;
  @media (min-width: ${(p) => p.theme.breakpointMd}) {
    padding-left: 45px;
    padding-right: 45px;
  }
  @media (min-width: ${(p) => p.theme.breakpointXl}) {
    padding-left: 85px;
    padding-right: 85px;
  }
`

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    max-width: 100%;
  }
`

const EmblaCarouselReact = styled.div`
  overflow: hidden;
`

const CarouselInner = styled.div`
  align-items: center;
`

const Slide = styled.div`
  min-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
`

const Quote = styled(Heading4)`
  margin-bottom: 16px;
  max-width: 556px;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: ${(p) => p.theme.breakpointLg}) {
    max-width: 680px;
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 170%;
  }
`

const Image = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 22px;
  max-width: 200px;
`

const DotButtonContainer = styled.div`
  margin-top: 42px;
  margin-left: auto;
  margin-right: auto;
`

const DotButton = styled.button`
  width: 12px;
  height: 12px;
  transition: all 0.1s ease;
  background-color: #c1c4d3;
  border-radius: 50%;
  :not(:last-child) {
    margin-right: 12px;
  }
  ${(p) =>
    p.selected &&
    css`
      background-color: ${(p) => p.theme.textColor};
    `}
`

const Testimonials = ({ items }) => {
  const itemsCount = get(items, 'length')

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false })

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [isRunning, setIsRunning] = useState(true)
  const delay = 8000

  const scrollTo = useCallback((index) => emblaApi.scrollTo(index), [emblaApi])
  const scrollNext = useCallback(() => emblaApi.scrollNext(), [emblaApi])
  const stopAutoplay = () => setIsRunning(false)

  useInterval(
    () => {
      if (itemsCount > 1) {
        if (selectedIndex === scrollSnaps.length - 1) {
          scrollTo(0)
        } else {
          scrollNext()
        }
      }
    },
    isRunning ? delay : null
  )

  useEffect(() => {
    const onSelect = () => {
      setSelectedIndex(emblaApi.selectedScrollSnap())
    }
    if (emblaApi) {
      setScrollSnaps(emblaApi.scrollSnapList())
      emblaApi.on('select', onSelect)
      onSelect()
    }
    return () => emblaApi && emblaApi.destroy()
  }, [emblaApi])

  return (
    <OuterContainer>
      <CustomContainer>
        <CarouselContainer>
          <EmblaCarouselReact ref={emblaRef}>
            <CarouselInner style={{ display: 'flex' }}>
              {items &&
                items.map((item, index) => {
                  const { image, quote, name, details } = item
                  const imageUrl = get(image, 'url')
                  return (
                    <Slide key={index}>
                      {imageUrl && <Image src={imageUrl} alt="" />}
                      {quote && (
                        <Quote center as="blockquote">
                          {quote}
                        </Quote>
                      )}
                      {name && (
                        <Paragraph center bold>
                          {name}
                        </Paragraph>
                      )}
                      {details && <Paragraph center>{details}</Paragraph>}
                    </Slide>
                  )
                })}
            </CarouselInner>
          </EmblaCarouselReact>
          <DotButtonContainer>
            {scrollSnaps.map((snap, index) => {
              return (
                <DotButton
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => {
                    scrollTo(index)
                    stopAutoplay()
                  }}
                  aria-label="Change slide"
                ></DotButton>
              )
            })}
          </DotButtonContainer>
        </CarouselContainer>
      </CustomContainer>
    </OuterContainer>
  )
}

export default Testimonials
