import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import LandingPageContainer from '../../styles/LandingPageContainer'

import logo from '../../images/pusher-logo.svg'
import twitterLogo from '../../images/twitter-logo.svg'
import youtubeLogo from '../../images/youtube-logo.svg'
import linkedinLogo from '../../images/linkedin-logo.svg'
import githubLogo from '../../images/github-logo.svg'
import twitchLogo from '../../images/twitch-logo.svg'
import discordLogo from '../../images/discord-logo.svg'

const CustomContainer = styled(LandingPageContainer)`
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    padding-top: 64px;
    padding-bottom: 56px;
  }
`

const Wrapper = styled.div`
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const Logo = styled.img`
  height: 54px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    height: 62px;
  }
`

const SocialMediaContainer = styled.div`
  margin-top: 48px;
  ul {
    display: flex;
  }
  li {
    margin-right: 18px;
  }
  li:last-of-type {
    margin-right: 0;
  }
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    margin-top: 0px;
  }
`

const Copyright = styled.p`
  margin-top: 24px;
  font-family: 'Maison Neue';
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.25px;
  @media (min-width: ${(p) => p.theme.breakpointMdLg}) {
    margin-top: 48px;
  }
`

const Footer = () => {
  return (
    <div>
      <CustomContainer>
        <Wrapper>
          <Link to="/">
            <Logo src={logo} alt="Pusher" />
          </Link>
          <SocialMediaContainer>
            <ul>
              <li>
                <a href="https://twitter.com/pusher" target="_blank" rel="noopener noreferrer">
                  <img src={twitterLogo} alt="Twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCoyqucxoFXDFnh3khD0rjUg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={youtubeLogo} alt="Youtube" />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/pusher-app/" target="_blank" rel="noopener noreferrer">
                  <img src={linkedinLogo} alt="LinkedIn" />
                </a>
              </li>
              <li>
                <a href="https://github.com/pusher" target="_blank" rel="noopener noreferrer">
                  <img src={githubLogo} alt="Github" />
                </a>
              </li>
              <li>
                <a href="https://www.twitch.tv/pusherdev" target="_blank" rel="noopener noreferrer">
                  <img src={twitchLogo} alt="Twitch" />
                </a>
              </li>
              <li>
                <a href="https://discord.gg/pEWjmP9uA7" target="_blank" rel="noopener noreferrer">
                  <img src={discordLogo} alt="Discord" />
                </a>
              </li>
            </ul>
          </SocialMediaContainer>
        </Wrapper>
        <Copyright>
          © {new Date().getFullYear()} Pusher Ltd. All rights reserved.
          <br />
          Pusher Limited is a company registered in England and Wales (No. 07489873) whose registered office is at
          Eighth Floor 6 New Street Square, New Fetter Lane, London, England, EC4A 3AQ.
        </Copyright>
      </CustomContainer>
    </div>
  )
}

export default Footer
